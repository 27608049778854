.bill-table {
  text-align: left;
  padding-top: 10px !important;
}
.bill-table .select-all-lines-input {
  margin-right: 15px;
}
.bill-table .no-message {
  color: #777;
}
.bill-table .actions {
  width: 170px;
}
.bill-table th.type {
  min-width: 100px;
}
.bill-table th.status {
  min-width: 130px;
}
.bill-table th .datepicker {
  background: #fff;
  padding-left: 5px;
}
.bill-table th .select2-container {
  font-weight: normal;
}
.bill-table .bill-status {
  line-height: 1em;
}
.bill-table .bill-status .--date {
  display: block;
  font-size: 11px;
  color: #777;
  margin-top: 2px;
}
.bill-table .color {
  margin-right: 4px;
}
.bill-table .color.red {
  color: rgba(238, 52, 50, 0.6);
}
.bill-table .color.green {
  color: #BEF0E0;
}
.bill-table .color.grey {
  color: #ccc;
}
.bill-table .color.yellow {
  color: rgba(241, 204, 0, 0.6);
}
.bill-table .--icon-red {
  color: #B41F1F;
}
.bill-table .new-billing-action {
  width: fit-content;
  margin: 20px 0 20px 20px;
}

.billing-ca {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 15px;
  justify-content: space-between;
  margin: 0 10px 10px 40px;
  gap: 40px;
}
.billing-ca span.--big {
  display: block;
  font-size: 20px;
  margin-top: 13px;
}
.billing-ca .section {
  width: 300px;
}
.billing-ca .current {
  text-transform: none;
  width: 100%;
}
.billing-ca .current th {
  color: #777;
  font-weight: normal;
  text-align: left;
}
.billing-ca .current td {
  text-align: right;
}

.main-content-new.--billing .actions-handler {
  display: flex;
  justify-content: center;
}
.main-content-new.--billing .actions-handler > a {
  margin-left: 20px;
}
.main-content-new.--billing .form-block.--margin-bottom {
  margin-bottom: 20px;
  padding: 10px;
}

#bill.main-content-new {
  width: 980px;
  margin: 0 auto;
  padding: 20px 0 100px 20px;
  display: inline-block;
}
#bill .select2-container {
  height: 26px;
}
#bill .select2-container.select2-container-disabled {
  background-color: #f4f4f4;
}
#bill .select2-container.select2-container-disabled .select2-choice {
  background: none;
  border: none;
}
#bill .select2-container .select2-choice {
  text-align: left;
}
#bill .section-content .information {
  gap: 12px;
}
#bill .section-content .information .type {
  min-width: 100px;
  text-align: left;
}
#bill .section-content .information .date {
  min-width: 130px;
}
#bill .section-content .information .datepicker {
  background: #fff;
  padding-left: 5px;
}
#bill .subject-wrapper {
  width: 70%;
  text-align: center;
}
#bill .billing-line-info-wrapper {
  width: 44%;
  margin-right: 30px;
}
#bill .billing-line-price-wrapper {
  width: 40%;
}
#bill .billing-line-price {
  margin-bottom: 15px;
}
#bill .billing-line-price input {
  width: 30%;
}
#bill .billing-line-description-input {
  margin-bottom: 15px;
}
#bill .remove-button-wrapper {
  margin: auto;
}

.--external-bill {
  max-width: 100%;
  margin: auto;
  width: 900px;
}

.bill-table.--external {
  max-width: 900px;
  margin: auto;
}
.bill-table.--external th {
  font-weight: normal;
  color: #777;
  text-align: center;
}
.bill-table.--external td {
  text-align: center;
}
.bill-table.--external td.actions a {
  margin: auto;
}