.new-select.select2-container {
  text-align: left;
  height: 26px;
}

.new-select .select2-choice {
  background-color: white;
  display: flex !important;
  border-radius: 0 !important;
}

.new-select.select2-container .select2-choice abbr {
  top: 5px;
}

.new-select .select2-arrow {
  float: none !important;
  margin-right: 0;
  margin-left: auto;
}

.new-select.select2-container .select2-choice > .select2-chosen {
  min-width: initial !important;
  max-width: initial !important;
  color: inherit;
}

.new-select-2.select2-container .select2-choice > .select2-chosen {
  min-width: initial !important;
  max-width: initial !important;
  color: inherit;
}