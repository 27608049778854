@import "colors";
$small: 1024px;
$smallMin: 1025px;
/**********************************  HOME  ***********************************/
.stat-dashboard {
  background: #fff;
  width: 100%;
  max-width: 1600px;
  padding: 20px 0;
  h3 {
    margin-bottom: 20px;
  }
  .dashboard-content {
    display: flex;
    @media (max-width: $small) {
      display: block;
    }
  }
  .left {
    width: calc(22% - 10px);
    @media (max-width: $small) {
      width: 100%;
    }
  }
  .right {
    width: calc(80% - 10px);
    margin-left: 20px;
    @media (max-width: $small) {
      width: 100%;
      margin-left: 0;
    }
  }

  .dashboard {
    margin-bottom: 20px;
    .--flex {
      gap: 40px;
    }

    .section.filter {
      margin-bottom: 56px;
    }

    .buttons {
      gap: 0;
      min-width: 0;
      width: 280px;
    }

  }

  .pie-chart {
    position: relative;
    .section-content {
      height: 275px;
    }
  }

  #transferPieChart {
    margin: auto;
    width: 100%!important;
    height: 100%!important;
    max-width: 200px!important;
    &.no-data {
      position: absolute;
      top: 45%;
      left: 0;
      right: 0;
    }
    .percent-inner {
      text-align: center;
      font-size: 40px;
      font-weight: bold;
      position: relative;
      top: -40%;
      height: 50px;
      &.no-data {
        font-size: 12px;
        font-weight: normal;
        top: -60%;
      }
      @media (max-width: 1351px) {
        top: -90px;
      }
    }
  }
  #transferRateChart {
    position: relative;
    z-index: 10;
    width: 100%!important;
  }

  .all-values {
    @media (min-width: $smallMin) {
      display: flex;
      gap: 40px;
    }
    @media (max-width: $small) {
      padding: 0 5%
    }
  }
  .active-values, .closed-values {
    .--flex {
      gap: 20px;
    }
    .--values {
      gap: 0 20px;
      @media (max-width: $small) {
        padding-left: 5%;
        padding-right: 5%;
        flex-wrap: wrap;
      }
    }
    .section {
      @media (max-width: $small) {
        margin-bottom: 10px;
        flex: 1 0 25%;
      }
      &.lead-confirmed-appointment {
        @media (max-width: $small) {
          flex: 1 1 100%;
        }
      }
    }
  }

  .active-values {
    @media (min-width: $smallMin) {
      width: 70%;
    }
  }
  .closed-values {
    @media (min-width: $smallMin) {
      width: 30%;
      .--values {
        flex-wrap: wrap;
      }
      .value {
        flex: 1 0 25%;
      }
    }
  }

  .value {
    padding: 10px;
    text-align: center;
    position: relative;
    cursor: pointer;
    gap: 0 !important;
    font-size: 13px;
    letter-spacing: 0.1rem;
    text-shadow: -1px 1px 0 rgba(150, 150, 150, 0.3),
    1px 1px 0 rgba(150, 150, 1500, 0.3);
    transition: 0.5s ease;
    @media (max-width: $small) {
      font-size: 12px;
      letter-spacing: 0;
    }
    &:hover {
      transition: 0.4s ease;
    }
    &:active {
      color: chartWhite() !important;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset,
      0 4px 4px rgba(0, 0, 0, .25);
      transition: 0.1s linear;
    }
    &.--check {
      color: chartWhite() !important;
      cursor: default;
      scale: 104%;
    }
    @media (min-width: $smallMin) {
      &.--half {
        width: 50%;
      }
      &.--third {
        width: 33.33%;
      }
    }
    .nb {
      font-size: 30px;
      display: block;
      text-align: center;
      font-weight: bold;
    }
    .text {
      width: 100%;
      font-weight: 500;
    }

    &.lead-waiting-result {
      background-color: chartFirework(.2);
      color: chartFirework();
      &:hover {
        background-color: chartFirework(.4);
      }
      &.--check {
        background-color: chartFirework(.8);
      }
    }
    &.lead-to-come {
      background-color: chartToCome(.2);
      color: chartToCome();
      &:hover {
        background-color: chartToCome(.4);
      }
      &.--check {
        background-color: chartToCome(.8);
      }
    }
    &.lead-received, &.lead-active, &.lead-closed {
      background-color: chartSky(.2);
      color: chartSky(.6);
      &:hover {
        background-color: chartSky(.4);
      }
      &.--check {
        background-color: chartSky(.8);
      }
    }
    &.lead-received {
      &.--check {
        scale: 101%;
      }
    }
    &.lead-assigned {
      background-color: chartAssigned(.2);
      color: chartAssigned();
      &:hover {
        background-color: chartAssigned(.4);
      }
      &.--check {
        background-color: chartAssigned(.8);
      }
    }
    &.lead-dms {
      background-color: chartDMS(.2);
      color: chartDMS();
      &:hover {
        background-color: chartDMS(.4);
      }
      &.--check {
        background-color: chartDMS(.8);
      }
    }
    &.lead-confirmed-appointment {
      background-color: chartAppointment(.2);
      color: chartAppointment();
      &:hover {
        background-color: chartAppointment(.4);
      }
      &.--check {
        background-color: chartAppointment(.8);
      }
    }
    &.lead-recontact, &.lead-offers-sent {
      background-color: chartRecontact(.2);
      color: rgba(247, 133, 155, 1);
      &:hover {
        background-color: chartRecontact(.4);
      }
      &.--check {
        background-color: chartRecontact(.8);
        color: chartWhite();
        cursor: default;
      }
    }
    &.lead-sale {
      background-color: chartSale(.2);
      color: chartSale();
      &:hover {
        background-color: chartSale(.4);
      }
      &.--check {
        background-color: chartSale(.8);
        color: chartWhite();
        cursor: default;
      }
    }
    &.lead-mandate {
      background-color: chartMandate(.2);
      color: chartMandate();
      &:hover {
        background-color: chartMandate(.4);
      }
      &.--check {
        background-color: chartMandate(.8);
        color: chartWhite();
        cursor: default;
      }
    }
    &.lead-lost {
      background-color: chartLost(.2);
      color: chartLost();
      &:hover {
        background-color: chartLost(.4);
      }
      &.--check {
        background-color: chartLost(.8);
        color: chartWhite();
        cursor: default;
      }
    }
    &.lead-abandoned {
      background-color: chartAbandoned(.2);
      color: chartAbandoned();
      &:hover {
        background-color: chartAbandoned(.4);
      }
      &.--check {
        background-color: chartAbandoned(.8);
        color: chartWhite();
        cursor: default;
      }
    }
  }
}

.comiseo-daterangepicker .ui-widget .ui-menu-item {
  background: $lightGreyBackground;
  &:hover {
    background: chartShimmer(.4);
  }
}
#contentFilter {
  background: none;
}
.stat-dashboard .filters {
  .interest {
    margin-top: -1px;
  }
  & > div {
    @media (min-width: $smallMin) {
      gap: 10px;
    }
    &.buttons {
      @media (max-width: $small) {
        flex-direction: column;
      }
    }
    & > div {
      gap: 2%;
      @media (max-width: $small) {
        display: block;
      }

      &.interest {
        margin-top: -1px;
      }
      & > div {
        width: 100%;
        max-width: 49%;
        min-width: 18%;
        @media (max-width: $small) {
          margin-bottom: 10px;
          max-width: none;
        }
      }
    }
  }
  .fields {
    width: 100%;
  }
  .hidden {
    input, select, .select2-container-multi .select2-choices .select2-search-field input,
    .select2-container.select2-container-disabled .select2-choice {
      background-color: $lightGreyBackground2!important;
      background-image: none!important;
      border: 1px solid #ddd!important;
      cursor: default!important;
    }
    .select2-container.select2-container-disabled .select2-choice .select2-arrow {
      background-color: $lightGreyBackground2!important;
      background-image: none!important;
      cursor: default!important;
      border: 0 !important;
      margin-right: 3px;
    }
  }
  input[type=text], input[type=number], input[type=url], input[type=password], input[type=email], input[type=phone],
  select[class=new-select], .select2-container:not(.select2-container-multi), .comiseo-daterangepicker-triggerbutton.ui-button,
  .select2-container .select2-choice > .select2-chosen, .select2-container:not(.select2-container-multi) .select2-choice,
  .select2-container-multi .select2-choices .select2-search-field input {
    height: 26px;
    line-height: 26px;
  }
  .select2-container-multi .select2-choices .select2-search-field input {
    width: 100%!important;
  }
  .select2-container-multi .select2-choices {
    min-height: 26px;
  }
  .select2-container-multi.select2-container-disabled .select2-choices {
    background: #fff;
    border: none;
  }
  .select2-container .select2-choice .select2-arrow b {
    margin-top: 8px;
  }
  .button-icon {
    margin-right: 0;
    width: 30px;
    height: 30px;
    border: 0;
    cursor: pointer;
  }
}

.board {
  .toggle-section-visibility {
    z-index: 50;
  }
  .section-content {
    @media (max-width: $small) {
      margin-top: 45px;
    }
  }
  .common-table {
    margin: -44px 0 0;
    position: relative;

    .pagination-wrapper {
      margin-right: 25px;
    }
    table {
      @media (max-width: $small) {
        margin: 0;
        width: 100%;
      }
    }
    td {
      @media (max-width: $small) {
        padding: 10px 5px;
        &:first-child {
          padding-left: 0;
        }
        &.actions {
          width: auto;
        }
      }
    }
    .no-result {
      td {
        border: none;
        text-align: center;
      }
    }
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
    th {
      color: $greyText2;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
    }
    .lead-checkbox-all {
      margin: 8px 5px 5px 30px;
      @media (max-width: $small) {
        margin-left: 0;
      }
    }
  }
  .common-loader {
    margin-bottom: 20px;
  }
  .id {
    color: $greyText2;
    font-size: 10px;
    text-align: center;
    width: 26px;
    @media (max-width: $small) {
      line-height: 14px;
    }
    span {
      margin: auto;
    }
    .days {
      @media (min-width: $smallMin) {
        display: none;
      }
    }
  }
  .seller-alias {
    display: block;
    color: $greyText2;
    font-size: 10px;
    line-height: 14px;
  }
  .interest, .subject {
    display: block;
    line-height: 14px;
  }
  .subject {
    color: $greyText2;
    font-size: 12px;
  }
  .customer {
    line-height: 14px;
    @media (max-width: $small) {
      width: 50%;
    }
    .subject {
      @media (min-width: $smallMin) {
        display: none;
      }
    }
  }
  .phone-link, .email-link {
    display: block;
    color: $greyText2;
  }
  .date {
    text-align: center;
  }
  .hour {
    display: block;
    color: $greyText2;
    font-size: 10px;
    line-height: 14px;
  }
  .status {
    text-align: center;
    font-weight: 500;
    white-space: nowrap;
  }
  .lost-reason, .appointment, .reminder {
    display: block;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    width: 100%;
  }
  @media (max-width: $small) {
    .pos-seller, .date, .demand, .id span:not(.days) {
      display: none;
    }
    .actions a:not(.--mobile) {
      display: none;
    }
  }
}
#exportLeads {
  margin-left: 20px;
}
#transferRate2 {
  @media (min-width: $smallMin) {
    display: none;
  }
}