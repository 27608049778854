.reassign-lead-form-wrapper {
  padding: 10px;
  max-height: 80vh;
  overflow: auto;
}
.reassign-lead-form-wrapper h4 {
  color: #777;
  font-size: 12px;
  margin-bottom: 10px;
}
.reassign-lead-form-wrapper .column {
  padding: 10px;
  text-align: center;
}
.reassign-lead-form-wrapper .form-block.--margin-bottom {
  margin-bottom: 20px;
}
.reassign-lead-form-wrapper .button-default {
  display: inline-block;
}
.reassign-lead-form-wrapper .reaffectPosLeadLine {
  border-top: 1px solid rgba(120, 120, 120, 0.4);
  padding: 10px;
  min-width: 440px;
}
.reassign-lead-form-wrapper .reaffectPosLeadLine-leftColumn {
  align-items: flex-start;
  justify-content: center;
}
.reassign-lead-form-wrapper .reaffectPosLeadLine-posName {
  font-weight: bold;
}
.reassign-lead-form-wrapper .reaffectPosLeadLine-posSelect {
  width: 200px !important;
}
.reassign-lead-form-wrapper .reaffectPosLeadLine-posSelect.--margin-bottom {
  margin-bottom: 10px;
}
.reassign-lead-form-wrapper .reaffectPosLeadLine-sellerSelectLoader {
  display: none;
}
.reassign-lead-form-wrapper .reaffectPosLeadLine-sellerSelectLoader img {
  width: 200px !important;
}
.reassign-lead-form-wrapper .confirm {
  margin-right: 20px;
}
.reassign-lead-form-wrapper .reassignPosBilling {
  width: 60% !important;
  margin: auto !important;
}