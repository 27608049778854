@import "colors";

.bill-table {
  text-align: left;
  padding-top: 10px !important;
  .select-all-lines-input {
    margin-right: 15px;
  }
  .no-message {
    color: #777;
  }
  .actions {
    width: 170px;
  }
  th {
    &.type {
      min-width: 100px;
    }
    &.status {
      min-width: 130px;
    }
    .datepicker {
      background: #fff;
      padding-left: 5px;
    }
    .select2-container {
      font-weight: normal;
    }
  }
  .bill-status {
    line-height: 1em;
    .--date {
      display: block;
      font-size: 11px;
      color: #777;
      margin-top: 2px;
    }
  }
  .color {
    margin-right: 4px;
    &.red {
      color: rgba(238, 52, 50, 0.6);
    }
    &.green {
      color: #BEF0E0;
    }
    &.grey {
      color: #ccc;
    }
    &.yellow {
      color: rgba(241, 204, 0, 0.6);
    }
  }
  .--icon-red {
    color: #B41F1F;
  }
  .new-billing-action {
    width: fit-content;
    margin: 20px 0 20px 20px;
  }
}

.billing-ca {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 15px;
  justify-content: space-between;
  margin: 0 10px 10px 40px;
  gap: 40px;
  span.--big {
    display: block;
    font-size: 20px;
    margin-top: 13px;
  }
  .section {
    width: 300px;
  }
  .current {
    text-transform: none;
    width: 100%;
    th {
      color: #777;
      font-weight: normal;
      text-align: left;
    }
    td {
      text-align: right;
    }
  }
}

// edit

.main-content-new.--billing {
  .actions-handler {
    display: flex;
    justify-content: center;
    &  > a {
      margin-left: 20px;
    }
  }
  .form-block.--margin-bottom {
    margin-bottom: 20px;
    padding: 10px;
  }
}
#bill {
  &.main-content-new {
    width: 980px;
    margin: 0 auto;
    padding: 20px 0 100px 20px;
    display: inline-block;
  }
  .select2-container {
    height: 26px;
    &.select2-container-disabled {
      background-color: $lightGreyBackground2;
      .select2-choice {
        background: none;
        border: none;
      }
    }
    .select2-choice {
      text-align: left;
    }
  }
  .section-content {
    .information {
      gap: 12px;
      .type {
        min-width: 100px;
        text-align: left;
      }
      .date {
        min-width: 130px
      }
      .datepicker {
        background: #fff;
        padding-left: 5px;
      }
    }
  }
  .subject-wrapper {
    width: 70%;
    text-align: center;
  }
  .billing-line-info-wrapper {
    width: 44%;
    margin-right: 30px;
  }
  .billing-line-price-wrapper {
    width: 40%;
  }
  .billing-line-price {
    margin-bottom: 15px;
    input {
      width: 30%;
    }
  }
  .billing-line-description-input {
    margin-bottom: 15px;
  }
  .remove-button-wrapper {
    margin: auto;
  }
}

.--external-bill {
  max-width: 100%;
  margin: auto;
  width: 900px;
}
.bill-table.--external {
  max-width: 900px;
  margin: auto;
  th {
    font-weight: normal;
    color: #777;
    text-align: center;
  }
  td {
    text-align: center;
    &.actions a {
      margin: auto;
    }
  }
}
