.custom-popin {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #f3f3f3;
  border: 1px solid rgba(119, 119, 119, 0.6);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 13;
}
.custom-popin .popin-content {
  margin: 0 20px;
  min-width: 300px;
  position: relative;
}

.custom-popin .loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8) url("../images/loader.svg") no-repeat center center;
  background-size: 100px;
}

.custom-popin .title {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.custom-popin .content {
  padding: 1em;
}

.custom-popin .content p {
  margin-bottom: 1em;
}

.custom-popin .confirm-popin, .custom-popin .popin-close {
  width: 100px;
  margin: 1em auto;
  text-align: center;
}

.popin-content .title {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  margin: 5px 0 10px 0;
}
.popin-content.section {
  max-width: 500px;
  margin: auto;
}
.popin-content .row {
  margin-bottom: 20px;
}
.popin-content .row .errors {
  margin-top: 5px;
}
.popin-content .popin-message {
  padding: 5px;
  border-radius: 2px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}
.popin-content .popin-message .alert-message {
  margin-top: 20px;
}
.popin-content .popin-message.title {
  text-transform: uppercase;
}
.popin-content .popin-message .valid, .popin-content .popin-message .restart {
  color: #158bdb;
}
.popin-content .popin-message .order {
  color: #a4c400;
}
.popin-content .popin-message .manage {
  color: #39c2d2;
}
.popin-content .popin-message .timer {
  color: #dc4fad;
}
.popin-content .popin-message .offer {
  color: #1ba1e2;
}
.popin-content .popin-message .lost-validate {
  color: #d75553;
}
.popin-content .popin-message .delete {
  color: #f4b90c;
}
.popin-content .datetime .date, .popin-content .datetime .hour, .popin-content .datetime .minute {
  width: 32%;
  margin-left: 1%;
  float: left;
}
.popin-content .datetime .date {
  width: 34%;
  margin-left: 0;
}
.popin-content .datetime .minute {
  margin-left: 0;
  float: right;
}
.popin-content input[type=text], .popin-content input[type=number], .popin-content input[type=url], .popin-content input[type=password], .popin-content input[type=email], .popin-content input[type=phone], .popin-content input[type=time], .popin-content select[class=new-select] {
  height: 26px;
}
.popin-content .datepicker {
  background: #fff;
  border: 0 !important;
}
.popin-content .select2-container {
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.45);
  text-align: left;
}
.popin-content textarea {
  height: 52px;
}
.popin-content .button {
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}
.popin-content .button.--cancel {
  background: #777;
}
.popin-content .button.--delete {
  background: rgb(238, 52, 50);
}
.popin-content .button.--confirm {
  background: rgb(0, 1, 107);
}

#reaffect {
  width: 300px;
  padding-bottom: 10px;
}

#lostDismiss {
  width: 300px;
}