@import "colors";

.common-table {
  width: 100%;
  margin: 0 10px 10px 40px;
  height: fit-content;
  padding: initial !important;
}
.common-table input[type='checkbox'] {
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.common-table .icon {
  font-size: 18px;
  cursor: pointer;
}
.common-table .actions {
  width: 108px;
  position: relative;
}
.common-table header, .common-table footer {
  margin: 20px 20px 0 30px;
  padding-bottom: 15px;
}
.common-table .pagination-wrapper {
  gap: 10px;
}
.common-table .icon-Arrow-Left-2, .common-table .icon-Arrow-Right-2 {
  padding-top: 3px;
}
.common-loader {
  text-align: center;
  padding: 20px 0;
  &.--hidden {
    display: none;
  }
}
.common-loader img {
  width: 100px;
}
.table-line__separator {
  border-bottom: 1px solid rgba(120,120,120,0.4);
  width: 95%;
  margin: auto;
}
.common-table .no-message {
  margin: 24px auto;
  text-align: center;
}
.tableLine {
  padding: 5px;
  min-height: 60px;
  margin: auto;
  width: 95%;
  text-align: center;
}
.tableLine .select-line {
  margin-right: 5px;
}
.tableLine:hover {
  background-color: rgba(190, 240, 224, 0.4);
}
.common-table table {
  margin: 0 20px 0 20px;
  width: calc(100% - 40px);
}
.common-table th {
  padding: 10px;
}
.common-table td {
  padding: 10px;
}
.common-table td {
  border-top: 1px solid rgba(120,120,120,0.4);
  border-bottom: 1px solid rgba(120,120,120,0.4);
}
.common-table .sort-arrow {
  display: block;
  cursor: pointer;
  color: #cfcfcf;
}
.common-table .sort-arrow.selected {
  color: chartSky();
}
.common-table .sort-arrow.sort-asc {
  margin-bottom: 1px;
}
.common-table .sort-arrow-wrapper {
  margin-left: 10px;
}
.common-table .--list-lead-link {
  border-radius: 1em;
  text-decoration: none;
  color: chartSky();
  padding : 0 .5em 0 .5em;
  transition: 0.1s;
}
.common-table .--list-lead-link:hover {
  background : chartSky(.3);
  transition: all 0.3s ease;
}
.common-table .--list-lead-link:active {
  background-color: chartSky(.3);
  transition: all 0.3s ease
}
