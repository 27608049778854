@import "colors";
/**** FORM LEAVE ****/
.seller-leave-list-section .common-loader {
  width: 50px;
}
.seller-form-leave-wrapper {
  display: flex;
  padding: 0 20px;
}
.seller-form-leave-wrapper input {
  width: 14%!important;
  min-width: 110px;
  margin-right: 50px;
}
.seller-form-leave-wrapper textarea {
  margin-right: 32px;
  height: 26px;
  width: 52%;
}
.seller-leave-list {
  margin: 10px;
  font-weight: 500;
}
.seller-leave-line {
  border-bottom: 1px solid rgba(120,120,120,0.4);
  padding: 10px 0;
}
.seller-leave-line__dates {
  width: 40%;
}
.seller-leave-line__dates p {
  margin-right: 30px;
}
.seller-leave-line__dates p span {
  margin-right: 10px;
}
.seller-leave-line__comment {
  text-align: left;
  width: 50%;
}
.seller-leave-line.--old .seller-leave-line__comment {
  width: 60%;
}
.seller-leave-line__comment-info {
  min-height: 24px;
  cursor: pointer;
}
.seller-leave-line__buttons {
  width: 10%;
  justify-content: flex-end;
}
.leave-error {
  color: red;
  text-align: center;
}
.seller-leave-list .none {
  color: rgba(119, 119, 119, 1);
  font-style: italic;
  text-align: center;
}
.seller-leave-line.--current {
  color: rgb(238, 52, 50);
}

@media (max-width: 1325px) {
  .seller-form-leave-wrapper {
    flex-direction: column;
    padding: 0 20px;
  }
  .seller-form-leave-wrapper input, .seller-form-leave-wrapper textarea {
    width: 100%;
  }
  .seller-form-leave-wrapper input, .seller-form-leave-wrapper textarea {
    margin-bottom: 10px;
  }
  .seller-leave-line, .seller-leave-line__dates, .seller-leave-line__buttons {
    display: block;
    width: 100%;
  }
  .seller-leave-line__comment {
    width: auto;
    text-align: center;
  }
  .seller-leave-line.--old .seller-leave-line__comment {
    width: auto;
  }
  .seller-form-leave-wrapper .button-icon, .seller-leave-line .button-icon {
    margin: auto;
  }
}

.seller-leaves {

  width: 980px;
  margin: 40px auto auto;

  .section {
    margin-bottom: 40px;
  }

  .section h3 {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }

  #contentFilter {
    background: $lightGreyBackground!important;
  }

  .pos, .user {
    width: 40%;
  }
  .pos {
    .select2-container {
      text-align: left;
    }
  }

  .button-icon {
    margin-right: 0;
    width: 30px;
    height: 30px;
    border: 0;
    cursor: pointer;
  }

  .filters .select2-container-multi .select2-choices {
    border: none;
    .select2-search-field input {
      height: 26px;
      line-height: 26px;
    }
  }
  .icon-cancel-circle, .icon-checkmark-circle {
    position: absolute;
    right: 30px;
    top: 5px;
    display: block;
    font-size: 15px;
  }
  .icon-cancel-circle {
    color: red;
  }
  .icon-checkmark-circle {
    color: green;
  }
}
