@import "colors";

.custom-popin {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $lightGreyBackground;
  border: 1px solid rgba(119, 119, 119, 0.6);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 13;
  .popin-content {
    margin: 0 20px;
    min-width: 300px;
    position: relative;
  }
}
.custom-popin .loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.8) url('../images/loader.svg') no-repeat center center;
  background-size: 100px;
}

.custom-popin .title {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
.custom-popin .content {
  padding: 1em;
}
.custom-popin .content p {
  margin-bottom: 1em;
}
.custom-popin .confirm-popin, .custom-popin .popin-close {
  width: 100px;
  margin: 1em auto;
  text-align: center;
}

.popin-content {
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    margin: 5px 0 10px 0;
  }

  &.section {
    max-width: 500px;
    margin: auto;
  }

  .row {
    margin-bottom: 20px;
    .errors {
      margin-top: 5px;
    }
  }

  .popin-message {
    padding: 5px;
    border-radius: 2px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    //color: #000;

    .alert-message {
      margin-top: 20px;
    }

    &.title {
      text-transform: uppercase;
    }

    .valid, .restart {
      color: #158bdb;
    }
    .order {
      color:#a4c400;
    }
    .manage {
      color: #39c2d2;
    }
    .timer {
      color: #dc4fad;
    }
    .offer {
      color: #1ba1e2;
    }
    .lost-validate {
      color: $red;
    }
    .delete {
      color: #f4b90c;
    }
  }

  .datetime {
    .date, .hour, .minute {
      width: 32%;
      margin-left: 1%;
      float: left;
    }
    .date {
      width: 34%;
      margin-left: 0;
    }
    .minute {
      margin-left: 0;
      float: right;
    }
  }

  input[type=text], input[type=number], input[type=url], input[type=password], input[type=email], input[type=phone], input[type=time], select[class=new-select] {
    height: 26px;
  }
  .datepicker {
    background: #fff;
    border: 0!important;
  }
  .select2-container {
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.45);
    text-align: left;
  }
  textarea {
    height: 52px;
  }
  .button {
    width: 100%;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    &.--cancel {
      background: $greyText2;
    }
    &.--delete {
      background: chartFirework();
    }
    &.--confirm {
      background:chartSky();
    }
  }
}
#reaffect {
  width: 300px;
  padding-bottom: 10px;
}
#lostDismiss {
  width: 300px;
}