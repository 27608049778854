.custom-radio input[type=radio]:checked,
.custom-radio input[type=radio]:not(:checked) {
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  opacity: 0;
  position: absolute;
  /* clip: rect(0 0 0 0); */
  /* -webkit-appearance: none;
  -moz-appearance:  none;
  -o-appearance:    none;
  appearance:     none; */
}

#foo:checked,
.custom-radio input[type=radio] + label:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 50%;
  margin-top: -4px;
  width: 6px;
  height: 6px;
  background-color: #000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid rgb(0, 1, 107);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.custom-radio input[type=radio]:checked + label:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

#foo:checked:before,
.custom-radio input[type=radio] + label {
  padding-left: 26px;
  margin-right: 16px;
  position: relative;
  cursor: pointer;
}

#foo:checked,
.custom-radio input[type=radio] + label:before {
  content: "";
  position: absolute;
  left: -1px;
  top: 50%;
  margin-top: -7px;
  width: 12px;
  height: 12px;
  border: 1px solid rgb(0, 1, 107);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
}

#foo:checked {
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  margin-top: -11px;
  width: 8px;
  height: 8px;
  /* background: #fff; */
  border: 1px solid rgb(0, 1, 107);
}

.custom-radio input[type=radio] + label:before {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}

.custom-radio input[type=radio]:focus + label:before {
  border: 1px solid rgb(0, 1, 107);
}