.lost-validation {
  text-align: left;
}
.lost-validation .section {
  margin-bottom: 40px;
}
.lost-validation .section h3 {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.lost-validation .section.leads {
  max-width: 1320px;
  margin: auto;
}
.lost-validation #contentFilter {
  background: #f3f3f3 !important;
  width: 980px;
  margin: 40px auto;
}
.lost-validation .pos, .lost-validation .user {
  width: 40%;
}
.lost-validation .pos .select2-container {
  text-align: left;
}
.lost-validation span:not(.phone-link) .button-icon, .lost-validation .buttons .button-icon {
  margin-right: 0;
  width: 30px;
  height: 30px;
  border: 0;
  cursor: pointer;
}
.lost-validation .filters .select2-container-multi .select2-choices {
  border: none;
}
.lost-validation .filters .select2-container-multi .select2-choices .select2-search-field input {
  height: 26px;
  line-height: 26px;
}
.lost-validation .icon-cancel-circle, .lost-validation .icon-checkmark-circle {
  position: absolute;
  right: 30px;
  top: 5px;
  display: block;
  font-size: 15px;
}
.lost-validation .icon-cancel-circle {
  color: red;
}
.lost-validation .icon-checkmark-circle {
  color: green;
}