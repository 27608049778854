.profile {
  .phone-error {
    color: red;
  }
  &.--first {
    margin-top: 40px;
  }
  .phone-field-wrapper {
    max-width: 112px;
  }
  .change-password {
    width: 58%;
    .inputs-block {
      width: 50%;
      margin-top: 40px;
    }
    .resetting_message_block {
      margin-left: 10px;
      padding-top: 40px;
    }
    .message-block ul {
      margin-left: 15px;
    }
    .bottom {
      margin-top: 40px;
    }
  }
  .sync-calendar {
    width: 35.5%;
    .button-default {
      margin-top: 40px;
    }
    a.button-default {
      width: fit-content;
      height: 16px;
      line-height: 16px;
    }
  }
  .calendars {
    margin-top: 40px!important;
  }
}
.section {
  &.--calendars {
    max-width: 500px;
    margin: auto;
    width: 100%;
    .authorization {
      font-size: 11px;
      line-height: 14px;
      margin-bottom: 40px;
      text-align: center;
    }
  }
}
.calendars {
  .line {
    span {
      width: calc(100% - 50px);
      .default {
        color: rgba(120,120,120, .6);
      }
    }
  }
}