@import "colors";

.reassign-lead-form-wrapper {
  padding: 10px;
  max-height: 80vh;
  overflow: auto;
  h4 {
    color: $greyText2;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .column {
    padding: 10px;
    text-align: center;
  }
  .form-block.--margin-bottom {
    margin-bottom: 20px;
  }
  .button-default {
    display: inline-block;
  }
  .reaffectPosLeadLine {
    border-top: 1px solid rgba(120,120,120,0.4);
    padding: 10px;
    min-width: 440px;
  }
  .reaffectPosLeadLine-leftColumn {
    align-items: flex-start;
    justify-content: center;
  }
  .reaffectPosLeadLine-posName {
    font-weight: bold;
  }
  .reaffectPosLeadLine-posSelect {
    width: 200px !important;
    &.--margin-bottom {
      margin-bottom: 10px;
    }
  }
  .reaffectPosLeadLine-sellerSelectLoader {
    display: none;
    img {
      width: 200px !important;
    }
  }
  .confirm {
    margin-right: 20px;
  }
  .reassignPosBilling {
    width: 60% !important;
    margin: auto !important;
  }
}
