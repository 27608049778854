@import "../colors";
/* Optional scoped theme for tag-it which mimics the zendesk widget. */


ul.tagit {
  border-style: solid;
  border-width: 1px;
  border-color: #C6C6C6;
  background: inherit;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, .45);
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  .tagit-new {
    padding: 0!important;
    margin: 0!important;
    input {
      font-size: 12px;
    }
  }
  li.tagit-choice {
    background: #C7C7C7;
    padding: 5px 28px 5px 5px;
    margin: 2px;
    position: relative;
    line-height: 13px;
    color: #fff;
    cursor: default;
    border-radius: 2px;
    z-index: 2;
    font-family: opensans, arial, helvetica, sans-serif;
    font-size: 12px;
    & + .tagit-new {
      input::placeholder {
        color: transparent;
      }
    }

    a.tagit-close {
      text-decoration: none;
      right: 5px;
      position: absolute;
      top: 3px;
      width: 15px;
      height: 15px;
      margin-top: 0;
      &:after {
        font-family: "icoscorimmo";
        font-size: 9px;
        color: rgb(0, 1, 107);
        content: "\e9f7";
        padding: 4px;
        border-radius: 10px;
        font-weight: bold;
      }
      &:hover:after {
        background-color: rgba(0, 1, 107, 0.5);
        transition: all 0.3s ease;
      }
      .text-icon {
        display: none;
      }
    }
    .ui-icon {
      display: none;
    }
  }
}




/* Forked from a jQuery UI theme, so that we don't require the jQuery UI CSS as a dependency. */
.tagit-autocomplete.ui-autocomplete { position: absolute; cursor: default; }
* html .tagit-autocomplete.ui-autocomplete { width:1px; } /* without this, the menu expands to 100% in IE6 */
.tagit-autocomplete.ui-menu {
  list-style:none;
  padding: 2px;
  margin: 0;
  display:block;
  float: left;
}
.tagit-autocomplete.ui-menu .ui-menu {
  margin-top: -3px;
}
.tagit-autocomplete.ui-menu .ui-menu-item {
  margin:0;
  padding: 0;
  zoom: 1;
  float: left;
  clear: left;
  width: 100%;
}
.tagit-autocomplete.ui-menu .ui-menu-item a {
  text-decoration:none;
  display:block;
  padding:.2em .4em;
  line-height:1.5;
  zoom:1;
}
.tagit-autocomplete .ui-menu .ui-menu-item a.ui-state-hover,
.tagit-autocomplete .ui-menu .ui-menu-item a.ui-state-active {
  font-weight: normal;
  margin: -1px;
}
.tagit-autocomplete.ui-widget-content { border: 1px solid #aaaaaa; background: #ffffff 50% 50% repeat-x; color: #222222; }
.tagit-autocomplete.ui-corner-all, .tagit-autocomplete .ui-corner-all { -moz-border-radius: 4px; -webkit-border-radius: 4px; -khtml-border-radius: 4px; border-radius: 4px; }
.tagit-autocomplete .ui-state-active  { border: 1px solid #aaaaaa; }

.tagit-autocomplete .ui-widget-content { border: 1px solid #aaaaaa; }
.tagit .ui-helper-hidden-accessible { position: absolute !important; clip: rect(1px,1px,1px,1px); }


