.lead-note-form, .contact-note-form {
  width: 100%;
}
.lead-note-form .lead-note-list-wrapper, .lead-note-form .contact-note-list-wrapper, .contact-note-form .lead-note-list-wrapper, .contact-note-form .contact-note-list-wrapper {
  margin: 0 20px 20px 20px;
}
.lead-note-form .lead-note-form-wrapper, .lead-note-form .contact-note-form-wrapper, .contact-note-form .lead-note-form-wrapper, .contact-note-form .contact-note-form-wrapper {
  margin: 0 20px 10px 20px;
}
.lead-note-form .user-note, .contact-note-form .user-note {
  width: 60%;
  position: relative;
  border-radius: 5px;
  line-height: 15px;
  padding: 5px 10px;
  margin-top: 20px;
}
.lead-note-form .user-note:after, .contact-note-form .user-note:after {
  content: " ";
  height: 0;
  min-width: 0;
  position: absolute;
  pointer-events: none;
  top: 10px;
  text-align: left;
  border: 10px solid transparent;
}
.lead-note-form .--current-user-note, .contact-note-form .--current-user-note {
  background-color: rgba(255, 153, 162, 0.2);
  margin-left: auto;
  margin-right: 10px;
}
.lead-note-form .--current-user-note:after, .contact-note-form .--current-user-note:after {
  border-color: transparent;
  border-left-color: rgba(255, 153, 162, 0.2);
  right: -20px;
}
.lead-note-form .--other-user-note, .contact-note-form .--other-user-note {
  background-color: rgba(241, 204, 0, 0.2);
  margin-left: 10px;
}
.lead-note-form .--other-user-note:after, .contact-note-form .--other-user-note:after {
  border-color: rgba(57, 194, 210, 0);
  border-right-color: rgba(241, 204, 0, 0.2);
  left: -20px;
}
.lead-note-form .date-author, .contact-note-form .date-author {
  font-size: 10px;
  color: rgba(119, 119, 119, 0.5);
}
.lead-note-form .note-content, .contact-note-form .note-content {
  font-size: 12px;
  color: #777;
}
.lead-note-form .note-content .button-default, .lead-note-form .note-content .button-defaut, .contact-note-form .note-content .button-default, .contact-note-form .note-content .button-defaut {
  width: fit-content;
  margin-top: 10px;
}
.lead-note-form textarea, .contact-note-form textarea {
  min-height: 60px;
  resize: vertical;
}
.lead-note-form .submit-comment, .contact-note-form .submit-comment {
  display: block;
  width: fit-content;
  margin: 10px auto 0 auto;
}