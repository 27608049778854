.profile .phone-error {
  color: red;
}
.profile.--first {
  margin-top: 40px;
}
.profile .phone-field-wrapper {
  max-width: 112px;
}
.profile .change-password {
  width: 58%;
}
.profile .change-password .inputs-block {
  width: 50%;
  margin-top: 40px;
}
.profile .change-password .resetting_message_block {
  margin-left: 10px;
  padding-top: 40px;
}
.profile .change-password .message-block ul {
  margin-left: 15px;
}
.profile .change-password .bottom {
  margin-top: 40px;
}
.profile .sync-calendar {
  width: 35.5%;
}
.profile .sync-calendar .button-default {
  margin-top: 40px;
}
.profile .sync-calendar a.button-default {
  width: fit-content;
  height: 16px;
  line-height: 16px;
}
.profile .calendars {
  margin-top: 40px !important;
}

.section.--calendars {
  max-width: 500px;
  margin: auto;
  width: 100%;
}
.section.--calendars .authorization {
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 40px;
  text-align: center;
}

.calendars .line span {
  width: calc(100% - 50px);
}
.calendars .line span .default {
  color: rgba(120, 120, 120, 0.6);
}