/**********************************  HOME  ***********************************/
.stat-dashboard {
  background: #fff;
  width: 100%;
  max-width: 1600px;
  padding: 20px 0;
}
.stat-dashboard h3 {
  margin-bottom: 20px;
}
.stat-dashboard .dashboard-content {
  display: flex;
}
@media (max-width: 1024px) {
  .stat-dashboard .dashboard-content {
    display: block;
  }
}
.stat-dashboard .left {
  width: calc(22% - 10px);
}
@media (max-width: 1024px) {
  .stat-dashboard .left {
    width: 100%;
  }
}
.stat-dashboard .right {
  width: calc(80% - 10px);
  margin-left: 20px;
}
@media (max-width: 1024px) {
  .stat-dashboard .right {
    width: 100%;
    margin-left: 0;
  }
}
.stat-dashboard .dashboard {
  margin-bottom: 20px;
}
.stat-dashboard .dashboard .--flex {
  gap: 40px;
}
.stat-dashboard .dashboard .section.filter {
  margin-bottom: 56px;
}
.stat-dashboard .dashboard .buttons {
  gap: 0;
  min-width: 0;
  width: 280px;
}
.stat-dashboard .pie-chart {
  position: relative;
}
.stat-dashboard .pie-chart .section-content {
  height: 275px;
}
.stat-dashboard #transferPieChart {
  margin: auto;
  width: 100% !important;
  height: 100% !important;
  max-width: 200px !important;
}
.stat-dashboard #transferPieChart.no-data {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
}
.stat-dashboard #transferPieChart .percent-inner {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  position: relative;
  top: -40%;
  height: 50px;
}
.stat-dashboard #transferPieChart .percent-inner.no-data {
  font-size: 12px;
  font-weight: normal;
  top: -60%;
}
@media (max-width: 1351px) {
  .stat-dashboard #transferPieChart .percent-inner {
    top: -90px;
  }
}
.stat-dashboard #transferRateChart {
  position: relative;
  z-index: 10;
  width: 100% !important;
}
@media (min-width: 1025px) {
  .stat-dashboard .all-values {
    display: flex;
    gap: 40px;
  }
}
@media (max-width: 1024px) {
  .stat-dashboard .all-values {
    padding: 0 5%;
  }
}
.stat-dashboard .active-values .--flex, .stat-dashboard .closed-values .--flex {
  gap: 20px;
}
.stat-dashboard .active-values .--values, .stat-dashboard .closed-values .--values {
  gap: 0 20px;
}
@media (max-width: 1024px) {
  .stat-dashboard .active-values .--values, .stat-dashboard .closed-values .--values {
    padding-left: 5%;
    padding-right: 5%;
    flex-wrap: wrap;
  }
}
@media (max-width: 1024px) {
  .stat-dashboard .active-values .section, .stat-dashboard .closed-values .section {
    margin-bottom: 10px;
    flex: 1 0 25%;
  }
}
@media (max-width: 1024px) {
  .stat-dashboard .active-values .section.lead-confirmed-appointment, .stat-dashboard .closed-values .section.lead-confirmed-appointment {
    flex: 1 1 100%;
  }
}
@media (min-width: 1025px) {
  .stat-dashboard .active-values {
    width: 70%;
  }
}
@media (min-width: 1025px) {
  .stat-dashboard .closed-values {
    width: 30%;
  }
  .stat-dashboard .closed-values .--values {
    flex-wrap: wrap;
  }
  .stat-dashboard .closed-values .value {
    flex: 1 0 25%;
  }
}
.stat-dashboard .value {
  padding: 10px;
  text-align: center;
  position: relative;
  cursor: pointer;
  gap: 0 !important;
  font-size: 13px;
  letter-spacing: 0.1rem;
  text-shadow: -1px 1px 0 rgba(150, 150, 150, 0.3), 1px 1px 0 rgba(150, 150, 255, 0.3);
  transition: 0.5s ease;
}
@media (max-width: 1024px) {
  .stat-dashboard .value {
    font-size: 12px;
    letter-spacing: 0;
  }
}
.stat-dashboard .value:hover {
  transition: 0.4s ease;
}
.stat-dashboard .value:active {
  color: rgb(255, 255, 255) !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset, 0 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.1s linear;
}
.stat-dashboard .value.--check {
  color: rgb(255, 255, 255) !important;
  cursor: default;
  scale: 104%;
}
@media (min-width: 1025px) {
  .stat-dashboard .value.--half {
    width: 50%;
  }
  .stat-dashboard .value.--third {
    width: 33.33%;
  }
}
.stat-dashboard .value .nb {
  font-size: 30px;
  display: block;
  text-align: center;
  font-weight: bold;
}
.stat-dashboard .value .text {
  width: 100%;
  font-weight: 500;
}
.stat-dashboard .value.lead-waiting-result {
  background-color: rgba(238, 52, 50, 0.2);
  color: rgb(238, 52, 50);
}
.stat-dashboard .value.lead-waiting-result:hover {
  background-color: rgba(238, 52, 50, 0.4);
}
.stat-dashboard .value.lead-waiting-result.--check {
  background-color: rgba(238, 52, 50, 0.8);
}
.stat-dashboard .value.lead-to-come {
  background-color: rgba(154, 127, 201, 0.2);
  color: rgb(154, 127, 201);
}
.stat-dashboard .value.lead-to-come:hover {
  background-color: rgba(154, 127, 201, 0.4);
}
.stat-dashboard .value.lead-to-come.--check {
  background-color: rgba(154, 127, 201, 0.8);
}
.stat-dashboard .value.lead-received, .stat-dashboard .value.lead-active, .stat-dashboard .value.lead-closed {
  background-color: rgba(0, 1, 107, 0.2);
  color: rgba(0, 1, 107, 0.6);
}
.stat-dashboard .value.lead-received:hover, .stat-dashboard .value.lead-active:hover, .stat-dashboard .value.lead-closed:hover {
  background-color: rgba(0, 1, 107, 0.4);
}
.stat-dashboard .value.lead-received.--check, .stat-dashboard .value.lead-active.--check, .stat-dashboard .value.lead-closed.--check {
  background-color: rgba(0, 1, 107, 0.8);
}
.stat-dashboard .value.lead-received.--check {
  scale: 101%;
}
.stat-dashboard .value.lead-assigned {
  background-color: rgba(170, 0, 255, 0.2);
  color: rgb(170, 0, 255);
}
.stat-dashboard .value.lead-assigned:hover {
  background-color: rgba(170, 0, 255, 0.4);
}
.stat-dashboard .value.lead-assigned.--check {
  background-color: rgba(170, 0, 255, 0.8);
}
.stat-dashboard .value.lead-dms {
  background-color: rgba(65, 187, 203, 0.2);
  color: rgb(65, 187, 203);
}
.stat-dashboard .value.lead-dms:hover {
  background-color: rgba(65, 187, 203, 0.4);
}
.stat-dashboard .value.lead-dms.--check {
  background-color: rgba(65, 187, 203, 0.8);
}
.stat-dashboard .value.lead-confirmed-appointment {
  background-color: rgba(21, 139, 219, 0.2);
  color: rgb(21, 139, 219);
}
.stat-dashboard .value.lead-confirmed-appointment:hover {
  background-color: rgba(21, 139, 219, 0.4);
}
.stat-dashboard .value.lead-confirmed-appointment.--check {
  background-color: rgba(21, 139, 219, 0.8);
}
.stat-dashboard .value.lead-recontact, .stat-dashboard .value.lead-offers-sent {
  background-color: rgba(243, 159, 175, 0.2);
  color: rgb(247, 133, 155);
}
.stat-dashboard .value.lead-recontact:hover, .stat-dashboard .value.lead-offers-sent:hover {
  background-color: rgba(243, 159, 175, 0.4);
}
.stat-dashboard .value.lead-recontact.--check, .stat-dashboard .value.lead-offers-sent.--check {
  background-color: rgba(243, 159, 175, 0.8);
  color: rgb(255, 255, 255);
  cursor: default;
}
.stat-dashboard .value.lead-sale {
  background-color: rgba(28, 134, 25, 0.2);
  color: rgb(28, 134, 25);
}
.stat-dashboard .value.lead-sale:hover {
  background-color: rgba(28, 134, 25, 0.4);
}
.stat-dashboard .value.lead-sale.--check {
  background-color: rgba(28, 134, 25, 0.8);
  color: rgb(255, 255, 255);
  cursor: default;
}
.stat-dashboard .value.lead-mandate {
  background-color: rgba(70, 181, 116, 0.2);
  color: rgb(70, 181, 116);
}
.stat-dashboard .value.lead-mandate:hover {
  background-color: rgba(70, 181, 116, 0.4);
}
.stat-dashboard .value.lead-mandate.--check {
  background-color: rgba(70, 181, 116, 0.8);
  color: rgb(255, 255, 255);
  cursor: default;
}
.stat-dashboard .value.lead-lost {
  background-color: rgba(241, 156, 0, 0.2);
  color: rgb(241, 156, 0);
}
.stat-dashboard .value.lead-lost:hover {
  background-color: rgba(241, 156, 0, 0.4);
}
.stat-dashboard .value.lead-lost.--check {
  background-color: rgba(241, 156, 0, 0.8);
  color: rgb(255, 255, 255);
  cursor: default;
}
.stat-dashboard .value.lead-abandoned {
  background-color: rgba(119, 119, 119, 0.2);
  color: rgb(119, 119, 119);
}
.stat-dashboard .value.lead-abandoned:hover {
  background-color: rgba(119, 119, 119, 0.4);
}
.stat-dashboard .value.lead-abandoned.--check {
  background-color: rgba(119, 119, 119, 0.8);
  color: rgb(255, 255, 255);
  cursor: default;
}

.comiseo-daterangepicker .ui-widget .ui-menu-item {
  background: #f3f3f3;
}
.comiseo-daterangepicker .ui-widget .ui-menu-item:hover {
  background: rgba(190, 240, 224, 0.4);
}

#contentFilter {
  background: none;
}

.stat-dashboard .filters .interest {
  margin-top: -1px;
}
@media (min-width: 1025px) {
  .stat-dashboard .filters > div {
    gap: 10px;
  }
}
@media (max-width: 1024px) {
  .stat-dashboard .filters > div.buttons {
    flex-direction: column;
  }
}
.stat-dashboard .filters > div > div {
  gap: 2%;
}
@media (max-width: 1024px) {
  .stat-dashboard .filters > div > div {
    display: block;
  }
}
.stat-dashboard .filters > div > div.interest {
  margin-top: -1px;
}
.stat-dashboard .filters > div > div > div {
  width: 100%;
  max-width: 49%;
  min-width: 18%;
}
@media (max-width: 1024px) {
  .stat-dashboard .filters > div > div > div {
    margin-bottom: 10px;
    max-width: none;
  }
}
.stat-dashboard .filters .fields {
  width: 100%;
}
.stat-dashboard .filters .hidden input, .stat-dashboard .filters .hidden select, .stat-dashboard .filters .hidden .select2-container-multi .select2-choices .select2-search-field input,
.stat-dashboard .filters .hidden .select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4 !important;
  background-image: none !important;
  border: 1px solid #ddd !important;
  cursor: default !important;
}
.stat-dashboard .filters .hidden .select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4 !important;
  background-image: none !important;
  cursor: default !important;
  border: 0 !important;
  margin-right: 3px;
}
.stat-dashboard .filters input[type=text], .stat-dashboard .filters input[type=number], .stat-dashboard .filters input[type=url], .stat-dashboard .filters input[type=password], .stat-dashboard .filters input[type=email], .stat-dashboard .filters input[type=phone],
.stat-dashboard .filters select[class=new-select], .stat-dashboard .filters .select2-container:not(.select2-container-multi), .stat-dashboard .filters .comiseo-daterangepicker-triggerbutton.ui-button,
.stat-dashboard .filters .select2-container .select2-choice > .select2-chosen, .stat-dashboard .filters .select2-container:not(.select2-container-multi) .select2-choice,
.stat-dashboard .filters .select2-container-multi .select2-choices .select2-search-field input {
  height: 26px;
  line-height: 26px;
}
.stat-dashboard .filters .select2-container-multi .select2-choices .select2-search-field input {
  width: 100% !important;
}
.stat-dashboard .filters .select2-container-multi .select2-choices {
  min-height: 26px;
}
.stat-dashboard .filters .select2-container-multi.select2-container-disabled .select2-choices {
  background: #fff;
  border: none;
}
.stat-dashboard .filters .select2-container .select2-choice .select2-arrow b {
  margin-top: 8px;
}
.stat-dashboard .filters .button-icon {
  margin-right: 0;
  width: 30px;
  height: 30px;
  border: 0;
  cursor: pointer;
}

.board .toggle-section-visibility {
  z-index: 50;
}
@media (max-width: 1024px) {
  .board .section-content {
    margin-top: 45px;
  }
}
.board .common-table {
  margin: -44px 0 0;
  position: relative;
}
.board .common-table .pagination-wrapper {
  margin-right: 25px;
}
@media (max-width: 1024px) {
  .board .common-table table {
    margin: 0;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .board .common-table td {
    padding: 10px 5px;
  }
  .board .common-table td:first-child {
    padding-left: 0;
  }
  .board .common-table td.actions {
    width: auto;
  }
}
.board .common-table .no-result td {
  border: none;
  text-align: center;
}
.board .common-table tr:last-child td {
  border-bottom: none;
}
.board .common-table th {
  color: #777;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
}
.board .common-table .lead-checkbox-all {
  margin: 8px 5px 5px 30px;
}
@media (max-width: 1024px) {
  .board .common-table .lead-checkbox-all {
    margin-left: 0;
  }
}
.board .common-loader {
  margin-bottom: 20px;
}
.board .id {
  color: #777;
  font-size: 10px;
  text-align: center;
  width: 26px;
}
@media (max-width: 1024px) {
  .board .id {
    line-height: 14px;
  }
}
.board .id span {
  margin: auto;
}
@media (min-width: 1025px) {
  .board .id .days {
    display: none;
  }
}
.board .seller-alias {
  display: block;
  color: #777;
  font-size: 10px;
  line-height: 14px;
}
.board .interest, .board .subject {
  display: block;
  line-height: 14px;
}
.board .subject {
  color: #777;
  font-size: 12px;
}
.board .customer {
  line-height: 14px;
}
@media (max-width: 1024px) {
  .board .customer {
    width: 50%;
  }
}
@media (min-width: 1025px) {
  .board .customer .subject {
    display: none;
  }
}
.board .phone-link, .board .email-link {
  display: block;
  color: #777;
}
.board .date {
  text-align: center;
}
.board .hour {
  display: block;
  color: #777;
  font-size: 10px;
  line-height: 14px;
}
.board .status {
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
}
.board .lost-reason, .board .appointment, .board .reminder {
  display: block;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  width: 100%;
}
@media (max-width: 1024px) {
  .board .pos-seller, .board .date, .board .demand, .board .id span:not(.days) {
    display: none;
  }
  .board .actions a:not(.--mobile) {
    display: none;
  }
}

#exportLeads {
  margin-left: 20px;
}

@media (min-width: 1025px) {
  #transferRate2 {
    display: none;
  }
}