span.tooltip {
  display: none;
  position: absolute;
  width: 80px;
  line-height: 20px;
  padding: 3px 5px;
  text-align: center;
  color: #fff;
  background: #000;
  border-radius: 5px;
  z-index: 55;
  bottom: 28px;
  text-indent: 0;
  font-size: 10px;
  font-weight: normal;
  white-space: normal;
}
.picto span.tooltip {
  left: -38px;
}

span.tooltip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  bottom: -19px;
  left: 38px;
}
.picto:hover span.tooltip, .tooltip-container:hover span.tooltip {
  display: block;
}
.tooltip-container {
  position: relative;
}

.from {
  display: block;
  color: #747272;
  font-size: 25px;
  width: 20px;
  text-align: center;
  cursor: help;
}
.from:before {
  font-size: 20px;
}

.from.transfered {
  color: rgba(20, 170, 71, 1);
}
.from.not-transfered {
  color: rgba(238, 52, 50, 1);
}
.from.on-closed-hours {
  color: rgb(201, 201, 201);
}
.from.api, .from.email, .from.physical {
  color: rgba(0, 1, 107, 0.6);
}
.from.email.transfered {
  color: rgba(20, 170, 71, 1);
}

