/*font face*/
@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-regular-webfont.woff") format("woff");
  src: url("../fonts/opensans-regular-webfont.eot");
  src: url("../fonts/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-regular-webfont.ttf") format("truetype"), url("../fonts/opensans-regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-bold-webfont.woff") format("woff");
  src: url("../fonts/opensans-bold-webfont.eot");
  src: url("../fonts/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-bold-webfont.ttf") format("truetype"), url("../fonts/opensans-bold-webfont.svg#open_sansbold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-semibold-webfont.woff") format("woff");
  src: url("../fonts/opensans-semibold-webfont.eot");
  src: url("../fonts/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-semibold-webfont.ttf") format("truetype"), url("../fonts/opensans-semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/BebasNeue-Regular.eot");
  src: url("../fonts/BebasNeue-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/BebasNeue-Regular.woff2") format("woff2"), url("../fonts/BebasNeue-Regular.woff") format("woff"), url("../fonts/BebasNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}