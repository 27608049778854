@import "colors";

.lead-note-form, .contact-note-form {
  width: 100%;
  .lead-note-list-wrapper, .contact-note-list-wrapper {
    margin: 0 20px 20px 20px;
  }
  .lead-note-form-wrapper, .contact-note-form-wrapper {
    margin: 0 20px 10px 20px;
  }
  .user-note {
    width: 60%;
    position: relative;
    border-radius: 5px;
    line-height: 15px;
    padding: 5px 10px;
    margin-top: 20px;
    &:after {
      content: " ";
      height: 0;
      min-width: 0;
      position: absolute;
      pointer-events: none;
      top: 10px;
      text-align: left;
      border: 10px solid transparent;
    }
  }
  .--current-user-note {
    background-color: rgba(255, 153, 162, 0.2);
    margin-left: auto;
    margin-right: 10px;
    &:after{
      border-color: transparent;
      border-left-color: rgba(255, 153, 162, 0.2);
      right: -20px;
    }
  }
  .--other-user-note {
    background-color: rgba(241, 204, 0, 0.2);
    margin-left: 10px;
    &:after{
      border-color: rgba(57, 194, 210, 0);
      border-right-color: rgba(241, 204, 0, 0.2);;
      left: -20px;
    }
  }
  .date-author {
    font-size: 10px;
    color: rgba(119, 119, 119, 0.5);
  }
  .note-content {
    font-size: 12px;
    color: $greyText2;
  }
  .note-content {
    // button-defaut pour les classes en dur dans la bdd (table note "ajouter à mon agenda")
    .button-default, .button-defaut {
      width: fit-content;
      margin-top: 10px;
    }
  }
  textarea {
    min-height: 60px;
    resize: vertical;
  }
  .submit-comment {
    display: block;
    width: fit-content;
    margin: 10px auto 0 auto;
  }
}