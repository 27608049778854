@import "colors";

body {
  color: $greyText;
  background: #f7f7f7;
  font-family: opensans, arial, helvetica, sans-serif;
}
/* For modern browsers */
.cf:before,.cf:after {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.cf {
  zoom: 1;
}

@-moz-document url-prefix() {
  select {
    text-indent: 0.01px;
    text-overflow: '';
  }
}
.--orange {
  color: #FF4B00;
}

.--hidden {
  display: none!important;
}
.--visibility-hidden {
  visibility: hidden!important;
}
.--show-block {
  display: block!important;
}
.popLead #wrap {
  background:transparent;
}

@media (min-width: 1265px) {
  .container {
    min-width: 1250px;
    margin: auto;
  }
}
.center, .data .content th.center {
  text-align: center;
}
.flex {
  display: flex;
}
.--bold {
  font-weight: bold;
}
.--grey {
  color: $greyText2;
}

.overdue {
  color: #D11818!important;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  line-height: 10px;
}

/*******************************  FORM ELEMENTS *******************************/
.fields {
  background: $lightGreyBackground;
  margin: 10px 1%;
  padding: 10px 15px;
}
.fields + .fields {
  margin-top: 10px!important;
}
.field {
  margin: 15px 0;
}
form label.required:after {
  content: ' *';
}
input[type=text], input[type=password], input[type=email], input[type=url], input[type=number], input[type=time], select[class=new-select], select[class=number], textarea, .select2-container {
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.45);
}
.select2-container {
  background-color: white;
}
input[type=text], input[type=number], input[type=url], input[type=password], input[type=email],
input[type=phone], input[type=time], textarea, select[class=new-select] {
  border: none;
  background-color: #fff;
  padding: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}
input[type=text],input[type=number], input[type=url], input[type=password], input[type=email],
input[type=phone], input[type=time], select[class=new-select] {
  height: 26px;
}
input::placeholder {
  color: #D3D3D3;
}
textarea::placeholder {
  color: #D3D3D3;
}
.data .radio label, .data .checkbox label, .data .inline label {
  display: inline-block;
  padding-right: 10px;
}
.data .radio div, .data .checkbox div {
  display: inline-block;
}
.data .checkbox .select-origin {
  display: block ;
}
.data .radio div label, .data .checkbox div label {
  padding-left: 5px;
}
.data .no-label {
  padding: 30px 0 0;
}

.button, input.button {
  background: #41bbcb;
  border: none;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  padding: 5px 15px;
}
.input:before {
  color: #fff;
  background: #cfcfcf;
  font-size: 20px;
}

.switch-title-block {
  display: flex;
  align-items: center;
}



/******************************  RIGHT COLUMN  *******************************/
.main-content {
  width: 980px;
  margin:0 auto;
  background: #f7f7f7;
  padding: 20px 0 100px 20px;
  vertical-align: top;
  position: relative;
  text-align: left;
}

.main-content > h2 {
  text-align:left;
}

h2 {
  color: #9A9A9A;
  font-size: 12px;
  margin-bottom: 20px;
}
h2 a {
  color: #9A9A9A;
}

/**********************************  DATA  ***********************************/
.data {
  background: #fff;
  margin-bottom: 20px;
}
.data .header {
  background: #444444;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  height: 34px;
}
.data .header[class^="icon-"]:before, .data .header[class*=" icon-"]:before {
  font-size: 22px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  padding-right: 5px;
  margin-left: 5px;
  float: left;
}
.data .header h3 {
  font-weight: bold;
  padding: 0 10px;
  line-height: 34px;
  float: left;
  text-align: left;
  white-space: nowrap;
}
.data .header[class^="icon-"], .data .header[class*=" icon-"] {
  padding: 0;
}
.data .header .hide-data, .data .header .show-data {
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
  text-indent: -9999em;
  position: absolute;
  right: 10px;
  top: 10px;
}
.data .header .hide-data {
  background-image: url('../images/v2/pictos/toggle-up.png');
}
.data .header .show-data {
  background-image: url('../images/v2/pictos/toggle-down.png');
}
.data .header .icon-download {
  position: absolute;
  right: 55px;
  cursor: pointer;
  display: none;
}
.data .content {
  background: #fff;
  padding: 11px 2%;
}
.data .content table {
  width: 100%;
}

.data .content .no-data, .section .no-data {
  padding: 10px 0;
  text-align: center;
  width: 100%;
}
.data .content .even {
  background: #f6f6f6
}
.data .content tr:first-child {
  border-bottom: 1px solid white;
}

.data .content td {
  padding: 10px 5px;
}
.data .content td.id {
  font-weight: bold;
}
.data table .nowrap {
  white-space: nowrap;
}
.data .content .actions {
  text-align: center;
}
.data .content .actions .picto {
  position: relative;
}
.data .content .actions a {
  font-size: 18px;
  display: inline-block;
}

.data .content th {
  color: #717171;
  padding: 10px 5px;
  text-align: left;
}
.data .content th a {
  color: #717171
}
.data .content td {
  font-size: 11px;
}
.data .content .status {
  font-weight: 500;
}

.data .content .pad {
  width: 30%;
  float: left;
  border: 2px solid #000;
  margin: 1%;
  text-align: center;
  padding: 10px 0;
  line-height: 18px;
  min-height: 40px;
}

.data .content .pad .name {
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle;
}

.data .content .pad.hierarchy1 {
  color:#41bbcb;
  border-color:#41bbcb;
}

.data .content .pad.hierarchy2 {
  color:#8300ff;
  border-color:#8300ff;
}


#listPos .fields label {
  display: inline-block;
  width: 280px;
}
#listPos .fields .keyword {
  width: 150px;
}

.data .header span {
  width: 34px;
  float: left;
  height: 34px;
}
.data .header span:before {
  font-size: 28px;
  line-height: 35px;
  height: 34px;
  color: #fff;
  display: block;
  margin: auto;

}
.data .bottom {
  text-align: center;
  padding-bottom: 10px;
}
.data .bottom .button {
  display: inline-block;
}
/**********************************  FILTER  ***********************************/
#contentFilter .filterBtn {
  background-color:#41bbcb;
  color:white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 12px 20px;
  display: inline-block;
  cursor: pointer;
  transition: all ease-out 0.2s;
  border-radius: 110px;
  margin: 10px 0;
  line-height: 16px;
}

#contentFilter .filterBtn:hover {
  background-color:#4fadb9;
}

#contentFilter .filterBtn:before {
  font-size: 14px;
  position: relative;
  top: 2px;
  margin: 5px 9px;
}

#contentFilter .filterBtn.alterFilterBtn {
  background: #9fda64;
}

#contentFilter .filterBtn.alterFilterBtn:hover {
  background: #83b551;
}

.datepickers .ui-button-icon::after {
  content: '';
  border: 8px solid transparent;
  border-top: 11px solid #000000;
  display: block;
  margin-top: 4px;
  transform: scaleX(0.8);
  -ms-transform: scaleX(0.8);

}

/*          FILTRES
-----------1. Style général-------------*/

.data.filter .content {
  padding:0;
}
#contentFilter #userFilter {
  font-size:12px;
}

#contentFilter .filtersContainer {
  display: table;
  table-layout: fixed;
  width: 100%;
  border-bottom: 1px solid lightgrey;
}
#contentFilter .filtersContainer .select2-container {
  box-shadow: none;
}

#contentFilter .filterBtnContainer {
  text-align:center;
}
#contentFilter .filterBtnContainer .button-default {
  max-width: 50px;
  margin: 20px auto;
}

#contentFilter .filter {
  display: table-cell;
  border-left: 1px solid lightgrey;
  transition:all ease-out 0.2s;
  transform: scale(1);
  -ms-transform: scaleX(0.8);
}

#contentFilter .filter.hidden {
  display: none;
  transform: scale(0);
  -ms-transform: scale(0);
}

#contentFilter .filter:first-child{
  border-left: none;
}

#contentFilter .filter > span {
  font-size: 11px;
  color: grey;
  display: block;
  padding: 10px;
  border-bottom: 1px dotted lightgrey;
}
#contentFilter .datepickers {
  background: #fff;
  width: 100%;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.45);
}
#contentFilter .datepicker-container {
  float: left;
  width: 120px
}
#contentFilter .datepicker {
  border: 1px solid #ccc;
}


/*********************************  SEARCH  ***********************************/

#searchForm .button-default {
  margin-left: auto;
  margin-right: auto;
}
.call.icon-phone:before {
  color: #41bbcb;
  font-size: 14px;
}

/*******info concession du plead*******/
#meteo {
  font-size:0;
}

.cityInfos, .weatherIcon, .temperatureInfos {
  display:inline-block;
  vertical-align:middle;
  font-size:10px;
}

.cityInfos {
  width:60%;
}

.cityInfos > div {
  display:block;
}

.cityInfos .city {
  display: block;
  font-weight: bold;
  font-size: 12px;
  color: #333;
}

.temperatureInfos {
  width: 18%;
  text-align: right;
  font-size: 18px;
}

.temperatureInfos:after {
  content:'°C';
  display:inline-block;

}

.meteo .value {
  background: #ededed;
  padding: 2%;
  width: 81%;
  max-width: 300px;
}
/********form lead********/
.datetime .date, .datetime .hour, .datetime .minute {
  width: 48%;
  margin-left: 1%;
  float: left;
}
.datetime .hour, .datetime .minute {
  width: 23%;
}

/*****************************  LEAD VENDEUR  *********************************/
.evolveLead {
  padding-bottom: 30px;
  a {
    margin: 15px auto 0 auto;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    text-align: center;
    font-size: 14px;
    width: 200px;
    padding: 0;
  }
}

.popin-content .answers .button-default, .leadInfoFields .button-default, .seller-lead-to-treat-section .properties .button-default {
  text-align: center;
  margin: 10px auto;
  width: 100%;
  display: block;
  padding-left: 0;
  padding-right: 0;
}
.answers .button-default {
  line-height: 14px;
}

.leadInfoFields .button-default, .seller-lead-to-treat-section .properties .button-default {
  padding: 0;
  width: 120px;
}
#sellerLeadForm {
  .main-content {
    background: #fff;
  }
  .left {
    width: 640px;
    float: left;
    margin-right: 20px;
  }
  .right {
    width: 320px;
    float: left;
  }
  .col {
    width: 49%;
    float: left;
  }
  .col + .col {
    margin-left: 2%;
  }
  .block .title {
    background: #939393;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    padding: 7px 0;
  }
  .fields {
    position:relative;
    margin: 0 0 20px;
    background-color:white;
  }
  .data {
    .content {
      td {
        font-size:13px;
      }
    }
  }
  .buttons {
    a {
      display: block;
      margin-bottom: 15px;
      color: #fff;
      text-align: center;
      &:before {
        color: #fff;
        font-size: 40px;
        float: left;
        padding-left: 10px;
      }
    }
    .dismiss-valid {
      background: $red;
      height: 150px;
      line-height: 150px;
      font-size: 17px;
      font-weight: bold;
    }
  }
}

#sellerLeadForm .status, .data.seller-lead-to-treat-section .status {
  display: block;
  font-weight: bold;
  text-align: center;
  max-width: 350px;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}
.fields.favorite .active .icon-phone::after {
  content:"\2605";
  display:inline-block;
  font-size:27px;
  color:#ffde00;
  vertical-align: middle;
}


.seller-lead-to-treat-section {
  max-width: 980px;
  margin: 0 auto;
  .evolution {
    background: #00a600;
    color:white;
  }
  .--flex-column {
    min-width: 300px;
  }
  .section {
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
  .status {
    margin-bottom: 10px;
    .lost-reason, .appointment, .reminder {
      display: block;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      width: 100%;
    }
}
/********************************  EMAILS *************************************/
.received-message {
  border-left: 3px solid #39c2d2;
  margin: 10px 0;
  padding: 10px;
  background: #fff;
}
#iframe1 {
  width :100%;
  min-height: 350px;
  border: none;
}
.--plain, .--html {
  margin-bottom: 20px;
  border: 1px solid rgba(120,120,120, .4);
  padding: 5px;
}
.--plain {
  max-height: 350px;
  overflow: auto;
  &.--hidden {
    display: none;
  }
}
.--html {
  height: 350px;
  &.--hidden {
    display: none;
  }
}

.change-format {
  position: absolute;
  top: 3px;
  left: 0;
}

#receivedEmail {
  .status {
    padding: 10px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin: 10px 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    position: relative;
    border: 1px solid #FAEFDD;
    background: #FCF7E4;
  }
  .infos {
    background: #F9F9F9;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin: 10px 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 10px;
    .col {
      width: 49%;
      float: left;
      margin-right: 1%;
    }
  }
}

/********************************* STATS **************************************/
.comiseo-daterangepicker-active .ui-button-icon::after{
  border-color:white;
}
.pop-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #000 center center no-repeat;
  opacity: .7;
  z-index: 3000;
}
.button-reopen-lead {
  position: relative;
  top: -12px;
  display: inline-block;
  line-height: 32px;
}
.datepickers .comiseo-daterangepicker-triggerbutton {
  width: 100%;
  font-size: 11px;
  background-color: white!important;
  color: rgba(0,0,0,1)!important;
  border-radius: initial!important;
}
.datepickers.--disabled .comiseo-daterangepicker-triggerbutton {
  background: #f1f1f1!important;
  cursor: not-allowed!important;
}


.datepickers .ui-button-icon {
  display: inline-block;
  float: right;
}

.comiseo-daterangepicker-buttonpanel button {
  background-color: rgba(0,1,104,1);
  color: white;
  border-radius: 3px;
  padding: 2px 25px;
  cursor: pointer;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  transition: 0.1s;
}

.filter span {
  text-align: left!important;
}

.comiseo-daterangepicker-calendar .ui-datepicker-calendar .ui-state-highlight {
  border:1px solid rgba(0,1,104,.7);
  color: rgba(0,1,104,1);
}


ul.tagit {
  background-color: white;
}

.note .delete
{
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 20px;
  display: block;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  top: 5px;
}
/*        STATS 2016
          0. Réglages généraux
-----------------------------------------------*/
/*        FILTERS
          1. Date Picker
-----------------------------------------------*/

.ui-widget .ui-menu-item {
  padding: 5px 10px;
  margin-bottom: 0;
  transition: all ease-out 0.2s;
}

.ui-widget .ui-menu-item:hover {
  background-color: rgba(190, 240, 224, 0.8);
  cursor: pointer;
}

.ui-widget .ui-menu-item a.ui-state-focus {
  color: rgba(0,0,0,1);
  border:none;
  background:transparent;
}

.comiseo-daterangepicker-calendar .ui-datepicker .ui-datepicker-header {
  border: none;
  background: transparent;
  color: #666;
  border-bottom: 1px dotted lightgrey;
  padding-bottom: 8px;
}

.comiseo-daterangepicker-calendar .ui-datepicker .ui-datepicker-prev,
.comiseo-daterangepicker-calendar .ui-datepicker .ui-datepicker-next,
.comiseo-daterangepicker-calendar .ui-datepicker .ui-datepicker-prev span,
.comiseo-daterangepicker-calendar .ui-datepicker .ui-datepicker-next span {
  background:transparent;
  border: none;
  margin-top:-1px;
}

.comiseo-daterangepicker-calendar .ui-datepicker .ui-datepicker-prev span:before,
.comiseo-daterangepicker-calendar .ui-datepicker .ui-datepicker-next span:before {
  content:'';
  border:7px solid transparent;
  width:0;
  height:0;
  display:block;
  position:relative;
}

.comiseo-daterangepicker-calendar .ui-datepicker .ui-datepicker-next span:before {
  border-left:10px solid #555;
}

.comiseo-daterangepicker-calendar .ui-datepicker .ui-datepicker-prev span:before {
  border-right:10px solid #555;
}

.comiseo-daterangepicker-calendar .ui-datepicker table thead {
  background: transparent;
}

.comiseo-daterangepicker .ui-datepicker td.ui-state-highlight {
  border: none;
  margin: 0;
  padding: 0;
  background-color: rgba(190, 240, 224, 1)
}


.comiseo-daterangepicker-calendar td.ui-state-highlight a.ui-state-default {
  background: transparent;
  padding: 4px;
  color: rgb(0,0,0);
  border: none;
}

/* Operator/sellersList */

.select-lead-seller-checkbox {
  display: none;
}
#s2id_search_pos {
  opacity: 1 !important;
  height: initial !important;
  position: initial !important;
}

#s2id_search_pos .select2-choice {
  background-color: white;
}
#searchForm .flex {
  display: flex;
  flex-direction: column;
}

#operator_enabled label::after {
  content: "" !important;
}
#operator_showLeadRecords label::after {
  content: "" !important;
}
.icon-cancel-circle.icon-ac3 {
  color: red;
  display: block;
  font-size: 15px;
}
.icon-checkmark-circle.icon-ac3 {
  color: green;
  display: block;
  font-size: 15px;
  cursor: pointer;
}
form .errors li {
  color: $red;
  font-weight: bold;
}
.change-password {
  .fields {
    display: flex;
  }
  .inputs-block {
    width: 50%;
  }
  .message-block {
    margin-left: 10px;
    padding-top: 40px;
    ul {
      margin-left: 15px;
    }
  }
}

.delete.icon-remove {
  color: chartSky();
  font-size: 20px;
  text-decoration: none !important;
  cursor: pointer;
}

.seller-lead-to-treat-section .--margin-top {
  margin-top: 20px;
}
.seller-lead-to-treat-section .former {
  margin-top: 10px;
}

.common-right-section.section-error .message {
  text-align: center;
  margin: 50px auto;
}
.section-error .buttons a {
  margin-left: 20px;
}

.common-right-section.section-overdue {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 156px);
  padding-top: 56px;
  padding-bottom: 56px;
  box-sizing: border-box;

  .message {
    text-align: center;
    margin: 50px auto;
  }
}

/* Common popin */
.opacity-layer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 12;
}
.opacity-layer.--above {
  z-index: 50;
  background: rgba(0, 0, 0, 0.8);

}

.no-wrap {
  white-space: nowrap;
}
.pop-confirmation {
  position: fixed;
  top: 25%;
  width: 600px;
  margin: auto auto auto -300px;
  left: 50%;
  z-index: 3001;
  &.--white {
    background: #fff;
  }
  &.--high {
    top: 10%
  }
}
.pop-confirmation h3 {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}
.pop-confirmation .text {
  text-align: center;
  margin: 20px auto;
}
textarea {
  resize: none;
}
.phone-link a, .email-link a {
  color: chartSky();
  font-size: 12px;
  display: inline-block;
  width: 16px;
  height: 16px;
}
.viad-select span .icon-cancel-circle, .viad-select span  .icon-checkmark-circle {
  margin-top: 7px;
}
.viad-select1 {
  display: flex;
  color:#000;
  span {
    display: block;
    width: 13px;
    height: 13px;
    margin-right: 8px;
    &.green {
      color: green;
    }
    &.red {
      color: red;
    }
  }
}
body.--new {
  background: #fff;
}
.exports, .contact-container {
  width: 640px;
  margin: auto;
  text-align: left;
}
.exports h3, .contact-container h3, .profile h3, .ac3-pos h3 {
  font-weight: bold;
}
.exports .button-default, .contact-container .button-default, .profile .button-default {
  display: block;
  margin: 10px auto;
}
.exports .fields {
  width: 300px;
  margin: auto
}
.exports .row, .contact-container .row {
  margin-bottom: 20px;
}
.exports .datepickers .comiseo-daterangepicker-triggerbutton {
  border: none;
  height: 26px;
  margin-top: 0;
  color: #000!important;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.45);

  font-size: 12px;
}
.exports .select2-default .select2-chosen {
  color: #bbb!important;
}
.contact-container {
  .phone {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    a {
      color: rgb(0, 1, 107);
    }
  }
  .--flex {
    gap: 20px;
    & > div {
      width: 69%;
    }
  }
  .user-info {
    width: 31%!important;
  }
}
.contact-container .user-info input, .profile .--disabled input {
  background: #ccc;
  color: $greyText2
}
.profile, .ac3-pos {
  width: 980px;
  margin: 10px auto;
}

.ac3-pos-list {
  margin-top: 20px;
}
.ac3-pos .pos-ac3-domain {
  width: 20%;
  margin-right: 20px;
  background: #ccc;
  color: $greyText2
}
#wrap {
  background-color: white !important;
}
.main-content-new {
  margin: 30px;
}
#filterOrigin {
  margin-bottom: 15px;
}

.download-calendar {
  display: none;
}
.players-wrapper {
  margin-top: 5px;
  audio {
    max-width: 100%;
  }
}
ul.tagit {
  background-color: #fff!important;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.45);
  border-radius: 0!important;
  border: 0!important;
}



.small-lead {
  line-height: 18px;
  padding: 0 20px 7px 20px;
  text-align: left;
  display: block;
  &:hover {
    background-color: chartShimmer(0.4);
  }
  &.--current {
    background-color: chartShimmer(0.8);
  }
  &:not(:first-child) {
    .inside {
      border-top: 1px solid #ccc;
    }
  }
  .inside {
    display: block;
    padding-top: 7px;
  }
  .status {
    font-weight: 500;
  }
  .date {
    color: $greyText2;
    float: right;
    font-size: 10px;
  }
  .pos {
    display: block;
  }
  .seller {
    color: $greyText2;
    display: inline-block;
  }
  .interest {
    color: $greyText2;
    font-size: 10px;
    margin-left: 10px;
  }
}

