.span-status.lead-outprocess, .status.lead-outprocess {
  color: #999;
}
.span-status.lead-assignedTransfer, .status.lead-assignedTransfer {
  color: #cd39ff;
}
.span-status.lead-dms, .status.lead-dms {
  color: #41bbcb;
}
.span-status.lead-received, .status.lead-received {
  color: #009794 !important;
}
.span-status.lead-not-in-process, .status.lead-not-in-process {
  color: #00aba9 !important;
}
.span-status.lead-active, .status.lead-active {
  color: #6a00ff !important;
}
.span-status.lead-follow-dms, .status.lead-follow-dms {
  color: #41bbcb !important;
}
.span-status.lead-assigned, .status.lead-assigned {
  color: #aa00ff;
}
.span-status.lead-recontact, .status.lead-recontact {
  color: rgb(243, 159, 175);
}
.span-status.lead-follow, .status.lead-follow {
  color: #F39FAF;
}
.span-status.lead-confirmed-appointment, .status.lead-confirmed-appointment {
  color: #158BDB;
}
.span-status.lead-offers-sent, .status.lead-offers-sent {
  color: rgb(243, 159, 175);
}
.span-status.lead-waiting-result, .status.lead-waiting-result {
  color: #fa6800;
}
.span-status.lead-closed, .status.lead-closed {
  color: #499411;
}
.span-status.lead-sale, .status.lead-sale {
  color: #a4c400;
}
.span-status.lead-won, .status.lead-won {
  color: rgb(28, 134, 25);
}
.span-status.lead-mandate, .status.lead-mandate {
  color: rgb(70, 181, 116);
}
.span-status.lead-waiting-validation, .status.lead-waiting-validation {
  color: #e3c800;
}
.span-status.lead-abandoned, .status.lead-abandoned {
  color: rgb(119, 119, 119);
}
.span-status.notify-later, .status.notify-later {
  color: #e3c800;
}
.span-status.lead-lost, .status.lead-lost {
  color: rgb(241, 156, 0);
}
.span-status.lead-anonymized, .status.lead-anonymized {
  color: #f4b90c;
}
.span-status.lead-operator, .status.lead-operator {
  color: #bbb;
}
.span-status.lead-other, .status.lead-other {
  color: #c1c1c1;
}

.seller-lead-to-treat-section .dismiss-valid {
  background-color: #f4b90c;
  color: white;
}

.color-1-0 {
  background: rgb(140, 0, 28) !important;
}

.color-2-0 {
  background: rgb(140, 0, 28) !important;
}

.color-2-1 {
  background: rgb(252, 32, 60) !important;
}

.color-3-0 {
  background: rgb(140, 0, 28) !important;
}

.color-3-1 {
  background: rgb(252, 32, 60) !important;
}

.color-3-2 {
  background: rgb(252, 142, 60) !important;
}

.color-4-0 {
  background: rgb(140, 0, 28) !important;
}

.color-4-1 {
  background: rgb(252, 32, 60) !important;
}

.color-4-2 {
  background: rgb(252, 105, 60) !important;
}

.color-4-3 {
  background: rgb(252, 179, 60) !important;
}

.color-5-0 {
  background: rgb(140, 0, 28) !important;
}

.color-5-1 {
  background: rgb(252, 32, 60) !important;
}

.color-5-2 {
  background: rgb(252, 87, 60) !important;
}

.color-5-3 {
  background: rgb(252, 142, 60) !important;
}

.color-5-4 {
  background: rgb(252, 197, 60) !important;
}

.color-6-0 {
  background: rgb(140, 0, 28) !important;
}

.color-6-1 {
  background: rgb(252, 32, 60) !important;
}

.color-6-2 {
  background: rgb(252, 76, 60) !important;
}

.color-6-3 {
  background: rgb(252, 120, 60) !important;
}

.color-6-4 {
  background: rgb(252, 164, 60) !important;
}

.color-6-5 {
  background: rgb(252, 208, 60) !important;
}

.color-7-0 {
  background: rgb(140, 0, 28) !important;
}

.color-7-1 {
  background: rgb(252, 32, 60) !important;
}

.color-7-2 {
  background: rgb(252, 69, 60) !important;
}

.color-7-3 {
  background: rgb(252, 105, 60) !important;
}

.color-7-4 {
  background: rgb(252, 142, 60) !important;
}

.color-7-5 {
  background: rgb(252, 179, 60) !important;
}

.color-7-6 {
  background: rgb(252, 215, 60) !important;
}

.color-8-0 {
  background: rgb(140, 0, 28) !important;
}

.color-8-1 {
  background: rgb(252, 32, 60) !important;
}

.color-8-2 {
  background: rgb(252, 63, 60) !important;
}

.color-8-3 {
  background: rgb(252, 95, 60) !important;
}

.color-8-4 {
  background: rgb(252, 126, 60) !important;
}

.color-8-5 {
  background: rgb(252, 158, 60) !important;
}

.color-8-6 {
  background: rgb(252, 189, 60) !important;
}

.color-8-7 {
  background: rgb(252, 221, 60) !important;
}

.color-9-0 {
  background: rgb(140, 0, 28) !important;
}

.color-9-1 {
  background: rgb(252, 32, 60) !important;
}

.color-9-2 {
  background: rgb(252, 60, 60) !important;
}

.color-9-3 {
  background: rgb(252, 87, 60) !important;
}

.color-9-4 {
  background: rgb(252, 115, 60) !important;
}

.color-9-5 {
  background: rgb(252, 142, 60) !important;
}

.color-9-6 {
  background: rgb(252, 170, 60) !important;
}

.color-9-7 {
  background: rgb(252, 197, 60) !important;
}

.color-9-8 {
  background: rgb(252, 225, 60) !important;
}

.color-10-0 {
  background: rgb(140, 0, 28) !important;
}

.color-10-1 {
  background: rgb(252, 32, 60) !important;
}

.color-10-2 {
  background: rgb(252, 56, 60) !important;
}

.color-10-3 {
  background: rgb(252, 81, 60) !important;
}

.color-10-4 {
  background: rgb(252, 105, 60) !important;
}

.color-10-5 {
  background: rgb(252, 130, 60) !important;
}

.color-10-6 {
  background: rgb(252, 154, 60) !important;
}

.color-10-7 {
  background: rgb(252, 179, 60) !important;
}

.color-10-8 {
  background: rgb(252, 203, 60) !important;
}

.color-10-9 {
  background: rgb(252, 228, 60) !important;
}

.color-11-0 {
  background: rgb(140, 0, 28) !important;
}

.color-11-1 {
  background: rgb(252, 32, 60) !important;
}

.color-11-2 {
  background: rgb(252, 54, 60) !important;
}

.color-11-3 {
  background: rgb(252, 76, 60) !important;
}

.color-11-4 {
  background: rgb(252, 98, 60) !important;
}

.color-11-5 {
  background: rgb(252, 120, 60) !important;
}

.color-11-6 {
  background: rgb(252, 142, 60) !important;
}

.color-11-7 {
  background: rgb(252, 164, 60) !important;
}

.color-11-8 {
  background: rgb(252, 186, 60) !important;
}

.color-11-9 {
  background: rgb(252, 208, 60) !important;
}

.color-11-10 {
  background: rgb(252, 230, 60) !important;
}

.color-12-0 {
  background: rgb(140, 0, 28) !important;
}

.color-12-1 {
  background: rgb(252, 32, 60) !important;
}

.color-12-2 {
  background: rgb(252, 52, 60) !important;
}

.color-12-3 {
  background: rgb(252, 72, 60) !important;
}

.color-12-4 {
  background: rgb(252, 92, 60) !important;
}

.color-12-5 {
  background: rgb(252, 112, 60) !important;
}

.color-12-6 {
  background: rgb(252, 132, 60) !important;
}

.color-12-7 {
  background: rgb(252, 152, 60) !important;
}

.color-12-8 {
  background: rgb(252, 172, 60) !important;
}

.color-12-9 {
  background: rgb(252, 192, 60) !important;
}

.color-12-10 {
  background: rgb(252, 212, 60) !important;
}

.color-12-11 {
  background: rgb(252, 232, 60) !important;
}

.color-13-0 {
  background: rgb(140, 0, 28) !important;
}

.color-13-1 {
  background: rgb(252, 32, 60) !important;
}

.color-13-2 {
  background: rgb(252, 50, 60) !important;
}

.color-13-3 {
  background: rgb(252, 69, 60) !important;
}

.color-13-4 {
  background: rgb(252, 87, 60) !important;
}

.color-13-5 {
  background: rgb(252, 105, 60) !important;
}

.color-13-6 {
  background: rgb(252, 124, 60) !important;
}

.color-13-7 {
  background: rgb(252, 142, 60) !important;
}

.color-13-8 {
  background: rgb(252, 160, 60) !important;
}

.color-13-9 {
  background: rgb(252, 179, 60) !important;
}

.color-13-10 {
  background: rgb(252, 197, 60) !important;
}

.color-13-11 {
  background: rgb(252, 215, 60) !important;
}

.color-13-12 {
  background: rgb(252, 234, 60) !important;
}

.color-14-0 {
  background: rgb(140, 0, 28) !important;
}

.color-14-1 {
  background: rgb(252, 32, 60) !important;
}

.color-14-2 {
  background: rgb(252, 49, 60) !important;
}

.color-14-3 {
  background: rgb(252, 66, 60) !important;
}

.color-14-4 {
  background: rgb(252, 83, 60) !important;
}

.color-14-5 {
  background: rgb(252, 100, 60) !important;
}

.color-14-6 {
  background: rgb(252, 117, 60) !important;
}

.color-14-7 {
  background: rgb(252, 134, 60) !important;
}

.color-14-8 {
  background: rgb(252, 150, 60) !important;
}

.color-14-9 {
  background: rgb(252, 167, 60) !important;
}

.color-14-10 {
  background: rgb(252, 184, 60) !important;
}

.color-14-11 {
  background: rgb(252, 201, 60) !important;
}

.color-14-12 {
  background: rgb(252, 218, 60) !important;
}

.color-14-13 {
  background: rgb(252, 235, 60) !important;
}

.color-15-0 {
  background: rgb(140, 0, 28) !important;
}

.color-15-1 {
  background: rgb(252, 32, 60) !important;
}

.color-15-2 {
  background: rgb(252, 48, 60) !important;
}

.color-15-3 {
  background: rgb(252, 63, 60) !important;
}

.color-15-4 {
  background: rgb(252, 79, 60) !important;
}

.color-15-5 {
  background: rgb(252, 95, 60) !important;
}

.color-15-6 {
  background: rgb(252, 111, 60) !important;
}

.color-15-7 {
  background: rgb(252, 126, 60) !important;
}

.color-15-8 {
  background: rgb(252, 142, 60) !important;
}

.color-15-9 {
  background: rgb(252, 158, 60) !important;
}

.color-15-10 {
  background: rgb(252, 173, 60) !important;
}

.color-15-11 {
  background: rgb(252, 189, 60) !important;
}

.color-15-12 {
  background: rgb(252, 205, 60) !important;
}

.color-15-13 {
  background: rgb(252, 221, 60) !important;
}

.color-15-14 {
  background: rgb(252, 236, 60) !important;
}

.color-16-0 {
  background: rgb(140, 0, 28) !important;
}

.color-16-1 {
  background: rgb(252, 32, 60) !important;
}

.color-16-2 {
  background: rgb(252, 47, 60) !important;
}

.color-16-3 {
  background: rgb(252, 61, 60) !important;
}

.color-16-4 {
  background: rgb(252, 76, 60) !important;
}

.color-16-5 {
  background: rgb(252, 91, 60) !important;
}

.color-16-6 {
  background: rgb(252, 105, 60) !important;
}

.color-16-7 {
  background: rgb(252, 120, 60) !important;
}

.color-16-8 {
  background: rgb(252, 135, 60) !important;
}

.color-16-9 {
  background: rgb(252, 149, 60) !important;
}

.color-16-10 {
  background: rgb(252, 164, 60) !important;
}

.color-16-11 {
  background: rgb(252, 179, 60) !important;
}

.color-16-12 {
  background: rgb(252, 193, 60) !important;
}

.color-16-13 {
  background: rgb(252, 208, 60) !important;
}

.color-16-14 {
  background: rgb(252, 223, 60) !important;
}

.color-16-15 {
  background: rgb(252, 237, 60) !important;
}

.color-17-0 {
  background: rgb(140, 0, 28) !important;
}

.color-17-1 {
  background: rgb(252, 32, 60) !important;
}

.color-17-2 {
  background: rgb(252, 46, 60) !important;
}

.color-17-3 {
  background: rgb(252, 60, 60) !important;
}

.color-17-4 {
  background: rgb(252, 73, 60) !important;
}

.color-17-5 {
  background: rgb(252, 87, 60) !important;
}

.color-17-6 {
  background: rgb(252, 101, 60) !important;
}

.color-17-7 {
  background: rgb(252, 115, 60) !important;
}

.color-17-8 {
  background: rgb(252, 128, 60) !important;
}

.color-17-9 {
  background: rgb(252, 142, 60) !important;
}

.color-17-10 {
  background: rgb(252, 156, 60) !important;
}

.color-17-11 {
  background: rgb(252, 170, 60) !important;
}

.color-17-12 {
  background: rgb(252, 183, 60) !important;
}

.color-17-13 {
  background: rgb(252, 197, 60) !important;
}

.color-17-14 {
  background: rgb(252, 211, 60) !important;
}

.color-17-15 {
  background: rgb(252, 225, 60) !important;
}

.color-17-16 {
  background: rgb(252, 238, 60) !important;
}

.color-18-0 {
  background: rgb(140, 0, 28) !important;
}

.color-18-1 {
  background: rgb(252, 32, 60) !important;
}

.color-18-2 {
  background: rgb(252, 45, 60) !important;
}

.color-18-3 {
  background: rgb(252, 58, 60) !important;
}

.color-18-4 {
  background: rgb(252, 71, 60) !important;
}

.color-18-5 {
  background: rgb(252, 84, 60) !important;
}

.color-18-6 {
  background: rgb(252, 97, 60) !important;
}

.color-18-7 {
  background: rgb(252, 110, 60) !important;
}

.color-18-8 {
  background: rgb(252, 123, 60) !important;
}

.color-18-9 {
  background: rgb(252, 136, 60) !important;
}

.color-18-10 {
  background: rgb(252, 148, 60) !important;
}

.color-18-11 {
  background: rgb(252, 161, 60) !important;
}

.color-18-12 {
  background: rgb(252, 174, 60) !important;
}

.color-18-13 {
  background: rgb(252, 187, 60) !important;
}

.color-18-14 {
  background: rgb(252, 200, 60) !important;
}

.color-18-15 {
  background: rgb(252, 213, 60) !important;
}

.color-18-16 {
  background: rgb(252, 226, 60) !important;
}

.color-18-17 {
  background: rgb(252, 239, 60) !important;
}

.color-19-0 {
  background: rgb(140, 0, 28) !important;
}

.color-19-1 {
  background: rgb(252, 32, 60) !important;
}

.color-19-2 {
  background: rgb(252, 44, 60) !important;
}

.color-19-3 {
  background: rgb(252, 56, 60) !important;
}

.color-19-4 {
  background: rgb(252, 69, 60) !important;
}

.color-19-5 {
  background: rgb(252, 81, 60) !important;
}

.color-19-6 {
  background: rgb(252, 93, 60) !important;
}

.color-19-7 {
  background: rgb(252, 105, 60) !important;
}

.color-19-8 {
  background: rgb(252, 118, 60) !important;
}

.color-19-9 {
  background: rgb(252, 130, 60) !important;
}

.color-19-10 {
  background: rgb(252, 142, 60) !important;
}

.color-19-11 {
  background: rgb(252, 154, 60) !important;
}

.color-19-12 {
  background: rgb(252, 166, 60) !important;
}

.color-19-13 {
  background: rgb(252, 179, 60) !important;
}

.color-19-14 {
  background: rgb(252, 191, 60) !important;
}

.color-19-15 {
  background: rgb(252, 203, 60) !important;
}

.color-19-16 {
  background: rgb(252, 215, 60) !important;
}

.color-19-17 {
  background: rgb(252, 228, 60) !important;
}

.color-19-18 {
  background: rgb(252, 240, 60) !important;
}

.color-20-0 {
  background: rgb(140, 0, 28) !important;
}

.color-20-1 {
  background: rgb(252, 32, 60) !important;
}

.color-20-2 {
  background: rgb(252, 44, 60) !important;
}

.color-20-3 {
  background: rgb(252, 55, 60) !important;
}

.color-20-4 {
  background: rgb(252, 67, 60) !important;
}

.color-20-5 {
  background: rgb(252, 78, 60) !important;
}

.color-20-6 {
  background: rgb(252, 90, 60) !important;
}

.color-20-7 {
  background: rgb(252, 101, 60) !important;
}

.color-20-8 {
  background: rgb(252, 113, 60) !important;
}

.color-20-9 {
  background: rgb(252, 125, 60) !important;
}

.color-20-10 {
  background: rgb(252, 136, 60) !important;
}

.color-20-11 {
  background: rgb(252, 148, 60) !important;
}

.color-20-12 {
  background: rgb(252, 159, 60) !important;
}

.color-20-13 {
  background: rgb(252, 171, 60) !important;
}

.color-20-14 {
  background: rgb(252, 183, 60) !important;
}

.color-20-15 {
  background: rgb(252, 194, 60) !important;
}

.color-20-16 {
  background: rgb(252, 206, 60) !important;
}

.color-20-17 {
  background: rgb(252, 217, 60) !important;
}

.color-20-18 {
  background: rgb(252, 229, 60) !important;
}

.color-20-19 {
  background: rgb(252, 240, 60) !important;
}

.color-21-0 {
  background: rgb(140, 0, 28) !important;
}

.color-21-1 {
  background: rgb(252, 32, 60) !important;
}

.color-21-2 {
  background: rgb(252, 43, 60) !important;
}

.color-21-3 {
  background: rgb(252, 54, 60) !important;
}

.color-21-4 {
  background: rgb(252, 65, 60) !important;
}

.color-21-5 {
  background: rgb(252, 76, 60) !important;
}

.color-21-6 {
  background: rgb(252, 87, 60) !important;
}

.color-21-7 {
  background: rgb(252, 98, 60) !important;
}

.color-21-8 {
  background: rgb(252, 109, 60) !important;
}

.color-21-9 {
  background: rgb(252, 120, 60) !important;
}

.color-21-10 {
  background: rgb(252, 131, 60) !important;
}

.color-21-11 {
  background: rgb(252, 142, 60) !important;
}

.color-21-12 {
  background: rgb(252, 153, 60) !important;
}

.color-21-13 {
  background: rgb(252, 164, 60) !important;
}

.color-21-14 {
  background: rgb(252, 175, 60) !important;
}

.color-21-15 {
  background: rgb(252, 186, 60) !important;
}

.color-21-16 {
  background: rgb(252, 197, 60) !important;
}

.color-21-17 {
  background: rgb(252, 208, 60) !important;
}

.color-21-18 {
  background: rgb(252, 219, 60) !important;
}

.color-21-19 {
  background: rgb(252, 230, 60) !important;
}

.color-21-20 {
  background: rgb(252, 241, 60) !important;
}

.color-22-0 {
  background: rgb(140, 0, 28) !important;
}

.color-22-1 {
  background: rgb(252, 32, 60) !important;
}

.color-22-2 {
  background: rgb(252, 42, 60) !important;
}

.color-22-3 {
  background: rgb(252, 53, 60) !important;
}

.color-22-4 {
  background: rgb(252, 63, 60) !important;
}

.color-22-5 {
  background: rgb(252, 74, 60) !important;
}

.color-22-6 {
  background: rgb(252, 84, 60) !important;
}

.color-22-7 {
  background: rgb(252, 95, 60) !important;
}

.color-22-8 {
  background: rgb(252, 105, 60) !important;
}

.color-22-9 {
  background: rgb(252, 116, 60) !important;
}

.color-22-10 {
  background: rgb(252, 126, 60) !important;
}

.color-22-11 {
  background: rgb(252, 137, 60) !important;
}

.color-22-12 {
  background: rgb(252, 147, 60) !important;
}

.color-22-13 {
  background: rgb(252, 158, 60) !important;
}

.color-22-14 {
  background: rgb(252, 168, 60) !important;
}

.color-22-15 {
  background: rgb(252, 179, 60) !important;
}

.color-22-16 {
  background: rgb(252, 189, 60) !important;
}

.color-22-17 {
  background: rgb(252, 200, 60) !important;
}

.color-22-18 {
  background: rgb(252, 210, 60) !important;
}

.color-22-19 {
  background: rgb(252, 221, 60) !important;
}

.color-22-20 {
  background: rgb(252, 231, 60) !important;
}

.color-22-21 {
  background: rgb(252, 242, 60) !important;
}

.color-23-0 {
  background: rgb(140, 0, 28) !important;
}

.color-23-1 {
  background: rgb(252, 32, 60) !important;
}

.color-23-2 {
  background: rgb(252, 42, 60) !important;
}

.color-23-3 {
  background: rgb(252, 52, 60) !important;
}

.color-23-4 {
  background: rgb(252, 62, 60) !important;
}

.color-23-5 {
  background: rgb(252, 72, 60) !important;
}

.color-23-6 {
  background: rgb(252, 82, 60) !important;
}

.color-23-7 {
  background: rgb(252, 92, 60) !important;
}

.color-23-8 {
  background: rgb(252, 102, 60) !important;
}

.color-23-9 {
  background: rgb(252, 112, 60) !important;
}

.color-23-10 {
  background: rgb(252, 122, 60) !important;
}

.color-23-11 {
  background: rgb(252, 132, 60) !important;
}

.color-23-12 {
  background: rgb(252, 142, 60) !important;
}

.color-23-13 {
  background: rgb(252, 152, 60) !important;
}

.color-23-14 {
  background: rgb(252, 162, 60) !important;
}

.color-23-15 {
  background: rgb(252, 172, 60) !important;
}

.color-23-16 {
  background: rgb(252, 182, 60) !important;
}

.color-23-17 {
  background: rgb(252, 192, 60) !important;
}

.color-23-18 {
  background: rgb(252, 202, 60) !important;
}

.color-23-19 {
  background: rgb(252, 212, 60) !important;
}

.color-23-20 {
  background: rgb(252, 222, 60) !important;
}

.color-23-21 {
  background: rgb(252, 232, 60) !important;
}

.color-23-22 {
  background: rgb(252, 242, 60) !important;
}

.color-24-0 {
  background: rgb(140, 0, 28) !important;
}

.color-24-1 {
  background: rgb(252, 32, 60) !important;
}

.color-24-2 {
  background: rgb(252, 42, 60) !important;
}

.color-24-3 {
  background: rgb(252, 51, 60) !important;
}

.color-24-4 {
  background: rgb(252, 61, 60) !important;
}

.color-24-5 {
  background: rgb(252, 70, 60) !important;
}

.color-24-6 {
  background: rgb(252, 80, 60) !important;
}

.color-24-7 {
  background: rgb(252, 89, 60) !important;
}

.color-24-8 {
  background: rgb(252, 99, 60) !important;
}

.color-24-9 {
  background: rgb(252, 109, 60) !important;
}

.color-24-10 {
  background: rgb(252, 118, 60) !important;
}

.color-24-11 {
  background: rgb(252, 128, 60) !important;
}

.color-24-12 {
  background: rgb(252, 137, 60) !important;
}

.color-24-13 {
  background: rgb(252, 147, 60) !important;
}

.color-24-14 {
  background: rgb(252, 156, 60) !important;
}

.color-24-15 {
  background: rgb(252, 166, 60) !important;
}

.color-24-16 {
  background: rgb(252, 175, 60) !important;
}

.color-24-17 {
  background: rgb(252, 185, 60) !important;
}

.color-24-18 {
  background: rgb(252, 195, 60) !important;
}

.color-24-19 {
  background: rgb(252, 204, 60) !important;
}

.color-24-20 {
  background: rgb(252, 214, 60) !important;
}

.color-24-21 {
  background: rgb(252, 223, 60) !important;
}

.color-24-22 {
  background: rgb(252, 233, 60) !important;
}

.color-24-23 {
  background: rgb(252, 242, 60) !important;
}

.color-25-0 {
  background: rgb(140, 0, 28) !important;
}

.color-25-1 {
  background: rgb(252, 32, 60) !important;
}

.color-25-2 {
  background: rgb(252, 41, 60) !important;
}

.color-25-3 {
  background: rgb(252, 50, 60) !important;
}

.color-25-4 {
  background: rgb(252, 60, 60) !important;
}

.color-25-5 {
  background: rgb(252, 69, 60) !important;
}

.color-25-6 {
  background: rgb(252, 78, 60) !important;
}

.color-25-7 {
  background: rgb(252, 87, 60) !important;
}

.color-25-8 {
  background: rgb(252, 96, 60) !important;
}

.color-25-9 {
  background: rgb(252, 105, 60) !important;
}

.color-25-10 {
  background: rgb(252, 115, 60) !important;
}

.color-25-11 {
  background: rgb(252, 124, 60) !important;
}

.color-25-12 {
  background: rgb(252, 133, 60) !important;
}

.color-25-13 {
  background: rgb(252, 142, 60) !important;
}

.color-25-14 {
  background: rgb(252, 151, 60) !important;
}

.color-25-15 {
  background: rgb(252, 160, 60) !important;
}

.color-25-16 {
  background: rgb(252, 170, 60) !important;
}

.color-25-17 {
  background: rgb(252, 179, 60) !important;
}

.color-25-18 {
  background: rgb(252, 188, 60) !important;
}

.color-25-19 {
  background: rgb(252, 197, 60) !important;
}

.color-25-20 {
  background: rgb(252, 206, 60) !important;
}

.color-25-21 {
  background: rgb(252, 215, 60) !important;
}

.color-25-22 {
  background: rgb(252, 225, 60) !important;
}

.color-25-23 {
  background: rgb(252, 234, 60) !important;
}

.color-25-24 {
  background: rgb(252, 243, 60) !important;
}

.color-26-0 {
  background: rgb(140, 0, 28) !important;
}

.color-26-1 {
  background: rgb(252, 32, 60) !important;
}

.color-26-2 {
  background: rgb(252, 41, 60) !important;
}

.color-26-3 {
  background: rgb(252, 50, 60) !important;
}

.color-26-4 {
  background: rgb(252, 58, 60) !important;
}

.color-26-5 {
  background: rgb(252, 67, 60) !important;
}

.color-26-6 {
  background: rgb(252, 76, 60) !important;
}

.color-26-7 {
  background: rgb(252, 85, 60) !important;
}

.color-26-8 {
  background: rgb(252, 94, 60) !important;
}

.color-26-9 {
  background: rgb(252, 102, 60) !important;
}

.color-26-10 {
  background: rgb(252, 111, 60) !important;
}

.color-26-11 {
  background: rgb(252, 120, 60) !important;
}

.color-26-12 {
  background: rgb(252, 129, 60) !important;
}

.color-26-13 {
  background: rgb(252, 138, 60) !important;
}

.color-26-14 {
  background: rgb(252, 146, 60) !important;
}

.color-26-15 {
  background: rgb(252, 155, 60) !important;
}

.color-26-16 {
  background: rgb(252, 164, 60) !important;
}

.color-26-17 {
  background: rgb(252, 173, 60) !important;
}

.color-26-18 {
  background: rgb(252, 182, 60) !important;
}

.color-26-19 {
  background: rgb(252, 190, 60) !important;
}

.color-26-20 {
  background: rgb(252, 199, 60) !important;
}

.color-26-21 {
  background: rgb(252, 208, 60) !important;
}

.color-26-22 {
  background: rgb(252, 217, 60) !important;
}

.color-26-23 {
  background: rgb(252, 226, 60) !important;
}

.color-26-24 {
  background: rgb(252, 234, 60) !important;
}

.color-26-25 {
  background: rgb(252, 243, 60) !important;
}

.color-27-0 {
  background: rgb(140, 0, 28) !important;
}

.color-27-1 {
  background: rgb(252, 32, 60) !important;
}

.color-27-2 {
  background: rgb(252, 40, 60) !important;
}

.color-27-3 {
  background: rgb(252, 49, 60) !important;
}

.color-27-4 {
  background: rgb(252, 57, 60) !important;
}

.color-27-5 {
  background: rgb(252, 66, 60) !important;
}

.color-27-6 {
  background: rgb(252, 74, 60) !important;
}

.color-27-7 {
  background: rgb(252, 83, 60) !important;
}

.color-27-8 {
  background: rgb(252, 91, 60) !important;
}

.color-27-9 {
  background: rgb(252, 100, 60) !important;
}

.color-27-10 {
  background: rgb(252, 108, 60) !important;
}

.color-27-11 {
  background: rgb(252, 117, 60) !important;
}

.color-27-12 {
  background: rgb(252, 125, 60) !important;
}

.color-27-13 {
  background: rgb(252, 134, 60) !important;
}

.color-27-14 {
  background: rgb(252, 142, 60) !important;
}

.color-27-15 {
  background: rgb(252, 150, 60) !important;
}

.color-27-16 {
  background: rgb(252, 159, 60) !important;
}

.color-27-17 {
  background: rgb(252, 167, 60) !important;
}

.color-27-18 {
  background: rgb(252, 176, 60) !important;
}

.color-27-19 {
  background: rgb(252, 184, 60) !important;
}

.color-27-20 {
  background: rgb(252, 193, 60) !important;
}

.color-27-21 {
  background: rgb(252, 201, 60) !important;
}

.color-27-22 {
  background: rgb(252, 210, 60) !important;
}

.color-27-23 {
  background: rgb(252, 218, 60) !important;
}

.color-27-24 {
  background: rgb(252, 227, 60) !important;
}

.color-27-25 {
  background: rgb(252, 235, 60) !important;
}

.color-27-26 {
  background: rgb(252, 244, 60) !important;
}

.color-28-0 {
  background: rgb(140, 0, 28) !important;
}

.color-28-1 {
  background: rgb(252, 32, 60) !important;
}

.color-28-2 {
  background: rgb(252, 40, 60) !important;
}

.color-28-3 {
  background: rgb(252, 48, 60) !important;
}

.color-28-4 {
  background: rgb(252, 56, 60) !important;
}

.color-28-5 {
  background: rgb(252, 65, 60) !important;
}

.color-28-6 {
  background: rgb(252, 73, 60) !important;
}

.color-28-7 {
  background: rgb(252, 81, 60) !important;
}

.color-28-8 {
  background: rgb(252, 89, 60) !important;
}

.color-28-9 {
  background: rgb(252, 97, 60) !important;
}

.color-28-10 {
  background: rgb(252, 105, 60) !important;
}

.color-28-11 {
  background: rgb(252, 113, 60) !important;
}

.color-28-12 {
  background: rgb(252, 122, 60) !important;
}

.color-28-13 {
  background: rgb(252, 130, 60) !important;
}

.color-28-14 {
  background: rgb(252, 138, 60) !important;
}

.color-28-15 {
  background: rgb(252, 146, 60) !important;
}

.color-28-16 {
  background: rgb(252, 154, 60) !important;
}

.color-28-17 {
  background: rgb(252, 162, 60) !important;
}

.color-28-18 {
  background: rgb(252, 171, 60) !important;
}

.color-28-19 {
  background: rgb(252, 179, 60) !important;
}

.color-28-20 {
  background: rgb(252, 187, 60) !important;
}

.color-28-21 {
  background: rgb(252, 195, 60) !important;
}

.color-28-22 {
  background: rgb(252, 203, 60) !important;
}

.color-28-23 {
  background: rgb(252, 211, 60) !important;
}

.color-28-24 {
  background: rgb(252, 219, 60) !important;
}

.color-28-25 {
  background: rgb(252, 228, 60) !important;
}

.color-28-26 {
  background: rgb(252, 236, 60) !important;
}

.color-28-27 {
  background: rgb(252, 244, 60) !important;
}

.color-29-0 {
  background: rgb(140, 0, 28) !important;
}

.color-29-1 {
  background: rgb(252, 32, 60) !important;
}

.color-29-2 {
  background: rgb(252, 40, 60) !important;
}

.color-29-3 {
  background: rgb(252, 48, 60) !important;
}

.color-29-4 {
  background: rgb(252, 56, 60) !important;
}

.color-29-5 {
  background: rgb(252, 63, 60) !important;
}

.color-29-6 {
  background: rgb(252, 71, 60) !important;
}

.color-29-7 {
  background: rgb(252, 79, 60) !important;
}

.color-29-8 {
  background: rgb(252, 87, 60) !important;
}

.color-29-9 {
  background: rgb(252, 95, 60) !important;
}

.color-29-10 {
  background: rgb(252, 103, 60) !important;
}

.color-29-11 {
  background: rgb(252, 111, 60) !important;
}

.color-29-12 {
  background: rgb(252, 118, 60) !important;
}

.color-29-13 {
  background: rgb(252, 126, 60) !important;
}

.color-29-14 {
  background: rgb(252, 134, 60) !important;
}

.color-29-15 {
  background: rgb(252, 142, 60) !important;
}

.color-29-16 {
  background: rgb(252, 150, 60) !important;
}

.color-29-17 {
  background: rgb(252, 158, 60) !important;
}

.color-29-18 {
  background: rgb(252, 166, 60) !important;
}

.color-29-19 {
  background: rgb(252, 173, 60) !important;
}

.color-29-20 {
  background: rgb(252, 181, 60) !important;
}

.color-29-21 {
  background: rgb(252, 189, 60) !important;
}

.color-29-22 {
  background: rgb(252, 197, 60) !important;
}

.color-29-23 {
  background: rgb(252, 205, 60) !important;
}

.color-29-24 {
  background: rgb(252, 213, 60) !important;
}

.color-29-25 {
  background: rgb(252, 221, 60) !important;
}

.color-29-26 {
  background: rgb(252, 228, 60) !important;
}

.color-29-27 {
  background: rgb(252, 236, 60) !important;
}

.color-29-28 {
  background: rgb(252, 244, 60) !important;
}

.color-30-0 {
  background: rgb(140, 0, 28) !important;
}

.color-30-1 {
  background: rgb(252, 32, 60) !important;
}

.color-30-2 {
  background: rgb(252, 40, 60) !important;
}

.color-30-3 {
  background: rgb(252, 47, 60) !important;
}

.color-30-4 {
  background: rgb(252, 55, 60) !important;
}

.color-30-5 {
  background: rgb(252, 62, 60) !important;
}

.color-30-6 {
  background: rgb(252, 70, 60) !important;
}

.color-30-7 {
  background: rgb(252, 78, 60) !important;
}

.color-30-8 {
  background: rgb(252, 85, 60) !important;
}

.color-30-9 {
  background: rgb(252, 93, 60) !important;
}

.color-30-10 {
  background: rgb(252, 100, 60) !important;
}

.color-30-11 {
  background: rgb(252, 108, 60) !important;
}

.color-30-12 {
  background: rgb(252, 115, 60) !important;
}

.color-30-13 {
  background: rgb(252, 123, 60) !important;
}

.color-30-14 {
  background: rgb(252, 131, 60) !important;
}

.color-30-15 {
  background: rgb(252, 138, 60) !important;
}

.color-30-16 {
  background: rgb(252, 146, 60) !important;
}

.color-30-17 {
  background: rgb(252, 153, 60) !important;
}

.color-30-18 {
  background: rgb(252, 161, 60) !important;
}

.color-30-19 {
  background: rgb(252, 169, 60) !important;
}

.color-30-20 {
  background: rgb(252, 176, 60) !important;
}

.color-30-21 {
  background: rgb(252, 184, 60) !important;
}

.color-30-22 {
  background: rgb(252, 191, 60) !important;
}

.color-30-23 {
  background: rgb(252, 199, 60) !important;
}

.color-30-24 {
  background: rgb(252, 206, 60) !important;
}

.color-30-25 {
  background: rgb(252, 214, 60) !important;
}

.color-30-26 {
  background: rgb(252, 222, 60) !important;
}

.color-30-27 {
  background: rgb(252, 229, 60) !important;
}

.color-30-28 {
  background: rgb(252, 237, 60) !important;
}

.color-30-29 {
  background: rgb(252, 244, 60) !important;
}

.color-31-0 {
  background: rgb(140, 0, 28) !important;
}

.color-31-1 {
  background: rgb(252, 32, 60) !important;
}

.color-31-2 {
  background: rgb(252, 39, 60) !important;
}

.color-31-3 {
  background: rgb(252, 47, 60) !important;
}

.color-31-4 {
  background: rgb(252, 54, 60) !important;
}

.color-31-5 {
  background: rgb(252, 61, 60) !important;
}

.color-31-6 {
  background: rgb(252, 69, 60) !important;
}

.color-31-7 {
  background: rgb(252, 76, 60) !important;
}

.color-31-8 {
  background: rgb(252, 83, 60) !important;
}

.color-31-9 {
  background: rgb(252, 91, 60) !important;
}

.color-31-10 {
  background: rgb(252, 98, 60) !important;
}

.color-31-11 {
  background: rgb(252, 105, 60) !important;
}

.color-31-12 {
  background: rgb(252, 113, 60) !important;
}

.color-31-13 {
  background: rgb(252, 120, 60) !important;
}

.color-31-14 {
  background: rgb(252, 127, 60) !important;
}

.color-31-15 {
  background: rgb(252, 135, 60) !important;
}

.color-31-16 {
  background: rgb(252, 142, 60) !important;
}

.color-31-17 {
  background: rgb(252, 149, 60) !important;
}

.color-31-18 {
  background: rgb(252, 157, 60) !important;
}

.color-31-19 {
  background: rgb(252, 164, 60) !important;
}

.color-31-20 {
  background: rgb(252, 171, 60) !important;
}

.color-31-21 {
  background: rgb(252, 179, 60) !important;
}

.color-31-22 {
  background: rgb(252, 186, 60) !important;
}

.color-31-23 {
  background: rgb(252, 193, 60) !important;
}

.color-31-24 {
  background: rgb(252, 201, 60) !important;
}

.color-31-25 {
  background: rgb(252, 208, 60) !important;
}

.color-31-26 {
  background: rgb(252, 215, 60) !important;
}

.color-31-27 {
  background: rgb(252, 223, 60) !important;
}

.color-31-28 {
  background: rgb(252, 230, 60) !important;
}

.color-31-29 {
  background: rgb(252, 237, 60) !important;
}

.color-31-30 {
  background: rgb(252, 245, 60) !important;
}

.color-32-0 {
  background: rgb(140, 0, 28) !important;
}

.color-32-1 {
  background: rgb(252, 32, 60) !important;
}

.color-32-2 {
  background: rgb(252, 39, 60) !important;
}

.color-32-3 {
  background: rgb(252, 46, 60) !important;
}

.color-32-4 {
  background: rgb(252, 53, 60) !important;
}

.color-32-5 {
  background: rgb(252, 60, 60) !important;
}

.color-32-6 {
  background: rgb(252, 67, 60) !important;
}

.color-32-7 {
  background: rgb(252, 75, 60) !important;
}

.color-32-8 {
  background: rgb(252, 82, 60) !important;
}

.color-32-9 {
  background: rgb(252, 89, 60) !important;
}

.color-32-10 {
  background: rgb(252, 96, 60) !important;
}

.color-32-11 {
  background: rgb(252, 103, 60) !important;
}

.color-32-12 {
  background: rgb(252, 110, 60) !important;
}

.color-32-13 {
  background: rgb(252, 117, 60) !important;
}

.color-32-14 {
  background: rgb(252, 124, 60) !important;
}

.color-32-15 {
  background: rgb(252, 131, 60) !important;
}

.color-32-16 {
  background: rgb(252, 138, 60) !important;
}

.color-32-17 {
  background: rgb(252, 146, 60) !important;
}

.color-32-18 {
  background: rgb(252, 153, 60) !important;
}

.color-32-19 {
  background: rgb(252, 160, 60) !important;
}

.color-32-20 {
  background: rgb(252, 167, 60) !important;
}

.color-32-21 {
  background: rgb(252, 174, 60) !important;
}

.color-32-22 {
  background: rgb(252, 181, 60) !important;
}

.color-32-23 {
  background: rgb(252, 188, 60) !important;
}

.color-32-24 {
  background: rgb(252, 195, 60) !important;
}

.color-32-25 {
  background: rgb(252, 202, 60) !important;
}

.color-32-26 {
  background: rgb(252, 209, 60) !important;
}

.color-32-27 {
  background: rgb(252, 217, 60) !important;
}

.color-32-28 {
  background: rgb(252, 224, 60) !important;
}

.color-32-29 {
  background: rgb(252, 231, 60) !important;
}

.color-32-30 {
  background: rgb(252, 238, 60) !important;
}

.color-32-31 {
  background: rgb(252, 245, 60) !important;
}

.color-33-0 {
  background: rgb(140, 0, 28) !important;
}

.color-33-1 {
  background: rgb(252, 32, 60) !important;
}

.color-33-2 {
  background: rgb(252, 39, 60) !important;
}

.color-33-3 {
  background: rgb(252, 46, 60) !important;
}

.color-33-4 {
  background: rgb(252, 53, 60) !important;
}

.color-33-5 {
  background: rgb(252, 60, 60) !important;
}

.color-33-6 {
  background: rgb(252, 66, 60) !important;
}

.color-33-7 {
  background: rgb(252, 73, 60) !important;
}

.color-33-8 {
  background: rgb(252, 80, 60) !important;
}

.color-33-9 {
  background: rgb(252, 87, 60) !important;
}

.color-33-10 {
  background: rgb(252, 94, 60) !important;
}

.color-33-11 {
  background: rgb(252, 101, 60) !important;
}

.color-33-12 {
  background: rgb(252, 108, 60) !important;
}

.color-33-13 {
  background: rgb(252, 115, 60) !important;
}

.color-33-14 {
  background: rgb(252, 121, 60) !important;
}

.color-33-15 {
  background: rgb(252, 128, 60) !important;
}

.color-33-16 {
  background: rgb(252, 135, 60) !important;
}

.color-33-17 {
  background: rgb(252, 142, 60) !important;
}

.color-33-18 {
  background: rgb(252, 149, 60) !important;
}

.color-33-19 {
  background: rgb(252, 156, 60) !important;
}

.color-33-20 {
  background: rgb(252, 163, 60) !important;
}

.color-33-21 {
  background: rgb(252, 170, 60) !important;
}

.color-33-22 {
  background: rgb(252, 176, 60) !important;
}

.color-33-23 {
  background: rgb(252, 183, 60) !important;
}

.color-33-24 {
  background: rgb(252, 190, 60) !important;
}

.color-33-25 {
  background: rgb(252, 197, 60) !important;
}

.color-33-26 {
  background: rgb(252, 204, 60) !important;
}

.color-33-27 {
  background: rgb(252, 211, 60) !important;
}

.color-33-28 {
  background: rgb(252, 218, 60) !important;
}

.color-33-29 {
  background: rgb(252, 225, 60) !important;
}

.color-33-30 {
  background: rgb(252, 231, 60) !important;
}

.color-33-31 {
  background: rgb(252, 238, 60) !important;
}

.color-33-32 {
  background: rgb(252, 245, 60) !important;
}

.color-34-0 {
  background: rgb(140, 0, 28) !important;
}

.color-34-1 {
  background: rgb(252, 32, 60) !important;
}

.color-34-2 {
  background: rgb(252, 39, 60) !important;
}

.color-34-3 {
  background: rgb(252, 45, 60) !important;
}

.color-34-4 {
  background: rgb(252, 52, 60) !important;
}

.color-34-5 {
  background: rgb(252, 59, 60) !important;
}

.color-34-6 {
  background: rgb(252, 65, 60) !important;
}

.color-34-7 {
  background: rgb(252, 72, 60) !important;
}

.color-34-8 {
  background: rgb(252, 79, 60) !important;
}

.color-34-9 {
  background: rgb(252, 85, 60) !important;
}

.color-34-10 {
  background: rgb(252, 92, 60) !important;
}

.color-34-11 {
  background: rgb(252, 99, 60) !important;
}

.color-34-12 {
  background: rgb(252, 105, 60) !important;
}

.color-34-13 {
  background: rgb(252, 112, 60) !important;
}

.color-34-14 {
  background: rgb(252, 119, 60) !important;
}

.color-34-15 {
  background: rgb(252, 125, 60) !important;
}

.color-34-16 {
  background: rgb(252, 132, 60) !important;
}

.color-34-17 {
  background: rgb(252, 139, 60) !important;
}

.color-34-18 {
  background: rgb(252, 145, 60) !important;
}

.color-34-19 {
  background: rgb(252, 152, 60) !important;
}

.color-34-20 {
  background: rgb(252, 159, 60) !important;
}

.color-34-21 {
  background: rgb(252, 165, 60) !important;
}

.color-34-22 {
  background: rgb(252, 172, 60) !important;
}

.color-34-23 {
  background: rgb(252, 179, 60) !important;
}

.color-34-24 {
  background: rgb(252, 185, 60) !important;
}

.color-34-25 {
  background: rgb(252, 192, 60) !important;
}

.color-34-26 {
  background: rgb(252, 199, 60) !important;
}

.color-34-27 {
  background: rgb(252, 205, 60) !important;
}

.color-34-28 {
  background: rgb(252, 212, 60) !important;
}

.color-34-29 {
  background: rgb(252, 219, 60) !important;
}

.color-34-30 {
  background: rgb(252, 225, 60) !important;
}

.color-34-31 {
  background: rgb(252, 232, 60) !important;
}

.color-34-32 {
  background: rgb(252, 239, 60) !important;
}

.color-34-33 {
  background: rgb(252, 245, 60) !important;
}

.color-35-0 {
  background: rgb(140, 0, 28) !important;
}

.color-35-1 {
  background: rgb(252, 32, 60) !important;
}

.color-35-2 {
  background: rgb(252, 38, 60) !important;
}

.color-35-3 {
  background: rgb(252, 45, 60) !important;
}

.color-35-4 {
  background: rgb(252, 51, 60) !important;
}

.color-35-5 {
  background: rgb(252, 58, 60) !important;
}

.color-35-6 {
  background: rgb(252, 64, 60) !important;
}

.color-35-7 {
  background: rgb(252, 71, 60) !important;
}

.color-35-8 {
  background: rgb(252, 77, 60) !important;
}

.color-35-9 {
  background: rgb(252, 84, 60) !important;
}

.color-35-10 {
  background: rgb(252, 90, 60) !important;
}

.color-35-11 {
  background: rgb(252, 97, 60) !important;
}

.color-35-12 {
  background: rgb(252, 103, 60) !important;
}

.color-35-13 {
  background: rgb(252, 110, 60) !important;
}

.color-35-14 {
  background: rgb(252, 116, 60) !important;
}

.color-35-15 {
  background: rgb(252, 123, 60) !important;
}

.color-35-16 {
  background: rgb(252, 129, 60) !important;
}

.color-35-17 {
  background: rgb(252, 136, 60) !important;
}

.color-35-18 {
  background: rgb(252, 142, 60) !important;
}

.color-35-19 {
  background: rgb(252, 148, 60) !important;
}

.color-35-20 {
  background: rgb(252, 155, 60) !important;
}

.color-35-21 {
  background: rgb(252, 161, 60) !important;
}

.color-35-22 {
  background: rgb(252, 168, 60) !important;
}

.color-35-23 {
  background: rgb(252, 174, 60) !important;
}

.color-35-24 {
  background: rgb(252, 181, 60) !important;
}

.color-35-25 {
  background: rgb(252, 187, 60) !important;
}

.color-35-26 {
  background: rgb(252, 194, 60) !important;
}

.color-35-27 {
  background: rgb(252, 200, 60) !important;
}

.color-35-28 {
  background: rgb(252, 207, 60) !important;
}

.color-35-29 {
  background: rgb(252, 213, 60) !important;
}

.color-35-30 {
  background: rgb(252, 220, 60) !important;
}

.color-35-31 {
  background: rgb(252, 226, 60) !important;
}

.color-35-32 {
  background: rgb(252, 233, 60) !important;
}

.color-35-33 {
  background: rgb(252, 239, 60) !important;
}

.color-35-34 {
  background: rgb(252, 246, 60) !important;
}

.color-36-0 {
  background: rgb(140, 0, 28) !important;
}

.color-36-1 {
  background: rgb(252, 32, 60) !important;
}

.color-36-2 {
  background: rgb(252, 38, 60) !important;
}

.color-36-3 {
  background: rgb(252, 45, 60) !important;
}

.color-36-4 {
  background: rgb(252, 51, 60) !important;
}

.color-36-5 {
  background: rgb(252, 57, 60) !important;
}

.color-36-6 {
  background: rgb(252, 63, 60) !important;
}

.color-36-7 {
  background: rgb(252, 70, 60) !important;
}

.color-36-8 {
  background: rgb(252, 76, 60) !important;
}

.color-36-9 {
  background: rgb(252, 82, 60) !important;
}

.color-36-10 {
  background: rgb(252, 89, 60) !important;
}

.color-36-11 {
  background: rgb(252, 95, 60) !important;
}

.color-36-12 {
  background: rgb(252, 101, 60) !important;
}

.color-36-13 {
  background: rgb(252, 107, 60) !important;
}

.color-36-14 {
  background: rgb(252, 114, 60) !important;
}

.color-36-15 {
  background: rgb(252, 120, 60) !important;
}

.color-36-16 {
  background: rgb(252, 126, 60) !important;
}

.color-36-17 {
  background: rgb(252, 133, 60) !important;
}

.color-36-18 {
  background: rgb(252, 139, 60) !important;
}

.color-36-19 {
  background: rgb(252, 145, 60) !important;
}

.color-36-20 {
  background: rgb(252, 151, 60) !important;
}

.color-36-21 {
  background: rgb(252, 158, 60) !important;
}

.color-36-22 {
  background: rgb(252, 164, 60) !important;
}

.color-36-23 {
  background: rgb(252, 170, 60) !important;
}

.color-36-24 {
  background: rgb(252, 177, 60) !important;
}

.color-36-25 {
  background: rgb(252, 183, 60) !important;
}

.color-36-26 {
  background: rgb(252, 189, 60) !important;
}

.color-36-27 {
  background: rgb(252, 195, 60) !important;
}

.color-36-28 {
  background: rgb(252, 202, 60) !important;
}

.color-36-29 {
  background: rgb(252, 208, 60) !important;
}

.color-36-30 {
  background: rgb(252, 214, 60) !important;
}

.color-36-31 {
  background: rgb(252, 221, 60) !important;
}

.color-36-32 {
  background: rgb(252, 227, 60) !important;
}

.color-36-33 {
  background: rgb(252, 233, 60) !important;
}

.color-36-34 {
  background: rgb(252, 239, 60) !important;
}

.color-36-35 {
  background: rgb(252, 246, 60) !important;
}

.color-37-0 {
  background: rgb(140, 0, 28) !important;
}

.color-37-1 {
  background: rgb(252, 32, 60) !important;
}

.color-37-2 {
  background: rgb(252, 38, 60) !important;
}

.color-37-3 {
  background: rgb(252, 44, 60) !important;
}

.color-37-4 {
  background: rgb(252, 50, 60) !important;
}

.color-37-5 {
  background: rgb(252, 56, 60) !important;
}

.color-37-6 {
  background: rgb(252, 63, 60) !important;
}

.color-37-7 {
  background: rgb(252, 69, 60) !important;
}

.color-37-8 {
  background: rgb(252, 75, 60) !important;
}

.color-37-9 {
  background: rgb(252, 81, 60) !important;
}

.color-37-10 {
  background: rgb(252, 87, 60) !important;
}

.color-37-11 {
  background: rgb(252, 93, 60) !important;
}

.color-37-12 {
  background: rgb(252, 99, 60) !important;
}

.color-37-13 {
  background: rgb(252, 105, 60) !important;
}

.color-37-14 {
  background: rgb(252, 111, 60) !important;
}

.color-37-15 {
  background: rgb(252, 118, 60) !important;
}

.color-37-16 {
  background: rgb(252, 124, 60) !important;
}

.color-37-17 {
  background: rgb(252, 130, 60) !important;
}

.color-37-18 {
  background: rgb(252, 136, 60) !important;
}

.color-37-19 {
  background: rgb(252, 142, 60) !important;
}

.color-37-20 {
  background: rgb(252, 148, 60) !important;
}

.color-37-21 {
  background: rgb(252, 154, 60) !important;
}

.color-37-22 {
  background: rgb(252, 160, 60) !important;
}

.color-37-23 {
  background: rgb(252, 166, 60) !important;
}

.color-37-24 {
  background: rgb(252, 173, 60) !important;
}

.color-37-25 {
  background: rgb(252, 179, 60) !important;
}

.color-37-26 {
  background: rgb(252, 185, 60) !important;
}

.color-37-27 {
  background: rgb(252, 191, 60) !important;
}

.color-37-28 {
  background: rgb(252, 197, 60) !important;
}

.color-37-29 {
  background: rgb(252, 203, 60) !important;
}

.color-37-30 {
  background: rgb(252, 209, 60) !important;
}

.color-37-31 {
  background: rgb(252, 215, 60) !important;
}

.color-37-32 {
  background: rgb(252, 221, 60) !important;
}

.color-37-33 {
  background: rgb(252, 228, 60) !important;
}

.color-37-34 {
  background: rgb(252, 234, 60) !important;
}

.color-37-35 {
  background: rgb(252, 240, 60) !important;
}

.color-37-36 {
  background: rgb(252, 246, 60) !important;
}

.color-38-0 {
  background: rgb(140, 0, 28) !important;
}

.color-38-1 {
  background: rgb(252, 32, 60) !important;
}

.color-38-2 {
  background: rgb(252, 38, 60) !important;
}

.color-38-3 {
  background: rgb(252, 44, 60) !important;
}

.color-38-4 {
  background: rgb(252, 50, 60) !important;
}

.color-38-5 {
  background: rgb(252, 56, 60) !important;
}

.color-38-6 {
  background: rgb(252, 62, 60) !important;
}

.color-38-7 {
  background: rgb(252, 68, 60) !important;
}

.color-38-8 {
  background: rgb(252, 74, 60) !important;
}

.color-38-9 {
  background: rgb(252, 80, 60) !important;
}

.color-38-10 {
  background: rgb(252, 86, 60) !important;
}

.color-38-11 {
  background: rgb(252, 91, 60) !important;
}

.color-38-12 {
  background: rgb(252, 97, 60) !important;
}

.color-38-13 {
  background: rgb(252, 103, 60) !important;
}

.color-38-14 {
  background: rgb(252, 109, 60) !important;
}

.color-38-15 {
  background: rgb(252, 115, 60) !important;
}

.color-38-16 {
  background: rgb(252, 121, 60) !important;
}

.color-38-17 {
  background: rgb(252, 127, 60) !important;
}

.color-38-18 {
  background: rgb(252, 133, 60) !important;
}

.color-38-19 {
  background: rgb(252, 139, 60) !important;
}

.color-38-20 {
  background: rgb(252, 145, 60) !important;
}

.color-38-21 {
  background: rgb(252, 151, 60) !important;
}

.color-38-22 {
  background: rgb(252, 157, 60) !important;
}

.color-38-23 {
  background: rgb(252, 163, 60) !important;
}

.color-38-24 {
  background: rgb(252, 169, 60) !important;
}

.color-38-25 {
  background: rgb(252, 175, 60) !important;
}

.color-38-26 {
  background: rgb(252, 181, 60) !important;
}

.color-38-27 {
  background: rgb(252, 187, 60) !important;
}

.color-38-28 {
  background: rgb(252, 193, 60) !important;
}

.color-38-29 {
  background: rgb(252, 198, 60) !important;
}

.color-38-30 {
  background: rgb(252, 204, 60) !important;
}

.color-38-31 {
  background: rgb(252, 210, 60) !important;
}

.color-38-32 {
  background: rgb(252, 216, 60) !important;
}

.color-38-33 {
  background: rgb(252, 222, 60) !important;
}

.color-38-34 {
  background: rgb(252, 228, 60) !important;
}

.color-38-35 {
  background: rgb(252, 234, 60) !important;
}

.color-38-36 {
  background: rgb(252, 240, 60) !important;
}

.color-38-37 {
  background: rgb(252, 246, 60) !important;
}

.color-39-0 {
  background: rgb(140, 0, 28) !important;
}

.color-39-1 {
  background: rgb(252, 32, 60) !important;
}

.color-39-2 {
  background: rgb(252, 38, 60) !important;
}

.color-39-3 {
  background: rgb(252, 44, 60) !important;
}

.color-39-4 {
  background: rgb(252, 49, 60) !important;
}

.color-39-5 {
  background: rgb(252, 55, 60) !important;
}

.color-39-6 {
  background: rgb(252, 61, 60) !important;
}

.color-39-7 {
  background: rgb(252, 67, 60) !important;
}

.color-39-8 {
  background: rgb(252, 73, 60) !important;
}

.color-39-9 {
  background: rgb(252, 78, 60) !important;
}

.color-39-10 {
  background: rgb(252, 84, 60) !important;
}

.color-39-11 {
  background: rgb(252, 90, 60) !important;
}

.color-39-12 {
  background: rgb(252, 96, 60) !important;
}

.color-39-13 {
  background: rgb(252, 101, 60) !important;
}

.color-39-14 {
  background: rgb(252, 107, 60) !important;
}

.color-39-15 {
  background: rgb(252, 113, 60) !important;
}

.color-39-16 {
  background: rgb(252, 119, 60) !important;
}

.color-39-17 {
  background: rgb(252, 125, 60) !important;
}

.color-39-18 {
  background: rgb(252, 130, 60) !important;
}

.color-39-19 {
  background: rgb(252, 136, 60) !important;
}

.color-39-20 {
  background: rgb(252, 142, 60) !important;
}

.color-39-21 {
  background: rgb(252, 148, 60) !important;
}

.color-39-22 {
  background: rgb(252, 154, 60) !important;
}

.color-39-23 {
  background: rgb(252, 159, 60) !important;
}

.color-39-24 {
  background: rgb(252, 165, 60) !important;
}

.color-39-25 {
  background: rgb(252, 171, 60) !important;
}

.color-39-26 {
  background: rgb(252, 177, 60) !important;
}

.color-39-27 {
  background: rgb(252, 183, 60) !important;
}

.color-39-28 {
  background: rgb(252, 188, 60) !important;
}

.color-39-29 {
  background: rgb(252, 194, 60) !important;
}

.color-39-30 {
  background: rgb(252, 200, 60) !important;
}

.color-39-31 {
  background: rgb(252, 206, 60) !important;
}

.color-39-32 {
  background: rgb(252, 211, 60) !important;
}

.color-39-33 {
  background: rgb(252, 217, 60) !important;
}

.color-39-34 {
  background: rgb(252, 223, 60) !important;
}

.color-39-35 {
  background: rgb(252, 229, 60) !important;
}

.color-39-36 {
  background: rgb(252, 235, 60) !important;
}

.color-39-37 {
  background: rgb(252, 240, 60) !important;
}

.color-39-38 {
  background: rgb(252, 246, 60) !important;
}

.color-40-0 {
  background: rgb(140, 0, 28) !important;
}

.color-40-1 {
  background: rgb(252, 32, 60) !important;
}

.color-40-2 {
  background: rgb(252, 38, 60) !important;
}

.color-40-3 {
  background: rgb(252, 43, 60) !important;
}

.color-40-4 {
  background: rgb(252, 49, 60) !important;
}

.color-40-5 {
  background: rgb(252, 55, 60) !important;
}

.color-40-6 {
  background: rgb(252, 60, 60) !important;
}

.color-40-7 {
  background: rgb(252, 66, 60) !important;
}

.color-40-8 {
  background: rgb(252, 71, 60) !important;
}

.color-40-9 {
  background: rgb(252, 77, 60) !important;
}

.color-40-10 {
  background: rgb(252, 83, 60) !important;
}

.color-40-11 {
  background: rgb(252, 88, 60) !important;
}

.color-40-12 {
  background: rgb(252, 94, 60) !important;
}

.color-40-13 {
  background: rgb(252, 100, 60) !important;
}

.color-40-14 {
  background: rgb(252, 105, 60) !important;
}

.color-40-15 {
  background: rgb(252, 111, 60) !important;
}

.color-40-16 {
  background: rgb(252, 117, 60) !important;
}

.color-40-17 {
  background: rgb(252, 122, 60) !important;
}

.color-40-18 {
  background: rgb(252, 128, 60) !important;
}

.color-40-19 {
  background: rgb(252, 134, 60) !important;
}

.color-40-20 {
  background: rgb(252, 139, 60) !important;
}

.color-40-21 {
  background: rgb(252, 145, 60) !important;
}

.color-40-22 {
  background: rgb(252, 150, 60) !important;
}

.color-40-23 {
  background: rgb(252, 156, 60) !important;
}

.color-40-24 {
  background: rgb(252, 162, 60) !important;
}

.color-40-25 {
  background: rgb(252, 167, 60) !important;
}

.color-40-26 {
  background: rgb(252, 173, 60) !important;
}

.color-40-27 {
  background: rgb(252, 179, 60) !important;
}

.color-40-28 {
  background: rgb(252, 184, 60) !important;
}

.color-40-29 {
  background: rgb(252, 190, 60) !important;
}

.color-40-30 {
  background: rgb(252, 196, 60) !important;
}

.color-40-31 {
  background: rgb(252, 201, 60) !important;
}

.color-40-32 {
  background: rgb(252, 207, 60) !important;
}

.color-40-33 {
  background: rgb(252, 213, 60) !important;
}

.color-40-34 {
  background: rgb(252, 218, 60) !important;
}

.color-40-35 {
  background: rgb(252, 224, 60) !important;
}

.color-40-36 {
  background: rgb(252, 229, 60) !important;
}

.color-40-37 {
  background: rgb(252, 235, 60) !important;
}

.color-40-38 {
  background: rgb(252, 241, 60) !important;
}

.color-40-39 {
  background: rgb(252, 246, 60) !important;
}

.color-41-0 {
  background: rgb(140, 0, 28) !important;
}

.color-41-1 {
  background: rgb(252, 32, 60) !important;
}

.color-41-2 {
  background: rgb(252, 38, 60) !important;
}

.color-41-3 {
  background: rgb(252, 43, 60) !important;
}

.color-41-4 {
  background: rgb(252, 49, 60) !important;
}

.color-41-5 {
  background: rgb(252, 54, 60) !important;
}

.color-41-6 {
  background: rgb(252, 60, 60) !important;
}

.color-41-7 {
  background: rgb(252, 65, 60) !important;
}

.color-41-8 {
  background: rgb(252, 71, 60) !important;
}

.color-41-9 {
  background: rgb(252, 76, 60) !important;
}

.color-41-10 {
  background: rgb(252, 82, 60) !important;
}

.color-41-11 {
  background: rgb(252, 87, 60) !important;
}

.color-41-12 {
  background: rgb(252, 93, 60) !important;
}

.color-41-13 {
  background: rgb(252, 98, 60) !important;
}

.color-41-14 {
  background: rgb(252, 104, 60) !important;
}

.color-41-15 {
  background: rgb(252, 109, 60) !important;
}

.color-41-16 {
  background: rgb(252, 115, 60) !important;
}

.color-41-17 {
  background: rgb(252, 120, 60) !important;
}

.color-41-18 {
  background: rgb(252, 126, 60) !important;
}

.color-41-19 {
  background: rgb(252, 131, 60) !important;
}

.color-41-20 {
  background: rgb(252, 137, 60) !important;
}

.color-41-21 {
  background: rgb(252, 142, 60) !important;
}

.color-41-22 {
  background: rgb(252, 148, 60) !important;
}

.color-41-23 {
  background: rgb(252, 153, 60) !important;
}

.color-41-24 {
  background: rgb(252, 159, 60) !important;
}

.color-41-25 {
  background: rgb(252, 164, 60) !important;
}

.color-41-26 {
  background: rgb(252, 170, 60) !important;
}

.color-41-27 {
  background: rgb(252, 175, 60) !important;
}

.color-41-28 {
  background: rgb(252, 181, 60) !important;
}

.color-41-29 {
  background: rgb(252, 186, 60) !important;
}

.color-41-30 {
  background: rgb(252, 192, 60) !important;
}

.color-41-31 {
  background: rgb(252, 197, 60) !important;
}

.color-41-32 {
  background: rgb(252, 203, 60) !important;
}

.color-41-33 {
  background: rgb(252, 208, 60) !important;
}

.color-41-34 {
  background: rgb(252, 214, 60) !important;
}

.color-41-35 {
  background: rgb(252, 219, 60) !important;
}

.color-41-36 {
  background: rgb(252, 225, 60) !important;
}

.color-41-37 {
  background: rgb(252, 230, 60) !important;
}

.color-41-38 {
  background: rgb(252, 236, 60) !important;
}

.color-41-39 {
  background: rgb(252, 241, 60) !important;
}

.color-41-40 {
  background: rgb(252, 247, 60) !important;
}

.color-42-0 {
  background: rgb(140, 0, 28) !important;
}

.color-42-1 {
  background: rgb(252, 32, 60) !important;
}

.color-42-2 {
  background: rgb(252, 37, 60) !important;
}

.color-42-3 {
  background: rgb(252, 43, 60) !important;
}

.color-42-4 {
  background: rgb(252, 48, 60) !important;
}

.color-42-5 {
  background: rgb(252, 53, 60) !important;
}

.color-42-6 {
  background: rgb(252, 59, 60) !important;
}

.color-42-7 {
  background: rgb(252, 64, 60) !important;
}

.color-42-8 {
  background: rgb(252, 70, 60) !important;
}

.color-42-9 {
  background: rgb(252, 75, 60) !important;
}

.color-42-10 {
  background: rgb(252, 80, 60) !important;
}

.color-42-11 {
  background: rgb(252, 86, 60) !important;
}

.color-42-12 {
  background: rgb(252, 91, 60) !important;
}

.color-42-13 {
  background: rgb(252, 96, 60) !important;
}

.color-42-14 {
  background: rgb(252, 102, 60) !important;
}

.color-42-15 {
  background: rgb(252, 107, 60) !important;
}

.color-42-16 {
  background: rgb(252, 112, 60) !important;
}

.color-42-17 {
  background: rgb(252, 118, 60) !important;
}

.color-42-18 {
  background: rgb(252, 123, 60) !important;
}

.color-42-19 {
  background: rgb(252, 129, 60) !important;
}

.color-42-20 {
  background: rgb(252, 134, 60) !important;
}

.color-42-21 {
  background: rgb(252, 139, 60) !important;
}

.color-42-22 {
  background: rgb(252, 145, 60) !important;
}

.color-42-23 {
  background: rgb(252, 150, 60) !important;
}

.color-42-24 {
  background: rgb(252, 155, 60) !important;
}

.color-42-25 {
  background: rgb(252, 161, 60) !important;
}

.color-42-26 {
  background: rgb(252, 166, 60) !important;
}

.color-42-27 {
  background: rgb(252, 172, 60) !important;
}

.color-42-28 {
  background: rgb(252, 177, 60) !important;
}

.color-42-29 {
  background: rgb(252, 182, 60) !important;
}

.color-42-30 {
  background: rgb(252, 188, 60) !important;
}

.color-42-31 {
  background: rgb(252, 193, 60) !important;
}

.color-42-32 {
  background: rgb(252, 198, 60) !important;
}

.color-42-33 {
  background: rgb(252, 204, 60) !important;
}

.color-42-34 {
  background: rgb(252, 209, 60) !important;
}

.color-42-35 {
  background: rgb(252, 214, 60) !important;
}

.color-42-36 {
  background: rgb(252, 220, 60) !important;
}

.color-42-37 {
  background: rgb(252, 225, 60) !important;
}

.color-42-38 {
  background: rgb(252, 231, 60) !important;
}

.color-42-39 {
  background: rgb(252, 236, 60) !important;
}

.color-42-40 {
  background: rgb(252, 241, 60) !important;
}

.color-42-41 {
  background: rgb(252, 247, 60) !important;
}

.color-43-0 {
  background: rgb(140, 0, 28) !important;
}

.color-43-1 {
  background: rgb(252, 32, 60) !important;
}

.color-43-2 {
  background: rgb(252, 37, 60) !important;
}

.color-43-3 {
  background: rgb(252, 42, 60) !important;
}

.color-43-4 {
  background: rgb(252, 48, 60) !important;
}

.color-43-5 {
  background: rgb(252, 53, 60) !important;
}

.color-43-6 {
  background: rgb(252, 58, 60) !important;
}

.color-43-7 {
  background: rgb(252, 63, 60) !important;
}

.color-43-8 {
  background: rgb(252, 69, 60) !important;
}

.color-43-9 {
  background: rgb(252, 74, 60) !important;
}

.color-43-10 {
  background: rgb(252, 79, 60) !important;
}

.color-43-11 {
  background: rgb(252, 84, 60) !important;
}

.color-43-12 {
  background: rgb(252, 90, 60) !important;
}

.color-43-13 {
  background: rgb(252, 95, 60) !important;
}

.color-43-14 {
  background: rgb(252, 100, 60) !important;
}

.color-43-15 {
  background: rgb(252, 105, 60) !important;
}

.color-43-16 {
  background: rgb(252, 111, 60) !important;
}

.color-43-17 {
  background: rgb(252, 116, 60) !important;
}

.color-43-18 {
  background: rgb(252, 121, 60) !important;
}

.color-43-19 {
  background: rgb(252, 126, 60) !important;
}

.color-43-20 {
  background: rgb(252, 132, 60) !important;
}

.color-43-21 {
  background: rgb(252, 137, 60) !important;
}

.color-43-22 {
  background: rgb(252, 142, 60) !important;
}

.color-43-23 {
  background: rgb(252, 147, 60) !important;
}

.color-43-24 {
  background: rgb(252, 152, 60) !important;
}

.color-43-25 {
  background: rgb(252, 158, 60) !important;
}

.color-43-26 {
  background: rgb(252, 163, 60) !important;
}

.color-43-27 {
  background: rgb(252, 168, 60) !important;
}

.color-43-28 {
  background: rgb(252, 173, 60) !important;
}

.color-43-29 {
  background: rgb(252, 179, 60) !important;
}

.color-43-30 {
  background: rgb(252, 184, 60) !important;
}

.color-43-31 {
  background: rgb(252, 189, 60) !important;
}

.color-43-32 {
  background: rgb(252, 194, 60) !important;
}

.color-43-33 {
  background: rgb(252, 200, 60) !important;
}

.color-43-34 {
  background: rgb(252, 205, 60) !important;
}

.color-43-35 {
  background: rgb(252, 210, 60) !important;
}

.color-43-36 {
  background: rgb(252, 215, 60) !important;
}

.color-43-37 {
  background: rgb(252, 221, 60) !important;
}

.color-43-38 {
  background: rgb(252, 226, 60) !important;
}

.color-43-39 {
  background: rgb(252, 231, 60) !important;
}

.color-43-40 {
  background: rgb(252, 236, 60) !important;
}

.color-43-41 {
  background: rgb(252, 242, 60) !important;
}

.color-43-42 {
  background: rgb(252, 247, 60) !important;
}

.color-44-0 {
  background: rgb(140, 0, 28) !important;
}

.color-44-1 {
  background: rgb(252, 32, 60) !important;
}

.color-44-2 {
  background: rgb(252, 37, 60) !important;
}

.color-44-3 {
  background: rgb(252, 42, 60) !important;
}

.color-44-4 {
  background: rgb(252, 47, 60) !important;
}

.color-44-5 {
  background: rgb(252, 52, 60) !important;
}

.color-44-6 {
  background: rgb(252, 58, 60) !important;
}

.color-44-7 {
  background: rgb(252, 63, 60) !important;
}

.color-44-8 {
  background: rgb(252, 68, 60) !important;
}

.color-44-9 {
  background: rgb(252, 73, 60) !important;
}

.color-44-10 {
  background: rgb(252, 78, 60) !important;
}

.color-44-11 {
  background: rgb(252, 83, 60) !important;
}

.color-44-12 {
  background: rgb(252, 88, 60) !important;
}

.color-44-13 {
  background: rgb(252, 93, 60) !important;
}

.color-44-14 {
  background: rgb(252, 99, 60) !important;
}

.color-44-15 {
  background: rgb(252, 104, 60) !important;
}

.color-44-16 {
  background: rgb(252, 109, 60) !important;
}

.color-44-17 {
  background: rgb(252, 114, 60) !important;
}

.color-44-18 {
  background: rgb(252, 119, 60) !important;
}

.color-44-19 {
  background: rgb(252, 124, 60) !important;
}

.color-44-20 {
  background: rgb(252, 129, 60) !important;
}

.color-44-21 {
  background: rgb(252, 134, 60) !important;
}

.color-44-22 {
  background: rgb(252, 139, 60) !important;
}

.color-44-23 {
  background: rgb(252, 145, 60) !important;
}

.color-44-24 {
  background: rgb(252, 150, 60) !important;
}

.color-44-25 {
  background: rgb(252, 155, 60) !important;
}

.color-44-26 {
  background: rgb(252, 160, 60) !important;
}

.color-44-27 {
  background: rgb(252, 165, 60) !important;
}

.color-44-28 {
  background: rgb(252, 170, 60) !important;
}

.color-44-29 {
  background: rgb(252, 175, 60) !important;
}

.color-44-30 {
  background: rgb(252, 180, 60) !important;
}

.color-44-31 {
  background: rgb(252, 185, 60) !important;
}

.color-44-32 {
  background: rgb(252, 191, 60) !important;
}

.color-44-33 {
  background: rgb(252, 196, 60) !important;
}

.color-44-34 {
  background: rgb(252, 201, 60) !important;
}

.color-44-35 {
  background: rgb(252, 206, 60) !important;
}

.color-44-36 {
  background: rgb(252, 211, 60) !important;
}

.color-44-37 {
  background: rgb(252, 216, 60) !important;
}

.color-44-38 {
  background: rgb(252, 221, 60) !important;
}

.color-44-39 {
  background: rgb(252, 226, 60) !important;
}

.color-44-40 {
  background: rgb(252, 232, 60) !important;
}

.color-44-41 {
  background: rgb(252, 237, 60) !important;
}

.color-44-42 {
  background: rgb(252, 242, 60) !important;
}

.color-44-43 {
  background: rgb(252, 247, 60) !important;
}

.color-45-0 {
  background: rgb(140, 0, 28) !important;
}

.color-45-1 {
  background: rgb(252, 32, 60) !important;
}

.color-45-2 {
  background: rgb(252, 37, 60) !important;
}

.color-45-3 {
  background: rgb(252, 42, 60) !important;
}

.color-45-4 {
  background: rgb(252, 47, 60) !important;
}

.color-45-5 {
  background: rgb(252, 52, 60) !important;
}

.color-45-6 {
  background: rgb(252, 57, 60) !important;
}

.color-45-7 {
  background: rgb(252, 62, 60) !important;
}

.color-45-8 {
  background: rgb(252, 67, 60) !important;
}

.color-45-9 {
  background: rgb(252, 72, 60) !important;
}

.color-45-10 {
  background: rgb(252, 77, 60) !important;
}

.color-45-11 {
  background: rgb(252, 82, 60) !important;
}

.color-45-12 {
  background: rgb(252, 87, 60) !important;
}

.color-45-13 {
  background: rgb(252, 92, 60) !important;
}

.color-45-14 {
  background: rgb(252, 97, 60) !important;
}

.color-45-15 {
  background: rgb(252, 102, 60) !important;
}

.color-45-16 {
  background: rgb(252, 107, 60) !important;
}

.color-45-17 {
  background: rgb(252, 112, 60) !important;
}

.color-45-18 {
  background: rgb(252, 117, 60) !important;
}

.color-45-19 {
  background: rgb(252, 122, 60) !important;
}

.color-45-20 {
  background: rgb(252, 127, 60) !important;
}

.color-45-21 {
  background: rgb(252, 132, 60) !important;
}

.color-45-22 {
  background: rgb(252, 137, 60) !important;
}

.color-45-23 {
  background: rgb(252, 142, 60) !important;
}

.color-45-24 {
  background: rgb(252, 147, 60) !important;
}

.color-45-25 {
  background: rgb(252, 152, 60) !important;
}

.color-45-26 {
  background: rgb(252, 157, 60) !important;
}

.color-45-27 {
  background: rgb(252, 162, 60) !important;
}

.color-45-28 {
  background: rgb(252, 167, 60) !important;
}

.color-45-29 {
  background: rgb(252, 172, 60) !important;
}

.color-45-30 {
  background: rgb(252, 177, 60) !important;
}

.color-45-31 {
  background: rgb(252, 182, 60) !important;
}

.color-45-32 {
  background: rgb(252, 187, 60) !important;
}

.color-45-33 {
  background: rgb(252, 192, 60) !important;
}

.color-45-34 {
  background: rgb(252, 197, 60) !important;
}

.color-45-35 {
  background: rgb(252, 202, 60) !important;
}

.color-45-36 {
  background: rgb(252, 207, 60) !important;
}

.color-45-37 {
  background: rgb(252, 212, 60) !important;
}

.color-45-38 {
  background: rgb(252, 217, 60) !important;
}

.color-45-39 {
  background: rgb(252, 222, 60) !important;
}

.color-45-40 {
  background: rgb(252, 227, 60) !important;
}

.color-45-41 {
  background: rgb(252, 232, 60) !important;
}

.color-45-42 {
  background: rgb(252, 237, 60) !important;
}

.color-45-43 {
  background: rgb(252, 242, 60) !important;
}

.color-45-44 {
  background: rgb(252, 247, 60) !important;
}

.color-46-0 {
  background: rgb(140, 0, 28) !important;
}

.color-46-1 {
  background: rgb(252, 32, 60) !important;
}

.color-46-2 {
  background: rgb(252, 37, 60) !important;
}

.color-46-3 {
  background: rgb(252, 42, 60) !important;
}

.color-46-4 {
  background: rgb(252, 47, 60) !important;
}

.color-46-5 {
  background: rgb(252, 52, 60) !important;
}

.color-46-6 {
  background: rgb(252, 56, 60) !important;
}

.color-46-7 {
  background: rgb(252, 61, 60) !important;
}

.color-46-8 {
  background: rgb(252, 66, 60) !important;
}

.color-46-9 {
  background: rgb(252, 71, 60) !important;
}

.color-46-10 {
  background: rgb(252, 76, 60) !important;
}

.color-46-11 {
  background: rgb(252, 81, 60) !important;
}

.color-46-12 {
  background: rgb(252, 86, 60) !important;
}

.color-46-13 {
  background: rgb(252, 91, 60) !important;
}

.color-46-14 {
  background: rgb(252, 96, 60) !important;
}

.color-46-15 {
  background: rgb(252, 100, 60) !important;
}

.color-46-16 {
  background: rgb(252, 105, 60) !important;
}

.color-46-17 {
  background: rgb(252, 110, 60) !important;
}

.color-46-18 {
  background: rgb(252, 115, 60) !important;
}

.color-46-19 {
  background: rgb(252, 120, 60) !important;
}

.color-46-20 {
  background: rgb(252, 125, 60) !important;
}

.color-46-21 {
  background: rgb(252, 130, 60) !important;
}

.color-46-22 {
  background: rgb(252, 135, 60) !important;
}

.color-46-23 {
  background: rgb(252, 140, 60) !important;
}

.color-46-24 {
  background: rgb(252, 144, 60) !important;
}

.color-46-25 {
  background: rgb(252, 149, 60) !important;
}

.color-46-26 {
  background: rgb(252, 154, 60) !important;
}

.color-46-27 {
  background: rgb(252, 159, 60) !important;
}

.color-46-28 {
  background: rgb(252, 164, 60) !important;
}

.color-46-29 {
  background: rgb(252, 169, 60) !important;
}

.color-46-30 {
  background: rgb(252, 174, 60) !important;
}

.color-46-31 {
  background: rgb(252, 179, 60) !important;
}

.color-46-32 {
  background: rgb(252, 184, 60) !important;
}

.color-46-33 {
  background: rgb(252, 188, 60) !important;
}

.color-46-34 {
  background: rgb(252, 193, 60) !important;
}

.color-46-35 {
  background: rgb(252, 198, 60) !important;
}

.color-46-36 {
  background: rgb(252, 203, 60) !important;
}

.color-46-37 {
  background: rgb(252, 208, 60) !important;
}

.color-46-38 {
  background: rgb(252, 213, 60) !important;
}

.color-46-39 {
  background: rgb(252, 218, 60) !important;
}

.color-46-40 {
  background: rgb(252, 223, 60) !important;
}

.color-46-41 {
  background: rgb(252, 228, 60) !important;
}

.color-46-42 {
  background: rgb(252, 232, 60) !important;
}

.color-46-43 {
  background: rgb(252, 237, 60) !important;
}

.color-46-44 {
  background: rgb(252, 242, 60) !important;
}

.color-46-45 {
  background: rgb(252, 247, 60) !important;
}

.color-47-0 {
  background: rgb(140, 0, 28) !important;
}

.color-47-1 {
  background: rgb(252, 32, 60) !important;
}

.color-47-2 {
  background: rgb(252, 37, 60) !important;
}

.color-47-3 {
  background: rgb(252, 42, 60) !important;
}

.color-47-4 {
  background: rgb(252, 46, 60) !important;
}

.color-47-5 {
  background: rgb(252, 51, 60) !important;
}

.color-47-6 {
  background: rgb(252, 56, 60) !important;
}

.color-47-7 {
  background: rgb(252, 61, 60) !important;
}

.color-47-8 {
  background: rgb(252, 65, 60) !important;
}

.color-47-9 {
  background: rgb(252, 70, 60) !important;
}

.color-47-10 {
  background: rgb(252, 75, 60) !important;
}

.color-47-11 {
  background: rgb(252, 80, 60) !important;
}

.color-47-12 {
  background: rgb(252, 85, 60) !important;
}

.color-47-13 {
  background: rgb(252, 89, 60) !important;
}

.color-47-14 {
  background: rgb(252, 94, 60) !important;
}

.color-47-15 {
  background: rgb(252, 99, 60) !important;
}

.color-47-16 {
  background: rgb(252, 104, 60) !important;
}

.color-47-17 {
  background: rgb(252, 109, 60) !important;
}

.color-47-18 {
  background: rgb(252, 113, 60) !important;
}

.color-47-19 {
  background: rgb(252, 118, 60) !important;
}

.color-47-20 {
  background: rgb(252, 123, 60) !important;
}

.color-47-21 {
  background: rgb(252, 128, 60) !important;
}

.color-47-22 {
  background: rgb(252, 132, 60) !important;
}

.color-47-23 {
  background: rgb(252, 137, 60) !important;
}

.color-47-24 {
  background: rgb(252, 142, 60) !important;
}

.color-47-25 {
  background: rgb(252, 147, 60) !important;
}

.color-47-26 {
  background: rgb(252, 152, 60) !important;
}

.color-47-27 {
  background: rgb(252, 156, 60) !important;
}

.color-47-28 {
  background: rgb(252, 161, 60) !important;
}

.color-47-29 {
  background: rgb(252, 166, 60) !important;
}

.color-47-30 {
  background: rgb(252, 171, 60) !important;
}

.color-47-31 {
  background: rgb(252, 175, 60) !important;
}

.color-47-32 {
  background: rgb(252, 180, 60) !important;
}

.color-47-33 {
  background: rgb(252, 185, 60) !important;
}

.color-47-34 {
  background: rgb(252, 190, 60) !important;
}

.color-47-35 {
  background: rgb(252, 195, 60) !important;
}

.color-47-36 {
  background: rgb(252, 199, 60) !important;
}

.color-47-37 {
  background: rgb(252, 204, 60) !important;
}

.color-47-38 {
  background: rgb(252, 209, 60) !important;
}

.color-47-39 {
  background: rgb(252, 214, 60) !important;
}

.color-47-40 {
  background: rgb(252, 219, 60) !important;
}

.color-47-41 {
  background: rgb(252, 223, 60) !important;
}

.color-47-42 {
  background: rgb(252, 228, 60) !important;
}

.color-47-43 {
  background: rgb(252, 233, 60) !important;
}

.color-47-44 {
  background: rgb(252, 238, 60) !important;
}

.color-47-45 {
  background: rgb(252, 242, 60) !important;
}

.color-47-46 {
  background: rgb(252, 247, 60) !important;
}

.color-48-0 {
  background: rgb(140, 0, 28) !important;
}

.color-48-1 {
  background: rgb(252, 32, 60) !important;
}

.color-48-2 {
  background: rgb(252, 37, 60) !important;
}

.color-48-3 {
  background: rgb(252, 41, 60) !important;
}

.color-48-4 {
  background: rgb(252, 46, 60) !important;
}

.color-48-5 {
  background: rgb(252, 51, 60) !important;
}

.color-48-6 {
  background: rgb(252, 55, 60) !important;
}

.color-48-7 {
  background: rgb(252, 60, 60) !important;
}

.color-48-8 {
  background: rgb(252, 65, 60) !important;
}

.color-48-9 {
  background: rgb(252, 69, 60) !important;
}

.color-48-10 {
  background: rgb(252, 74, 60) !important;
}

.color-48-11 {
  background: rgb(252, 79, 60) !important;
}

.color-48-12 {
  background: rgb(252, 83, 60) !important;
}

.color-48-13 {
  background: rgb(252, 88, 60) !important;
}

.color-48-14 {
  background: rgb(252, 93, 60) !important;
}

.color-48-15 {
  background: rgb(252, 98, 60) !important;
}

.color-48-16 {
  background: rgb(252, 102, 60) !important;
}

.color-48-17 {
  background: rgb(252, 107, 60) !important;
}

.color-48-18 {
  background: rgb(252, 112, 60) !important;
}

.color-48-19 {
  background: rgb(252, 116, 60) !important;
}

.color-48-20 {
  background: rgb(252, 121, 60) !important;
}

.color-48-21 {
  background: rgb(252, 126, 60) !important;
}

.color-48-22 {
  background: rgb(252, 130, 60) !important;
}

.color-48-23 {
  background: rgb(252, 135, 60) !important;
}

.color-48-24 {
  background: rgb(252, 140, 60) !important;
}

.color-48-25 {
  background: rgb(252, 144, 60) !important;
}

.color-48-26 {
  background: rgb(252, 149, 60) !important;
}

.color-48-27 {
  background: rgb(252, 154, 60) !important;
}

.color-48-28 {
  background: rgb(252, 158, 60) !important;
}

.color-48-29 {
  background: rgb(252, 163, 60) !important;
}

.color-48-30 {
  background: rgb(252, 168, 60) !important;
}

.color-48-31 {
  background: rgb(252, 172, 60) !important;
}

.color-48-32 {
  background: rgb(252, 177, 60) !important;
}

.color-48-33 {
  background: rgb(252, 182, 60) !important;
}

.color-48-34 {
  background: rgb(252, 186, 60) !important;
}

.color-48-35 {
  background: rgb(252, 191, 60) !important;
}

.color-48-36 {
  background: rgb(252, 196, 60) !important;
}

.color-48-37 {
  background: rgb(252, 201, 60) !important;
}

.color-48-38 {
  background: rgb(252, 205, 60) !important;
}

.color-48-39 {
  background: rgb(252, 210, 60) !important;
}

.color-48-40 {
  background: rgb(252, 215, 60) !important;
}

.color-48-41 {
  background: rgb(252, 219, 60) !important;
}

.color-48-42 {
  background: rgb(252, 224, 60) !important;
}

.color-48-43 {
  background: rgb(252, 229, 60) !important;
}

.color-48-44 {
  background: rgb(252, 233, 60) !important;
}

.color-48-45 {
  background: rgb(252, 238, 60) !important;
}

.color-48-46 {
  background: rgb(252, 243, 60) !important;
}

.color-48-47 {
  background: rgb(252, 247, 60) !important;
}

.color-49-0 {
  background: rgb(140, 0, 28) !important;
}

.color-49-1 {
  background: rgb(252, 32, 60) !important;
}

.color-49-2 {
  background: rgb(252, 37, 60) !important;
}

.color-49-3 {
  background: rgb(252, 41, 60) !important;
}

.color-49-4 {
  background: rgb(252, 46, 60) !important;
}

.color-49-5 {
  background: rgb(252, 50, 60) !important;
}

.color-49-6 {
  background: rgb(252, 55, 60) !important;
}

.color-49-7 {
  background: rgb(252, 60, 60) !important;
}

.color-49-8 {
  background: rgb(252, 64, 60) !important;
}

.color-49-9 {
  background: rgb(252, 69, 60) !important;
}

.color-49-10 {
  background: rgb(252, 73, 60) !important;
}

.color-49-11 {
  background: rgb(252, 78, 60) !important;
}

.color-49-12 {
  background: rgb(252, 82, 60) !important;
}

.color-49-13 {
  background: rgb(252, 87, 60) !important;
}

.color-49-14 {
  background: rgb(252, 92, 60) !important;
}

.color-49-15 {
  background: rgb(252, 96, 60) !important;
}

.color-49-16 {
  background: rgb(252, 101, 60) !important;
}

.color-49-17 {
  background: rgb(252, 105, 60) !important;
}

.color-49-18 {
  background: rgb(252, 110, 60) !important;
}

.color-49-19 {
  background: rgb(252, 115, 60) !important;
}

.color-49-20 {
  background: rgb(252, 119, 60) !important;
}

.color-49-21 {
  background: rgb(252, 124, 60) !important;
}

.color-49-22 {
  background: rgb(252, 128, 60) !important;
}

.color-49-23 {
  background: rgb(252, 133, 60) !important;
}

.color-49-24 {
  background: rgb(252, 137, 60) !important;
}

.color-49-25 {
  background: rgb(252, 142, 60) !important;
}

.color-49-26 {
  background: rgb(252, 147, 60) !important;
}

.color-49-27 {
  background: rgb(252, 151, 60) !important;
}

.color-49-28 {
  background: rgb(252, 156, 60) !important;
}

.color-49-29 {
  background: rgb(252, 160, 60) !important;
}

.color-49-30 {
  background: rgb(252, 165, 60) !important;
}

.color-49-31 {
  background: rgb(252, 170, 60) !important;
}

.color-49-32 {
  background: rgb(252, 174, 60) !important;
}

.color-49-33 {
  background: rgb(252, 179, 60) !important;
}

.color-49-34 {
  background: rgb(252, 183, 60) !important;
}

.color-49-35 {
  background: rgb(252, 188, 60) !important;
}

.color-49-36 {
  background: rgb(252, 192, 60) !important;
}

.color-49-37 {
  background: rgb(252, 197, 60) !important;
}

.color-49-38 {
  background: rgb(252, 202, 60) !important;
}

.color-49-39 {
  background: rgb(252, 206, 60) !important;
}

.color-49-40 {
  background: rgb(252, 211, 60) !important;
}

.color-49-41 {
  background: rgb(252, 215, 60) !important;
}

.color-49-42 {
  background: rgb(252, 220, 60) !important;
}

.color-49-43 {
  background: rgb(252, 225, 60) !important;
}

.color-49-44 {
  background: rgb(252, 229, 60) !important;
}

.color-49-45 {
  background: rgb(252, 234, 60) !important;
}

.color-49-46 {
  background: rgb(252, 238, 60) !important;
}

.color-49-47 {
  background: rgb(252, 243, 60) !important;
}

.color-49-48 {
  background: rgb(252, 247, 60) !important;
}

.color-50-0 {
  background: rgb(140, 0, 28) !important;
}

.color-50-1 {
  background: rgb(252, 32, 60) !important;
}

.color-50-2 {
  background: rgb(252, 36, 60) !important;
}

.color-50-3 {
  background: rgb(252, 41, 60) !important;
}

.color-50-4 {
  background: rgb(252, 45, 60) !important;
}

.color-50-5 {
  background: rgb(252, 50, 60) !important;
}

.color-50-6 {
  background: rgb(252, 54, 60) !important;
}

.color-50-7 {
  background: rgb(252, 59, 60) !important;
}

.color-50-8 {
  background: rgb(252, 63, 60) !important;
}

.color-50-9 {
  background: rgb(252, 68, 60) !important;
}

.color-50-10 {
  background: rgb(252, 72, 60) !important;
}

.color-50-11 {
  background: rgb(252, 77, 60) !important;
}

.color-50-12 {
  background: rgb(252, 81, 60) !important;
}

.color-50-13 {
  background: rgb(252, 86, 60) !important;
}

.color-50-14 {
  background: rgb(252, 90, 60) !important;
}

.color-50-15 {
  background: rgb(252, 95, 60) !important;
}

.color-50-16 {
  background: rgb(252, 99, 60) !important;
}

.color-50-17 {
  background: rgb(252, 104, 60) !important;
}

.color-50-18 {
  background: rgb(252, 108, 60) !important;
}

.color-50-19 {
  background: rgb(252, 113, 60) !important;
}

.color-50-20 {
  background: rgb(252, 117, 60) !important;
}

.color-50-21 {
  background: rgb(252, 122, 60) !important;
}

.color-50-22 {
  background: rgb(252, 126, 60) !important;
}

.color-50-23 {
  background: rgb(252, 131, 60) !important;
}

.color-50-24 {
  background: rgb(252, 135, 60) !important;
}

.color-50-25 {
  background: rgb(252, 140, 60) !important;
}

.color-50-26 {
  background: rgb(252, 144, 60) !important;
}

.color-50-27 {
  background: rgb(252, 149, 60) !important;
}

.color-50-28 {
  background: rgb(252, 153, 60) !important;
}

.color-50-29 {
  background: rgb(252, 158, 60) !important;
}

.color-50-30 {
  background: rgb(252, 162, 60) !important;
}

.color-50-31 {
  background: rgb(252, 167, 60) !important;
}

.color-50-32 {
  background: rgb(252, 171, 60) !important;
}

.color-50-33 {
  background: rgb(252, 176, 60) !important;
}

.color-50-34 {
  background: rgb(252, 180, 60) !important;
}

.color-50-35 {
  background: rgb(252, 185, 60) !important;
}

.color-50-36 {
  background: rgb(252, 189, 60) !important;
}

.color-50-37 {
  background: rgb(252, 194, 60) !important;
}

.color-50-38 {
  background: rgb(252, 198, 60) !important;
}

.color-50-39 {
  background: rgb(252, 203, 60) !important;
}

.color-50-40 {
  background: rgb(252, 207, 60) !important;
}

.color-50-41 {
  background: rgb(252, 212, 60) !important;
}

.color-50-42 {
  background: rgb(252, 216, 60) !important;
}

.color-50-43 {
  background: rgb(252, 221, 60) !important;
}

.color-50-44 {
  background: rgb(252, 225, 60) !important;
}

.color-50-45 {
  background: rgb(252, 230, 60) !important;
}

.color-50-46 {
  background: rgb(252, 234, 60) !important;
}

.color-50-47 {
  background: rgb(252, 239, 60) !important;
}

.color-50-48 {
  background: rgb(252, 243, 60) !important;
}

.color-50-49 {
  background: rgb(252, 248, 60) !important;
}

.color-51-0 {
  background: rgb(140, 0, 28) !important;
}

.color-51-1 {
  background: rgb(252, 32, 60) !important;
}

.color-51-2 {
  background: rgb(252, 36, 60) !important;
}

.color-51-3 {
  background: rgb(252, 41, 60) !important;
}

.color-51-4 {
  background: rgb(252, 45, 60) !important;
}

.color-51-5 {
  background: rgb(252, 50, 60) !important;
}

.color-51-6 {
  background: rgb(252, 54, 60) !important;
}

.color-51-7 {
  background: rgb(252, 58, 60) !important;
}

.color-51-8 {
  background: rgb(252, 63, 60) !important;
}

.color-51-9 {
  background: rgb(252, 67, 60) !important;
}

.color-51-10 {
  background: rgb(252, 72, 60) !important;
}

.color-51-11 {
  background: rgb(252, 76, 60) !important;
}

.color-51-12 {
  background: rgb(252, 80, 60) !important;
}

.color-51-13 {
  background: rgb(252, 85, 60) !important;
}

.color-51-14 {
  background: rgb(252, 89, 60) !important;
}

.color-51-15 {
  background: rgb(252, 94, 60) !important;
}

.color-51-16 {
  background: rgb(252, 98, 60) !important;
}

.color-51-17 {
  background: rgb(252, 102, 60) !important;
}

.color-51-18 {
  background: rgb(252, 107, 60) !important;
}

.color-51-19 {
  background: rgb(252, 111, 60) !important;
}

.color-51-20 {
  background: rgb(252, 116, 60) !important;
}

.color-51-21 {
  background: rgb(252, 120, 60) !important;
}

.color-51-22 {
  background: rgb(252, 124, 60) !important;
}

.color-51-23 {
  background: rgb(252, 129, 60) !important;
}

.color-51-24 {
  background: rgb(252, 133, 60) !important;
}

.color-51-25 {
  background: rgb(252, 138, 60) !important;
}

.color-51-26 {
  background: rgb(252, 142, 60) !important;
}

.color-51-27 {
  background: rgb(252, 146, 60) !important;
}

.color-51-28 {
  background: rgb(252, 151, 60) !important;
}

.color-51-29 {
  background: rgb(252, 155, 60) !important;
}

.color-51-30 {
  background: rgb(252, 160, 60) !important;
}

.color-51-31 {
  background: rgb(252, 164, 60) !important;
}

.color-51-32 {
  background: rgb(252, 168, 60) !important;
}

.color-51-33 {
  background: rgb(252, 173, 60) !important;
}

.color-51-34 {
  background: rgb(252, 177, 60) !important;
}

.color-51-35 {
  background: rgb(252, 182, 60) !important;
}

.color-51-36 {
  background: rgb(252, 186, 60) !important;
}

.color-51-37 {
  background: rgb(252, 190, 60) !important;
}

.color-51-38 {
  background: rgb(252, 195, 60) !important;
}

.color-51-39 {
  background: rgb(252, 199, 60) !important;
}

.color-51-40 {
  background: rgb(252, 204, 60) !important;
}

.color-51-41 {
  background: rgb(252, 208, 60) !important;
}

.color-51-42 {
  background: rgb(252, 212, 60) !important;
}

.color-51-43 {
  background: rgb(252, 217, 60) !important;
}

.color-51-44 {
  background: rgb(252, 221, 60) !important;
}

.color-51-45 {
  background: rgb(252, 226, 60) !important;
}

.color-51-46 {
  background: rgb(252, 230, 60) !important;
}

.color-51-47 {
  background: rgb(252, 234, 60) !important;
}

.color-51-48 {
  background: rgb(252, 239, 60) !important;
}

.color-51-49 {
  background: rgb(252, 243, 60) !important;
}

.color-51-50 {
  background: rgb(252, 248, 60) !important;
}

.color-52-0 {
  background: rgb(140, 0, 28) !important;
}

.color-52-1 {
  background: rgb(252, 32, 60) !important;
}

.color-52-2 {
  background: rgb(252, 36, 60) !important;
}

.color-52-3 {
  background: rgb(252, 41, 60) !important;
}

.color-52-4 {
  background: rgb(252, 45, 60) !important;
}

.color-52-5 {
  background: rgb(252, 49, 60) !important;
}

.color-52-6 {
  background: rgb(252, 54, 60) !important;
}

.color-52-7 {
  background: rgb(252, 58, 60) !important;
}

.color-52-8 {
  background: rgb(252, 62, 60) !important;
}

.color-52-9 {
  background: rgb(252, 67, 60) !important;
}

.color-52-10 {
  background: rgb(252, 71, 60) !important;
}

.color-52-11 {
  background: rgb(252, 75, 60) !important;
}

.color-52-12 {
  background: rgb(252, 79, 60) !important;
}

.color-52-13 {
  background: rgb(252, 84, 60) !important;
}

.color-52-14 {
  background: rgb(252, 88, 60) !important;
}

.color-52-15 {
  background: rgb(252, 92, 60) !important;
}

.color-52-16 {
  background: rgb(252, 97, 60) !important;
}

.color-52-17 {
  background: rgb(252, 101, 60) !important;
}

.color-52-18 {
  background: rgb(252, 105, 60) !important;
}

.color-52-19 {
  background: rgb(252, 110, 60) !important;
}

.color-52-20 {
  background: rgb(252, 114, 60) !important;
}

.color-52-21 {
  background: rgb(252, 118, 60) !important;
}

.color-52-22 {
  background: rgb(252, 123, 60) !important;
}

.color-52-23 {
  background: rgb(252, 127, 60) !important;
}

.color-52-24 {
  background: rgb(252, 131, 60) !important;
}

.color-52-25 {
  background: rgb(252, 136, 60) !important;
}

.color-52-26 {
  background: rgb(252, 140, 60) !important;
}

.color-52-27 {
  background: rgb(252, 144, 60) !important;
}

.color-52-28 {
  background: rgb(252, 148, 60) !important;
}

.color-52-29 {
  background: rgb(252, 153, 60) !important;
}

.color-52-30 {
  background: rgb(252, 157, 60) !important;
}

.color-52-31 {
  background: rgb(252, 161, 60) !important;
}

.color-52-32 {
  background: rgb(252, 166, 60) !important;
}

.color-52-33 {
  background: rgb(252, 170, 60) !important;
}

.color-52-34 {
  background: rgb(252, 174, 60) !important;
}

.color-52-35 {
  background: rgb(252, 179, 60) !important;
}

.color-52-36 {
  background: rgb(252, 183, 60) !important;
}

.color-52-37 {
  background: rgb(252, 187, 60) !important;
}

.color-52-38 {
  background: rgb(252, 192, 60) !important;
}

.color-52-39 {
  background: rgb(252, 196, 60) !important;
}

.color-52-40 {
  background: rgb(252, 200, 60) !important;
}

.color-52-41 {
  background: rgb(252, 205, 60) !important;
}

.color-52-42 {
  background: rgb(252, 209, 60) !important;
}

.color-52-43 {
  background: rgb(252, 213, 60) !important;
}

.color-52-44 {
  background: rgb(252, 217, 60) !important;
}

.color-52-45 {
  background: rgb(252, 222, 60) !important;
}

.color-52-46 {
  background: rgb(252, 226, 60) !important;
}

.color-52-47 {
  background: rgb(252, 230, 60) !important;
}

.color-52-48 {
  background: rgb(252, 235, 60) !important;
}

.color-52-49 {
  background: rgb(252, 239, 60) !important;
}

.color-52-50 {
  background: rgb(252, 243, 60) !important;
}

.color-52-51 {
  background: rgb(252, 248, 60) !important;
}

.color-53-0 {
  background: rgb(140, 0, 28) !important;
}

.color-53-1 {
  background: rgb(252, 32, 60) !important;
}

.color-53-2 {
  background: rgb(252, 36, 60) !important;
}

.color-53-3 {
  background: rgb(252, 40, 60) !important;
}

.color-53-4 {
  background: rgb(252, 45, 60) !important;
}

.color-53-5 {
  background: rgb(252, 49, 60) !important;
}

.color-53-6 {
  background: rgb(252, 53, 60) !important;
}

.color-53-7 {
  background: rgb(252, 57, 60) !important;
}

.color-53-8 {
  background: rgb(252, 62, 60) !important;
}

.color-53-9 {
  background: rgb(252, 66, 60) !important;
}

.color-53-10 {
  background: rgb(252, 70, 60) !important;
}

.color-53-11 {
  background: rgb(252, 74, 60) !important;
}

.color-53-12 {
  background: rgb(252, 79, 60) !important;
}

.color-53-13 {
  background: rgb(252, 83, 60) !important;
}

.color-53-14 {
  background: rgb(252, 87, 60) !important;
}

.color-53-15 {
  background: rgb(252, 91, 60) !important;
}

.color-53-16 {
  background: rgb(252, 95, 60) !important;
}

.color-53-17 {
  background: rgb(252, 100, 60) !important;
}

.color-53-18 {
  background: rgb(252, 104, 60) !important;
}

.color-53-19 {
  background: rgb(252, 108, 60) !important;
}

.color-53-20 {
  background: rgb(252, 112, 60) !important;
}

.color-53-21 {
  background: rgb(252, 117, 60) !important;
}

.color-53-22 {
  background: rgb(252, 121, 60) !important;
}

.color-53-23 {
  background: rgb(252, 125, 60) !important;
}

.color-53-24 {
  background: rgb(252, 129, 60) !important;
}

.color-53-25 {
  background: rgb(252, 134, 60) !important;
}

.color-53-26 {
  background: rgb(252, 138, 60) !important;
}

.color-53-27 {
  background: rgb(252, 142, 60) !important;
}

.color-53-28 {
  background: rgb(252, 146, 60) !important;
}

.color-53-29 {
  background: rgb(252, 150, 60) !important;
}

.color-53-30 {
  background: rgb(252, 155, 60) !important;
}

.color-53-31 {
  background: rgb(252, 159, 60) !important;
}

.color-53-32 {
  background: rgb(252, 163, 60) !important;
}

.color-53-33 {
  background: rgb(252, 167, 60) !important;
}

.color-53-34 {
  background: rgb(252, 172, 60) !important;
}

.color-53-35 {
  background: rgb(252, 176, 60) !important;
}

.color-53-36 {
  background: rgb(252, 180, 60) !important;
}

.color-53-37 {
  background: rgb(252, 184, 60) !important;
}

.color-53-38 {
  background: rgb(252, 189, 60) !important;
}

.color-53-39 {
  background: rgb(252, 193, 60) !important;
}

.color-53-40 {
  background: rgb(252, 197, 60) !important;
}

.color-53-41 {
  background: rgb(252, 201, 60) !important;
}

.color-53-42 {
  background: rgb(252, 205, 60) !important;
}

.color-53-43 {
  background: rgb(252, 210, 60) !important;
}

.color-53-44 {
  background: rgb(252, 214, 60) !important;
}

.color-53-45 {
  background: rgb(252, 218, 60) !important;
}

.color-53-46 {
  background: rgb(252, 222, 60) !important;
}

.color-53-47 {
  background: rgb(252, 227, 60) !important;
}

.color-53-48 {
  background: rgb(252, 231, 60) !important;
}

.color-53-49 {
  background: rgb(252, 235, 60) !important;
}

.color-53-50 {
  background: rgb(252, 239, 60) !important;
}

.color-53-51 {
  background: rgb(252, 244, 60) !important;
}

.color-53-52 {
  background: rgb(252, 248, 60) !important;
}

.color-54-0 {
  background: rgb(140, 0, 28) !important;
}

.color-54-1 {
  background: rgb(252, 32, 60) !important;
}

.color-54-2 {
  background: rgb(252, 36, 60) !important;
}

.color-54-3 {
  background: rgb(252, 40, 60) !important;
}

.color-54-4 {
  background: rgb(252, 44, 60) !important;
}

.color-54-5 {
  background: rgb(252, 49, 60) !important;
}

.color-54-6 {
  background: rgb(252, 53, 60) !important;
}

.color-54-7 {
  background: rgb(252, 57, 60) !important;
}

.color-54-8 {
  background: rgb(252, 61, 60) !important;
}

.color-54-9 {
  background: rgb(252, 65, 60) !important;
}

.color-54-10 {
  background: rgb(252, 69, 60) !important;
}

.color-54-11 {
  background: rgb(252, 74, 60) !important;
}

.color-54-12 {
  background: rgb(252, 78, 60) !important;
}

.color-54-13 {
  background: rgb(252, 82, 60) !important;
}

.color-54-14 {
  background: rgb(252, 86, 60) !important;
}

.color-54-15 {
  background: rgb(252, 90, 60) !important;
}

.color-54-16 {
  background: rgb(252, 94, 60) !important;
}

.color-54-17 {
  background: rgb(252, 98, 60) !important;
}

.color-54-18 {
  background: rgb(252, 103, 60) !important;
}

.color-54-19 {
  background: rgb(252, 107, 60) !important;
}

.color-54-20 {
  background: rgb(252, 111, 60) !important;
}

.color-54-21 {
  background: rgb(252, 115, 60) !important;
}

.color-54-22 {
  background: rgb(252, 119, 60) !important;
}

.color-54-23 {
  background: rgb(252, 123, 60) !important;
}

.color-54-24 {
  background: rgb(252, 127, 60) !important;
}

.color-54-25 {
  background: rgb(252, 132, 60) !important;
}

.color-54-26 {
  background: rgb(252, 136, 60) !important;
}

.color-54-27 {
  background: rgb(252, 140, 60) !important;
}

.color-54-28 {
  background: rgb(252, 144, 60) !important;
}

.color-54-29 {
  background: rgb(252, 148, 60) !important;
}

.color-54-30 {
  background: rgb(252, 152, 60) !important;
}

.color-54-31 {
  background: rgb(252, 157, 60) !important;
}

.color-54-32 {
  background: rgb(252, 161, 60) !important;
}

.color-54-33 {
  background: rgb(252, 165, 60) !important;
}

.color-54-34 {
  background: rgb(252, 169, 60) !important;
}

.color-54-35 {
  background: rgb(252, 173, 60) !important;
}

.color-54-36 {
  background: rgb(252, 177, 60) !important;
}

.color-54-37 {
  background: rgb(252, 181, 60) !important;
}

.color-54-38 {
  background: rgb(252, 186, 60) !important;
}

.color-54-39 {
  background: rgb(252, 190, 60) !important;
}

.color-54-40 {
  background: rgb(252, 194, 60) !important;
}

.color-54-41 {
  background: rgb(252, 198, 60) !important;
}

.color-54-42 {
  background: rgb(252, 202, 60) !important;
}

.color-54-43 {
  background: rgb(252, 206, 60) !important;
}

.color-54-44 {
  background: rgb(252, 210, 60) !important;
}

.color-54-45 {
  background: rgb(252, 215, 60) !important;
}

.color-54-46 {
  background: rgb(252, 219, 60) !important;
}

.color-54-47 {
  background: rgb(252, 223, 60) !important;
}

.color-54-48 {
  background: rgb(252, 227, 60) !important;
}

.color-54-49 {
  background: rgb(252, 231, 60) !important;
}

.color-54-50 {
  background: rgb(252, 235, 60) !important;
}

.color-54-51 {
  background: rgb(252, 240, 60) !important;
}

.color-54-52 {
  background: rgb(252, 244, 60) !important;
}

.color-54-53 {
  background: rgb(252, 248, 60) !important;
}

.color-55-0 {
  background: rgb(140, 0, 28) !important;
}

.color-55-1 {
  background: rgb(252, 32, 60) !important;
}

.color-55-2 {
  background: rgb(252, 36, 60) !important;
}

.color-55-3 {
  background: rgb(252, 40, 60) !important;
}

.color-55-4 {
  background: rgb(252, 44, 60) !important;
}

.color-55-5 {
  background: rgb(252, 48, 60) !important;
}

.color-55-6 {
  background: rgb(252, 52, 60) !important;
}

.color-55-7 {
  background: rgb(252, 56, 60) !important;
}

.color-55-8 {
  background: rgb(252, 61, 60) !important;
}

.color-55-9 {
  background: rgb(252, 65, 60) !important;
}

.color-55-10 {
  background: rgb(252, 69, 60) !important;
}

.color-55-11 {
  background: rgb(252, 73, 60) !important;
}

.color-55-12 {
  background: rgb(252, 77, 60) !important;
}

.color-55-13 {
  background: rgb(252, 81, 60) !important;
}

.color-55-14 {
  background: rgb(252, 85, 60) !important;
}

.color-55-15 {
  background: rgb(252, 89, 60) !important;
}

.color-55-16 {
  background: rgb(252, 93, 60) !important;
}

.color-55-17 {
  background: rgb(252, 97, 60) !important;
}

.color-55-18 {
  background: rgb(252, 101, 60) !important;
}

.color-55-19 {
  background: rgb(252, 105, 60) !important;
}

.color-55-20 {
  background: rgb(252, 109, 60) !important;
}

.color-55-21 {
  background: rgb(252, 113, 60) !important;
}

.color-55-22 {
  background: rgb(252, 118, 60) !important;
}

.color-55-23 {
  background: rgb(252, 122, 60) !important;
}

.color-55-24 {
  background: rgb(252, 126, 60) !important;
}

.color-55-25 {
  background: rgb(252, 130, 60) !important;
}

.color-55-26 {
  background: rgb(252, 134, 60) !important;
}

.color-55-27 {
  background: rgb(252, 138, 60) !important;
}

.color-55-28 {
  background: rgb(252, 142, 60) !important;
}

.color-55-29 {
  background: rgb(252, 146, 60) !important;
}

.color-55-30 {
  background: rgb(252, 150, 60) !important;
}

.color-55-31 {
  background: rgb(252, 154, 60) !important;
}

.color-55-32 {
  background: rgb(252, 158, 60) !important;
}

.color-55-33 {
  background: rgb(252, 162, 60) !important;
}

.color-55-34 {
  background: rgb(252, 166, 60) !important;
}

.color-55-35 {
  background: rgb(252, 171, 60) !important;
}

.color-55-36 {
  background: rgb(252, 175, 60) !important;
}

.color-55-37 {
  background: rgb(252, 179, 60) !important;
}

.color-55-38 {
  background: rgb(252, 183, 60) !important;
}

.color-55-39 {
  background: rgb(252, 187, 60) !important;
}

.color-55-40 {
  background: rgb(252, 191, 60) !important;
}

.color-55-41 {
  background: rgb(252, 195, 60) !important;
}

.color-55-42 {
  background: rgb(252, 199, 60) !important;
}

.color-55-43 {
  background: rgb(252, 203, 60) !important;
}

.color-55-44 {
  background: rgb(252, 207, 60) !important;
}

.color-55-45 {
  background: rgb(252, 211, 60) !important;
}

.color-55-46 {
  background: rgb(252, 215, 60) !important;
}

.color-55-47 {
  background: rgb(252, 219, 60) !important;
}

.color-55-48 {
  background: rgb(252, 223, 60) !important;
}

.color-55-49 {
  background: rgb(252, 228, 60) !important;
}

.color-55-50 {
  background: rgb(252, 232, 60) !important;
}

.color-55-51 {
  background: rgb(252, 236, 60) !important;
}

.color-55-52 {
  background: rgb(252, 240, 60) !important;
}

.color-55-53 {
  background: rgb(252, 244, 60) !important;
}

.color-55-54 {
  background: rgb(252, 248, 60) !important;
}

.color-56-0 {
  background: rgb(140, 0, 28) !important;
}

.color-56-1 {
  background: rgb(252, 32, 60) !important;
}

.color-56-2 {
  background: rgb(252, 36, 60) !important;
}

.color-56-3 {
  background: rgb(252, 40, 60) !important;
}

.color-56-4 {
  background: rgb(252, 44, 60) !important;
}

.color-56-5 {
  background: rgb(252, 48, 60) !important;
}

.color-56-6 {
  background: rgb(252, 52, 60) !important;
}

.color-56-7 {
  background: rgb(252, 56, 60) !important;
}

.color-56-8 {
  background: rgb(252, 60, 60) !important;
}

.color-56-9 {
  background: rgb(252, 64, 60) !important;
}

.color-56-10 {
  background: rgb(252, 68, 60) !important;
}

.color-56-11 {
  background: rgb(252, 72, 60) !important;
}

.color-56-12 {
  background: rgb(252, 76, 60) !important;
}

.color-56-13 {
  background: rgb(252, 80, 60) !important;
}

.color-56-14 {
  background: rgb(252, 84, 60) !important;
}

.color-56-15 {
  background: rgb(252, 88, 60) !important;
}

.color-56-16 {
  background: rgb(252, 92, 60) !important;
}

.color-56-17 {
  background: rgb(252, 96, 60) !important;
}

.color-56-18 {
  background: rgb(252, 100, 60) !important;
}

.color-56-19 {
  background: rgb(252, 104, 60) !important;
}

.color-56-20 {
  background: rgb(252, 108, 60) !important;
}

.color-56-21 {
  background: rgb(252, 112, 60) !important;
}

.color-56-22 {
  background: rgb(252, 116, 60) !important;
}

.color-56-23 {
  background: rgb(252, 120, 60) !important;
}

.color-56-24 {
  background: rgb(252, 124, 60) !important;
}

.color-56-25 {
  background: rgb(252, 128, 60) !important;
}

.color-56-26 {
  background: rgb(252, 132, 60) !important;
}

.color-56-27 {
  background: rgb(252, 136, 60) !important;
}

.color-56-28 {
  background: rgb(252, 140, 60) !important;
}

.color-56-29 {
  background: rgb(252, 144, 60) !important;
}

.color-56-30 {
  background: rgb(252, 148, 60) !important;
}

.color-56-31 {
  background: rgb(252, 152, 60) !important;
}

.color-56-32 {
  background: rgb(252, 156, 60) !important;
}

.color-56-33 {
  background: rgb(252, 160, 60) !important;
}

.color-56-34 {
  background: rgb(252, 164, 60) !important;
}

.color-56-35 {
  background: rgb(252, 168, 60) !important;
}

.color-56-36 {
  background: rgb(252, 172, 60) !important;
}

.color-56-37 {
  background: rgb(252, 176, 60) !important;
}

.color-56-38 {
  background: rgb(252, 180, 60) !important;
}

.color-56-39 {
  background: rgb(252, 184, 60) !important;
}

.color-56-40 {
  background: rgb(252, 188, 60) !important;
}

.color-56-41 {
  background: rgb(252, 192, 60) !important;
}

.color-56-42 {
  background: rgb(252, 196, 60) !important;
}

.color-56-43 {
  background: rgb(252, 200, 60) !important;
}

.color-56-44 {
  background: rgb(252, 204, 60) !important;
}

.color-56-45 {
  background: rgb(252, 208, 60) !important;
}

.color-56-46 {
  background: rgb(252, 212, 60) !important;
}

.color-56-47 {
  background: rgb(252, 216, 60) !important;
}

.color-56-48 {
  background: rgb(252, 220, 60) !important;
}

.color-56-49 {
  background: rgb(252, 224, 60) !important;
}

.color-56-50 {
  background: rgb(252, 228, 60) !important;
}

.color-56-51 {
  background: rgb(252, 232, 60) !important;
}

.color-56-52 {
  background: rgb(252, 236, 60) !important;
}

.color-56-53 {
  background: rgb(252, 240, 60) !important;
}

.color-56-54 {
  background: rgb(252, 244, 60) !important;
}

.color-56-55 {
  background: rgb(252, 248, 60) !important;
}

.color-57-0 {
  background: rgb(140, 0, 28) !important;
}

.color-57-1 {
  background: rgb(252, 32, 60) !important;
}

.color-57-2 {
  background: rgb(252, 36, 60) !important;
}

.color-57-3 {
  background: rgb(252, 40, 60) !important;
}

.color-57-4 {
  background: rgb(252, 44, 60) !important;
}

.color-57-5 {
  background: rgb(252, 48, 60) !important;
}

.color-57-6 {
  background: rgb(252, 52, 60) !important;
}

.color-57-7 {
  background: rgb(252, 56, 60) !important;
}

.color-57-8 {
  background: rgb(252, 60, 60) !important;
}

.color-57-9 {
  background: rgb(252, 63, 60) !important;
}

.color-57-10 {
  background: rgb(252, 67, 60) !important;
}

.color-57-11 {
  background: rgb(252, 71, 60) !important;
}

.color-57-12 {
  background: rgb(252, 75, 60) !important;
}

.color-57-13 {
  background: rgb(252, 79, 60) !important;
}

.color-57-14 {
  background: rgb(252, 83, 60) !important;
}

.color-57-15 {
  background: rgb(252, 87, 60) !important;
}

.color-57-16 {
  background: rgb(252, 91, 60) !important;
}

.color-57-17 {
  background: rgb(252, 95, 60) !important;
}

.color-57-18 {
  background: rgb(252, 99, 60) !important;
}

.color-57-19 {
  background: rgb(252, 103, 60) !important;
}

.color-57-20 {
  background: rgb(252, 107, 60) !important;
}

.color-57-21 {
  background: rgb(252, 111, 60) !important;
}

.color-57-22 {
  background: rgb(252, 115, 60) !important;
}

.color-57-23 {
  background: rgb(252, 118, 60) !important;
}

.color-57-24 {
  background: rgb(252, 122, 60) !important;
}

.color-57-25 {
  background: rgb(252, 126, 60) !important;
}

.color-57-26 {
  background: rgb(252, 130, 60) !important;
}

.color-57-27 {
  background: rgb(252, 134, 60) !important;
}

.color-57-28 {
  background: rgb(252, 138, 60) !important;
}

.color-57-29 {
  background: rgb(252, 142, 60) !important;
}

.color-57-30 {
  background: rgb(252, 146, 60) !important;
}

.color-57-31 {
  background: rgb(252, 150, 60) !important;
}

.color-57-32 {
  background: rgb(252, 154, 60) !important;
}

.color-57-33 {
  background: rgb(252, 158, 60) !important;
}

.color-57-34 {
  background: rgb(252, 162, 60) !important;
}

.color-57-35 {
  background: rgb(252, 166, 60) !important;
}

.color-57-36 {
  background: rgb(252, 170, 60) !important;
}

.color-57-37 {
  background: rgb(252, 173, 60) !important;
}

.color-57-38 {
  background: rgb(252, 177, 60) !important;
}

.color-57-39 {
  background: rgb(252, 181, 60) !important;
}

.color-57-40 {
  background: rgb(252, 185, 60) !important;
}

.color-57-41 {
  background: rgb(252, 189, 60) !important;
}

.color-57-42 {
  background: rgb(252, 193, 60) !important;
}

.color-57-43 {
  background: rgb(252, 197, 60) !important;
}

.color-57-44 {
  background: rgb(252, 201, 60) !important;
}

.color-57-45 {
  background: rgb(252, 205, 60) !important;
}

.color-57-46 {
  background: rgb(252, 209, 60) !important;
}

.color-57-47 {
  background: rgb(252, 213, 60) !important;
}

.color-57-48 {
  background: rgb(252, 217, 60) !important;
}

.color-57-49 {
  background: rgb(252, 221, 60) !important;
}

.color-57-50 {
  background: rgb(252, 225, 60) !important;
}

.color-57-51 {
  background: rgb(252, 228, 60) !important;
}

.color-57-52 {
  background: rgb(252, 232, 60) !important;
}

.color-57-53 {
  background: rgb(252, 236, 60) !important;
}

.color-57-54 {
  background: rgb(252, 240, 60) !important;
}

.color-57-55 {
  background: rgb(252, 244, 60) !important;
}

.color-57-56 {
  background: rgb(252, 248, 60) !important;
}

.color-58-0 {
  background: rgb(140, 0, 28) !important;
}

.color-58-1 {
  background: rgb(252, 32, 60) !important;
}

.color-58-2 {
  background: rgb(252, 36, 60) !important;
}

.color-58-3 {
  background: rgb(252, 40, 60) !important;
}

.color-58-4 {
  background: rgb(252, 44, 60) !important;
}

.color-58-5 {
  background: rgb(252, 47, 60) !important;
}

.color-58-6 {
  background: rgb(252, 51, 60) !important;
}

.color-58-7 {
  background: rgb(252, 55, 60) !important;
}

.color-58-8 {
  background: rgb(252, 59, 60) !important;
}

.color-58-9 {
  background: rgb(252, 63, 60) !important;
}

.color-58-10 {
  background: rgb(252, 67, 60) !important;
}

.color-58-11 {
  background: rgb(252, 71, 60) !important;
}

.color-58-12 {
  background: rgb(252, 74, 60) !important;
}

.color-58-13 {
  background: rgb(252, 78, 60) !important;
}

.color-58-14 {
  background: rgb(252, 82, 60) !important;
}

.color-58-15 {
  background: rgb(252, 86, 60) !important;
}

.color-58-16 {
  background: rgb(252, 90, 60) !important;
}

.color-58-17 {
  background: rgb(252, 94, 60) !important;
}

.color-58-18 {
  background: rgb(252, 98, 60) !important;
}

.color-58-19 {
  background: rgb(252, 101, 60) !important;
}

.color-58-20 {
  background: rgb(252, 105, 60) !important;
}

.color-58-21 {
  background: rgb(252, 109, 60) !important;
}

.color-58-22 {
  background: rgb(252, 113, 60) !important;
}

.color-58-23 {
  background: rgb(252, 117, 60) !important;
}

.color-58-24 {
  background: rgb(252, 121, 60) !important;
}

.color-58-25 {
  background: rgb(252, 125, 60) !important;
}

.color-58-26 {
  background: rgb(252, 128, 60) !important;
}

.color-58-27 {
  background: rgb(252, 132, 60) !important;
}

.color-58-28 {
  background: rgb(252, 136, 60) !important;
}

.color-58-29 {
  background: rgb(252, 140, 60) !important;
}

.color-58-30 {
  background: rgb(252, 144, 60) !important;
}

.color-58-31 {
  background: rgb(252, 148, 60) !important;
}

.color-58-32 {
  background: rgb(252, 152, 60) !important;
}

.color-58-33 {
  background: rgb(252, 156, 60) !important;
}

.color-58-34 {
  background: rgb(252, 159, 60) !important;
}

.color-58-35 {
  background: rgb(252, 163, 60) !important;
}

.color-58-36 {
  background: rgb(252, 167, 60) !important;
}

.color-58-37 {
  background: rgb(252, 171, 60) !important;
}

.color-58-38 {
  background: rgb(252, 175, 60) !important;
}

.color-58-39 {
  background: rgb(252, 179, 60) !important;
}

.color-58-40 {
  background: rgb(252, 183, 60) !important;
}

.color-58-41 {
  background: rgb(252, 186, 60) !important;
}

.color-58-42 {
  background: rgb(252, 190, 60) !important;
}

.color-58-43 {
  background: rgb(252, 194, 60) !important;
}

.color-58-44 {
  background: rgb(252, 198, 60) !important;
}

.color-58-45 {
  background: rgb(252, 202, 60) !important;
}

.color-58-46 {
  background: rgb(252, 206, 60) !important;
}

.color-58-47 {
  background: rgb(252, 210, 60) !important;
}

.color-58-48 {
  background: rgb(252, 213, 60) !important;
}

.color-58-49 {
  background: rgb(252, 217, 60) !important;
}

.color-58-50 {
  background: rgb(252, 221, 60) !important;
}

.color-58-51 {
  background: rgb(252, 225, 60) !important;
}

.color-58-52 {
  background: rgb(252, 229, 60) !important;
}

.color-58-53 {
  background: rgb(252, 233, 60) !important;
}

.color-58-54 {
  background: rgb(252, 237, 60) !important;
}

.color-58-55 {
  background: rgb(252, 240, 60) !important;
}

.color-58-56 {
  background: rgb(252, 244, 60) !important;
}

.color-58-57 {
  background: rgb(252, 248, 60) !important;
}

.color-59-0 {
  background: rgb(140, 0, 28) !important;
}

.color-59-1 {
  background: rgb(252, 32, 60) !important;
}

.color-59-2 {
  background: rgb(252, 36, 60) !important;
}

.color-59-3 {
  background: rgb(252, 40, 60) !important;
}

.color-59-4 {
  background: rgb(252, 43, 60) !important;
}

.color-59-5 {
  background: rgb(252, 47, 60) !important;
}

.color-59-6 {
  background: rgb(252, 51, 60) !important;
}

.color-59-7 {
  background: rgb(252, 55, 60) !important;
}

.color-59-8 {
  background: rgb(252, 59, 60) !important;
}

.color-59-9 {
  background: rgb(252, 62, 60) !important;
}

.color-59-10 {
  background: rgb(252, 66, 60) !important;
}

.color-59-11 {
  background: rgb(252, 70, 60) !important;
}

.color-59-12 {
  background: rgb(252, 74, 60) !important;
}

.color-59-13 {
  background: rgb(252, 78, 60) !important;
}

.color-59-14 {
  background: rgb(252, 81, 60) !important;
}

.color-59-15 {
  background: rgb(252, 85, 60) !important;
}

.color-59-16 {
  background: rgb(252, 89, 60) !important;
}

.color-59-17 {
  background: rgb(252, 93, 60) !important;
}

.color-59-18 {
  background: rgb(252, 96, 60) !important;
}

.color-59-19 {
  background: rgb(252, 100, 60) !important;
}

.color-59-20 {
  background: rgb(252, 104, 60) !important;
}

.color-59-21 {
  background: rgb(252, 108, 60) !important;
}

.color-59-22 {
  background: rgb(252, 112, 60) !important;
}

.color-59-23 {
  background: rgb(252, 115, 60) !important;
}

.color-59-24 {
  background: rgb(252, 119, 60) !important;
}

.color-59-25 {
  background: rgb(252, 123, 60) !important;
}

.color-59-26 {
  background: rgb(252, 127, 60) !important;
}

.color-59-27 {
  background: rgb(252, 131, 60) !important;
}

.color-59-28 {
  background: rgb(252, 134, 60) !important;
}

.color-59-29 {
  background: rgb(252, 138, 60) !important;
}

.color-59-30 {
  background: rgb(252, 142, 60) !important;
}

.color-59-31 {
  background: rgb(252, 146, 60) !important;
}

.color-59-32 {
  background: rgb(252, 150, 60) !important;
}

.color-59-33 {
  background: rgb(252, 153, 60) !important;
}

.color-59-34 {
  background: rgb(252, 157, 60) !important;
}

.color-59-35 {
  background: rgb(252, 161, 60) !important;
}

.color-59-36 {
  background: rgb(252, 165, 60) !important;
}

.color-59-37 {
  background: rgb(252, 169, 60) !important;
}

.color-59-38 {
  background: rgb(252, 172, 60) !important;
}

.color-59-39 {
  background: rgb(252, 176, 60) !important;
}

.color-59-40 {
  background: rgb(252, 180, 60) !important;
}

.color-59-41 {
  background: rgb(252, 184, 60) !important;
}

.color-59-42 {
  background: rgb(252, 188, 60) !important;
}

.color-59-43 {
  background: rgb(252, 191, 60) !important;
}

.color-59-44 {
  background: rgb(252, 195, 60) !important;
}

.color-59-45 {
  background: rgb(252, 199, 60) !important;
}

.color-59-46 {
  background: rgb(252, 203, 60) !important;
}

.color-59-47 {
  background: rgb(252, 206, 60) !important;
}

.color-59-48 {
  background: rgb(252, 210, 60) !important;
}

.color-59-49 {
  background: rgb(252, 214, 60) !important;
}

.color-59-50 {
  background: rgb(252, 218, 60) !important;
}

.color-59-51 {
  background: rgb(252, 222, 60) !important;
}

.color-59-52 {
  background: rgb(252, 225, 60) !important;
}

.color-59-53 {
  background: rgb(252, 229, 60) !important;
}

.color-59-54 {
  background: rgb(252, 233, 60) !important;
}

.color-59-55 {
  background: rgb(252, 237, 60) !important;
}

.color-59-56 {
  background: rgb(252, 241, 60) !important;
}

.color-59-57 {
  background: rgb(252, 244, 60) !important;
}

.color-59-58 {
  background: rgb(252, 248, 60) !important;
}

.color-60-0 {
  background: rgb(140, 0, 28) !important;
}

.color-60-1 {
  background: rgb(252, 32, 60) !important;
}

.color-60-2 {
  background: rgb(252, 36, 60) !important;
}

.color-60-3 {
  background: rgb(252, 39, 60) !important;
}

.color-60-4 {
  background: rgb(252, 43, 60) !important;
}

.color-60-5 {
  background: rgb(252, 47, 60) !important;
}

.color-60-6 {
  background: rgb(252, 51, 60) !important;
}

.color-60-7 {
  background: rgb(252, 54, 60) !important;
}

.color-60-8 {
  background: rgb(252, 58, 60) !important;
}

.color-60-9 {
  background: rgb(252, 62, 60) !important;
}

.color-60-10 {
  background: rgb(252, 66, 60) !important;
}

.color-60-11 {
  background: rgb(252, 69, 60) !important;
}

.color-60-12 {
  background: rgb(252, 73, 60) !important;
}

.color-60-13 {
  background: rgb(252, 77, 60) !important;
}

.color-60-14 {
  background: rgb(252, 80, 60) !important;
}

.color-60-15 {
  background: rgb(252, 84, 60) !important;
}

.color-60-16 {
  background: rgb(252, 88, 60) !important;
}

.color-60-17 {
  background: rgb(252, 92, 60) !important;
}

.color-60-18 {
  background: rgb(252, 95, 60) !important;
}

.color-60-19 {
  background: rgb(252, 99, 60) !important;
}

.color-60-20 {
  background: rgb(252, 103, 60) !important;
}

.color-60-21 {
  background: rgb(252, 107, 60) !important;
}

.color-60-22 {
  background: rgb(252, 110, 60) !important;
}

.color-60-23 {
  background: rgb(252, 114, 60) !important;
}

.color-60-24 {
  background: rgb(252, 118, 60) !important;
}

.color-60-25 {
  background: rgb(252, 121, 60) !important;
}

.color-60-26 {
  background: rgb(252, 125, 60) !important;
}

.color-60-27 {
  background: rgb(252, 129, 60) !important;
}

.color-60-28 {
  background: rgb(252, 133, 60) !important;
}

.color-60-29 {
  background: rgb(252, 136, 60) !important;
}

.color-60-30 {
  background: rgb(252, 140, 60) !important;
}

.color-60-31 {
  background: rgb(252, 144, 60) !important;
}

.color-60-32 {
  background: rgb(252, 148, 60) !important;
}

.color-60-33 {
  background: rgb(252, 151, 60) !important;
}

.color-60-34 {
  background: rgb(252, 155, 60) !important;
}

.color-60-35 {
  background: rgb(252, 159, 60) !important;
}

.color-60-36 {
  background: rgb(252, 163, 60) !important;
}

.color-60-37 {
  background: rgb(252, 166, 60) !important;
}

.color-60-38 {
  background: rgb(252, 170, 60) !important;
}

.color-60-39 {
  background: rgb(252, 174, 60) !important;
}

.color-60-40 {
  background: rgb(252, 177, 60) !important;
}

.color-60-41 {
  background: rgb(252, 181, 60) !important;
}

.color-60-42 {
  background: rgb(252, 185, 60) !important;
}

.color-60-43 {
  background: rgb(252, 189, 60) !important;
}

.color-60-44 {
  background: rgb(252, 192, 60) !important;
}

.color-60-45 {
  background: rgb(252, 196, 60) !important;
}

.color-60-46 {
  background: rgb(252, 200, 60) !important;
}

.color-60-47 {
  background: rgb(252, 204, 60) !important;
}

.color-60-48 {
  background: rgb(252, 207, 60) !important;
}

.color-60-49 {
  background: rgb(252, 211, 60) !important;
}

.color-60-50 {
  background: rgb(252, 215, 60) !important;
}

.color-60-51 {
  background: rgb(252, 218, 60) !important;
}

.color-60-52 {
  background: rgb(252, 222, 60) !important;
}

.color-60-53 {
  background: rgb(252, 226, 60) !important;
}

.color-60-54 {
  background: rgb(252, 230, 60) !important;
}

.color-60-55 {
  background: rgb(252, 233, 60) !important;
}

.color-60-56 {
  background: rgb(252, 237, 60) !important;
}

.color-60-57 {
  background: rgb(252, 241, 60) !important;
}

.color-60-58 {
  background: rgb(252, 245, 60) !important;
}

.color-60-59 {
  background: rgb(252, 248, 60) !important;
}

.color-61-0 {
  background: rgb(140, 0, 28) !important;
}

.color-61-1 {
  background: rgb(252, 32, 60) !important;
}

.color-61-2 {
  background: rgb(252, 36, 60) !important;
}

.color-61-3 {
  background: rgb(252, 39, 60) !important;
}

.color-61-4 {
  background: rgb(252, 43, 60) !important;
}

.color-61-5 {
  background: rgb(252, 47, 60) !important;
}

.color-61-6 {
  background: rgb(252, 50, 60) !important;
}

.color-61-7 {
  background: rgb(252, 54, 60) !important;
}

.color-61-8 {
  background: rgb(252, 58, 60) !important;
}

.color-61-9 {
  background: rgb(252, 61, 60) !important;
}

.color-61-10 {
  background: rgb(252, 65, 60) !important;
}

.color-61-11 {
  background: rgb(252, 69, 60) !important;
}

.color-61-12 {
  background: rgb(252, 72, 60) !important;
}

.color-61-13 {
  background: rgb(252, 76, 60) !important;
}

.color-61-14 {
  background: rgb(252, 80, 60) !important;
}

.color-61-15 {
  background: rgb(252, 83, 60) !important;
}

.color-61-16 {
  background: rgb(252, 87, 60) !important;
}

.color-61-17 {
  background: rgb(252, 91, 60) !important;
}

.color-61-18 {
  background: rgb(252, 94, 60) !important;
}

.color-61-19 {
  background: rgb(252, 98, 60) !important;
}

.color-61-20 {
  background: rgb(252, 102, 60) !important;
}

.color-61-21 {
  background: rgb(252, 105, 60) !important;
}

.color-61-22 {
  background: rgb(252, 109, 60) !important;
}

.color-61-23 {
  background: rgb(252, 113, 60) !important;
}

.color-61-24 {
  background: rgb(252, 116, 60) !important;
}

.color-61-25 {
  background: rgb(252, 120, 60) !important;
}

.color-61-26 {
  background: rgb(252, 124, 60) !important;
}

.color-61-27 {
  background: rgb(252, 127, 60) !important;
}

.color-61-28 {
  background: rgb(252, 131, 60) !important;
}

.color-61-29 {
  background: rgb(252, 135, 60) !important;
}

.color-61-30 {
  background: rgb(252, 138, 60) !important;
}

.color-61-31 {
  background: rgb(252, 142, 60) !important;
}

.color-61-32 {
  background: rgb(252, 146, 60) !important;
}

.color-61-33 {
  background: rgb(252, 149, 60) !important;
}

.color-61-34 {
  background: rgb(252, 153, 60) !important;
}

.color-61-35 {
  background: rgb(252, 157, 60) !important;
}

.color-61-36 {
  background: rgb(252, 160, 60) !important;
}

.color-61-37 {
  background: rgb(252, 164, 60) !important;
}

.color-61-38 {
  background: rgb(252, 168, 60) !important;
}

.color-61-39 {
  background: rgb(252, 171, 60) !important;
}

.color-61-40 {
  background: rgb(252, 175, 60) !important;
}

.color-61-41 {
  background: rgb(252, 179, 60) !important;
}

.color-61-42 {
  background: rgb(252, 182, 60) !important;
}

.color-61-43 {
  background: rgb(252, 186, 60) !important;
}

.color-61-44 {
  background: rgb(252, 190, 60) !important;
}

.color-61-45 {
  background: rgb(252, 193, 60) !important;
}

.color-61-46 {
  background: rgb(252, 197, 60) !important;
}

.color-61-47 {
  background: rgb(252, 201, 60) !important;
}

.color-61-48 {
  background: rgb(252, 204, 60) !important;
}

.color-61-49 {
  background: rgb(252, 208, 60) !important;
}

.color-61-50 {
  background: rgb(252, 212, 60) !important;
}

.color-61-51 {
  background: rgb(252, 215, 60) !important;
}

.color-61-52 {
  background: rgb(252, 219, 60) !important;
}

.color-61-53 {
  background: rgb(252, 223, 60) !important;
}

.color-61-54 {
  background: rgb(252, 226, 60) !important;
}

.color-61-55 {
  background: rgb(252, 230, 60) !important;
}

.color-61-56 {
  background: rgb(252, 234, 60) !important;
}

.color-61-57 {
  background: rgb(252, 237, 60) !important;
}

.color-61-58 {
  background: rgb(252, 241, 60) !important;
}

.color-61-59 {
  background: rgb(252, 245, 60) !important;
}

.color-61-60 {
  background: rgb(252, 248, 60) !important;
}

.color-62-0 {
  background: rgb(140, 0, 28) !important;
}

.color-62-1 {
  background: rgb(252, 32, 60) !important;
}

.color-62-2 {
  background: rgb(252, 36, 60) !important;
}

.color-62-3 {
  background: rgb(252, 39, 60) !important;
}

.color-62-4 {
  background: rgb(252, 43, 60) !important;
}

.color-62-5 {
  background: rgb(252, 46, 60) !important;
}

.color-62-6 {
  background: rgb(252, 50, 60) !important;
}

.color-62-7 {
  background: rgb(252, 54, 60) !important;
}

.color-62-8 {
  background: rgb(252, 57, 60) !important;
}

.color-62-9 {
  background: rgb(252, 61, 60) !important;
}

.color-62-10 {
  background: rgb(252, 64, 60) !important;
}

.color-62-11 {
  background: rgb(252, 68, 60) !important;
}

.color-62-12 {
  background: rgb(252, 72, 60) !important;
}

.color-62-13 {
  background: rgb(252, 75, 60) !important;
}

.color-62-14 {
  background: rgb(252, 79, 60) !important;
}

.color-62-15 {
  background: rgb(252, 82, 60) !important;
}

.color-62-16 {
  background: rgb(252, 86, 60) !important;
}

.color-62-17 {
  background: rgb(252, 90, 60) !important;
}

.color-62-18 {
  background: rgb(252, 93, 60) !important;
}

.color-62-19 {
  background: rgb(252, 97, 60) !important;
}

.color-62-20 {
  background: rgb(252, 101, 60) !important;
}

.color-62-21 {
  background: rgb(252, 104, 60) !important;
}

.color-62-22 {
  background: rgb(252, 108, 60) !important;
}

.color-62-23 {
  background: rgb(252, 111, 60) !important;
}

.color-62-24 {
  background: rgb(252, 115, 60) !important;
}

.color-62-25 {
  background: rgb(252, 119, 60) !important;
}

.color-62-26 {
  background: rgb(252, 122, 60) !important;
}

.color-62-27 {
  background: rgb(252, 126, 60) !important;
}

.color-62-28 {
  background: rgb(252, 129, 60) !important;
}

.color-62-29 {
  background: rgb(252, 133, 60) !important;
}

.color-62-30 {
  background: rgb(252, 137, 60) !important;
}

.color-62-31 {
  background: rgb(252, 140, 60) !important;
}

.color-62-32 {
  background: rgb(252, 144, 60) !important;
}

.color-62-33 {
  background: rgb(252, 147, 60) !important;
}

.color-62-34 {
  background: rgb(252, 151, 60) !important;
}

.color-62-35 {
  background: rgb(252, 155, 60) !important;
}

.color-62-36 {
  background: rgb(252, 158, 60) !important;
}

.color-62-37 {
  background: rgb(252, 162, 60) !important;
}

.color-62-38 {
  background: rgb(252, 165, 60) !important;
}

.color-62-39 {
  background: rgb(252, 169, 60) !important;
}

.color-62-40 {
  background: rgb(252, 173, 60) !important;
}

.color-62-41 {
  background: rgb(252, 176, 60) !important;
}

.color-62-42 {
  background: rgb(252, 180, 60) !important;
}

.color-62-43 {
  background: rgb(252, 183, 60) !important;
}

.color-62-44 {
  background: rgb(252, 187, 60) !important;
}

.color-62-45 {
  background: rgb(252, 191, 60) !important;
}

.color-62-46 {
  background: rgb(252, 194, 60) !important;
}

.color-62-47 {
  background: rgb(252, 198, 60) !important;
}

.color-62-48 {
  background: rgb(252, 202, 60) !important;
}

.color-62-49 {
  background: rgb(252, 205, 60) !important;
}

.color-62-50 {
  background: rgb(252, 209, 60) !important;
}

.color-62-51 {
  background: rgb(252, 212, 60) !important;
}

.color-62-52 {
  background: rgb(252, 216, 60) !important;
}

.color-62-53 {
  background: rgb(252, 220, 60) !important;
}

.color-62-54 {
  background: rgb(252, 223, 60) !important;
}

.color-62-55 {
  background: rgb(252, 227, 60) !important;
}

.color-62-56 {
  background: rgb(252, 230, 60) !important;
}

.color-62-57 {
  background: rgb(252, 234, 60) !important;
}

.color-62-58 {
  background: rgb(252, 238, 60) !important;
}

.color-62-59 {
  background: rgb(252, 241, 60) !important;
}

.color-62-60 {
  background: rgb(252, 245, 60) !important;
}

.color-62-61 {
  background: rgb(252, 248, 60) !important;
}

.color-63-0 {
  background: rgb(140, 0, 28) !important;
}

.color-63-1 {
  background: rgb(252, 32, 60) !important;
}

.color-63-2 {
  background: rgb(252, 36, 60) !important;
}

.color-63-3 {
  background: rgb(252, 39, 60) !important;
}

.color-63-4 {
  background: rgb(252, 43, 60) !important;
}

.color-63-5 {
  background: rgb(252, 46, 60) !important;
}

.color-63-6 {
  background: rgb(252, 50, 60) !important;
}

.color-63-7 {
  background: rgb(252, 53, 60) !important;
}

.color-63-8 {
  background: rgb(252, 57, 60) !important;
}

.color-63-9 {
  background: rgb(252, 60, 60) !important;
}

.color-63-10 {
  background: rgb(252, 64, 60) !important;
}

.color-63-11 {
  background: rgb(252, 67, 60) !important;
}

.color-63-12 {
  background: rgb(252, 71, 60) !important;
}

.color-63-13 {
  background: rgb(252, 75, 60) !important;
}

.color-63-14 {
  background: rgb(252, 78, 60) !important;
}

.color-63-15 {
  background: rgb(252, 82, 60) !important;
}

.color-63-16 {
  background: rgb(252, 85, 60) !important;
}

.color-63-17 {
  background: rgb(252, 89, 60) !important;
}

.color-63-18 {
  background: rgb(252, 92, 60) !important;
}

.color-63-19 {
  background: rgb(252, 96, 60) !important;
}

.color-63-20 {
  background: rgb(252, 99, 60) !important;
}

.color-63-21 {
  background: rgb(252, 103, 60) !important;
}

.color-63-22 {
  background: rgb(252, 107, 60) !important;
}

.color-63-23 {
  background: rgb(252, 110, 60) !important;
}

.color-63-24 {
  background: rgb(252, 114, 60) !important;
}

.color-63-25 {
  background: rgb(252, 117, 60) !important;
}

.color-63-26 {
  background: rgb(252, 121, 60) !important;
}

.color-63-27 {
  background: rgb(252, 124, 60) !important;
}

.color-63-28 {
  background: rgb(252, 128, 60) !important;
}

.color-63-29 {
  background: rgb(252, 131, 60) !important;
}

.color-63-30 {
  background: rgb(252, 135, 60) !important;
}

.color-63-31 {
  background: rgb(252, 138, 60) !important;
}

.color-63-32 {
  background: rgb(252, 142, 60) !important;
}

.color-63-33 {
  background: rgb(252, 146, 60) !important;
}

.color-63-34 {
  background: rgb(252, 149, 60) !important;
}

.color-63-35 {
  background: rgb(252, 153, 60) !important;
}

.color-63-36 {
  background: rgb(252, 156, 60) !important;
}

.color-63-37 {
  background: rgb(252, 160, 60) !important;
}

.color-63-38 {
  background: rgb(252, 163, 60) !important;
}

.color-63-39 {
  background: rgb(252, 167, 60) !important;
}

.color-63-40 {
  background: rgb(252, 170, 60) !important;
}

.color-63-41 {
  background: rgb(252, 174, 60) !important;
}

.color-63-42 {
  background: rgb(252, 177, 60) !important;
}

.color-63-43 {
  background: rgb(252, 181, 60) !important;
}

.color-63-44 {
  background: rgb(252, 185, 60) !important;
}

.color-63-45 {
  background: rgb(252, 188, 60) !important;
}

.color-63-46 {
  background: rgb(252, 192, 60) !important;
}

.color-63-47 {
  background: rgb(252, 195, 60) !important;
}

.color-63-48 {
  background: rgb(252, 199, 60) !important;
}

.color-63-49 {
  background: rgb(252, 202, 60) !important;
}

.color-63-50 {
  background: rgb(252, 206, 60) !important;
}

.color-63-51 {
  background: rgb(252, 209, 60) !important;
}

.color-63-52 {
  background: rgb(252, 213, 60) !important;
}

.color-63-53 {
  background: rgb(252, 217, 60) !important;
}

.color-63-54 {
  background: rgb(252, 220, 60) !important;
}

.color-63-55 {
  background: rgb(252, 224, 60) !important;
}

.color-63-56 {
  background: rgb(252, 227, 60) !important;
}

.color-63-57 {
  background: rgb(252, 231, 60) !important;
}

.color-63-58 {
  background: rgb(252, 234, 60) !important;
}

.color-63-59 {
  background: rgb(252, 238, 60) !important;
}

.color-63-60 {
  background: rgb(252, 241, 60) !important;
}

.color-63-61 {
  background: rgb(252, 245, 60) !important;
}

.color-63-62 {
  background: rgb(252, 248, 60) !important;
}

.color-64-0 {
  background: rgb(140, 0, 28) !important;
}

.color-64-1 {
  background: rgb(252, 32, 60) !important;
}

.color-64-2 {
  background: rgb(252, 35, 60) !important;
}

.color-64-3 {
  background: rgb(252, 39, 60) !important;
}

.color-64-4 {
  background: rgb(252, 42, 60) !important;
}

.color-64-5 {
  background: rgb(252, 46, 60) !important;
}

.color-64-6 {
  background: rgb(252, 49, 60) !important;
}

.color-64-7 {
  background: rgb(252, 53, 60) !important;
}

.color-64-8 {
  background: rgb(252, 56, 60) !important;
}

.color-64-9 {
  background: rgb(252, 60, 60) !important;
}

.color-64-10 {
  background: rgb(252, 63, 60) !important;
}

.color-64-11 {
  background: rgb(252, 67, 60) !important;
}

.color-64-12 {
  background: rgb(252, 70, 60) !important;
}

.color-64-13 {
  background: rgb(252, 74, 60) !important;
}

.color-64-14 {
  background: rgb(252, 77, 60) !important;
}

.color-64-15 {
  background: rgb(252, 81, 60) !important;
}

.color-64-16 {
  background: rgb(252, 84, 60) !important;
}

.color-64-17 {
  background: rgb(252, 88, 60) !important;
}

.color-64-18 {
  background: rgb(252, 91, 60) !important;
}

.color-64-19 {
  background: rgb(252, 95, 60) !important;
}

.color-64-20 {
  background: rgb(252, 98, 60) !important;
}

.color-64-21 {
  background: rgb(252, 102, 60) !important;
}

.color-64-22 {
  background: rgb(252, 105, 60) !important;
}

.color-64-23 {
  background: rgb(252, 109, 60) !important;
}

.color-64-24 {
  background: rgb(252, 112, 60) !important;
}

.color-64-25 {
  background: rgb(252, 116, 60) !important;
}

.color-64-26 {
  background: rgb(252, 119, 60) !important;
}

.color-64-27 {
  background: rgb(252, 123, 60) !important;
}

.color-64-28 {
  background: rgb(252, 126, 60) !important;
}

.color-64-29 {
  background: rgb(252, 130, 60) !important;
}

.color-64-30 {
  background: rgb(252, 133, 60) !important;
}

.color-64-31 {
  background: rgb(252, 137, 60) !important;
}

.color-64-32 {
  background: rgb(252, 140, 60) !important;
}

.color-64-33 {
  background: rgb(252, 144, 60) !important;
}

.color-64-34 {
  background: rgb(252, 147, 60) !important;
}

.color-64-35 {
  background: rgb(252, 151, 60) !important;
}

.color-64-36 {
  background: rgb(252, 154, 60) !important;
}

.color-64-37 {
  background: rgb(252, 158, 60) !important;
}

.color-64-38 {
  background: rgb(252, 161, 60) !important;
}

.color-64-39 {
  background: rgb(252, 165, 60) !important;
}

.color-64-40 {
  background: rgb(252, 168, 60) !important;
}

.color-64-41 {
  background: rgb(252, 172, 60) !important;
}

.color-64-42 {
  background: rgb(252, 175, 60) !important;
}

.color-64-43 {
  background: rgb(252, 179, 60) !important;
}

.color-64-44 {
  background: rgb(252, 182, 60) !important;
}

.color-64-45 {
  background: rgb(252, 186, 60) !important;
}

.color-64-46 {
  background: rgb(252, 189, 60) !important;
}

.color-64-47 {
  background: rgb(252, 193, 60) !important;
}

.color-64-48 {
  background: rgb(252, 196, 60) !important;
}

.color-64-49 {
  background: rgb(252, 200, 60) !important;
}

.color-64-50 {
  background: rgb(252, 203, 60) !important;
}

.color-64-51 {
  background: rgb(252, 207, 60) !important;
}

.color-64-52 {
  background: rgb(252, 210, 60) !important;
}

.color-64-53 {
  background: rgb(252, 214, 60) !important;
}

.color-64-54 {
  background: rgb(252, 217, 60) !important;
}

.color-64-55 {
  background: rgb(252, 221, 60) !important;
}

.color-64-56 {
  background: rgb(252, 224, 60) !important;
}

.color-64-57 {
  background: rgb(252, 228, 60) !important;
}

.color-64-58 {
  background: rgb(252, 231, 60) !important;
}

.color-64-59 {
  background: rgb(252, 235, 60) !important;
}

.color-64-60 {
  background: rgb(252, 238, 60) !important;
}

.color-64-61 {
  background: rgb(252, 242, 60) !important;
}

.color-64-62 {
  background: rgb(252, 245, 60) !important;
}

.color-64-63 {
  background: rgb(252, 249, 60) !important;
}

.color-65-0 {
  background: rgb(140, 0, 28) !important;
}

.color-65-1 {
  background: rgb(252, 32, 60) !important;
}

.color-65-2 {
  background: rgb(252, 35, 60) !important;
}

.color-65-3 {
  background: rgb(252, 39, 60) !important;
}

.color-65-4 {
  background: rgb(252, 42, 60) !important;
}

.color-65-5 {
  background: rgb(252, 46, 60) !important;
}

.color-65-6 {
  background: rgb(252, 49, 60) !important;
}

.color-65-7 {
  background: rgb(252, 53, 60) !important;
}

.color-65-8 {
  background: rgb(252, 56, 60) !important;
}

.color-65-9 {
  background: rgb(252, 60, 60) !important;
}

.color-65-10 {
  background: rgb(252, 63, 60) !important;
}

.color-65-11 {
  background: rgb(252, 66, 60) !important;
}

.color-65-12 {
  background: rgb(252, 70, 60) !important;
}

.color-65-13 {
  background: rgb(252, 73, 60) !important;
}

.color-65-14 {
  background: rgb(252, 77, 60) !important;
}

.color-65-15 {
  background: rgb(252, 80, 60) !important;
}

.color-65-16 {
  background: rgb(252, 84, 60) !important;
}

.color-65-17 {
  background: rgb(252, 87, 60) !important;
}

.color-65-18 {
  background: rgb(252, 90, 60) !important;
}

.color-65-19 {
  background: rgb(252, 94, 60) !important;
}

.color-65-20 {
  background: rgb(252, 97, 60) !important;
}

.color-65-21 {
  background: rgb(252, 101, 60) !important;
}

.color-65-22 {
  background: rgb(252, 104, 60) !important;
}

.color-65-23 {
  background: rgb(252, 108, 60) !important;
}

.color-65-24 {
  background: rgb(252, 111, 60) !important;
}

.color-65-25 {
  background: rgb(252, 115, 60) !important;
}

.color-65-26 {
  background: rgb(252, 118, 60) !important;
}

.color-65-27 {
  background: rgb(252, 121, 60) !important;
}

.color-65-28 {
  background: rgb(252, 125, 60) !important;
}

.color-65-29 {
  background: rgb(252, 128, 60) !important;
}

.color-65-30 {
  background: rgb(252, 132, 60) !important;
}

.color-65-31 {
  background: rgb(252, 135, 60) !important;
}

.color-65-32 {
  background: rgb(252, 139, 60) !important;
}

.color-65-33 {
  background: rgb(252, 142, 60) !important;
}

.color-65-34 {
  background: rgb(252, 145, 60) !important;
}

.color-65-35 {
  background: rgb(252, 149, 60) !important;
}

.color-65-36 {
  background: rgb(252, 152, 60) !important;
}

.color-65-37 {
  background: rgb(252, 156, 60) !important;
}

.color-65-38 {
  background: rgb(252, 159, 60) !important;
}

.color-65-39 {
  background: rgb(252, 163, 60) !important;
}

.color-65-40 {
  background: rgb(252, 166, 60) !important;
}

.color-65-41 {
  background: rgb(252, 170, 60) !important;
}

.color-65-42 {
  background: rgb(252, 173, 60) !important;
}

.color-65-43 {
  background: rgb(252, 176, 60) !important;
}

.color-65-44 {
  background: rgb(252, 180, 60) !important;
}

.color-65-45 {
  background: rgb(252, 183, 60) !important;
}

.color-65-46 {
  background: rgb(252, 187, 60) !important;
}

.color-65-47 {
  background: rgb(252, 190, 60) !important;
}

.color-65-48 {
  background: rgb(252, 194, 60) !important;
}

.color-65-49 {
  background: rgb(252, 197, 60) !important;
}

.color-65-50 {
  background: rgb(252, 200, 60) !important;
}

.color-65-51 {
  background: rgb(252, 204, 60) !important;
}

.color-65-52 {
  background: rgb(252, 207, 60) !important;
}

.color-65-53 {
  background: rgb(252, 211, 60) !important;
}

.color-65-54 {
  background: rgb(252, 214, 60) !important;
}

.color-65-55 {
  background: rgb(252, 218, 60) !important;
}

.color-65-56 {
  background: rgb(252, 221, 60) !important;
}

.color-65-57 {
  background: rgb(252, 225, 60) !important;
}

.color-65-58 {
  background: rgb(252, 228, 60) !important;
}

.color-65-59 {
  background: rgb(252, 231, 60) !important;
}

.color-65-60 {
  background: rgb(252, 235, 60) !important;
}

.color-65-61 {
  background: rgb(252, 238, 60) !important;
}

.color-65-62 {
  background: rgb(252, 242, 60) !important;
}

.color-65-63 {
  background: rgb(252, 245, 60) !important;
}

.color-65-64 {
  background: rgb(252, 249, 60) !important;
}

.color-66-0 {
  background: rgb(140, 0, 28) !important;
}

.color-66-1 {
  background: rgb(252, 32, 60) !important;
}

.color-66-2 {
  background: rgb(252, 35, 60) !important;
}

.color-66-3 {
  background: rgb(252, 39, 60) !important;
}

.color-66-4 {
  background: rgb(252, 42, 60) !important;
}

.color-66-5 {
  background: rgb(252, 46, 60) !important;
}

.color-66-6 {
  background: rgb(252, 49, 60) !important;
}

.color-66-7 {
  background: rgb(252, 52, 60) !important;
}

.color-66-8 {
  background: rgb(252, 56, 60) !important;
}

.color-66-9 {
  background: rgb(252, 59, 60) !important;
}

.color-66-10 {
  background: rgb(252, 62, 60) !important;
}

.color-66-11 {
  background: rgb(252, 66, 60) !important;
}

.color-66-12 {
  background: rgb(252, 69, 60) !important;
}

.color-66-13 {
  background: rgb(252, 73, 60) !important;
}

.color-66-14 {
  background: rgb(252, 76, 60) !important;
}

.color-66-15 {
  background: rgb(252, 79, 60) !important;
}

.color-66-16 {
  background: rgb(252, 83, 60) !important;
}

.color-66-17 {
  background: rgb(252, 86, 60) !important;
}

.color-66-18 {
  background: rgb(252, 90, 60) !important;
}

.color-66-19 {
  background: rgb(252, 93, 60) !important;
}

.color-66-20 {
  background: rgb(252, 96, 60) !important;
}

.color-66-21 {
  background: rgb(252, 100, 60) !important;
}

.color-66-22 {
  background: rgb(252, 103, 60) !important;
}

.color-66-23 {
  background: rgb(252, 106, 60) !important;
}

.color-66-24 {
  background: rgb(252, 110, 60) !important;
}

.color-66-25 {
  background: rgb(252, 113, 60) !important;
}

.color-66-26 {
  background: rgb(252, 117, 60) !important;
}

.color-66-27 {
  background: rgb(252, 120, 60) !important;
}

.color-66-28 {
  background: rgb(252, 123, 60) !important;
}

.color-66-29 {
  background: rgb(252, 127, 60) !important;
}

.color-66-30 {
  background: rgb(252, 130, 60) !important;
}

.color-66-31 {
  background: rgb(252, 134, 60) !important;
}

.color-66-32 {
  background: rgb(252, 137, 60) !important;
}

.color-66-33 {
  background: rgb(252, 140, 60) !important;
}

.color-66-34 {
  background: rgb(252, 144, 60) !important;
}

.color-66-35 {
  background: rgb(252, 147, 60) !important;
}

.color-66-36 {
  background: rgb(252, 150, 60) !important;
}

.color-66-37 {
  background: rgb(252, 154, 60) !important;
}

.color-66-38 {
  background: rgb(252, 157, 60) !important;
}

.color-66-39 {
  background: rgb(252, 161, 60) !important;
}

.color-66-40 {
  background: rgb(252, 164, 60) !important;
}

.color-66-41 {
  background: rgb(252, 167, 60) !important;
}

.color-66-42 {
  background: rgb(252, 171, 60) !important;
}

.color-66-43 {
  background: rgb(252, 174, 60) !important;
}

.color-66-44 {
  background: rgb(252, 178, 60) !important;
}

.color-66-45 {
  background: rgb(252, 181, 60) !important;
}

.color-66-46 {
  background: rgb(252, 184, 60) !important;
}

.color-66-47 {
  background: rgb(252, 188, 60) !important;
}

.color-66-48 {
  background: rgb(252, 191, 60) !important;
}

.color-66-49 {
  background: rgb(252, 194, 60) !important;
}

.color-66-50 {
  background: rgb(252, 198, 60) !important;
}

.color-66-51 {
  background: rgb(252, 201, 60) !important;
}

.color-66-52 {
  background: rgb(252, 205, 60) !important;
}

.color-66-53 {
  background: rgb(252, 208, 60) !important;
}

.color-66-54 {
  background: rgb(252, 211, 60) !important;
}

.color-66-55 {
  background: rgb(252, 215, 60) !important;
}

.color-66-56 {
  background: rgb(252, 218, 60) !important;
}

.color-66-57 {
  background: rgb(252, 222, 60) !important;
}

.color-66-58 {
  background: rgb(252, 225, 60) !important;
}

.color-66-59 {
  background: rgb(252, 228, 60) !important;
}

.color-66-60 {
  background: rgb(252, 232, 60) !important;
}

.color-66-61 {
  background: rgb(252, 235, 60) !important;
}

.color-66-62 {
  background: rgb(252, 238, 60) !important;
}

.color-66-63 {
  background: rgb(252, 242, 60) !important;
}

.color-66-64 {
  background: rgb(252, 245, 60) !important;
}

.color-66-65 {
  background: rgb(252, 249, 60) !important;
}

.color-67-0 {
  background: rgb(140, 0, 28) !important;
}

.color-67-1 {
  background: rgb(252, 32, 60) !important;
}

.color-67-2 {
  background: rgb(252, 35, 60) !important;
}

.color-67-3 {
  background: rgb(252, 39, 60) !important;
}

.color-67-4 {
  background: rgb(252, 42, 60) !important;
}

.color-67-5 {
  background: rgb(252, 45, 60) !important;
}

.color-67-6 {
  background: rgb(252, 49, 60) !important;
}

.color-67-7 {
  background: rgb(252, 52, 60) !important;
}

.color-67-8 {
  background: rgb(252, 55, 60) !important;
}

.color-67-9 {
  background: rgb(252, 59, 60) !important;
}

.color-67-10 {
  background: rgb(252, 62, 60) !important;
}

.color-67-11 {
  background: rgb(252, 65, 60) !important;
}

.color-67-12 {
  background: rgb(252, 69, 60) !important;
}

.color-67-13 {
  background: rgb(252, 72, 60) !important;
}

.color-67-14 {
  background: rgb(252, 75, 60) !important;
}

.color-67-15 {
  background: rgb(252, 79, 60) !important;
}

.color-67-16 {
  background: rgb(252, 82, 60) !important;
}

.color-67-17 {
  background: rgb(252, 85, 60) !important;
}

.color-67-18 {
  background: rgb(252, 89, 60) !important;
}

.color-67-19 {
  background: rgb(252, 92, 60) !important;
}

.color-67-20 {
  background: rgb(252, 95, 60) !important;
}

.color-67-21 {
  background: rgb(252, 99, 60) !important;
}

.color-67-22 {
  background: rgb(252, 102, 60) !important;
}

.color-67-23 {
  background: rgb(252, 105, 60) !important;
}

.color-67-24 {
  background: rgb(252, 109, 60) !important;
}

.color-67-25 {
  background: rgb(252, 112, 60) !important;
}

.color-67-26 {
  background: rgb(252, 115, 60) !important;
}

.color-67-27 {
  background: rgb(252, 119, 60) !important;
}

.color-67-28 {
  background: rgb(252, 122, 60) !important;
}

.color-67-29 {
  background: rgb(252, 125, 60) !important;
}

.color-67-30 {
  background: rgb(252, 129, 60) !important;
}

.color-67-31 {
  background: rgb(252, 132, 60) !important;
}

.color-67-32 {
  background: rgb(252, 135, 60) !important;
}

.color-67-33 {
  background: rgb(252, 139, 60) !important;
}

.color-67-34 {
  background: rgb(252, 142, 60) !important;
}

.color-67-35 {
  background: rgb(252, 145, 60) !important;
}

.color-67-36 {
  background: rgb(252, 149, 60) !important;
}

.color-67-37 {
  background: rgb(252, 152, 60) !important;
}

.color-67-38 {
  background: rgb(252, 155, 60) !important;
}

.color-67-39 {
  background: rgb(252, 159, 60) !important;
}

.color-67-40 {
  background: rgb(252, 162, 60) !important;
}

.color-67-41 {
  background: rgb(252, 165, 60) !important;
}

.color-67-42 {
  background: rgb(252, 169, 60) !important;
}

.color-67-43 {
  background: rgb(252, 172, 60) !important;
}

.color-67-44 {
  background: rgb(252, 175, 60) !important;
}

.color-67-45 {
  background: rgb(252, 179, 60) !important;
}

.color-67-46 {
  background: rgb(252, 182, 60) !important;
}

.color-67-47 {
  background: rgb(252, 185, 60) !important;
}

.color-67-48 {
  background: rgb(252, 189, 60) !important;
}

.color-67-49 {
  background: rgb(252, 192, 60) !important;
}

.color-67-50 {
  background: rgb(252, 195, 60) !important;
}

.color-67-51 {
  background: rgb(252, 199, 60) !important;
}

.color-67-52 {
  background: rgb(252, 202, 60) !important;
}

.color-67-53 {
  background: rgb(252, 205, 60) !important;
}

.color-67-54 {
  background: rgb(252, 209, 60) !important;
}

.color-67-55 {
  background: rgb(252, 212, 60) !important;
}

.color-67-56 {
  background: rgb(252, 215, 60) !important;
}

.color-67-57 {
  background: rgb(252, 219, 60) !important;
}

.color-67-58 {
  background: rgb(252, 222, 60) !important;
}

.color-67-59 {
  background: rgb(252, 225, 60) !important;
}

.color-67-60 {
  background: rgb(252, 229, 60) !important;
}

.color-67-61 {
  background: rgb(252, 232, 60) !important;
}

.color-67-62 {
  background: rgb(252, 235, 60) !important;
}

.color-67-63 {
  background: rgb(252, 239, 60) !important;
}

.color-67-64 {
  background: rgb(252, 242, 60) !important;
}

.color-67-65 {
  background: rgb(252, 245, 60) !important;
}

.color-67-66 {
  background: rgb(252, 249, 60) !important;
}

.color-68-0 {
  background: rgb(140, 0, 28) !important;
}

.color-68-1 {
  background: rgb(252, 32, 60) !important;
}

.color-68-2 {
  background: rgb(252, 35, 60) !important;
}

.color-68-3 {
  background: rgb(252, 39, 60) !important;
}

.color-68-4 {
  background: rgb(252, 42, 60) !important;
}

.color-68-5 {
  background: rgb(252, 45, 60) !important;
}

.color-68-6 {
  background: rgb(252, 48, 60) !important;
}

.color-68-7 {
  background: rgb(252, 52, 60) !important;
}

.color-68-8 {
  background: rgb(252, 55, 60) !important;
}

.color-68-9 {
  background: rgb(252, 58, 60) !important;
}

.color-68-10 {
  background: rgb(252, 62, 60) !important;
}

.color-68-11 {
  background: rgb(252, 65, 60) !important;
}

.color-68-12 {
  background: rgb(252, 68, 60) !important;
}

.color-68-13 {
  background: rgb(252, 71, 60) !important;
}

.color-68-14 {
  background: rgb(252, 75, 60) !important;
}

.color-68-15 {
  background: rgb(252, 78, 60) !important;
}

.color-68-16 {
  background: rgb(252, 81, 60) !important;
}

.color-68-17 {
  background: rgb(252, 85, 60) !important;
}

.color-68-18 {
  background: rgb(252, 88, 60) !important;
}

.color-68-19 {
  background: rgb(252, 91, 60) !important;
}

.color-68-20 {
  background: rgb(252, 94, 60) !important;
}

.color-68-21 {
  background: rgb(252, 98, 60) !important;
}

.color-68-22 {
  background: rgb(252, 101, 60) !important;
}

.color-68-23 {
  background: rgb(252, 104, 60) !important;
}

.color-68-24 {
  background: rgb(252, 108, 60) !important;
}

.color-68-25 {
  background: rgb(252, 111, 60) !important;
}

.color-68-26 {
  background: rgb(252, 114, 60) !important;
}

.color-68-27 {
  background: rgb(252, 117, 60) !important;
}

.color-68-28 {
  background: rgb(252, 121, 60) !important;
}

.color-68-29 {
  background: rgb(252, 124, 60) !important;
}

.color-68-30 {
  background: rgb(252, 127, 60) !important;
}

.color-68-31 {
  background: rgb(252, 131, 60) !important;
}

.color-68-32 {
  background: rgb(252, 134, 60) !important;
}

.color-68-33 {
  background: rgb(252, 137, 60) !important;
}

.color-68-34 {
  background: rgb(252, 140, 60) !important;
}

.color-68-35 {
  background: rgb(252, 144, 60) !important;
}

.color-68-36 {
  background: rgb(252, 147, 60) !important;
}

.color-68-37 {
  background: rgb(252, 150, 60) !important;
}

.color-68-38 {
  background: rgb(252, 153, 60) !important;
}

.color-68-39 {
  background: rgb(252, 157, 60) !important;
}

.color-68-40 {
  background: rgb(252, 160, 60) !important;
}

.color-68-41 {
  background: rgb(252, 163, 60) !important;
}

.color-68-42 {
  background: rgb(252, 167, 60) !important;
}

.color-68-43 {
  background: rgb(252, 170, 60) !important;
}

.color-68-44 {
  background: rgb(252, 173, 60) !important;
}

.color-68-45 {
  background: rgb(252, 176, 60) !important;
}

.color-68-46 {
  background: rgb(252, 180, 60) !important;
}

.color-68-47 {
  background: rgb(252, 183, 60) !important;
}

.color-68-48 {
  background: rgb(252, 186, 60) !important;
}

.color-68-49 {
  background: rgb(252, 190, 60) !important;
}

.color-68-50 {
  background: rgb(252, 193, 60) !important;
}

.color-68-51 {
  background: rgb(252, 196, 60) !important;
}

.color-68-52 {
  background: rgb(252, 199, 60) !important;
}

.color-68-53 {
  background: rgb(252, 203, 60) !important;
}

.color-68-54 {
  background: rgb(252, 206, 60) !important;
}

.color-68-55 {
  background: rgb(252, 209, 60) !important;
}

.color-68-56 {
  background: rgb(252, 213, 60) !important;
}

.color-68-57 {
  background: rgb(252, 216, 60) !important;
}

.color-68-58 {
  background: rgb(252, 219, 60) !important;
}

.color-68-59 {
  background: rgb(252, 222, 60) !important;
}

.color-68-60 {
  background: rgb(252, 226, 60) !important;
}

.color-68-61 {
  background: rgb(252, 229, 60) !important;
}

.color-68-62 {
  background: rgb(252, 232, 60) !important;
}

.color-68-63 {
  background: rgb(252, 236, 60) !important;
}

.color-68-64 {
  background: rgb(252, 239, 60) !important;
}

.color-68-65 {
  background: rgb(252, 242, 60) !important;
}

.color-68-66 {
  background: rgb(252, 245, 60) !important;
}

.color-68-67 {
  background: rgb(252, 249, 60) !important;
}

.color-69-0 {
  background: rgb(140, 0, 28) !important;
}

.color-69-1 {
  background: rgb(252, 32, 60) !important;
}

.color-69-2 {
  background: rgb(252, 35, 60) !important;
}

.color-69-3 {
  background: rgb(252, 38, 60) !important;
}

.color-69-4 {
  background: rgb(252, 42, 60) !important;
}

.color-69-5 {
  background: rgb(252, 45, 60) !important;
}

.color-69-6 {
  background: rgb(252, 48, 60) !important;
}

.color-69-7 {
  background: rgb(252, 51, 60) !important;
}

.color-69-8 {
  background: rgb(252, 55, 60) !important;
}

.color-69-9 {
  background: rgb(252, 58, 60) !important;
}

.color-69-10 {
  background: rgb(252, 61, 60) !important;
}

.color-69-11 {
  background: rgb(252, 64, 60) !important;
}

.color-69-12 {
  background: rgb(252, 68, 60) !important;
}

.color-69-13 {
  background: rgb(252, 71, 60) !important;
}

.color-69-14 {
  background: rgb(252, 74, 60) !important;
}

.color-69-15 {
  background: rgb(252, 77, 60) !important;
}

.color-69-16 {
  background: rgb(252, 81, 60) !important;
}

.color-69-17 {
  background: rgb(252, 84, 60) !important;
}

.color-69-18 {
  background: rgb(252, 87, 60) !important;
}

.color-69-19 {
  background: rgb(252, 90, 60) !important;
}

.color-69-20 {
  background: rgb(252, 93, 60) !important;
}

.color-69-21 {
  background: rgb(252, 97, 60) !important;
}

.color-69-22 {
  background: rgb(252, 100, 60) !important;
}

.color-69-23 {
  background: rgb(252, 103, 60) !important;
}

.color-69-24 {
  background: rgb(252, 106, 60) !important;
}

.color-69-25 {
  background: rgb(252, 110, 60) !important;
}

.color-69-26 {
  background: rgb(252, 113, 60) !important;
}

.color-69-27 {
  background: rgb(252, 116, 60) !important;
}

.color-69-28 {
  background: rgb(252, 119, 60) !important;
}

.color-69-29 {
  background: rgb(252, 123, 60) !important;
}

.color-69-30 {
  background: rgb(252, 126, 60) !important;
}

.color-69-31 {
  background: rgb(252, 129, 60) !important;
}

.color-69-32 {
  background: rgb(252, 132, 60) !important;
}

.color-69-33 {
  background: rgb(252, 136, 60) !important;
}

.color-69-34 {
  background: rgb(252, 139, 60) !important;
}

.color-69-35 {
  background: rgb(252, 142, 60) !important;
}

.color-69-36 {
  background: rgb(252, 145, 60) !important;
}

.color-69-37 {
  background: rgb(252, 148, 60) !important;
}

.color-69-38 {
  background: rgb(252, 152, 60) !important;
}

.color-69-39 {
  background: rgb(252, 155, 60) !important;
}

.color-69-40 {
  background: rgb(252, 158, 60) !important;
}

.color-69-41 {
  background: rgb(252, 161, 60) !important;
}

.color-69-42 {
  background: rgb(252, 165, 60) !important;
}

.color-69-43 {
  background: rgb(252, 168, 60) !important;
}

.color-69-44 {
  background: rgb(252, 171, 60) !important;
}

.color-69-45 {
  background: rgb(252, 174, 60) !important;
}

.color-69-46 {
  background: rgb(252, 178, 60) !important;
}

.color-69-47 {
  background: rgb(252, 181, 60) !important;
}

.color-69-48 {
  background: rgb(252, 184, 60) !important;
}

.color-69-49 {
  background: rgb(252, 187, 60) !important;
}

.color-69-50 {
  background: rgb(252, 191, 60) !important;
}

.color-69-51 {
  background: rgb(252, 194, 60) !important;
}

.color-69-52 {
  background: rgb(252, 197, 60) !important;
}

.color-69-53 {
  background: rgb(252, 200, 60) !important;
}

.color-69-54 {
  background: rgb(252, 203, 60) !important;
}

.color-69-55 {
  background: rgb(252, 207, 60) !important;
}

.color-69-56 {
  background: rgb(252, 210, 60) !important;
}

.color-69-57 {
  background: rgb(252, 213, 60) !important;
}

.color-69-58 {
  background: rgb(252, 216, 60) !important;
}

.color-69-59 {
  background: rgb(252, 220, 60) !important;
}

.color-69-60 {
  background: rgb(252, 223, 60) !important;
}

.color-69-61 {
  background: rgb(252, 226, 60) !important;
}

.color-69-62 {
  background: rgb(252, 229, 60) !important;
}

.color-69-63 {
  background: rgb(252, 233, 60) !important;
}

.color-69-64 {
  background: rgb(252, 236, 60) !important;
}

.color-69-65 {
  background: rgb(252, 239, 60) !important;
}

.color-69-66 {
  background: rgb(252, 242, 60) !important;
}

.color-69-67 {
  background: rgb(252, 246, 60) !important;
}

.color-69-68 {
  background: rgb(252, 249, 60) !important;
}

.color-70-0 {
  background: rgb(140, 0, 28) !important;
}

.color-70-1 {
  background: rgb(252, 32, 60) !important;
}

.color-70-2 {
  background: rgb(252, 35, 60) !important;
}

.color-70-3 {
  background: rgb(252, 38, 60) !important;
}

.color-70-4 {
  background: rgb(252, 42, 60) !important;
}

.color-70-5 {
  background: rgb(252, 45, 60) !important;
}

.color-70-6 {
  background: rgb(252, 48, 60) !important;
}

.color-70-7 {
  background: rgb(252, 51, 60) !important;
}

.color-70-8 {
  background: rgb(252, 54, 60) !important;
}

.color-70-9 {
  background: rgb(252, 58, 60) !important;
}

.color-70-10 {
  background: rgb(252, 61, 60) !important;
}

.color-70-11 {
  background: rgb(252, 64, 60) !important;
}

.color-70-12 {
  background: rgb(252, 67, 60) !important;
}

.color-70-13 {
  background: rgb(252, 70, 60) !important;
}

.color-70-14 {
  background: rgb(252, 73, 60) !important;
}

.color-70-15 {
  background: rgb(252, 77, 60) !important;
}

.color-70-16 {
  background: rgb(252, 80, 60) !important;
}

.color-70-17 {
  background: rgb(252, 83, 60) !important;
}

.color-70-18 {
  background: rgb(252, 86, 60) !important;
}

.color-70-19 {
  background: rgb(252, 89, 60) !important;
}

.color-70-20 {
  background: rgb(252, 93, 60) !important;
}

.color-70-21 {
  background: rgb(252, 96, 60) !important;
}

.color-70-22 {
  background: rgb(252, 99, 60) !important;
}

.color-70-23 {
  background: rgb(252, 102, 60) !important;
}

.color-70-24 {
  background: rgb(252, 105, 60) !important;
}

.color-70-25 {
  background: rgb(252, 109, 60) !important;
}

.color-70-26 {
  background: rgb(252, 112, 60) !important;
}

.color-70-27 {
  background: rgb(252, 115, 60) !important;
}

.color-70-28 {
  background: rgb(252, 118, 60) !important;
}

.color-70-29 {
  background: rgb(252, 121, 60) !important;
}

.color-70-30 {
  background: rgb(252, 124, 60) !important;
}

.color-70-31 {
  background: rgb(252, 128, 60) !important;
}

.color-70-32 {
  background: rgb(252, 131, 60) !important;
}

.color-70-33 {
  background: rgb(252, 134, 60) !important;
}

.color-70-34 {
  background: rgb(252, 137, 60) !important;
}

.color-70-35 {
  background: rgb(252, 140, 60) !important;
}

.color-70-36 {
  background: rgb(252, 144, 60) !important;
}

.color-70-37 {
  background: rgb(252, 147, 60) !important;
}

.color-70-38 {
  background: rgb(252, 150, 60) !important;
}

.color-70-39 {
  background: rgb(252, 153, 60) !important;
}

.color-70-40 {
  background: rgb(252, 156, 60) !important;
}

.color-70-41 {
  background: rgb(252, 160, 60) !important;
}

.color-70-42 {
  background: rgb(252, 163, 60) !important;
}

.color-70-43 {
  background: rgb(252, 166, 60) !important;
}

.color-70-44 {
  background: rgb(252, 169, 60) !important;
}

.color-70-45 {
  background: rgb(252, 172, 60) !important;
}

.color-70-46 {
  background: rgb(252, 175, 60) !important;
}

.color-70-47 {
  background: rgb(252, 179, 60) !important;
}

.color-70-48 {
  background: rgb(252, 182, 60) !important;
}

.color-70-49 {
  background: rgb(252, 185, 60) !important;
}

.color-70-50 {
  background: rgb(252, 188, 60) !important;
}

.color-70-51 {
  background: rgb(252, 191, 60) !important;
}

.color-70-52 {
  background: rgb(252, 195, 60) !important;
}

.color-70-53 {
  background: rgb(252, 198, 60) !important;
}

.color-70-54 {
  background: rgb(252, 201, 60) !important;
}

.color-70-55 {
  background: rgb(252, 204, 60) !important;
}

.color-70-56 {
  background: rgb(252, 207, 60) !important;
}

.color-70-57 {
  background: rgb(252, 211, 60) !important;
}

.color-70-58 {
  background: rgb(252, 214, 60) !important;
}

.color-70-59 {
  background: rgb(252, 217, 60) !important;
}

.color-70-60 {
  background: rgb(252, 220, 60) !important;
}

.color-70-61 {
  background: rgb(252, 223, 60) !important;
}

.color-70-62 {
  background: rgb(252, 226, 60) !important;
}

.color-70-63 {
  background: rgb(252, 230, 60) !important;
}

.color-70-64 {
  background: rgb(252, 233, 60) !important;
}

.color-70-65 {
  background: rgb(252, 236, 60) !important;
}

.color-70-66 {
  background: rgb(252, 239, 60) !important;
}

.color-70-67 {
  background: rgb(252, 242, 60) !important;
}

.color-70-68 {
  background: rgb(252, 246, 60) !important;
}

.color-70-69 {
  background: rgb(252, 249, 60) !important;
}

.color-71-0 {
  background: rgb(140, 0, 28) !important;
}

.color-71-1 {
  background: rgb(252, 32, 60) !important;
}

.color-71-2 {
  background: rgb(252, 35, 60) !important;
}

.color-71-3 {
  background: rgb(252, 38, 60) !important;
}

.color-71-4 {
  background: rgb(252, 41, 60) !important;
}

.color-71-5 {
  background: rgb(252, 45, 60) !important;
}

.color-71-6 {
  background: rgb(252, 48, 60) !important;
}

.color-71-7 {
  background: rgb(252, 51, 60) !important;
}

.color-71-8 {
  background: rgb(252, 54, 60) !important;
}

.color-71-9 {
  background: rgb(252, 57, 60) !important;
}

.color-71-10 {
  background: rgb(252, 60, 60) !important;
}

.color-71-11 {
  background: rgb(252, 63, 60) !important;
}

.color-71-12 {
  background: rgb(252, 67, 60) !important;
}

.color-71-13 {
  background: rgb(252, 70, 60) !important;
}

.color-71-14 {
  background: rgb(252, 73, 60) !important;
}

.color-71-15 {
  background: rgb(252, 76, 60) !important;
}

.color-71-16 {
  background: rgb(252, 79, 60) !important;
}

.color-71-17 {
  background: rgb(252, 82, 60) !important;
}

.color-71-18 {
  background: rgb(252, 85, 60) !important;
}

.color-71-19 {
  background: rgb(252, 89, 60) !important;
}

.color-71-20 {
  background: rgb(252, 92, 60) !important;
}

.color-71-21 {
  background: rgb(252, 95, 60) !important;
}

.color-71-22 {
  background: rgb(252, 98, 60) !important;
}

.color-71-23 {
  background: rgb(252, 101, 60) !important;
}

.color-71-24 {
  background: rgb(252, 104, 60) !important;
}

.color-71-25 {
  background: rgb(252, 107, 60) !important;
}

.color-71-26 {
  background: rgb(252, 111, 60) !important;
}

.color-71-27 {
  background: rgb(252, 114, 60) !important;
}

.color-71-28 {
  background: rgb(252, 117, 60) !important;
}

.color-71-29 {
  background: rgb(252, 120, 60) !important;
}

.color-71-30 {
  background: rgb(252, 123, 60) !important;
}

.color-71-31 {
  background: rgb(252, 126, 60) !important;
}

.color-71-32 {
  background: rgb(252, 129, 60) !important;
}

.color-71-33 {
  background: rgb(252, 133, 60) !important;
}

.color-71-34 {
  background: rgb(252, 136, 60) !important;
}

.color-71-35 {
  background: rgb(252, 139, 60) !important;
}

.color-71-36 {
  background: rgb(252, 142, 60) !important;
}

.color-71-37 {
  background: rgb(252, 145, 60) !important;
}

.color-71-38 {
  background: rgb(252, 148, 60) !important;
}

.color-71-39 {
  background: rgb(252, 151, 60) !important;
}

.color-71-40 {
  background: rgb(252, 155, 60) !important;
}

.color-71-41 {
  background: rgb(252, 158, 60) !important;
}

.color-71-42 {
  background: rgb(252, 161, 60) !important;
}

.color-71-43 {
  background: rgb(252, 164, 60) !important;
}

.color-71-44 {
  background: rgb(252, 167, 60) !important;
}

.color-71-45 {
  background: rgb(252, 170, 60) !important;
}

.color-71-46 {
  background: rgb(252, 173, 60) !important;
}

.color-71-47 {
  background: rgb(252, 177, 60) !important;
}

.color-71-48 {
  background: rgb(252, 180, 60) !important;
}

.color-71-49 {
  background: rgb(252, 183, 60) !important;
}

.color-71-50 {
  background: rgb(252, 186, 60) !important;
}

.color-71-51 {
  background: rgb(252, 189, 60) !important;
}

.color-71-52 {
  background: rgb(252, 192, 60) !important;
}

.color-71-53 {
  background: rgb(252, 195, 60) !important;
}

.color-71-54 {
  background: rgb(252, 199, 60) !important;
}

.color-71-55 {
  background: rgb(252, 202, 60) !important;
}

.color-71-56 {
  background: rgb(252, 205, 60) !important;
}

.color-71-57 {
  background: rgb(252, 208, 60) !important;
}

.color-71-58 {
  background: rgb(252, 211, 60) !important;
}

.color-71-59 {
  background: rgb(252, 214, 60) !important;
}

.color-71-60 {
  background: rgb(252, 217, 60) !important;
}

.color-71-61 {
  background: rgb(252, 221, 60) !important;
}

.color-71-62 {
  background: rgb(252, 224, 60) !important;
}

.color-71-63 {
  background: rgb(252, 227, 60) !important;
}

.color-71-64 {
  background: rgb(252, 230, 60) !important;
}

.color-71-65 {
  background: rgb(252, 233, 60) !important;
}

.color-71-66 {
  background: rgb(252, 236, 60) !important;
}

.color-71-67 {
  background: rgb(252, 239, 60) !important;
}

.color-71-68 {
  background: rgb(252, 243, 60) !important;
}

.color-71-69 {
  background: rgb(252, 246, 60) !important;
}

.color-71-70 {
  background: rgb(252, 249, 60) !important;
}

.color-72-0 {
  background: rgb(140, 0, 28) !important;
}

.color-72-1 {
  background: rgb(252, 32, 60) !important;
}

.color-72-2 {
  background: rgb(252, 35, 60) !important;
}

.color-72-3 {
  background: rgb(252, 38, 60) !important;
}

.color-72-4 {
  background: rgb(252, 41, 60) !important;
}

.color-72-5 {
  background: rgb(252, 44, 60) !important;
}

.color-72-6 {
  background: rgb(252, 47, 60) !important;
}

.color-72-7 {
  background: rgb(252, 51, 60) !important;
}

.color-72-8 {
  background: rgb(252, 54, 60) !important;
}

.color-72-9 {
  background: rgb(252, 57, 60) !important;
}

.color-72-10 {
  background: rgb(252, 60, 60) !important;
}

.color-72-11 {
  background: rgb(252, 63, 60) !important;
}

.color-72-12 {
  background: rgb(252, 66, 60) !important;
}

.color-72-13 {
  background: rgb(252, 69, 60) !important;
}

.color-72-14 {
  background: rgb(252, 72, 60) !important;
}

.color-72-15 {
  background: rgb(252, 75, 60) !important;
}

.color-72-16 {
  background: rgb(252, 78, 60) !important;
}

.color-72-17 {
  background: rgb(252, 82, 60) !important;
}

.color-72-18 {
  background: rgb(252, 85, 60) !important;
}

.color-72-19 {
  background: rgb(252, 88, 60) !important;
}

.color-72-20 {
  background: rgb(252, 91, 60) !important;
}

.color-72-21 {
  background: rgb(252, 94, 60) !important;
}

.color-72-22 {
  background: rgb(252, 97, 60) !important;
}

.color-72-23 {
  background: rgb(252, 100, 60) !important;
}

.color-72-24 {
  background: rgb(252, 103, 60) !important;
}

.color-72-25 {
  background: rgb(252, 106, 60) !important;
}

.color-72-26 {
  background: rgb(252, 109, 60) !important;
}

.color-72-27 {
  background: rgb(252, 113, 60) !important;
}

.color-72-28 {
  background: rgb(252, 116, 60) !important;
}

.color-72-29 {
  background: rgb(252, 119, 60) !important;
}

.color-72-30 {
  background: rgb(252, 122, 60) !important;
}

.color-72-31 {
  background: rgb(252, 125, 60) !important;
}

.color-72-32 {
  background: rgb(252, 128, 60) !important;
}

.color-72-33 {
  background: rgb(252, 131, 60) !important;
}

.color-72-34 {
  background: rgb(252, 134, 60) !important;
}

.color-72-35 {
  background: rgb(252, 137, 60) !important;
}

.color-72-36 {
  background: rgb(252, 140, 60) !important;
}

.color-72-37 {
  background: rgb(252, 144, 60) !important;
}

.color-72-38 {
  background: rgb(252, 147, 60) !important;
}

.color-72-39 {
  background: rgb(252, 150, 60) !important;
}

.color-72-40 {
  background: rgb(252, 153, 60) !important;
}

.color-72-41 {
  background: rgb(252, 156, 60) !important;
}

.color-72-42 {
  background: rgb(252, 159, 60) !important;
}

.color-72-43 {
  background: rgb(252, 162, 60) !important;
}

.color-72-44 {
  background: rgb(252, 165, 60) !important;
}

.color-72-45 {
  background: rgb(252, 168, 60) !important;
}

.color-72-46 {
  background: rgb(252, 171, 60) !important;
}

.color-72-47 {
  background: rgb(252, 175, 60) !important;
}

.color-72-48 {
  background: rgb(252, 178, 60) !important;
}

.color-72-49 {
  background: rgb(252, 181, 60) !important;
}

.color-72-50 {
  background: rgb(252, 184, 60) !important;
}

.color-72-51 {
  background: rgb(252, 187, 60) !important;
}

.color-72-52 {
  background: rgb(252, 190, 60) !important;
}

.color-72-53 {
  background: rgb(252, 193, 60) !important;
}

.color-72-54 {
  background: rgb(252, 196, 60) !important;
}

.color-72-55 {
  background: rgb(252, 199, 60) !important;
}

.color-72-56 {
  background: rgb(252, 202, 60) !important;
}

.color-72-57 {
  background: rgb(252, 206, 60) !important;
}

.color-72-58 {
  background: rgb(252, 209, 60) !important;
}

.color-72-59 {
  background: rgb(252, 212, 60) !important;
}

.color-72-60 {
  background: rgb(252, 215, 60) !important;
}

.color-72-61 {
  background: rgb(252, 218, 60) !important;
}

.color-72-62 {
  background: rgb(252, 221, 60) !important;
}

.color-72-63 {
  background: rgb(252, 224, 60) !important;
}

.color-72-64 {
  background: rgb(252, 227, 60) !important;
}

.color-72-65 {
  background: rgb(252, 230, 60) !important;
}

.color-72-66 {
  background: rgb(252, 233, 60) !important;
}

.color-72-67 {
  background: rgb(252, 237, 60) !important;
}

.color-72-68 {
  background: rgb(252, 240, 60) !important;
}

.color-72-69 {
  background: rgb(252, 243, 60) !important;
}

.color-72-70 {
  background: rgb(252, 246, 60) !important;
}

.color-72-71 {
  background: rgb(252, 249, 60) !important;
}

.color-73-0 {
  background: rgb(140, 0, 28) !important;
}

.color-73-1 {
  background: rgb(252, 32, 60) !important;
}

.color-73-2 {
  background: rgb(252, 35, 60) !important;
}

.color-73-3 {
  background: rgb(252, 38, 60) !important;
}

.color-73-4 {
  background: rgb(252, 41, 60) !important;
}

.color-73-5 {
  background: rgb(252, 44, 60) !important;
}

.color-73-6 {
  background: rgb(252, 47, 60) !important;
}

.color-73-7 {
  background: rgb(252, 50, 60) !important;
}

.color-73-8 {
  background: rgb(252, 53, 60) !important;
}

.color-73-9 {
  background: rgb(252, 56, 60) !important;
}

.color-73-10 {
  background: rgb(252, 60, 60) !important;
}

.color-73-11 {
  background: rgb(252, 63, 60) !important;
}

.color-73-12 {
  background: rgb(252, 66, 60) !important;
}

.color-73-13 {
  background: rgb(252, 69, 60) !important;
}

.color-73-14 {
  background: rgb(252, 72, 60) !important;
}

.color-73-15 {
  background: rgb(252, 75, 60) !important;
}

.color-73-16 {
  background: rgb(252, 78, 60) !important;
}

.color-73-17 {
  background: rgb(252, 81, 60) !important;
}

.color-73-18 {
  background: rgb(252, 84, 60) !important;
}

.color-73-19 {
  background: rgb(252, 87, 60) !important;
}

.color-73-20 {
  background: rgb(252, 90, 60) !important;
}

.color-73-21 {
  background: rgb(252, 93, 60) !important;
}

.color-73-22 {
  background: rgb(252, 96, 60) !important;
}

.color-73-23 {
  background: rgb(252, 99, 60) !important;
}

.color-73-24 {
  background: rgb(252, 102, 60) !important;
}

.color-73-25 {
  background: rgb(252, 105, 60) !important;
}

.color-73-26 {
  background: rgb(252, 108, 60) !important;
}

.color-73-27 {
  background: rgb(252, 111, 60) !important;
}

.color-73-28 {
  background: rgb(252, 115, 60) !important;
}

.color-73-29 {
  background: rgb(252, 118, 60) !important;
}

.color-73-30 {
  background: rgb(252, 121, 60) !important;
}

.color-73-31 {
  background: rgb(252, 124, 60) !important;
}

.color-73-32 {
  background: rgb(252, 127, 60) !important;
}

.color-73-33 {
  background: rgb(252, 130, 60) !important;
}

.color-73-34 {
  background: rgb(252, 133, 60) !important;
}

.color-73-35 {
  background: rgb(252, 136, 60) !important;
}

.color-73-36 {
  background: rgb(252, 139, 60) !important;
}

.color-73-37 {
  background: rgb(252, 142, 60) !important;
}

.color-73-38 {
  background: rgb(252, 145, 60) !important;
}

.color-73-39 {
  background: rgb(252, 148, 60) !important;
}

.color-73-40 {
  background: rgb(252, 151, 60) !important;
}

.color-73-41 {
  background: rgb(252, 154, 60) !important;
}

.color-73-42 {
  background: rgb(252, 157, 60) !important;
}

.color-73-43 {
  background: rgb(252, 160, 60) !important;
}

.color-73-44 {
  background: rgb(252, 163, 60) !important;
}

.color-73-45 {
  background: rgb(252, 166, 60) !important;
}

.color-73-46 {
  background: rgb(252, 170, 60) !important;
}

.color-73-47 {
  background: rgb(252, 173, 60) !important;
}

.color-73-48 {
  background: rgb(252, 176, 60) !important;
}

.color-73-49 {
  background: rgb(252, 179, 60) !important;
}

.color-73-50 {
  background: rgb(252, 182, 60) !important;
}

.color-73-51 {
  background: rgb(252, 185, 60) !important;
}

.color-73-52 {
  background: rgb(252, 188, 60) !important;
}

.color-73-53 {
  background: rgb(252, 191, 60) !important;
}

.color-73-54 {
  background: rgb(252, 194, 60) !important;
}

.color-73-55 {
  background: rgb(252, 197, 60) !important;
}

.color-73-56 {
  background: rgb(252, 200, 60) !important;
}

.color-73-57 {
  background: rgb(252, 203, 60) !important;
}

.color-73-58 {
  background: rgb(252, 206, 60) !important;
}

.color-73-59 {
  background: rgb(252, 209, 60) !important;
}

.color-73-60 {
  background: rgb(252, 212, 60) !important;
}

.color-73-61 {
  background: rgb(252, 215, 60) !important;
}

.color-73-62 {
  background: rgb(252, 218, 60) !important;
}

.color-73-63 {
  background: rgb(252, 221, 60) !important;
}

.color-73-64 {
  background: rgb(252, 225, 60) !important;
}

.color-73-65 {
  background: rgb(252, 228, 60) !important;
}

.color-73-66 {
  background: rgb(252, 231, 60) !important;
}

.color-73-67 {
  background: rgb(252, 234, 60) !important;
}

.color-73-68 {
  background: rgb(252, 237, 60) !important;
}

.color-73-69 {
  background: rgb(252, 240, 60) !important;
}

.color-73-70 {
  background: rgb(252, 243, 60) !important;
}

.color-73-71 {
  background: rgb(252, 246, 60) !important;
}

.color-73-72 {
  background: rgb(252, 249, 60) !important;
}

.color-74-0 {
  background: rgb(140, 0, 28) !important;
}

.color-74-1 {
  background: rgb(252, 32, 60) !important;
}

.color-74-2 {
  background: rgb(252, 35, 60) !important;
}

.color-74-3 {
  background: rgb(252, 38, 60) !important;
}

.color-74-4 {
  background: rgb(252, 41, 60) !important;
}

.color-74-5 {
  background: rgb(252, 44, 60) !important;
}

.color-74-6 {
  background: rgb(252, 47, 60) !important;
}

.color-74-7 {
  background: rgb(252, 50, 60) !important;
}

.color-74-8 {
  background: rgb(252, 53, 60) !important;
}

.color-74-9 {
  background: rgb(252, 56, 60) !important;
}

.color-74-10 {
  background: rgb(252, 59, 60) !important;
}

.color-74-11 {
  background: rgb(252, 62, 60) !important;
}

.color-74-12 {
  background: rgb(252, 65, 60) !important;
}

.color-74-13 {
  background: rgb(252, 68, 60) !important;
}

.color-74-14 {
  background: rgb(252, 71, 60) !important;
}

.color-74-15 {
  background: rgb(252, 74, 60) !important;
}

.color-74-16 {
  background: rgb(252, 77, 60) !important;
}

.color-74-17 {
  background: rgb(252, 80, 60) !important;
}

.color-74-18 {
  background: rgb(252, 83, 60) !important;
}

.color-74-19 {
  background: rgb(252, 86, 60) !important;
}

.color-74-20 {
  background: rgb(252, 89, 60) !important;
}

.color-74-21 {
  background: rgb(252, 92, 60) !important;
}

.color-74-22 {
  background: rgb(252, 95, 60) !important;
}

.color-74-23 {
  background: rgb(252, 98, 60) !important;
}

.color-74-24 {
  background: rgb(252, 101, 60) !important;
}

.color-74-25 {
  background: rgb(252, 104, 60) !important;
}

.color-74-26 {
  background: rgb(252, 107, 60) !important;
}

.color-74-27 {
  background: rgb(252, 110, 60) !important;
}

.color-74-28 {
  background: rgb(252, 113, 60) !important;
}

.color-74-29 {
  background: rgb(252, 116, 60) !important;
}

.color-74-30 {
  background: rgb(252, 119, 60) !important;
}

.color-74-31 {
  background: rgb(252, 122, 60) !important;
}

.color-74-32 {
  background: rgb(252, 125, 60) !important;
}

.color-74-33 {
  background: rgb(252, 128, 60) !important;
}

.color-74-34 {
  background: rgb(252, 131, 60) !important;
}

.color-74-35 {
  background: rgb(252, 134, 60) !important;
}

.color-74-36 {
  background: rgb(252, 137, 60) !important;
}

.color-74-37 {
  background: rgb(252, 140, 60) !important;
}

.color-74-38 {
  background: rgb(252, 144, 60) !important;
}

.color-74-39 {
  background: rgb(252, 147, 60) !important;
}

.color-74-40 {
  background: rgb(252, 150, 60) !important;
}

.color-74-41 {
  background: rgb(252, 153, 60) !important;
}

.color-74-42 {
  background: rgb(252, 156, 60) !important;
}

.color-74-43 {
  background: rgb(252, 159, 60) !important;
}

.color-74-44 {
  background: rgb(252, 162, 60) !important;
}

.color-74-45 {
  background: rgb(252, 165, 60) !important;
}

.color-74-46 {
  background: rgb(252, 168, 60) !important;
}

.color-74-47 {
  background: rgb(252, 171, 60) !important;
}

.color-74-48 {
  background: rgb(252, 174, 60) !important;
}

.color-74-49 {
  background: rgb(252, 177, 60) !important;
}

.color-74-50 {
  background: rgb(252, 180, 60) !important;
}

.color-74-51 {
  background: rgb(252, 183, 60) !important;
}

.color-74-52 {
  background: rgb(252, 186, 60) !important;
}

.color-74-53 {
  background: rgb(252, 189, 60) !important;
}

.color-74-54 {
  background: rgb(252, 192, 60) !important;
}

.color-74-55 {
  background: rgb(252, 195, 60) !important;
}

.color-74-56 {
  background: rgb(252, 198, 60) !important;
}

.color-74-57 {
  background: rgb(252, 201, 60) !important;
}

.color-74-58 {
  background: rgb(252, 204, 60) !important;
}

.color-74-59 {
  background: rgb(252, 207, 60) !important;
}

.color-74-60 {
  background: rgb(252, 210, 60) !important;
}

.color-74-61 {
  background: rgb(252, 213, 60) !important;
}

.color-74-62 {
  background: rgb(252, 216, 60) !important;
}

.color-74-63 {
  background: rgb(252, 219, 60) !important;
}

.color-74-64 {
  background: rgb(252, 222, 60) !important;
}

.color-74-65 {
  background: rgb(252, 225, 60) !important;
}

.color-74-66 {
  background: rgb(252, 228, 60) !important;
}

.color-74-67 {
  background: rgb(252, 231, 60) !important;
}

.color-74-68 {
  background: rgb(252, 234, 60) !important;
}

.color-74-69 {
  background: rgb(252, 237, 60) !important;
}

.color-74-70 {
  background: rgb(252, 240, 60) !important;
}

.color-74-71 {
  background: rgb(252, 243, 60) !important;
}

.color-74-72 {
  background: rgb(252, 246, 60) !important;
}

.color-74-73 {
  background: rgb(252, 249, 60) !important;
}

.color-75-0 {
  background: rgb(140, 0, 28) !important;
}

.color-75-1 {
  background: rgb(252, 32, 60) !important;
}

.color-75-2 {
  background: rgb(252, 35, 60) !important;
}

.color-75-3 {
  background: rgb(252, 38, 60) !important;
}

.color-75-4 {
  background: rgb(252, 41, 60) !important;
}

.color-75-5 {
  background: rgb(252, 44, 60) !important;
}

.color-75-6 {
  background: rgb(252, 47, 60) !important;
}

.color-75-7 {
  background: rgb(252, 50, 60) !important;
}

.color-75-8 {
  background: rgb(252, 53, 60) !important;
}

.color-75-9 {
  background: rgb(252, 56, 60) !important;
}

.color-75-10 {
  background: rgb(252, 59, 60) !important;
}

.color-75-11 {
  background: rgb(252, 62, 60) !important;
}

.color-75-12 {
  background: rgb(252, 65, 60) !important;
}

.color-75-13 {
  background: rgb(252, 68, 60) !important;
}

.color-75-14 {
  background: rgb(252, 71, 60) !important;
}

.color-75-15 {
  background: rgb(252, 74, 60) !important;
}

.color-75-16 {
  background: rgb(252, 77, 60) !important;
}

.color-75-17 {
  background: rgb(252, 80, 60) !important;
}

.color-75-18 {
  background: rgb(252, 83, 60) !important;
}

.color-75-19 {
  background: rgb(252, 86, 60) !important;
}

.color-75-20 {
  background: rgb(252, 88, 60) !important;
}

.color-75-21 {
  background: rgb(252, 91, 60) !important;
}

.color-75-22 {
  background: rgb(252, 94, 60) !important;
}

.color-75-23 {
  background: rgb(252, 97, 60) !important;
}

.color-75-24 {
  background: rgb(252, 100, 60) !important;
}

.color-75-25 {
  background: rgb(252, 103, 60) !important;
}

.color-75-26 {
  background: rgb(252, 106, 60) !important;
}

.color-75-27 {
  background: rgb(252, 109, 60) !important;
}

.color-75-28 {
  background: rgb(252, 112, 60) !important;
}

.color-75-29 {
  background: rgb(252, 115, 60) !important;
}

.color-75-30 {
  background: rgb(252, 118, 60) !important;
}

.color-75-31 {
  background: rgb(252, 121, 60) !important;
}

.color-75-32 {
  background: rgb(252, 124, 60) !important;
}

.color-75-33 {
  background: rgb(252, 127, 60) !important;
}

.color-75-34 {
  background: rgb(252, 130, 60) !important;
}

.color-75-35 {
  background: rgb(252, 133, 60) !important;
}

.color-75-36 {
  background: rgb(252, 136, 60) !important;
}

.color-75-37 {
  background: rgb(252, 139, 60) !important;
}

.color-75-38 {
  background: rgb(252, 142, 60) !important;
}

.color-75-39 {
  background: rgb(252, 145, 60) !important;
}

.color-75-40 {
  background: rgb(252, 148, 60) !important;
}

.color-75-41 {
  background: rgb(252, 151, 60) !important;
}

.color-75-42 {
  background: rgb(252, 154, 60) !important;
}

.color-75-43 {
  background: rgb(252, 157, 60) !important;
}

.color-75-44 {
  background: rgb(252, 160, 60) !important;
}

.color-75-45 {
  background: rgb(252, 163, 60) !important;
}

.color-75-46 {
  background: rgb(252, 166, 60) !important;
}

.color-75-47 {
  background: rgb(252, 169, 60) !important;
}

.color-75-48 {
  background: rgb(252, 172, 60) !important;
}

.color-75-49 {
  background: rgb(252, 175, 60) !important;
}

.color-75-50 {
  background: rgb(252, 178, 60) !important;
}

.color-75-51 {
  background: rgb(252, 181, 60) !important;
}

.color-75-52 {
  background: rgb(252, 184, 60) !important;
}

.color-75-53 {
  background: rgb(252, 187, 60) !important;
}

.color-75-54 {
  background: rgb(252, 190, 60) !important;
}

.color-75-55 {
  background: rgb(252, 193, 60) !important;
}

.color-75-56 {
  background: rgb(252, 196, 60) !important;
}

.color-75-57 {
  background: rgb(252, 198, 60) !important;
}

.color-75-58 {
  background: rgb(252, 201, 60) !important;
}

.color-75-59 {
  background: rgb(252, 204, 60) !important;
}

.color-75-60 {
  background: rgb(252, 207, 60) !important;
}

.color-75-61 {
  background: rgb(252, 210, 60) !important;
}

.color-75-62 {
  background: rgb(252, 213, 60) !important;
}

.color-75-63 {
  background: rgb(252, 216, 60) !important;
}

.color-75-64 {
  background: rgb(252, 219, 60) !important;
}

.color-75-65 {
  background: rgb(252, 222, 60) !important;
}

.color-75-66 {
  background: rgb(252, 225, 60) !important;
}

.color-75-67 {
  background: rgb(252, 228, 60) !important;
}

.color-75-68 {
  background: rgb(252, 231, 60) !important;
}

.color-75-69 {
  background: rgb(252, 234, 60) !important;
}

.color-75-70 {
  background: rgb(252, 237, 60) !important;
}

.color-75-71 {
  background: rgb(252, 240, 60) !important;
}

.color-75-72 {
  background: rgb(252, 243, 60) !important;
}

.color-75-73 {
  background: rgb(252, 246, 60) !important;
}

.color-75-74 {
  background: rgb(252, 249, 60) !important;
}

.color-76-0 {
  background: rgb(140, 0, 28) !important;
}

.color-76-1 {
  background: rgb(252, 32, 60) !important;
}

.color-76-2 {
  background: rgb(252, 35, 60) !important;
}

.color-76-3 {
  background: rgb(252, 38, 60) !important;
}

.color-76-4 {
  background: rgb(252, 41, 60) !important;
}

.color-76-5 {
  background: rgb(252, 44, 60) !important;
}

.color-76-6 {
  background: rgb(252, 47, 60) !important;
}

.color-76-7 {
  background: rgb(252, 50, 60) !important;
}

.color-76-8 {
  background: rgb(252, 53, 60) !important;
}

.color-76-9 {
  background: rgb(252, 55, 60) !important;
}

.color-76-10 {
  background: rgb(252, 58, 60) !important;
}

.color-76-11 {
  background: rgb(252, 61, 60) !important;
}

.color-76-12 {
  background: rgb(252, 64, 60) !important;
}

.color-76-13 {
  background: rgb(252, 67, 60) !important;
}

.color-76-14 {
  background: rgb(252, 70, 60) !important;
}

.color-76-15 {
  background: rgb(252, 73, 60) !important;
}

.color-76-16 {
  background: rgb(252, 76, 60) !important;
}

.color-76-17 {
  background: rgb(252, 79, 60) !important;
}

.color-76-18 {
  background: rgb(252, 82, 60) !important;
}

.color-76-19 {
  background: rgb(252, 85, 60) !important;
}

.color-76-20 {
  background: rgb(252, 88, 60) !important;
}

.color-76-21 {
  background: rgb(252, 91, 60) !important;
}

.color-76-22 {
  background: rgb(252, 94, 60) !important;
}

.color-76-23 {
  background: rgb(252, 97, 60) !important;
}

.color-76-24 {
  background: rgb(252, 99, 60) !important;
}

.color-76-25 {
  background: rgb(252, 102, 60) !important;
}

.color-76-26 {
  background: rgb(252, 105, 60) !important;
}

.color-76-27 {
  background: rgb(252, 108, 60) !important;
}

.color-76-28 {
  background: rgb(252, 111, 60) !important;
}

.color-76-29 {
  background: rgb(252, 114, 60) !important;
}

.color-76-30 {
  background: rgb(252, 117, 60) !important;
}

.color-76-31 {
  background: rgb(252, 120, 60) !important;
}

.color-76-32 {
  background: rgb(252, 123, 60) !important;
}

.color-76-33 {
  background: rgb(252, 126, 60) !important;
}

.color-76-34 {
  background: rgb(252, 129, 60) !important;
}

.color-76-35 {
  background: rgb(252, 132, 60) !important;
}

.color-76-36 {
  background: rgb(252, 135, 60) !important;
}

.color-76-37 {
  background: rgb(252, 138, 60) !important;
}

.color-76-38 {
  background: rgb(252, 141, 60) !important;
}

.color-76-39 {
  background: rgb(252, 143, 60) !important;
}

.color-76-40 {
  background: rgb(252, 146, 60) !important;
}

.color-76-41 {
  background: rgb(252, 149, 60) !important;
}

.color-76-42 {
  background: rgb(252, 152, 60) !important;
}

.color-76-43 {
  background: rgb(252, 155, 60) !important;
}

.color-76-44 {
  background: rgb(252, 158, 60) !important;
}

.color-76-45 {
  background: rgb(252, 161, 60) !important;
}

.color-76-46 {
  background: rgb(252, 164, 60) !important;
}

.color-76-47 {
  background: rgb(252, 167, 60) !important;
}

.color-76-48 {
  background: rgb(252, 170, 60) !important;
}

.color-76-49 {
  background: rgb(252, 173, 60) !important;
}

.color-76-50 {
  background: rgb(252, 176, 60) !important;
}

.color-76-51 {
  background: rgb(252, 179, 60) !important;
}

.color-76-52 {
  background: rgb(252, 182, 60) !important;
}

.color-76-53 {
  background: rgb(252, 185, 60) !important;
}

.color-76-54 {
  background: rgb(252, 187, 60) !important;
}

.color-76-55 {
  background: rgb(252, 190, 60) !important;
}

.color-76-56 {
  background: rgb(252, 193, 60) !important;
}

.color-76-57 {
  background: rgb(252, 196, 60) !important;
}

.color-76-58 {
  background: rgb(252, 199, 60) !important;
}

.color-76-59 {
  background: rgb(252, 202, 60) !important;
}

.color-76-60 {
  background: rgb(252, 205, 60) !important;
}

.color-76-61 {
  background: rgb(252, 208, 60) !important;
}

.color-76-62 {
  background: rgb(252, 211, 60) !important;
}

.color-76-63 {
  background: rgb(252, 214, 60) !important;
}

.color-76-64 {
  background: rgb(252, 217, 60) !important;
}

.color-76-65 {
  background: rgb(252, 220, 60) !important;
}

.color-76-66 {
  background: rgb(252, 223, 60) !important;
}

.color-76-67 {
  background: rgb(252, 226, 60) !important;
}

.color-76-68 {
  background: rgb(252, 229, 60) !important;
}

.color-76-69 {
  background: rgb(252, 231, 60) !important;
}

.color-76-70 {
  background: rgb(252, 234, 60) !important;
}

.color-76-71 {
  background: rgb(252, 237, 60) !important;
}

.color-76-72 {
  background: rgb(252, 240, 60) !important;
}

.color-76-73 {
  background: rgb(252, 243, 60) !important;
}

.color-76-74 {
  background: rgb(252, 246, 60) !important;
}

.color-76-75 {
  background: rgb(252, 249, 60) !important;
}

.color-77-0 {
  background: rgb(140, 0, 28) !important;
}

.color-77-1 {
  background: rgb(252, 32, 60) !important;
}

.color-77-2 {
  background: rgb(252, 35, 60) !important;
}

.color-77-3 {
  background: rgb(252, 38, 60) !important;
}

.color-77-4 {
  background: rgb(252, 41, 60) !important;
}

.color-77-5 {
  background: rgb(252, 44, 60) !important;
}

.color-77-6 {
  background: rgb(252, 46, 60) !important;
}

.color-77-7 {
  background: rgb(252, 49, 60) !important;
}

.color-77-8 {
  background: rgb(252, 52, 60) !important;
}

.color-77-9 {
  background: rgb(252, 55, 60) !important;
}

.color-77-10 {
  background: rgb(252, 58, 60) !important;
}

.color-77-11 {
  background: rgb(252, 61, 60) !important;
}

.color-77-12 {
  background: rgb(252, 64, 60) !important;
}

.color-77-13 {
  background: rgb(252, 67, 60) !important;
}

.color-77-14 {
  background: rgb(252, 70, 60) !important;
}

.color-77-15 {
  background: rgb(252, 73, 60) !important;
}

.color-77-16 {
  background: rgb(252, 75, 60) !important;
}

.color-77-17 {
  background: rgb(252, 78, 60) !important;
}

.color-77-18 {
  background: rgb(252, 81, 60) !important;
}

.color-77-19 {
  background: rgb(252, 84, 60) !important;
}

.color-77-20 {
  background: rgb(252, 87, 60) !important;
}

.color-77-21 {
  background: rgb(252, 90, 60) !important;
}

.color-77-22 {
  background: rgb(252, 93, 60) !important;
}

.color-77-23 {
  background: rgb(252, 96, 60) !important;
}

.color-77-24 {
  background: rgb(252, 99, 60) !important;
}

.color-77-25 {
  background: rgb(252, 101, 60) !important;
}

.color-77-26 {
  background: rgb(252, 104, 60) !important;
}

.color-77-27 {
  background: rgb(252, 107, 60) !important;
}

.color-77-28 {
  background: rgb(252, 110, 60) !important;
}

.color-77-29 {
  background: rgb(252, 113, 60) !important;
}

.color-77-30 {
  background: rgb(252, 116, 60) !important;
}

.color-77-31 {
  background: rgb(252, 119, 60) !important;
}

.color-77-32 {
  background: rgb(252, 122, 60) !important;
}

.color-77-33 {
  background: rgb(252, 125, 60) !important;
}

.color-77-34 {
  background: rgb(252, 128, 60) !important;
}

.color-77-35 {
  background: rgb(252, 130, 60) !important;
}

.color-77-36 {
  background: rgb(252, 133, 60) !important;
}

.color-77-37 {
  background: rgb(252, 136, 60) !important;
}

.color-77-38 {
  background: rgb(252, 139, 60) !important;
}

.color-77-39 {
  background: rgb(252, 142, 60) !important;
}

.color-77-40 {
  background: rgb(252, 145, 60) !important;
}

.color-77-41 {
  background: rgb(252, 148, 60) !important;
}

.color-77-42 {
  background: rgb(252, 151, 60) !important;
}

.color-77-43 {
  background: rgb(252, 154, 60) !important;
}

.color-77-44 {
  background: rgb(252, 156, 60) !important;
}

.color-77-45 {
  background: rgb(252, 159, 60) !important;
}

.color-77-46 {
  background: rgb(252, 162, 60) !important;
}

.color-77-47 {
  background: rgb(252, 165, 60) !important;
}

.color-77-48 {
  background: rgb(252, 168, 60) !important;
}

.color-77-49 {
  background: rgb(252, 171, 60) !important;
}

.color-77-50 {
  background: rgb(252, 174, 60) !important;
}

.color-77-51 {
  background: rgb(252, 177, 60) !important;
}

.color-77-52 {
  background: rgb(252, 180, 60) !important;
}

.color-77-53 {
  background: rgb(252, 183, 60) !important;
}

.color-77-54 {
  background: rgb(252, 185, 60) !important;
}

.color-77-55 {
  background: rgb(252, 188, 60) !important;
}

.color-77-56 {
  background: rgb(252, 191, 60) !important;
}

.color-77-57 {
  background: rgb(252, 194, 60) !important;
}

.color-77-58 {
  background: rgb(252, 197, 60) !important;
}

.color-77-59 {
  background: rgb(252, 200, 60) !important;
}

.color-77-60 {
  background: rgb(252, 203, 60) !important;
}

.color-77-61 {
  background: rgb(252, 206, 60) !important;
}

.color-77-62 {
  background: rgb(252, 209, 60) !important;
}

.color-77-63 {
  background: rgb(252, 211, 60) !important;
}

.color-77-64 {
  background: rgb(252, 214, 60) !important;
}

.color-77-65 {
  background: rgb(252, 217, 60) !important;
}

.color-77-66 {
  background: rgb(252, 220, 60) !important;
}

.color-77-67 {
  background: rgb(252, 223, 60) !important;
}

.color-77-68 {
  background: rgb(252, 226, 60) !important;
}

.color-77-69 {
  background: rgb(252, 229, 60) !important;
}

.color-77-70 {
  background: rgb(252, 232, 60) !important;
}

.color-77-71 {
  background: rgb(252, 235, 60) !important;
}

.color-77-72 {
  background: rgb(252, 238, 60) !important;
}

.color-77-73 {
  background: rgb(252, 240, 60) !important;
}

.color-77-74 {
  background: rgb(252, 243, 60) !important;
}

.color-77-75 {
  background: rgb(252, 246, 60) !important;
}

.color-77-76 {
  background: rgb(252, 249, 60) !important;
}

.color-78-0 {
  background: rgb(140, 0, 28) !important;
}

.color-78-1 {
  background: rgb(252, 32, 60) !important;
}

.color-78-2 {
  background: rgb(252, 35, 60) !important;
}

.color-78-3 {
  background: rgb(252, 38, 60) !important;
}

.color-78-4 {
  background: rgb(252, 41, 60) !important;
}

.color-78-5 {
  background: rgb(252, 43, 60) !important;
}

.color-78-6 {
  background: rgb(252, 46, 60) !important;
}

.color-78-7 {
  background: rgb(252, 49, 60) !important;
}

.color-78-8 {
  background: rgb(252, 52, 60) !important;
}

.color-78-9 {
  background: rgb(252, 55, 60) !important;
}

.color-78-10 {
  background: rgb(252, 58, 60) !important;
}

.color-78-11 {
  background: rgb(252, 61, 60) !important;
}

.color-78-12 {
  background: rgb(252, 63, 60) !important;
}

.color-78-13 {
  background: rgb(252, 66, 60) !important;
}

.color-78-14 {
  background: rgb(252, 69, 60) !important;
}

.color-78-15 {
  background: rgb(252, 72, 60) !important;
}

.color-78-16 {
  background: rgb(252, 75, 60) !important;
}

.color-78-17 {
  background: rgb(252, 78, 60) !important;
}

.color-78-18 {
  background: rgb(252, 81, 60) !important;
}

.color-78-19 {
  background: rgb(252, 83, 60) !important;
}

.color-78-20 {
  background: rgb(252, 86, 60) !important;
}

.color-78-21 {
  background: rgb(252, 89, 60) !important;
}

.color-78-22 {
  background: rgb(252, 92, 60) !important;
}

.color-78-23 {
  background: rgb(252, 95, 60) !important;
}

.color-78-24 {
  background: rgb(252, 98, 60) !important;
}

.color-78-25 {
  background: rgb(252, 101, 60) !important;
}

.color-78-26 {
  background: rgb(252, 103, 60) !important;
}

.color-78-27 {
  background: rgb(252, 106, 60) !important;
}

.color-78-28 {
  background: rgb(252, 109, 60) !important;
}

.color-78-29 {
  background: rgb(252, 112, 60) !important;
}

.color-78-30 {
  background: rgb(252, 115, 60) !important;
}

.color-78-31 {
  background: rgb(252, 118, 60) !important;
}

.color-78-32 {
  background: rgb(252, 121, 60) !important;
}

.color-78-33 {
  background: rgb(252, 123, 60) !important;
}

.color-78-34 {
  background: rgb(252, 126, 60) !important;
}

.color-78-35 {
  background: rgb(252, 129, 60) !important;
}

.color-78-36 {
  background: rgb(252, 132, 60) !important;
}

.color-78-37 {
  background: rgb(252, 135, 60) !important;
}

.color-78-38 {
  background: rgb(252, 138, 60) !important;
}

.color-78-39 {
  background: rgb(252, 141, 60) !important;
}

.color-78-40 {
  background: rgb(252, 143, 60) !important;
}

.color-78-41 {
  background: rgb(252, 146, 60) !important;
}

.color-78-42 {
  background: rgb(252, 149, 60) !important;
}

.color-78-43 {
  background: rgb(252, 152, 60) !important;
}

.color-78-44 {
  background: rgb(252, 155, 60) !important;
}

.color-78-45 {
  background: rgb(252, 158, 60) !important;
}

.color-78-46 {
  background: rgb(252, 161, 60) !important;
}

.color-78-47 {
  background: rgb(252, 163, 60) !important;
}

.color-78-48 {
  background: rgb(252, 166, 60) !important;
}

.color-78-49 {
  background: rgb(252, 169, 60) !important;
}

.color-78-50 {
  background: rgb(252, 172, 60) !important;
}

.color-78-51 {
  background: rgb(252, 175, 60) !important;
}

.color-78-52 {
  background: rgb(252, 178, 60) !important;
}

.color-78-53 {
  background: rgb(252, 181, 60) !important;
}

.color-78-54 {
  background: rgb(252, 183, 60) !important;
}

.color-78-55 {
  background: rgb(252, 186, 60) !important;
}

.color-78-56 {
  background: rgb(252, 189, 60) !important;
}

.color-78-57 {
  background: rgb(252, 192, 60) !important;
}

.color-78-58 {
  background: rgb(252, 195, 60) !important;
}

.color-78-59 {
  background: rgb(252, 198, 60) !important;
}

.color-78-60 {
  background: rgb(252, 201, 60) !important;
}

.color-78-61 {
  background: rgb(252, 203, 60) !important;
}

.color-78-62 {
  background: rgb(252, 206, 60) !important;
}

.color-78-63 {
  background: rgb(252, 209, 60) !important;
}

.color-78-64 {
  background: rgb(252, 212, 60) !important;
}

.color-78-65 {
  background: rgb(252, 215, 60) !important;
}

.color-78-66 {
  background: rgb(252, 218, 60) !important;
}

.color-78-67 {
  background: rgb(252, 221, 60) !important;
}

.color-78-68 {
  background: rgb(252, 223, 60) !important;
}

.color-78-69 {
  background: rgb(252, 226, 60) !important;
}

.color-78-70 {
  background: rgb(252, 229, 60) !important;
}

.color-78-71 {
  background: rgb(252, 232, 60) !important;
}

.color-78-72 {
  background: rgb(252, 235, 60) !important;
}

.color-78-73 {
  background: rgb(252, 238, 60) !important;
}

.color-78-74 {
  background: rgb(252, 241, 60) !important;
}

.color-78-75 {
  background: rgb(252, 243, 60) !important;
}

.color-78-76 {
  background: rgb(252, 246, 60) !important;
}

.color-78-77 {
  background: rgb(252, 249, 60) !important;
}

.color-79-0 {
  background: rgb(140, 0, 28) !important;
}

.color-79-1 {
  background: rgb(252, 32, 60) !important;
}

.color-79-2 {
  background: rgb(252, 35, 60) !important;
}

.color-79-3 {
  background: rgb(252, 38, 60) !important;
}

.color-79-4 {
  background: rgb(252, 40, 60) !important;
}

.color-79-5 {
  background: rgb(252, 43, 60) !important;
}

.color-79-6 {
  background: rgb(252, 46, 60) !important;
}

.color-79-7 {
  background: rgb(252, 49, 60) !important;
}

.color-79-8 {
  background: rgb(252, 52, 60) !important;
}

.color-79-9 {
  background: rgb(252, 55, 60) !important;
}

.color-79-10 {
  background: rgb(252, 57, 60) !important;
}

.color-79-11 {
  background: rgb(252, 60, 60) !important;
}

.color-79-12 {
  background: rgb(252, 63, 60) !important;
}

.color-79-13 {
  background: rgb(252, 66, 60) !important;
}

.color-79-14 {
  background: rgb(252, 69, 60) !important;
}

.color-79-15 {
  background: rgb(252, 71, 60) !important;
}

.color-79-16 {
  background: rgb(252, 74, 60) !important;
}

.color-79-17 {
  background: rgb(252, 77, 60) !important;
}

.color-79-18 {
  background: rgb(252, 80, 60) !important;
}

.color-79-19 {
  background: rgb(252, 83, 60) !important;
}

.color-79-20 {
  background: rgb(252, 86, 60) !important;
}

.color-79-21 {
  background: rgb(252, 88, 60) !important;
}

.color-79-22 {
  background: rgb(252, 91, 60) !important;
}

.color-79-23 {
  background: rgb(252, 94, 60) !important;
}

.color-79-24 {
  background: rgb(252, 97, 60) !important;
}

.color-79-25 {
  background: rgb(252, 100, 60) !important;
}

.color-79-26 {
  background: rgb(252, 103, 60) !important;
}

.color-79-27 {
  background: rgb(252, 105, 60) !important;
}

.color-79-28 {
  background: rgb(252, 108, 60) !important;
}

.color-79-29 {
  background: rgb(252, 111, 60) !important;
}

.color-79-30 {
  background: rgb(252, 114, 60) !important;
}

.color-79-31 {
  background: rgb(252, 117, 60) !important;
}

.color-79-32 {
  background: rgb(252, 119, 60) !important;
}

.color-79-33 {
  background: rgb(252, 122, 60) !important;
}

.color-79-34 {
  background: rgb(252, 125, 60) !important;
}

.color-79-35 {
  background: rgb(252, 128, 60) !important;
}

.color-79-36 {
  background: rgb(252, 131, 60) !important;
}

.color-79-37 {
  background: rgb(252, 134, 60) !important;
}

.color-79-38 {
  background: rgb(252, 136, 60) !important;
}

.color-79-39 {
  background: rgb(252, 139, 60) !important;
}

.color-79-40 {
  background: rgb(252, 142, 60) !important;
}

.color-79-41 {
  background: rgb(252, 145, 60) !important;
}

.color-79-42 {
  background: rgb(252, 148, 60) !important;
}

.color-79-43 {
  background: rgb(252, 150, 60) !important;
}

.color-79-44 {
  background: rgb(252, 153, 60) !important;
}

.color-79-45 {
  background: rgb(252, 156, 60) !important;
}

.color-79-46 {
  background: rgb(252, 159, 60) !important;
}

.color-79-47 {
  background: rgb(252, 162, 60) !important;
}

.color-79-48 {
  background: rgb(252, 165, 60) !important;
}

.color-79-49 {
  background: rgb(252, 167, 60) !important;
}

.color-79-50 {
  background: rgb(252, 170, 60) !important;
}

.color-79-51 {
  background: rgb(252, 173, 60) !important;
}

.color-79-52 {
  background: rgb(252, 176, 60) !important;
}

.color-79-53 {
  background: rgb(252, 179, 60) !important;
}

.color-79-54 {
  background: rgb(252, 181, 60) !important;
}

.color-79-55 {
  background: rgb(252, 184, 60) !important;
}

.color-79-56 {
  background: rgb(252, 187, 60) !important;
}

.color-79-57 {
  background: rgb(252, 190, 60) !important;
}

.color-79-58 {
  background: rgb(252, 193, 60) !important;
}

.color-79-59 {
  background: rgb(252, 196, 60) !important;
}

.color-79-60 {
  background: rgb(252, 198, 60) !important;
}

.color-79-61 {
  background: rgb(252, 201, 60) !important;
}

.color-79-62 {
  background: rgb(252, 204, 60) !important;
}

.color-79-63 {
  background: rgb(252, 207, 60) !important;
}

.color-79-64 {
  background: rgb(252, 210, 60) !important;
}

.color-79-65 {
  background: rgb(252, 213, 60) !important;
}

.color-79-66 {
  background: rgb(252, 215, 60) !important;
}

.color-79-67 {
  background: rgb(252, 218, 60) !important;
}

.color-79-68 {
  background: rgb(252, 221, 60) !important;
}

.color-79-69 {
  background: rgb(252, 224, 60) !important;
}

.color-79-70 {
  background: rgb(252, 227, 60) !important;
}

.color-79-71 {
  background: rgb(252, 229, 60) !important;
}

.color-79-72 {
  background: rgb(252, 232, 60) !important;
}

.color-79-73 {
  background: rgb(252, 235, 60) !important;
}

.color-79-74 {
  background: rgb(252, 238, 60) !important;
}

.color-79-75 {
  background: rgb(252, 241, 60) !important;
}

.color-79-76 {
  background: rgb(252, 244, 60) !important;
}

.color-79-77 {
  background: rgb(252, 246, 60) !important;
}

.color-79-78 {
  background: rgb(252, 249, 60) !important;
}

.color-80-0 {
  background: rgb(140, 0, 28) !important;
}

.color-80-1 {
  background: rgb(252, 32, 60) !important;
}

.color-80-2 {
  background: rgb(252, 35, 60) !important;
}

.color-80-3 {
  background: rgb(252, 38, 60) !important;
}

.color-80-4 {
  background: rgb(252, 40, 60) !important;
}

.color-80-5 {
  background: rgb(252, 43, 60) !important;
}

.color-80-6 {
  background: rgb(252, 46, 60) !important;
}

.color-80-7 {
  background: rgb(252, 49, 60) !important;
}

.color-80-8 {
  background: rgb(252, 51, 60) !important;
}

.color-80-9 {
  background: rgb(252, 54, 60) !important;
}

.color-80-10 {
  background: rgb(252, 57, 60) !important;
}

.color-80-11 {
  background: rgb(252, 60, 60) !important;
}

.color-80-12 {
  background: rgb(252, 63, 60) !important;
}

.color-80-13 {
  background: rgb(252, 65, 60) !important;
}

.color-80-14 {
  background: rgb(252, 68, 60) !important;
}

.color-80-15 {
  background: rgb(252, 71, 60) !important;
}

.color-80-16 {
  background: rgb(252, 74, 60) !important;
}

.color-80-17 {
  background: rgb(252, 77, 60) !important;
}

.color-80-18 {
  background: rgb(252, 79, 60) !important;
}

.color-80-19 {
  background: rgb(252, 82, 60) !important;
}

.color-80-20 {
  background: rgb(252, 85, 60) !important;
}

.color-80-21 {
  background: rgb(252, 88, 60) !important;
}

.color-80-22 {
  background: rgb(252, 90, 60) !important;
}

.color-80-23 {
  background: rgb(252, 93, 60) !important;
}

.color-80-24 {
  background: rgb(252, 96, 60) !important;
}

.color-80-25 {
  background: rgb(252, 99, 60) !important;
}

.color-80-26 {
  background: rgb(252, 102, 60) !important;
}

.color-80-27 {
  background: rgb(252, 104, 60) !important;
}

.color-80-28 {
  background: rgb(252, 107, 60) !important;
}

.color-80-29 {
  background: rgb(252, 110, 60) !important;
}

.color-80-30 {
  background: rgb(252, 113, 60) !important;
}

.color-80-31 {
  background: rgb(252, 116, 60) !important;
}

.color-80-32 {
  background: rgb(252, 118, 60) !important;
}

.color-80-33 {
  background: rgb(252, 121, 60) !important;
}

.color-80-34 {
  background: rgb(252, 124, 60) !important;
}

.color-80-35 {
  background: rgb(252, 127, 60) !important;
}

.color-80-36 {
  background: rgb(252, 129, 60) !important;
}

.color-80-37 {
  background: rgb(252, 132, 60) !important;
}

.color-80-38 {
  background: rgb(252, 135, 60) !important;
}

.color-80-39 {
  background: rgb(252, 138, 60) !important;
}

.color-80-40 {
  background: rgb(252, 141, 60) !important;
}

.color-80-41 {
  background: rgb(252, 143, 60) !important;
}

.color-80-42 {
  background: rgb(252, 146, 60) !important;
}

.color-80-43 {
  background: rgb(252, 149, 60) !important;
}

.color-80-44 {
  background: rgb(252, 152, 60) !important;
}

.color-80-45 {
  background: rgb(252, 155, 60) !important;
}

.color-80-46 {
  background: rgb(252, 157, 60) !important;
}

.color-80-47 {
  background: rgb(252, 160, 60) !important;
}

.color-80-48 {
  background: rgb(252, 163, 60) !important;
}

.color-80-49 {
  background: rgb(252, 166, 60) !important;
}

.color-80-50 {
  background: rgb(252, 168, 60) !important;
}

.color-80-51 {
  background: rgb(252, 171, 60) !important;
}

.color-80-52 {
  background: rgb(252, 174, 60) !important;
}

.color-80-53 {
  background: rgb(252, 177, 60) !important;
}

.color-80-54 {
  background: rgb(252, 180, 60) !important;
}

.color-80-55 {
  background: rgb(252, 182, 60) !important;
}

.color-80-56 {
  background: rgb(252, 185, 60) !important;
}

.color-80-57 {
  background: rgb(252, 188, 60) !important;
}

.color-80-58 {
  background: rgb(252, 191, 60) !important;
}

.color-80-59 {
  background: rgb(252, 194, 60) !important;
}

.color-80-60 {
  background: rgb(252, 196, 60) !important;
}

.color-80-61 {
  background: rgb(252, 199, 60) !important;
}

.color-80-62 {
  background: rgb(252, 202, 60) !important;
}

.color-80-63 {
  background: rgb(252, 205, 60) !important;
}

.color-80-64 {
  background: rgb(252, 207, 60) !important;
}

.color-80-65 {
  background: rgb(252, 210, 60) !important;
}

.color-80-66 {
  background: rgb(252, 213, 60) !important;
}

.color-80-67 {
  background: rgb(252, 216, 60) !important;
}

.color-80-68 {
  background: rgb(252, 219, 60) !important;
}

.color-80-69 {
  background: rgb(252, 221, 60) !important;
}

.color-80-70 {
  background: rgb(252, 224, 60) !important;
}

.color-80-71 {
  background: rgb(252, 227, 60) !important;
}

.color-80-72 {
  background: rgb(252, 230, 60) !important;
}

.color-80-73 {
  background: rgb(252, 233, 60) !important;
}

.color-80-74 {
  background: rgb(252, 235, 60) !important;
}

.color-80-75 {
  background: rgb(252, 238, 60) !important;
}

.color-80-76 {
  background: rgb(252, 241, 60) !important;
}

.color-80-77 {
  background: rgb(252, 244, 60) !important;
}

.color-80-78 {
  background: rgb(252, 246, 60) !important;
}

.color-80-79 {
  background: rgb(252, 249, 60) !important;
}

.color-81-0 {
  background: rgb(140, 0, 28) !important;
}

.color-81-1 {
  background: rgb(252, 32, 60) !important;
}

.color-81-2 {
  background: rgb(252, 35, 60) !important;
}

.color-81-3 {
  background: rgb(252, 38, 60) !important;
}

.color-81-4 {
  background: rgb(252, 40, 60) !important;
}

.color-81-5 {
  background: rgb(252, 43, 60) !important;
}

.color-81-6 {
  background: rgb(252, 46, 60) !important;
}

.color-81-7 {
  background: rgb(252, 49, 60) !important;
}

.color-81-8 {
  background: rgb(252, 51, 60) !important;
}

.color-81-9 {
  background: rgb(252, 54, 60) !important;
}

.color-81-10 {
  background: rgb(252, 57, 60) !important;
}

.color-81-11 {
  background: rgb(252, 60, 60) !important;
}

.color-81-12 {
  background: rgb(252, 62, 60) !important;
}

.color-81-13 {
  background: rgb(252, 65, 60) !important;
}

.color-81-14 {
  background: rgb(252, 68, 60) !important;
}

.color-81-15 {
  background: rgb(252, 71, 60) !important;
}

.color-81-16 {
  background: rgb(252, 73, 60) !important;
}

.color-81-17 {
  background: rgb(252, 76, 60) !important;
}

.color-81-18 {
  background: rgb(252, 79, 60) !important;
}

.color-81-19 {
  background: rgb(252, 82, 60) !important;
}

.color-81-20 {
  background: rgb(252, 84, 60) !important;
}

.color-81-21 {
  background: rgb(252, 87, 60) !important;
}

.color-81-22 {
  background: rgb(252, 90, 60) !important;
}

.color-81-23 {
  background: rgb(252, 93, 60) !important;
}

.color-81-24 {
  background: rgb(252, 95, 60) !important;
}

.color-81-25 {
  background: rgb(252, 98, 60) !important;
}

.color-81-26 {
  background: rgb(252, 101, 60) !important;
}

.color-81-27 {
  background: rgb(252, 104, 60) !important;
}

.color-81-28 {
  background: rgb(252, 106, 60) !important;
}

.color-81-29 {
  background: rgb(252, 109, 60) !important;
}

.color-81-30 {
  background: rgb(252, 112, 60) !important;
}

.color-81-31 {
  background: rgb(252, 115, 60) !important;
}

.color-81-32 {
  background: rgb(252, 117, 60) !important;
}

.color-81-33 {
  background: rgb(252, 120, 60) !important;
}

.color-81-34 {
  background: rgb(252, 123, 60) !important;
}

.color-81-35 {
  background: rgb(252, 126, 60) !important;
}

.color-81-36 {
  background: rgb(252, 128, 60) !important;
}

.color-81-37 {
  background: rgb(252, 131, 60) !important;
}

.color-81-38 {
  background: rgb(252, 134, 60) !important;
}

.color-81-39 {
  background: rgb(252, 137, 60) !important;
}

.color-81-40 {
  background: rgb(252, 139, 60) !important;
}

.color-81-41 {
  background: rgb(252, 142, 60) !important;
}

.color-81-42 {
  background: rgb(252, 145, 60) !important;
}

.color-81-43 {
  background: rgb(252, 148, 60) !important;
}

.color-81-44 {
  background: rgb(252, 150, 60) !important;
}

.color-81-45 {
  background: rgb(252, 153, 60) !important;
}

.color-81-46 {
  background: rgb(252, 156, 60) !important;
}

.color-81-47 {
  background: rgb(252, 159, 60) !important;
}

.color-81-48 {
  background: rgb(252, 161, 60) !important;
}

.color-81-49 {
  background: rgb(252, 164, 60) !important;
}

.color-81-50 {
  background: rgb(252, 167, 60) !important;
}

.color-81-51 {
  background: rgb(252, 170, 60) !important;
}

.color-81-52 {
  background: rgb(252, 172, 60) !important;
}

.color-81-53 {
  background: rgb(252, 175, 60) !important;
}

.color-81-54 {
  background: rgb(252, 178, 60) !important;
}

.color-81-55 {
  background: rgb(252, 181, 60) !important;
}

.color-81-56 {
  background: rgb(252, 183, 60) !important;
}

.color-81-57 {
  background: rgb(252, 186, 60) !important;
}

.color-81-58 {
  background: rgb(252, 189, 60) !important;
}

.color-81-59 {
  background: rgb(252, 192, 60) !important;
}

.color-81-60 {
  background: rgb(252, 194, 60) !important;
}

.color-81-61 {
  background: rgb(252, 197, 60) !important;
}

.color-81-62 {
  background: rgb(252, 200, 60) !important;
}

.color-81-63 {
  background: rgb(252, 203, 60) !important;
}

.color-81-64 {
  background: rgb(252, 205, 60) !important;
}

.color-81-65 {
  background: rgb(252, 208, 60) !important;
}

.color-81-66 {
  background: rgb(252, 211, 60) !important;
}

.color-81-67 {
  background: rgb(252, 214, 60) !important;
}

.color-81-68 {
  background: rgb(252, 216, 60) !important;
}

.color-81-69 {
  background: rgb(252, 219, 60) !important;
}

.color-81-70 {
  background: rgb(252, 222, 60) !important;
}

.color-81-71 {
  background: rgb(252, 225, 60) !important;
}

.color-81-72 {
  background: rgb(252, 227, 60) !important;
}

.color-81-73 {
  background: rgb(252, 230, 60) !important;
}

.color-81-74 {
  background: rgb(252, 233, 60) !important;
}

.color-81-75 {
  background: rgb(252, 236, 60) !important;
}

.color-81-76 {
  background: rgb(252, 238, 60) !important;
}

.color-81-77 {
  background: rgb(252, 241, 60) !important;
}

.color-81-78 {
  background: rgb(252, 244, 60) !important;
}

.color-81-79 {
  background: rgb(252, 247, 60) !important;
}

.color-81-80 {
  background: rgb(252, 249, 60) !important;
}

.color-82-0 {
  background: rgb(140, 0, 28) !important;
}

.color-82-1 {
  background: rgb(252, 32, 60) !important;
}

.color-82-2 {
  background: rgb(252, 35, 60) !important;
}

.color-82-3 {
  background: rgb(252, 37, 60) !important;
}

.color-82-4 {
  background: rgb(252, 40, 60) !important;
}

.color-82-5 {
  background: rgb(252, 43, 60) !important;
}

.color-82-6 {
  background: rgb(252, 46, 60) !important;
}

.color-82-7 {
  background: rgb(252, 48, 60) !important;
}

.color-82-8 {
  background: rgb(252, 51, 60) !important;
}

.color-82-9 {
  background: rgb(252, 54, 60) !important;
}

.color-82-10 {
  background: rgb(252, 56, 60) !important;
}

.color-82-11 {
  background: rgb(252, 59, 60) !important;
}

.color-82-12 {
  background: rgb(252, 62, 60) !important;
}

.color-82-13 {
  background: rgb(252, 65, 60) !important;
}

.color-82-14 {
  background: rgb(252, 67, 60) !important;
}

.color-82-15 {
  background: rgb(252, 70, 60) !important;
}

.color-82-16 {
  background: rgb(252, 73, 60) !important;
}

.color-82-17 {
  background: rgb(252, 75, 60) !important;
}

.color-82-18 {
  background: rgb(252, 78, 60) !important;
}

.color-82-19 {
  background: rgb(252, 81, 60) !important;
}

.color-82-20 {
  background: rgb(252, 84, 60) !important;
}

.color-82-21 {
  background: rgb(252, 86, 60) !important;
}

.color-82-22 {
  background: rgb(252, 89, 60) !important;
}

.color-82-23 {
  background: rgb(252, 92, 60) !important;
}

.color-82-24 {
  background: rgb(252, 94, 60) !important;
}

.color-82-25 {
  background: rgb(252, 97, 60) !important;
}

.color-82-26 {
  background: rgb(252, 100, 60) !important;
}

.color-82-27 {
  background: rgb(252, 103, 60) !important;
}

.color-82-28 {
  background: rgb(252, 105, 60) !important;
}

.color-82-29 {
  background: rgb(252, 108, 60) !important;
}

.color-82-30 {
  background: rgb(252, 111, 60) !important;
}

.color-82-31 {
  background: rgb(252, 113, 60) !important;
}

.color-82-32 {
  background: rgb(252, 116, 60) !important;
}

.color-82-33 {
  background: rgb(252, 119, 60) !important;
}

.color-82-34 {
  background: rgb(252, 122, 60) !important;
}

.color-82-35 {
  background: rgb(252, 124, 60) !important;
}

.color-82-36 {
  background: rgb(252, 127, 60) !important;
}

.color-82-37 {
  background: rgb(252, 130, 60) !important;
}

.color-82-38 {
  background: rgb(252, 132, 60) !important;
}

.color-82-39 {
  background: rgb(252, 135, 60) !important;
}

.color-82-40 {
  background: rgb(252, 138, 60) !important;
}

.color-82-41 {
  background: rgb(252, 141, 60) !important;
}

.color-82-42 {
  background: rgb(252, 143, 60) !important;
}

.color-82-43 {
  background: rgb(252, 146, 60) !important;
}

.color-82-44 {
  background: rgb(252, 149, 60) !important;
}

.color-82-45 {
  background: rgb(252, 152, 60) !important;
}

.color-82-46 {
  background: rgb(252, 154, 60) !important;
}

.color-82-47 {
  background: rgb(252, 157, 60) !important;
}

.color-82-48 {
  background: rgb(252, 160, 60) !important;
}

.color-82-49 {
  background: rgb(252, 162, 60) !important;
}

.color-82-50 {
  background: rgb(252, 165, 60) !important;
}

.color-82-51 {
  background: rgb(252, 168, 60) !important;
}

.color-82-52 {
  background: rgb(252, 171, 60) !important;
}

.color-82-53 {
  background: rgb(252, 173, 60) !important;
}

.color-82-54 {
  background: rgb(252, 176, 60) !important;
}

.color-82-55 {
  background: rgb(252, 179, 60) !important;
}

.color-82-56 {
  background: rgb(252, 181, 60) !important;
}

.color-82-57 {
  background: rgb(252, 184, 60) !important;
}

.color-82-58 {
  background: rgb(252, 187, 60) !important;
}

.color-82-59 {
  background: rgb(252, 190, 60) !important;
}

.color-82-60 {
  background: rgb(252, 192, 60) !important;
}

.color-82-61 {
  background: rgb(252, 195, 60) !important;
}

.color-82-62 {
  background: rgb(252, 198, 60) !important;
}

.color-82-63 {
  background: rgb(252, 200, 60) !important;
}

.color-82-64 {
  background: rgb(252, 203, 60) !important;
}

.color-82-65 {
  background: rgb(252, 206, 60) !important;
}

.color-82-66 {
  background: rgb(252, 209, 60) !important;
}

.color-82-67 {
  background: rgb(252, 211, 60) !important;
}

.color-82-68 {
  background: rgb(252, 214, 60) !important;
}

.color-82-69 {
  background: rgb(252, 217, 60) !important;
}

.color-82-70 {
  background: rgb(252, 219, 60) !important;
}

.color-82-71 {
  background: rgb(252, 222, 60) !important;
}

.color-82-72 {
  background: rgb(252, 225, 60) !important;
}

.color-82-73 {
  background: rgb(252, 228, 60) !important;
}

.color-82-74 {
  background: rgb(252, 230, 60) !important;
}

.color-82-75 {
  background: rgb(252, 233, 60) !important;
}

.color-82-76 {
  background: rgb(252, 236, 60) !important;
}

.color-82-77 {
  background: rgb(252, 238, 60) !important;
}

.color-82-78 {
  background: rgb(252, 241, 60) !important;
}

.color-82-79 {
  background: rgb(252, 244, 60) !important;
}

.color-82-80 {
  background: rgb(252, 247, 60) !important;
}

.color-82-81 {
  background: rgb(252, 249, 60) !important;
}

.color-83-0 {
  background: rgb(140, 0, 28) !important;
}

.color-83-1 {
  background: rgb(252, 32, 60) !important;
}

.color-83-2 {
  background: rgb(252, 35, 60) !important;
}

.color-83-3 {
  background: rgb(252, 37, 60) !important;
}

.color-83-4 {
  background: rgb(252, 40, 60) !important;
}

.color-83-5 {
  background: rgb(252, 43, 60) !important;
}

.color-83-6 {
  background: rgb(252, 45, 60) !important;
}

.color-83-7 {
  background: rgb(252, 48, 60) !important;
}

.color-83-8 {
  background: rgb(252, 51, 60) !important;
}

.color-83-9 {
  background: rgb(252, 53, 60) !important;
}

.color-83-10 {
  background: rgb(252, 56, 60) !important;
}

.color-83-11 {
  background: rgb(252, 59, 60) !important;
}

.color-83-12 {
  background: rgb(252, 62, 60) !important;
}

.color-83-13 {
  background: rgb(252, 64, 60) !important;
}

.color-83-14 {
  background: rgb(252, 67, 60) !important;
}

.color-83-15 {
  background: rgb(252, 70, 60) !important;
}

.color-83-16 {
  background: rgb(252, 72, 60) !important;
}

.color-83-17 {
  background: rgb(252, 75, 60) !important;
}

.color-83-18 {
  background: rgb(252, 78, 60) !important;
}

.color-83-19 {
  background: rgb(252, 80, 60) !important;
}

.color-83-20 {
  background: rgb(252, 83, 60) !important;
}

.color-83-21 {
  background: rgb(252, 86, 60) !important;
}

.color-83-22 {
  background: rgb(252, 88, 60) !important;
}

.color-83-23 {
  background: rgb(252, 91, 60) !important;
}

.color-83-24 {
  background: rgb(252, 94, 60) !important;
}

.color-83-25 {
  background: rgb(252, 96, 60) !important;
}

.color-83-26 {
  background: rgb(252, 99, 60) !important;
}

.color-83-27 {
  background: rgb(252, 102, 60) !important;
}

.color-83-28 {
  background: rgb(252, 104, 60) !important;
}

.color-83-29 {
  background: rgb(252, 107, 60) !important;
}

.color-83-30 {
  background: rgb(252, 110, 60) !important;
}

.color-83-31 {
  background: rgb(252, 112, 60) !important;
}

.color-83-32 {
  background: rgb(252, 115, 60) !important;
}

.color-83-33 {
  background: rgb(252, 118, 60) !important;
}

.color-83-34 {
  background: rgb(252, 121, 60) !important;
}

.color-83-35 {
  background: rgb(252, 123, 60) !important;
}

.color-83-36 {
  background: rgb(252, 126, 60) !important;
}

.color-83-37 {
  background: rgb(252, 129, 60) !important;
}

.color-83-38 {
  background: rgb(252, 131, 60) !important;
}

.color-83-39 {
  background: rgb(252, 134, 60) !important;
}

.color-83-40 {
  background: rgb(252, 137, 60) !important;
}

.color-83-41 {
  background: rgb(252, 139, 60) !important;
}

.color-83-42 {
  background: rgb(252, 142, 60) !important;
}

.color-83-43 {
  background: rgb(252, 145, 60) !important;
}

.color-83-44 {
  background: rgb(252, 147, 60) !important;
}

.color-83-45 {
  background: rgb(252, 150, 60) !important;
}

.color-83-46 {
  background: rgb(252, 153, 60) !important;
}

.color-83-47 {
  background: rgb(252, 155, 60) !important;
}

.color-83-48 {
  background: rgb(252, 158, 60) !important;
}

.color-83-49 {
  background: rgb(252, 161, 60) !important;
}

.color-83-50 {
  background: rgb(252, 163, 60) !important;
}

.color-83-51 {
  background: rgb(252, 166, 60) !important;
}

.color-83-52 {
  background: rgb(252, 169, 60) !important;
}

.color-83-53 {
  background: rgb(252, 172, 60) !important;
}

.color-83-54 {
  background: rgb(252, 174, 60) !important;
}

.color-83-55 {
  background: rgb(252, 177, 60) !important;
}

.color-83-56 {
  background: rgb(252, 180, 60) !important;
}

.color-83-57 {
  background: rgb(252, 182, 60) !important;
}

.color-83-58 {
  background: rgb(252, 185, 60) !important;
}

.color-83-59 {
  background: rgb(252, 188, 60) !important;
}

.color-83-60 {
  background: rgb(252, 190, 60) !important;
}

.color-83-61 {
  background: rgb(252, 193, 60) !important;
}

.color-83-62 {
  background: rgb(252, 196, 60) !important;
}

.color-83-63 {
  background: rgb(252, 198, 60) !important;
}

.color-83-64 {
  background: rgb(252, 201, 60) !important;
}

.color-83-65 {
  background: rgb(252, 204, 60) !important;
}

.color-83-66 {
  background: rgb(252, 206, 60) !important;
}

.color-83-67 {
  background: rgb(252, 209, 60) !important;
}

.color-83-68 {
  background: rgb(252, 212, 60) !important;
}

.color-83-69 {
  background: rgb(252, 214, 60) !important;
}

.color-83-70 {
  background: rgb(252, 217, 60) !important;
}

.color-83-71 {
  background: rgb(252, 220, 60) !important;
}

.color-83-72 {
  background: rgb(252, 222, 60) !important;
}

.color-83-73 {
  background: rgb(252, 225, 60) !important;
}

.color-83-74 {
  background: rgb(252, 228, 60) !important;
}

.color-83-75 {
  background: rgb(252, 231, 60) !important;
}

.color-83-76 {
  background: rgb(252, 233, 60) !important;
}

.color-83-77 {
  background: rgb(252, 236, 60) !important;
}

.color-83-78 {
  background: rgb(252, 239, 60) !important;
}

.color-83-79 {
  background: rgb(252, 241, 60) !important;
}

.color-83-80 {
  background: rgb(252, 244, 60) !important;
}

.color-83-81 {
  background: rgb(252, 247, 60) !important;
}

.color-83-82 {
  background: rgb(252, 249, 60) !important;
}

.color-84-0 {
  background: rgb(140, 0, 28) !important;
}

.color-84-1 {
  background: rgb(252, 32, 60) !important;
}

.color-84-2 {
  background: rgb(252, 35, 60) !important;
}

.color-84-3 {
  background: rgb(252, 37, 60) !important;
}

.color-84-4 {
  background: rgb(252, 40, 60) !important;
}

.color-84-5 {
  background: rgb(252, 43, 60) !important;
}

.color-84-6 {
  background: rgb(252, 45, 60) !important;
}

.color-84-7 {
  background: rgb(252, 48, 60) !important;
}

.color-84-8 {
  background: rgb(252, 51, 60) !important;
}

.color-84-9 {
  background: rgb(252, 53, 60) !important;
}

.color-84-10 {
  background: rgb(252, 56, 60) !important;
}

.color-84-11 {
  background: rgb(252, 59, 60) !important;
}

.color-84-12 {
  background: rgb(252, 61, 60) !important;
}

.color-84-13 {
  background: rgb(252, 64, 60) !important;
}

.color-84-14 {
  background: rgb(252, 66, 60) !important;
}

.color-84-15 {
  background: rgb(252, 69, 60) !important;
}

.color-84-16 {
  background: rgb(252, 72, 60) !important;
}

.color-84-17 {
  background: rgb(252, 74, 60) !important;
}

.color-84-18 {
  background: rgb(252, 77, 60) !important;
}

.color-84-19 {
  background: rgb(252, 80, 60) !important;
}

.color-84-20 {
  background: rgb(252, 82, 60) !important;
}

.color-84-21 {
  background: rgb(252, 85, 60) !important;
}

.color-84-22 {
  background: rgb(252, 88, 60) !important;
}

.color-84-23 {
  background: rgb(252, 90, 60) !important;
}

.color-84-24 {
  background: rgb(252, 93, 60) !important;
}

.color-84-25 {
  background: rgb(252, 96, 60) !important;
}

.color-84-26 {
  background: rgb(252, 98, 60) !important;
}

.color-84-27 {
  background: rgb(252, 101, 60) !important;
}

.color-84-28 {
  background: rgb(252, 104, 60) !important;
}

.color-84-29 {
  background: rgb(252, 106, 60) !important;
}

.color-84-30 {
  background: rgb(252, 109, 60) !important;
}

.color-84-31 {
  background: rgb(252, 112, 60) !important;
}

.color-84-32 {
  background: rgb(252, 114, 60) !important;
}

.color-84-33 {
  background: rgb(252, 117, 60) !important;
}

.color-84-34 {
  background: rgb(252, 119, 60) !important;
}

.color-84-35 {
  background: rgb(252, 122, 60) !important;
}

.color-84-36 {
  background: rgb(252, 125, 60) !important;
}

.color-84-37 {
  background: rgb(252, 127, 60) !important;
}

.color-84-38 {
  background: rgb(252, 130, 60) !important;
}

.color-84-39 {
  background: rgb(252, 133, 60) !important;
}

.color-84-40 {
  background: rgb(252, 135, 60) !important;
}

.color-84-41 {
  background: rgb(252, 138, 60) !important;
}

.color-84-42 {
  background: rgb(252, 141, 60) !important;
}

.color-84-43 {
  background: rgb(252, 143, 60) !important;
}

.color-84-44 {
  background: rgb(252, 146, 60) !important;
}

.color-84-45 {
  background: rgb(252, 149, 60) !important;
}

.color-84-46 {
  background: rgb(252, 151, 60) !important;
}

.color-84-47 {
  background: rgb(252, 154, 60) !important;
}

.color-84-48 {
  background: rgb(252, 157, 60) !important;
}

.color-84-49 {
  background: rgb(252, 159, 60) !important;
}

.color-84-50 {
  background: rgb(252, 162, 60) !important;
}

.color-84-51 {
  background: rgb(252, 165, 60) !important;
}

.color-84-52 {
  background: rgb(252, 167, 60) !important;
}

.color-84-53 {
  background: rgb(252, 170, 60) !important;
}

.color-84-54 {
  background: rgb(252, 172, 60) !important;
}

.color-84-55 {
  background: rgb(252, 175, 60) !important;
}

.color-84-56 {
  background: rgb(252, 178, 60) !important;
}

.color-84-57 {
  background: rgb(252, 180, 60) !important;
}

.color-84-58 {
  background: rgb(252, 183, 60) !important;
}

.color-84-59 {
  background: rgb(252, 186, 60) !important;
}

.color-84-60 {
  background: rgb(252, 188, 60) !important;
}

.color-84-61 {
  background: rgb(252, 191, 60) !important;
}

.color-84-62 {
  background: rgb(252, 194, 60) !important;
}

.color-84-63 {
  background: rgb(252, 196, 60) !important;
}

.color-84-64 {
  background: rgb(252, 199, 60) !important;
}

.color-84-65 {
  background: rgb(252, 202, 60) !important;
}

.color-84-66 {
  background: rgb(252, 204, 60) !important;
}

.color-84-67 {
  background: rgb(252, 207, 60) !important;
}

.color-84-68 {
  background: rgb(252, 210, 60) !important;
}

.color-84-69 {
  background: rgb(252, 212, 60) !important;
}

.color-84-70 {
  background: rgb(252, 215, 60) !important;
}

.color-84-71 {
  background: rgb(252, 218, 60) !important;
}

.color-84-72 {
  background: rgb(252, 220, 60) !important;
}

.color-84-73 {
  background: rgb(252, 223, 60) !important;
}

.color-84-74 {
  background: rgb(252, 225, 60) !important;
}

.color-84-75 {
  background: rgb(252, 228, 60) !important;
}

.color-84-76 {
  background: rgb(252, 231, 60) !important;
}

.color-84-77 {
  background: rgb(252, 233, 60) !important;
}

.color-84-78 {
  background: rgb(252, 236, 60) !important;
}

.color-84-79 {
  background: rgb(252, 239, 60) !important;
}

.color-84-80 {
  background: rgb(252, 241, 60) !important;
}

.color-84-81 {
  background: rgb(252, 244, 60) !important;
}

.color-84-82 {
  background: rgb(252, 247, 60) !important;
}

.color-84-83 {
  background: rgb(252, 249, 60) !important;
}

.color-85-0 {
  background: rgb(140, 0, 28) !important;
}

.color-85-1 {
  background: rgb(252, 32, 60) !important;
}

.color-85-2 {
  background: rgb(252, 35, 60) !important;
}

.color-85-3 {
  background: rgb(252, 37, 60) !important;
}

.color-85-4 {
  background: rgb(252, 40, 60) !important;
}

.color-85-5 {
  background: rgb(252, 42, 60) !important;
}

.color-85-6 {
  background: rgb(252, 45, 60) !important;
}

.color-85-7 {
  background: rgb(252, 48, 60) !important;
}

.color-85-8 {
  background: rgb(252, 50, 60) !important;
}

.color-85-9 {
  background: rgb(252, 53, 60) !important;
}

.color-85-10 {
  background: rgb(252, 56, 60) !important;
}

.color-85-11 {
  background: rgb(252, 58, 60) !important;
}

.color-85-12 {
  background: rgb(252, 61, 60) !important;
}

.color-85-13 {
  background: rgb(252, 63, 60) !important;
}

.color-85-14 {
  background: rgb(252, 66, 60) !important;
}

.color-85-15 {
  background: rgb(252, 69, 60) !important;
}

.color-85-16 {
  background: rgb(252, 71, 60) !important;
}

.color-85-17 {
  background: rgb(252, 74, 60) !important;
}

.color-85-18 {
  background: rgb(252, 77, 60) !important;
}

.color-85-19 {
  background: rgb(252, 79, 60) !important;
}

.color-85-20 {
  background: rgb(252, 82, 60) !important;
}

.color-85-21 {
  background: rgb(252, 84, 60) !important;
}

.color-85-22 {
  background: rgb(252, 87, 60) !important;
}

.color-85-23 {
  background: rgb(252, 90, 60) !important;
}

.color-85-24 {
  background: rgb(252, 92, 60) !important;
}

.color-85-25 {
  background: rgb(252, 95, 60) !important;
}

.color-85-26 {
  background: rgb(252, 97, 60) !important;
}

.color-85-27 {
  background: rgb(252, 100, 60) !important;
}

.color-85-28 {
  background: rgb(252, 103, 60) !important;
}

.color-85-29 {
  background: rgb(252, 105, 60) !important;
}

.color-85-30 {
  background: rgb(252, 108, 60) !important;
}

.color-85-31 {
  background: rgb(252, 111, 60) !important;
}

.color-85-32 {
  background: rgb(252, 113, 60) !important;
}

.color-85-33 {
  background: rgb(252, 116, 60) !important;
}

.color-85-34 {
  background: rgb(252, 118, 60) !important;
}

.color-85-35 {
  background: rgb(252, 121, 60) !important;
}

.color-85-36 {
  background: rgb(252, 124, 60) !important;
}

.color-85-37 {
  background: rgb(252, 126, 60) !important;
}

.color-85-38 {
  background: rgb(252, 129, 60) !important;
}

.color-85-39 {
  background: rgb(252, 132, 60) !important;
}

.color-85-40 {
  background: rgb(252, 134, 60) !important;
}

.color-85-41 {
  background: rgb(252, 137, 60) !important;
}

.color-85-42 {
  background: rgb(252, 139, 60) !important;
}

.color-85-43 {
  background: rgb(252, 142, 60) !important;
}

.color-85-44 {
  background: rgb(252, 145, 60) !important;
}

.color-85-45 {
  background: rgb(252, 147, 60) !important;
}

.color-85-46 {
  background: rgb(252, 150, 60) !important;
}

.color-85-47 {
  background: rgb(252, 152, 60) !important;
}

.color-85-48 {
  background: rgb(252, 155, 60) !important;
}

.color-85-49 {
  background: rgb(252, 158, 60) !important;
}

.color-85-50 {
  background: rgb(252, 160, 60) !important;
}

.color-85-51 {
  background: rgb(252, 163, 60) !important;
}

.color-85-52 {
  background: rgb(252, 166, 60) !important;
}

.color-85-53 {
  background: rgb(252, 168, 60) !important;
}

.color-85-54 {
  background: rgb(252, 171, 60) !important;
}

.color-85-55 {
  background: rgb(252, 173, 60) !important;
}

.color-85-56 {
  background: rgb(252, 176, 60) !important;
}

.color-85-57 {
  background: rgb(252, 179, 60) !important;
}

.color-85-58 {
  background: rgb(252, 181, 60) !important;
}

.color-85-59 {
  background: rgb(252, 184, 60) !important;
}

.color-85-60 {
  background: rgb(252, 187, 60) !important;
}

.color-85-61 {
  background: rgb(252, 189, 60) !important;
}

.color-85-62 {
  background: rgb(252, 192, 60) !important;
}

.color-85-63 {
  background: rgb(252, 194, 60) !important;
}

.color-85-64 {
  background: rgb(252, 197, 60) !important;
}

.color-85-65 {
  background: rgb(252, 200, 60) !important;
}

.color-85-66 {
  background: rgb(252, 202, 60) !important;
}

.color-85-67 {
  background: rgb(252, 205, 60) !important;
}

.color-85-68 {
  background: rgb(252, 207, 60) !important;
}

.color-85-69 {
  background: rgb(252, 210, 60) !important;
}

.color-85-70 {
  background: rgb(252, 213, 60) !important;
}

.color-85-71 {
  background: rgb(252, 215, 60) !important;
}

.color-85-72 {
  background: rgb(252, 218, 60) !important;
}

.color-85-73 {
  background: rgb(252, 221, 60) !important;
}

.color-85-74 {
  background: rgb(252, 223, 60) !important;
}

.color-85-75 {
  background: rgb(252, 226, 60) !important;
}

.color-85-76 {
  background: rgb(252, 228, 60) !important;
}

.color-85-77 {
  background: rgb(252, 231, 60) !important;
}

.color-85-78 {
  background: rgb(252, 234, 60) !important;
}

.color-85-79 {
  background: rgb(252, 236, 60) !important;
}

.color-85-80 {
  background: rgb(252, 239, 60) !important;
}

.color-85-81 {
  background: rgb(252, 242, 60) !important;
}

.color-85-82 {
  background: rgb(252, 244, 60) !important;
}

.color-85-83 {
  background: rgb(252, 247, 60) !important;
}

.color-85-84 {
  background: rgb(252, 249, 60) !important;
}

.color-86-0 {
  background: rgb(140, 0, 28) !important;
}

.color-86-1 {
  background: rgb(252, 32, 60) !important;
}

.color-86-2 {
  background: rgb(252, 35, 60) !important;
}

.color-86-3 {
  background: rgb(252, 37, 60) !important;
}

.color-86-4 {
  background: rgb(252, 40, 60) !important;
}

.color-86-5 {
  background: rgb(252, 42, 60) !important;
}

.color-86-6 {
  background: rgb(252, 45, 60) !important;
}

.color-86-7 {
  background: rgb(252, 48, 60) !important;
}

.color-86-8 {
  background: rgb(252, 50, 60) !important;
}

.color-86-9 {
  background: rgb(252, 53, 60) !important;
}

.color-86-10 {
  background: rgb(252, 55, 60) !important;
}

.color-86-11 {
  background: rgb(252, 58, 60) !important;
}

.color-86-12 {
  background: rgb(252, 60, 60) !important;
}

.color-86-13 {
  background: rgb(252, 63, 60) !important;
}

.color-86-14 {
  background: rgb(252, 66, 60) !important;
}

.color-86-15 {
  background: rgb(252, 68, 60) !important;
}

.color-86-16 {
  background: rgb(252, 71, 60) !important;
}

.color-86-17 {
  background: rgb(252, 73, 60) !important;
}

.color-86-18 {
  background: rgb(252, 76, 60) !important;
}

.color-86-19 {
  background: rgb(252, 79, 60) !important;
}

.color-86-20 {
  background: rgb(252, 81, 60) !important;
}

.color-86-21 {
  background: rgb(252, 84, 60) !important;
}

.color-86-22 {
  background: rgb(252, 86, 60) !important;
}

.color-86-23 {
  background: rgb(252, 89, 60) !important;
}

.color-86-24 {
  background: rgb(252, 92, 60) !important;
}

.color-86-25 {
  background: rgb(252, 94, 60) !important;
}

.color-86-26 {
  background: rgb(252, 97, 60) !important;
}

.color-86-27 {
  background: rgb(252, 99, 60) !important;
}

.color-86-28 {
  background: rgb(252, 102, 60) !important;
}

.color-86-29 {
  background: rgb(252, 104, 60) !important;
}

.color-86-30 {
  background: rgb(252, 107, 60) !important;
}

.color-86-31 {
  background: rgb(252, 110, 60) !important;
}

.color-86-32 {
  background: rgb(252, 112, 60) !important;
}

.color-86-33 {
  background: rgb(252, 115, 60) !important;
}

.color-86-34 {
  background: rgb(252, 117, 60) !important;
}

.color-86-35 {
  background: rgb(252, 120, 60) !important;
}

.color-86-36 {
  background: rgb(252, 123, 60) !important;
}

.color-86-37 {
  background: rgb(252, 125, 60) !important;
}

.color-86-38 {
  background: rgb(252, 128, 60) !important;
}

.color-86-39 {
  background: rgb(252, 130, 60) !important;
}

.color-86-40 {
  background: rgb(252, 133, 60) !important;
}

.color-86-41 {
  background: rgb(252, 136, 60) !important;
}

.color-86-42 {
  background: rgb(252, 138, 60) !important;
}

.color-86-43 {
  background: rgb(252, 141, 60) !important;
}

.color-86-44 {
  background: rgb(252, 143, 60) !important;
}

.color-86-45 {
  background: rgb(252, 146, 60) !important;
}

.color-86-46 {
  background: rgb(252, 148, 60) !important;
}

.color-86-47 {
  background: rgb(252, 151, 60) !important;
}

.color-86-48 {
  background: rgb(252, 154, 60) !important;
}

.color-86-49 {
  background: rgb(252, 156, 60) !important;
}

.color-86-50 {
  background: rgb(252, 159, 60) !important;
}

.color-86-51 {
  background: rgb(252, 161, 60) !important;
}

.color-86-52 {
  background: rgb(252, 164, 60) !important;
}

.color-86-53 {
  background: rgb(252, 167, 60) !important;
}

.color-86-54 {
  background: rgb(252, 169, 60) !important;
}

.color-86-55 {
  background: rgb(252, 172, 60) !important;
}

.color-86-56 {
  background: rgb(252, 174, 60) !important;
}

.color-86-57 {
  background: rgb(252, 177, 60) !important;
}

.color-86-58 {
  background: rgb(252, 180, 60) !important;
}

.color-86-59 {
  background: rgb(252, 182, 60) !important;
}

.color-86-60 {
  background: rgb(252, 185, 60) !important;
}

.color-86-61 {
  background: rgb(252, 187, 60) !important;
}

.color-86-62 {
  background: rgb(252, 190, 60) !important;
}

.color-86-63 {
  background: rgb(252, 192, 60) !important;
}

.color-86-64 {
  background: rgb(252, 195, 60) !important;
}

.color-86-65 {
  background: rgb(252, 198, 60) !important;
}

.color-86-66 {
  background: rgb(252, 200, 60) !important;
}

.color-86-67 {
  background: rgb(252, 203, 60) !important;
}

.color-86-68 {
  background: rgb(252, 205, 60) !important;
}

.color-86-69 {
  background: rgb(252, 208, 60) !important;
}

.color-86-70 {
  background: rgb(252, 211, 60) !important;
}

.color-86-71 {
  background: rgb(252, 213, 60) !important;
}

.color-86-72 {
  background: rgb(252, 216, 60) !important;
}

.color-86-73 {
  background: rgb(252, 218, 60) !important;
}

.color-86-74 {
  background: rgb(252, 221, 60) !important;
}

.color-86-75 {
  background: rgb(252, 224, 60) !important;
}

.color-86-76 {
  background: rgb(252, 226, 60) !important;
}

.color-86-77 {
  background: rgb(252, 229, 60) !important;
}

.color-86-78 {
  background: rgb(252, 231, 60) !important;
}

.color-86-79 {
  background: rgb(252, 234, 60) !important;
}

.color-86-80 {
  background: rgb(252, 236, 60) !important;
}

.color-86-81 {
  background: rgb(252, 239, 60) !important;
}

.color-86-82 {
  background: rgb(252, 242, 60) !important;
}

.color-86-83 {
  background: rgb(252, 244, 60) !important;
}

.color-86-84 {
  background: rgb(252, 247, 60) !important;
}

.color-86-85 {
  background: rgb(252, 249, 60) !important;
}

.color-87-0 {
  background: rgb(140, 0, 28) !important;
}

.color-87-1 {
  background: rgb(252, 32, 60) !important;
}

.color-87-2 {
  background: rgb(252, 35, 60) !important;
}

.color-87-3 {
  background: rgb(252, 37, 60) !important;
}

.color-87-4 {
  background: rgb(252, 40, 60) !important;
}

.color-87-5 {
  background: rgb(252, 42, 60) !important;
}

.color-87-6 {
  background: rgb(252, 45, 60) !important;
}

.color-87-7 {
  background: rgb(252, 47, 60) !important;
}

.color-87-8 {
  background: rgb(252, 50, 60) !important;
}

.color-87-9 {
  background: rgb(252, 52, 60) !important;
}

.color-87-10 {
  background: rgb(252, 55, 60) !important;
}

.color-87-11 {
  background: rgb(252, 58, 60) !important;
}

.color-87-12 {
  background: rgb(252, 60, 60) !important;
}

.color-87-13 {
  background: rgb(252, 63, 60) !important;
}

.color-87-14 {
  background: rgb(252, 65, 60) !important;
}

.color-87-15 {
  background: rgb(252, 68, 60) !important;
}

.color-87-16 {
  background: rgb(252, 70, 60) !important;
}

.color-87-17 {
  background: rgb(252, 73, 60) !important;
}

.color-87-18 {
  background: rgb(252, 75, 60) !important;
}

.color-87-19 {
  background: rgb(252, 78, 60) !important;
}

.color-87-20 {
  background: rgb(252, 81, 60) !important;
}

.color-87-21 {
  background: rgb(252, 83, 60) !important;
}

.color-87-22 {
  background: rgb(252, 86, 60) !important;
}

.color-87-23 {
  background: rgb(252, 88, 60) !important;
}

.color-87-24 {
  background: rgb(252, 91, 60) !important;
}

.color-87-25 {
  background: rgb(252, 93, 60) !important;
}

.color-87-26 {
  background: rgb(252, 96, 60) !important;
}

.color-87-27 {
  background: rgb(252, 99, 60) !important;
}

.color-87-28 {
  background: rgb(252, 101, 60) !important;
}

.color-87-29 {
  background: rgb(252, 104, 60) !important;
}

.color-87-30 {
  background: rgb(252, 106, 60) !important;
}

.color-87-31 {
  background: rgb(252, 109, 60) !important;
}

.color-87-32 {
  background: rgb(252, 111, 60) !important;
}

.color-87-33 {
  background: rgb(252, 114, 60) !important;
}

.color-87-34 {
  background: rgb(252, 116, 60) !important;
}

.color-87-35 {
  background: rgb(252, 119, 60) !important;
}

.color-87-36 {
  background: rgb(252, 122, 60) !important;
}

.color-87-37 {
  background: rgb(252, 124, 60) !important;
}

.color-87-38 {
  background: rgb(252, 127, 60) !important;
}

.color-87-39 {
  background: rgb(252, 129, 60) !important;
}

.color-87-40 {
  background: rgb(252, 132, 60) !important;
}

.color-87-41 {
  background: rgb(252, 134, 60) !important;
}

.color-87-42 {
  background: rgb(252, 137, 60) !important;
}

.color-87-43 {
  background: rgb(252, 139, 60) !important;
}

.color-87-44 {
  background: rgb(252, 142, 60) !important;
}

.color-87-45 {
  background: rgb(252, 145, 60) !important;
}

.color-87-46 {
  background: rgb(252, 147, 60) !important;
}

.color-87-47 {
  background: rgb(252, 150, 60) !important;
}

.color-87-48 {
  background: rgb(252, 152, 60) !important;
}

.color-87-49 {
  background: rgb(252, 155, 60) !important;
}

.color-87-50 {
  background: rgb(252, 157, 60) !important;
}

.color-87-51 {
  background: rgb(252, 160, 60) !important;
}

.color-87-52 {
  background: rgb(252, 162, 60) !important;
}

.color-87-53 {
  background: rgb(252, 165, 60) !important;
}

.color-87-54 {
  background: rgb(252, 168, 60) !important;
}

.color-87-55 {
  background: rgb(252, 170, 60) !important;
}

.color-87-56 {
  background: rgb(252, 173, 60) !important;
}

.color-87-57 {
  background: rgb(252, 175, 60) !important;
}

.color-87-58 {
  background: rgb(252, 178, 60) !important;
}

.color-87-59 {
  background: rgb(252, 180, 60) !important;
}

.color-87-60 {
  background: rgb(252, 183, 60) !important;
}

.color-87-61 {
  background: rgb(252, 185, 60) !important;
}

.color-87-62 {
  background: rgb(252, 188, 60) !important;
}

.color-87-63 {
  background: rgb(252, 191, 60) !important;
}

.color-87-64 {
  background: rgb(252, 193, 60) !important;
}

.color-87-65 {
  background: rgb(252, 196, 60) !important;
}

.color-87-66 {
  background: rgb(252, 198, 60) !important;
}

.color-87-67 {
  background: rgb(252, 201, 60) !important;
}

.color-87-68 {
  background: rgb(252, 203, 60) !important;
}

.color-87-69 {
  background: rgb(252, 206, 60) !important;
}

.color-87-70 {
  background: rgb(252, 209, 60) !important;
}

.color-87-71 {
  background: rgb(252, 211, 60) !important;
}

.color-87-72 {
  background: rgb(252, 214, 60) !important;
}

.color-87-73 {
  background: rgb(252, 216, 60) !important;
}

.color-87-74 {
  background: rgb(252, 219, 60) !important;
}

.color-87-75 {
  background: rgb(252, 221, 60) !important;
}

.color-87-76 {
  background: rgb(252, 224, 60) !important;
}

.color-87-77 {
  background: rgb(252, 226, 60) !important;
}

.color-87-78 {
  background: rgb(252, 229, 60) !important;
}

.color-87-79 {
  background: rgb(252, 232, 60) !important;
}

.color-87-80 {
  background: rgb(252, 234, 60) !important;
}

.color-87-81 {
  background: rgb(252, 237, 60) !important;
}

.color-87-82 {
  background: rgb(252, 239, 60) !important;
}

.color-87-83 {
  background: rgb(252, 242, 60) !important;
}

.color-87-84 {
  background: rgb(252, 244, 60) !important;
}

.color-87-85 {
  background: rgb(252, 247, 60) !important;
}

.color-87-86 {
  background: rgb(252, 249, 60) !important;
}

.color-88-0 {
  background: rgb(140, 0, 28) !important;
}

.color-88-1 {
  background: rgb(252, 32, 60) !important;
}

.color-88-2 {
  background: rgb(252, 35, 60) !important;
}

.color-88-3 {
  background: rgb(252, 37, 60) !important;
}

.color-88-4 {
  background: rgb(252, 40, 60) !important;
}

.color-88-5 {
  background: rgb(252, 42, 60) !important;
}

.color-88-6 {
  background: rgb(252, 45, 60) !important;
}

.color-88-7 {
  background: rgb(252, 47, 60) !important;
}

.color-88-8 {
  background: rgb(252, 50, 60) !important;
}

.color-88-9 {
  background: rgb(252, 52, 60) !important;
}

.color-88-10 {
  background: rgb(252, 55, 60) !important;
}

.color-88-11 {
  background: rgb(252, 57, 60) !important;
}

.color-88-12 {
  background: rgb(252, 60, 60) !important;
}

.color-88-13 {
  background: rgb(252, 62, 60) !important;
}

.color-88-14 {
  background: rgb(252, 65, 60) !important;
}

.color-88-15 {
  background: rgb(252, 67, 60) !important;
}

.color-88-16 {
  background: rgb(252, 70, 60) !important;
}

.color-88-17 {
  background: rgb(252, 72, 60) !important;
}

.color-88-18 {
  background: rgb(252, 75, 60) !important;
}

.color-88-19 {
  background: rgb(252, 78, 60) !important;
}

.color-88-20 {
  background: rgb(252, 80, 60) !important;
}

.color-88-21 {
  background: rgb(252, 83, 60) !important;
}

.color-88-22 {
  background: rgb(252, 85, 60) !important;
}

.color-88-23 {
  background: rgb(252, 88, 60) !important;
}

.color-88-24 {
  background: rgb(252, 90, 60) !important;
}

.color-88-25 {
  background: rgb(252, 93, 60) !important;
}

.color-88-26 {
  background: rgb(252, 95, 60) !important;
}

.color-88-27 {
  background: rgb(252, 98, 60) !important;
}

.color-88-28 {
  background: rgb(252, 100, 60) !important;
}

.color-88-29 {
  background: rgb(252, 103, 60) !important;
}

.color-88-30 {
  background: rgb(252, 105, 60) !important;
}

.color-88-31 {
  background: rgb(252, 108, 60) !important;
}

.color-88-32 {
  background: rgb(252, 110, 60) !important;
}

.color-88-33 {
  background: rgb(252, 113, 60) !important;
}

.color-88-34 {
  background: rgb(252, 115, 60) !important;
}

.color-88-35 {
  background: rgb(252, 118, 60) !important;
}

.color-88-36 {
  background: rgb(252, 121, 60) !important;
}

.color-88-37 {
  background: rgb(252, 123, 60) !important;
}

.color-88-38 {
  background: rgb(252, 126, 60) !important;
}

.color-88-39 {
  background: rgb(252, 128, 60) !important;
}

.color-88-40 {
  background: rgb(252, 131, 60) !important;
}

.color-88-41 {
  background: rgb(252, 133, 60) !important;
}

.color-88-42 {
  background: rgb(252, 136, 60) !important;
}

.color-88-43 {
  background: rgb(252, 138, 60) !important;
}

.color-88-44 {
  background: rgb(252, 141, 60) !important;
}

.color-88-45 {
  background: rgb(252, 143, 60) !important;
}

.color-88-46 {
  background: rgb(252, 146, 60) !important;
}

.color-88-47 {
  background: rgb(252, 148, 60) !important;
}

.color-88-48 {
  background: rgb(252, 151, 60) !important;
}

.color-88-49 {
  background: rgb(252, 153, 60) !important;
}

.color-88-50 {
  background: rgb(252, 156, 60) !important;
}

.color-88-51 {
  background: rgb(252, 158, 60) !important;
}

.color-88-52 {
  background: rgb(252, 161, 60) !important;
}

.color-88-53 {
  background: rgb(252, 163, 60) !important;
}

.color-88-54 {
  background: rgb(252, 166, 60) !important;
}

.color-88-55 {
  background: rgb(252, 169, 60) !important;
}

.color-88-56 {
  background: rgb(252, 171, 60) !important;
}

.color-88-57 {
  background: rgb(252, 174, 60) !important;
}

.color-88-58 {
  background: rgb(252, 176, 60) !important;
}

.color-88-59 {
  background: rgb(252, 179, 60) !important;
}

.color-88-60 {
  background: rgb(252, 181, 60) !important;
}

.color-88-61 {
  background: rgb(252, 184, 60) !important;
}

.color-88-62 {
  background: rgb(252, 186, 60) !important;
}

.color-88-63 {
  background: rgb(252, 189, 60) !important;
}

.color-88-64 {
  background: rgb(252, 191, 60) !important;
}

.color-88-65 {
  background: rgb(252, 194, 60) !important;
}

.color-88-66 {
  background: rgb(252, 196, 60) !important;
}

.color-88-67 {
  background: rgb(252, 199, 60) !important;
}

.color-88-68 {
  background: rgb(252, 201, 60) !important;
}

.color-88-69 {
  background: rgb(252, 204, 60) !important;
}

.color-88-70 {
  background: rgb(252, 206, 60) !important;
}

.color-88-71 {
  background: rgb(252, 209, 60) !important;
}

.color-88-72 {
  background: rgb(252, 212, 60) !important;
}

.color-88-73 {
  background: rgb(252, 214, 60) !important;
}

.color-88-74 {
  background: rgb(252, 217, 60) !important;
}

.color-88-75 {
  background: rgb(252, 219, 60) !important;
}

.color-88-76 {
  background: rgb(252, 222, 60) !important;
}

.color-88-77 {
  background: rgb(252, 224, 60) !important;
}

.color-88-78 {
  background: rgb(252, 227, 60) !important;
}

.color-88-79 {
  background: rgb(252, 229, 60) !important;
}

.color-88-80 {
  background: rgb(252, 232, 60) !important;
}

.color-88-81 {
  background: rgb(252, 234, 60) !important;
}

.color-88-82 {
  background: rgb(252, 237, 60) !important;
}

.color-88-83 {
  background: rgb(252, 239, 60) !important;
}

.color-88-84 {
  background: rgb(252, 242, 60) !important;
}

.color-88-85 {
  background: rgb(252, 244, 60) !important;
}

.color-88-86 {
  background: rgb(252, 247, 60) !important;
}

.color-88-87 {
  background: rgb(252, 249, 60) !important;
}

.color-89-0 {
  background: rgb(140, 0, 28) !important;
}

.color-89-1 {
  background: rgb(252, 32, 60) !important;
}

.color-89-2 {
  background: rgb(252, 35, 60) !important;
}

.color-89-3 {
  background: rgb(252, 37, 60) !important;
}

.color-89-4 {
  background: rgb(252, 40, 60) !important;
}

.color-89-5 {
  background: rgb(252, 42, 60) !important;
}

.color-89-6 {
  background: rgb(252, 45, 60) !important;
}

.color-89-7 {
  background: rgb(252, 47, 60) !important;
}

.color-89-8 {
  background: rgb(252, 50, 60) !important;
}

.color-89-9 {
  background: rgb(252, 52, 60) !important;
}

.color-89-10 {
  background: rgb(252, 55, 60) !important;
}

.color-89-11 {
  background: rgb(252, 57, 60) !important;
}

.color-89-12 {
  background: rgb(252, 60, 60) !important;
}

.color-89-13 {
  background: rgb(252, 62, 60) !important;
}

.color-89-14 {
  background: rgb(252, 65, 60) !important;
}

.color-89-15 {
  background: rgb(252, 67, 60) !important;
}

.color-89-16 {
  background: rgb(252, 70, 60) !important;
}

.color-89-17 {
  background: rgb(252, 72, 60) !important;
}

.color-89-18 {
  background: rgb(252, 75, 60) !important;
}

.color-89-19 {
  background: rgb(252, 77, 60) !important;
}

.color-89-20 {
  background: rgb(252, 80, 60) !important;
}

.color-89-21 {
  background: rgb(252, 82, 60) !important;
}

.color-89-22 {
  background: rgb(252, 85, 60) !important;
}

.color-89-23 {
  background: rgb(252, 87, 60) !important;
}

.color-89-24 {
  background: rgb(252, 90, 60) !important;
}

.color-89-25 {
  background: rgb(252, 92, 60) !important;
}

.color-89-26 {
  background: rgb(252, 95, 60) !important;
}

.color-89-27 {
  background: rgb(252, 97, 60) !important;
}

.color-89-28 {
  background: rgb(252, 100, 60) !important;
}

.color-89-29 {
  background: rgb(252, 102, 60) !important;
}

.color-89-30 {
  background: rgb(252, 105, 60) !important;
}

.color-89-31 {
  background: rgb(252, 107, 60) !important;
}

.color-89-32 {
  background: rgb(252, 110, 60) !important;
}

.color-89-33 {
  background: rgb(252, 112, 60) !important;
}

.color-89-34 {
  background: rgb(252, 115, 60) !important;
}

.color-89-35 {
  background: rgb(252, 117, 60) !important;
}

.color-89-36 {
  background: rgb(252, 120, 60) !important;
}

.color-89-37 {
  background: rgb(252, 122, 60) !important;
}

.color-89-38 {
  background: rgb(252, 125, 60) !important;
}

.color-89-39 {
  background: rgb(252, 127, 60) !important;
}

.color-89-40 {
  background: rgb(252, 130, 60) !important;
}

.color-89-41 {
  background: rgb(252, 132, 60) !important;
}

.color-89-42 {
  background: rgb(252, 135, 60) !important;
}

.color-89-43 {
  background: rgb(252, 137, 60) !important;
}

.color-89-44 {
  background: rgb(252, 140, 60) !important;
}

.color-89-45 {
  background: rgb(252, 142, 60) !important;
}

.color-89-46 {
  background: rgb(252, 145, 60) !important;
}

.color-89-47 {
  background: rgb(252, 147, 60) !important;
}

.color-89-48 {
  background: rgb(252, 150, 60) !important;
}

.color-89-49 {
  background: rgb(252, 152, 60) !important;
}

.color-89-50 {
  background: rgb(252, 155, 60) !important;
}

.color-89-51 {
  background: rgb(252, 157, 60) !important;
}

.color-89-52 {
  background: rgb(252, 160, 60) !important;
}

.color-89-53 {
  background: rgb(252, 162, 60) !important;
}

.color-89-54 {
  background: rgb(252, 165, 60) !important;
}

.color-89-55 {
  background: rgb(252, 167, 60) !important;
}

.color-89-56 {
  background: rgb(252, 170, 60) !important;
}

.color-89-57 {
  background: rgb(252, 172, 60) !important;
}

.color-89-58 {
  background: rgb(252, 175, 60) !important;
}

.color-89-59 {
  background: rgb(252, 177, 60) !important;
}

.color-89-60 {
  background: rgb(252, 180, 60) !important;
}

.color-89-61 {
  background: rgb(252, 182, 60) !important;
}

.color-89-62 {
  background: rgb(252, 185, 60) !important;
}

.color-89-63 {
  background: rgb(252, 187, 60) !important;
}

.color-89-64 {
  background: rgb(252, 190, 60) !important;
}

.color-89-65 {
  background: rgb(252, 192, 60) !important;
}

.color-89-66 {
  background: rgb(252, 195, 60) !important;
}

.color-89-67 {
  background: rgb(252, 197, 60) !important;
}

.color-89-68 {
  background: rgb(252, 200, 60) !important;
}

.color-89-69 {
  background: rgb(252, 202, 60) !important;
}

.color-89-70 {
  background: rgb(252, 205, 60) !important;
}

.color-89-71 {
  background: rgb(252, 207, 60) !important;
}

.color-89-72 {
  background: rgb(252, 210, 60) !important;
}

.color-89-73 {
  background: rgb(252, 212, 60) !important;
}

.color-89-74 {
  background: rgb(252, 215, 60) !important;
}

.color-89-75 {
  background: rgb(252, 217, 60) !important;
}

.color-89-76 {
  background: rgb(252, 220, 60) !important;
}

.color-89-77 {
  background: rgb(252, 222, 60) !important;
}

.color-89-78 {
  background: rgb(252, 225, 60) !important;
}

.color-89-79 {
  background: rgb(252, 227, 60) !important;
}

.color-89-80 {
  background: rgb(252, 230, 60) !important;
}

.color-89-81 {
  background: rgb(252, 232, 60) !important;
}

.color-89-82 {
  background: rgb(252, 235, 60) !important;
}

.color-89-83 {
  background: rgb(252, 237, 60) !important;
}

.color-89-84 {
  background: rgb(252, 240, 60) !important;
}

.color-89-85 {
  background: rgb(252, 242, 60) !important;
}

.color-89-86 {
  background: rgb(252, 245, 60) !important;
}

.color-89-87 {
  background: rgb(252, 247, 60) !important;
}

.color-89-88 {
  background: rgb(252, 250, 60) !important;
}

.color-90-0 {
  background: rgb(140, 0, 28) !important;
}

.color-90-1 {
  background: rgb(252, 32, 60) !important;
}

.color-90-2 {
  background: rgb(252, 34, 60) !important;
}

.color-90-3 {
  background: rgb(252, 37, 60) !important;
}

.color-90-4 {
  background: rgb(252, 39, 60) !important;
}

.color-90-5 {
  background: rgb(252, 42, 60) !important;
}

.color-90-6 {
  background: rgb(252, 44, 60) !important;
}

.color-90-7 {
  background: rgb(252, 47, 60) !important;
}

.color-90-8 {
  background: rgb(252, 49, 60) !important;
}

.color-90-9 {
  background: rgb(252, 52, 60) !important;
}

.color-90-10 {
  background: rgb(252, 54, 60) !important;
}

.color-90-11 {
  background: rgb(252, 57, 60) !important;
}

.color-90-12 {
  background: rgb(252, 59, 60) !important;
}

.color-90-13 {
  background: rgb(252, 62, 60) !important;
}

.color-90-14 {
  background: rgb(252, 64, 60) !important;
}

.color-90-15 {
  background: rgb(252, 67, 60) !important;
}

.color-90-16 {
  background: rgb(252, 69, 60) !important;
}

.color-90-17 {
  background: rgb(252, 72, 60) !important;
}

.color-90-18 {
  background: rgb(252, 74, 60) !important;
}

.color-90-19 {
  background: rgb(252, 76, 60) !important;
}

.color-90-20 {
  background: rgb(252, 79, 60) !important;
}

.color-90-21 {
  background: rgb(252, 81, 60) !important;
}

.color-90-22 {
  background: rgb(252, 84, 60) !important;
}

.color-90-23 {
  background: rgb(252, 86, 60) !important;
}

.color-90-24 {
  background: rgb(252, 89, 60) !important;
}

.color-90-25 {
  background: rgb(252, 91, 60) !important;
}

.color-90-26 {
  background: rgb(252, 94, 60) !important;
}

.color-90-27 {
  background: rgb(252, 96, 60) !important;
}

.color-90-28 {
  background: rgb(252, 99, 60) !important;
}

.color-90-29 {
  background: rgb(252, 101, 60) !important;
}

.color-90-30 {
  background: rgb(252, 104, 60) !important;
}

.color-90-31 {
  background: rgb(252, 106, 60) !important;
}

.color-90-32 {
  background: rgb(252, 109, 60) !important;
}

.color-90-33 {
  background: rgb(252, 111, 60) !important;
}

.color-90-34 {
  background: rgb(252, 114, 60) !important;
}

.color-90-35 {
  background: rgb(252, 116, 60) !important;
}

.color-90-36 {
  background: rgb(252, 119, 60) !important;
}

.color-90-37 {
  background: rgb(252, 121, 60) !important;
}

.color-90-38 {
  background: rgb(252, 123, 60) !important;
}

.color-90-39 {
  background: rgb(252, 126, 60) !important;
}

.color-90-40 {
  background: rgb(252, 128, 60) !important;
}

.color-90-41 {
  background: rgb(252, 131, 60) !important;
}

.color-90-42 {
  background: rgb(252, 133, 60) !important;
}

.color-90-43 {
  background: rgb(252, 136, 60) !important;
}

.color-90-44 {
  background: rgb(252, 138, 60) !important;
}

.color-90-45 {
  background: rgb(252, 141, 60) !important;
}

.color-90-46 {
  background: rgb(252, 143, 60) !important;
}

.color-90-47 {
  background: rgb(252, 146, 60) !important;
}

.color-90-48 {
  background: rgb(252, 148, 60) !important;
}

.color-90-49 {
  background: rgb(252, 151, 60) !important;
}

.color-90-50 {
  background: rgb(252, 153, 60) !important;
}

.color-90-51 {
  background: rgb(252, 156, 60) !important;
}

.color-90-52 {
  background: rgb(252, 158, 60) !important;
}

.color-90-53 {
  background: rgb(252, 161, 60) !important;
}

.color-90-54 {
  background: rgb(252, 163, 60) !important;
}

.color-90-55 {
  background: rgb(252, 165, 60) !important;
}

.color-90-56 {
  background: rgb(252, 168, 60) !important;
}

.color-90-57 {
  background: rgb(252, 170, 60) !important;
}

.color-90-58 {
  background: rgb(252, 173, 60) !important;
}

.color-90-59 {
  background: rgb(252, 175, 60) !important;
}

.color-90-60 {
  background: rgb(252, 178, 60) !important;
}

.color-90-61 {
  background: rgb(252, 180, 60) !important;
}

.color-90-62 {
  background: rgb(252, 183, 60) !important;
}

.color-90-63 {
  background: rgb(252, 185, 60) !important;
}

.color-90-64 {
  background: rgb(252, 188, 60) !important;
}

.color-90-65 {
  background: rgb(252, 190, 60) !important;
}

.color-90-66 {
  background: rgb(252, 193, 60) !important;
}

.color-90-67 {
  background: rgb(252, 195, 60) !important;
}

.color-90-68 {
  background: rgb(252, 198, 60) !important;
}

.color-90-69 {
  background: rgb(252, 200, 60) !important;
}

.color-90-70 {
  background: rgb(252, 203, 60) !important;
}

.color-90-71 {
  background: rgb(252, 205, 60) !important;
}

.color-90-72 {
  background: rgb(252, 208, 60) !important;
}

.color-90-73 {
  background: rgb(252, 210, 60) !important;
}

.color-90-74 {
  background: rgb(252, 212, 60) !important;
}

.color-90-75 {
  background: rgb(252, 215, 60) !important;
}

.color-90-76 {
  background: rgb(252, 217, 60) !important;
}

.color-90-77 {
  background: rgb(252, 220, 60) !important;
}

.color-90-78 {
  background: rgb(252, 222, 60) !important;
}

.color-90-79 {
  background: rgb(252, 225, 60) !important;
}

.color-90-80 {
  background: rgb(252, 227, 60) !important;
}

.color-90-81 {
  background: rgb(252, 230, 60) !important;
}

.color-90-82 {
  background: rgb(252, 232, 60) !important;
}

.color-90-83 {
  background: rgb(252, 235, 60) !important;
}

.color-90-84 {
  background: rgb(252, 237, 60) !important;
}

.color-90-85 {
  background: rgb(252, 240, 60) !important;
}

.color-90-86 {
  background: rgb(252, 242, 60) !important;
}

.color-90-87 {
  background: rgb(252, 245, 60) !important;
}

.color-90-88 {
  background: rgb(252, 247, 60) !important;
}

.color-90-89 {
  background: rgb(252, 250, 60) !important;
}

.color-91-0 {
  background: rgb(140, 0, 28) !important;
}

.color-91-1 {
  background: rgb(252, 32, 60) !important;
}

.color-91-2 {
  background: rgb(252, 34, 60) !important;
}

.color-91-3 {
  background: rgb(252, 37, 60) !important;
}

.color-91-4 {
  background: rgb(252, 39, 60) !important;
}

.color-91-5 {
  background: rgb(252, 42, 60) !important;
}

.color-91-6 {
  background: rgb(252, 44, 60) !important;
}

.color-91-7 {
  background: rgb(252, 47, 60) !important;
}

.color-91-8 {
  background: rgb(252, 49, 60) !important;
}

.color-91-9 {
  background: rgb(252, 52, 60) !important;
}

.color-91-10 {
  background: rgb(252, 54, 60) !important;
}

.color-91-11 {
  background: rgb(252, 56, 60) !important;
}

.color-91-12 {
  background: rgb(252, 59, 60) !important;
}

.color-91-13 {
  background: rgb(252, 61, 60) !important;
}

.color-91-14 {
  background: rgb(252, 64, 60) !important;
}

.color-91-15 {
  background: rgb(252, 66, 60) !important;
}

.color-91-16 {
  background: rgb(252, 69, 60) !important;
}

.color-91-17 {
  background: rgb(252, 71, 60) !important;
}

.color-91-18 {
  background: rgb(252, 74, 60) !important;
}

.color-91-19 {
  background: rgb(252, 76, 60) !important;
}

.color-91-20 {
  background: rgb(252, 78, 60) !important;
}

.color-91-21 {
  background: rgb(252, 81, 60) !important;
}

.color-91-22 {
  background: rgb(252, 83, 60) !important;
}

.color-91-23 {
  background: rgb(252, 86, 60) !important;
}

.color-91-24 {
  background: rgb(252, 88, 60) !important;
}

.color-91-25 {
  background: rgb(252, 91, 60) !important;
}

.color-91-26 {
  background: rgb(252, 93, 60) !important;
}

.color-91-27 {
  background: rgb(252, 96, 60) !important;
}

.color-91-28 {
  background: rgb(252, 98, 60) !important;
}

.color-91-29 {
  background: rgb(252, 100, 60) !important;
}

.color-91-30 {
  background: rgb(252, 103, 60) !important;
}

.color-91-31 {
  background: rgb(252, 105, 60) !important;
}

.color-91-32 {
  background: rgb(252, 108, 60) !important;
}

.color-91-33 {
  background: rgb(252, 110, 60) !important;
}

.color-91-34 {
  background: rgb(252, 113, 60) !important;
}

.color-91-35 {
  background: rgb(252, 115, 60) !important;
}

.color-91-36 {
  background: rgb(252, 118, 60) !important;
}

.color-91-37 {
  background: rgb(252, 120, 60) !important;
}

.color-91-38 {
  background: rgb(252, 122, 60) !important;
}

.color-91-39 {
  background: rgb(252, 125, 60) !important;
}

.color-91-40 {
  background: rgb(252, 127, 60) !important;
}

.color-91-41 {
  background: rgb(252, 130, 60) !important;
}

.color-91-42 {
  background: rgb(252, 132, 60) !important;
}

.color-91-43 {
  background: rgb(252, 135, 60) !important;
}

.color-91-44 {
  background: rgb(252, 137, 60) !important;
}

.color-91-45 {
  background: rgb(252, 140, 60) !important;
}

.color-91-46 {
  background: rgb(252, 142, 60) !important;
}

.color-91-47 {
  background: rgb(252, 144, 60) !important;
}

.color-91-48 {
  background: rgb(252, 147, 60) !important;
}

.color-91-49 {
  background: rgb(252, 149, 60) !important;
}

.color-91-50 {
  background: rgb(252, 152, 60) !important;
}

.color-91-51 {
  background: rgb(252, 154, 60) !important;
}

.color-91-52 {
  background: rgb(252, 157, 60) !important;
}

.color-91-53 {
  background: rgb(252, 159, 60) !important;
}

.color-91-54 {
  background: rgb(252, 162, 60) !important;
}

.color-91-55 {
  background: rgb(252, 164, 60) !important;
}

.color-91-56 {
  background: rgb(252, 166, 60) !important;
}

.color-91-57 {
  background: rgb(252, 169, 60) !important;
}

.color-91-58 {
  background: rgb(252, 171, 60) !important;
}

.color-91-59 {
  background: rgb(252, 174, 60) !important;
}

.color-91-60 {
  background: rgb(252, 176, 60) !important;
}

.color-91-61 {
  background: rgb(252, 179, 60) !important;
}

.color-91-62 {
  background: rgb(252, 181, 60) !important;
}

.color-91-63 {
  background: rgb(252, 184, 60) !important;
}

.color-91-64 {
  background: rgb(252, 186, 60) !important;
}

.color-91-65 {
  background: rgb(252, 188, 60) !important;
}

.color-91-66 {
  background: rgb(252, 191, 60) !important;
}

.color-91-67 {
  background: rgb(252, 193, 60) !important;
}

.color-91-68 {
  background: rgb(252, 196, 60) !important;
}

.color-91-69 {
  background: rgb(252, 198, 60) !important;
}

.color-91-70 {
  background: rgb(252, 201, 60) !important;
}

.color-91-71 {
  background: rgb(252, 203, 60) !important;
}

.color-91-72 {
  background: rgb(252, 206, 60) !important;
}

.color-91-73 {
  background: rgb(252, 208, 60) !important;
}

.color-91-74 {
  background: rgb(252, 210, 60) !important;
}

.color-91-75 {
  background: rgb(252, 213, 60) !important;
}

.color-91-76 {
  background: rgb(252, 215, 60) !important;
}

.color-91-77 {
  background: rgb(252, 218, 60) !important;
}

.color-91-78 {
  background: rgb(252, 220, 60) !important;
}

.color-91-79 {
  background: rgb(252, 223, 60) !important;
}

.color-91-80 {
  background: rgb(252, 225, 60) !important;
}

.color-91-81 {
  background: rgb(252, 228, 60) !important;
}

.color-91-82 {
  background: rgb(252, 230, 60) !important;
}

.color-91-83 {
  background: rgb(252, 232, 60) !important;
}

.color-91-84 {
  background: rgb(252, 235, 60) !important;
}

.color-91-85 {
  background: rgb(252, 237, 60) !important;
}

.color-91-86 {
  background: rgb(252, 240, 60) !important;
}

.color-91-87 {
  background: rgb(252, 242, 60) !important;
}

.color-91-88 {
  background: rgb(252, 245, 60) !important;
}

.color-91-89 {
  background: rgb(252, 247, 60) !important;
}

.color-91-90 {
  background: rgb(252, 250, 60) !important;
}

.color-92-0 {
  background: rgb(140, 0, 28) !important;
}

.color-92-1 {
  background: rgb(252, 32, 60) !important;
}

.color-92-2 {
  background: rgb(252, 34, 60) !important;
}

.color-92-3 {
  background: rgb(252, 37, 60) !important;
}

.color-92-4 {
  background: rgb(252, 39, 60) !important;
}

.color-92-5 {
  background: rgb(252, 42, 60) !important;
}

.color-92-6 {
  background: rgb(252, 44, 60) !important;
}

.color-92-7 {
  background: rgb(252, 47, 60) !important;
}

.color-92-8 {
  background: rgb(252, 49, 60) !important;
}

.color-92-9 {
  background: rgb(252, 51, 60) !important;
}

.color-92-10 {
  background: rgb(252, 54, 60) !important;
}

.color-92-11 {
  background: rgb(252, 56, 60) !important;
}

.color-92-12 {
  background: rgb(252, 59, 60) !important;
}

.color-92-13 {
  background: rgb(252, 61, 60) !important;
}

.color-92-14 {
  background: rgb(252, 63, 60) !important;
}

.color-92-15 {
  background: rgb(252, 66, 60) !important;
}

.color-92-16 {
  background: rgb(252, 68, 60) !important;
}

.color-92-17 {
  background: rgb(252, 71, 60) !important;
}

.color-92-18 {
  background: rgb(252, 73, 60) !important;
}

.color-92-19 {
  background: rgb(252, 76, 60) !important;
}

.color-92-20 {
  background: rgb(252, 78, 60) !important;
}

.color-92-21 {
  background: rgb(252, 80, 60) !important;
}

.color-92-22 {
  background: rgb(252, 83, 60) !important;
}

.color-92-23 {
  background: rgb(252, 85, 60) !important;
}

.color-92-24 {
  background: rgb(252, 88, 60) !important;
}

.color-92-25 {
  background: rgb(252, 90, 60) !important;
}

.color-92-26 {
  background: rgb(252, 92, 60) !important;
}

.color-92-27 {
  background: rgb(252, 95, 60) !important;
}

.color-92-28 {
  background: rgb(252, 97, 60) !important;
}

.color-92-29 {
  background: rgb(252, 100, 60) !important;
}

.color-92-30 {
  background: rgb(252, 102, 60) !important;
}

.color-92-31 {
  background: rgb(252, 105, 60) !important;
}

.color-92-32 {
  background: rgb(252, 107, 60) !important;
}

.color-92-33 {
  background: rgb(252, 109, 60) !important;
}

.color-92-34 {
  background: rgb(252, 112, 60) !important;
}

.color-92-35 {
  background: rgb(252, 114, 60) !important;
}

.color-92-36 {
  background: rgb(252, 117, 60) !important;
}

.color-92-37 {
  background: rgb(252, 119, 60) !important;
}

.color-92-38 {
  background: rgb(252, 121, 60) !important;
}

.color-92-39 {
  background: rgb(252, 124, 60) !important;
}

.color-92-40 {
  background: rgb(252, 126, 60) !important;
}

.color-92-41 {
  background: rgb(252, 129, 60) !important;
}

.color-92-42 {
  background: rgb(252, 131, 60) !important;
}

.color-92-43 {
  background: rgb(252, 134, 60) !important;
}

.color-92-44 {
  background: rgb(252, 136, 60) !important;
}

.color-92-45 {
  background: rgb(252, 138, 60) !important;
}

.color-92-46 {
  background: rgb(252, 141, 60) !important;
}

.color-92-47 {
  background: rgb(252, 143, 60) !important;
}

.color-92-48 {
  background: rgb(252, 146, 60) !important;
}

.color-92-49 {
  background: rgb(252, 148, 60) !important;
}

.color-92-50 {
  background: rgb(252, 150, 60) !important;
}

.color-92-51 {
  background: rgb(252, 153, 60) !important;
}

.color-92-52 {
  background: rgb(252, 155, 60) !important;
}

.color-92-53 {
  background: rgb(252, 158, 60) !important;
}

.color-92-54 {
  background: rgb(252, 160, 60) !important;
}

.color-92-55 {
  background: rgb(252, 163, 60) !important;
}

.color-92-56 {
  background: rgb(252, 165, 60) !important;
}

.color-92-57 {
  background: rgb(252, 167, 60) !important;
}

.color-92-58 {
  background: rgb(252, 170, 60) !important;
}

.color-92-59 {
  background: rgb(252, 172, 60) !important;
}

.color-92-60 {
  background: rgb(252, 175, 60) !important;
}

.color-92-61 {
  background: rgb(252, 177, 60) !important;
}

.color-92-62 {
  background: rgb(252, 179, 60) !important;
}

.color-92-63 {
  background: rgb(252, 182, 60) !important;
}

.color-92-64 {
  background: rgb(252, 184, 60) !important;
}

.color-92-65 {
  background: rgb(252, 187, 60) !important;
}

.color-92-66 {
  background: rgb(252, 189, 60) !important;
}

.color-92-67 {
  background: rgb(252, 192, 60) !important;
}

.color-92-68 {
  background: rgb(252, 194, 60) !important;
}

.color-92-69 {
  background: rgb(252, 196, 60) !important;
}

.color-92-70 {
  background: rgb(252, 199, 60) !important;
}

.color-92-71 {
  background: rgb(252, 201, 60) !important;
}

.color-92-72 {
  background: rgb(252, 204, 60) !important;
}

.color-92-73 {
  background: rgb(252, 206, 60) !important;
}

.color-92-74 {
  background: rgb(252, 208, 60) !important;
}

.color-92-75 {
  background: rgb(252, 211, 60) !important;
}

.color-92-76 {
  background: rgb(252, 213, 60) !important;
}

.color-92-77 {
  background: rgb(252, 216, 60) !important;
}

.color-92-78 {
  background: rgb(252, 218, 60) !important;
}

.color-92-79 {
  background: rgb(252, 221, 60) !important;
}

.color-92-80 {
  background: rgb(252, 223, 60) !important;
}

.color-92-81 {
  background: rgb(252, 225, 60) !important;
}

.color-92-82 {
  background: rgb(252, 228, 60) !important;
}

.color-92-83 {
  background: rgb(252, 230, 60) !important;
}

.color-92-84 {
  background: rgb(252, 233, 60) !important;
}

.color-92-85 {
  background: rgb(252, 235, 60) !important;
}

.color-92-86 {
  background: rgb(252, 237, 60) !important;
}

.color-92-87 {
  background: rgb(252, 240, 60) !important;
}

.color-92-88 {
  background: rgb(252, 242, 60) !important;
}

.color-92-89 {
  background: rgb(252, 245, 60) !important;
}

.color-92-90 {
  background: rgb(252, 247, 60) !important;
}

.color-92-91 {
  background: rgb(252, 250, 60) !important;
}

.color-93-0 {
  background: rgb(140, 0, 28) !important;
}

.color-93-1 {
  background: rgb(252, 32, 60) !important;
}

.color-93-2 {
  background: rgb(252, 34, 60) !important;
}

.color-93-3 {
  background: rgb(252, 37, 60) !important;
}

.color-93-4 {
  background: rgb(252, 39, 60) !important;
}

.color-93-5 {
  background: rgb(252, 42, 60) !important;
}

.color-93-6 {
  background: rgb(252, 44, 60) !important;
}

.color-93-7 {
  background: rgb(252, 46, 60) !important;
}

.color-93-8 {
  background: rgb(252, 49, 60) !important;
}

.color-93-9 {
  background: rgb(252, 51, 60) !important;
}

.color-93-10 {
  background: rgb(252, 54, 60) !important;
}

.color-93-11 {
  background: rgb(252, 56, 60) !important;
}

.color-93-12 {
  background: rgb(252, 58, 60) !important;
}

.color-93-13 {
  background: rgb(252, 61, 60) !important;
}

.color-93-14 {
  background: rgb(252, 63, 60) !important;
}

.color-93-15 {
  background: rgb(252, 65, 60) !important;
}

.color-93-16 {
  background: rgb(252, 68, 60) !important;
}

.color-93-17 {
  background: rgb(252, 70, 60) !important;
}

.color-93-18 {
  background: rgb(252, 73, 60) !important;
}

.color-93-19 {
  background: rgb(252, 75, 60) !important;
}

.color-93-20 {
  background: rgb(252, 77, 60) !important;
}

.color-93-21 {
  background: rgb(252, 80, 60) !important;
}

.color-93-22 {
  background: rgb(252, 82, 60) !important;
}

.color-93-23 {
  background: rgb(252, 85, 60) !important;
}

.color-93-24 {
  background: rgb(252, 87, 60) !important;
}

.color-93-25 {
  background: rgb(252, 89, 60) !important;
}

.color-93-26 {
  background: rgb(252, 92, 60) !important;
}

.color-93-27 {
  background: rgb(252, 94, 60) !important;
}

.color-93-28 {
  background: rgb(252, 97, 60) !important;
}

.color-93-29 {
  background: rgb(252, 99, 60) !important;
}

.color-93-30 {
  background: rgb(252, 101, 60) !important;
}

.color-93-31 {
  background: rgb(252, 104, 60) !important;
}

.color-93-32 {
  background: rgb(252, 106, 60) !important;
}

.color-93-33 {
  background: rgb(252, 109, 60) !important;
}

.color-93-34 {
  background: rgb(252, 111, 60) !important;
}

.color-93-35 {
  background: rgb(252, 113, 60) !important;
}

.color-93-36 {
  background: rgb(252, 116, 60) !important;
}

.color-93-37 {
  background: rgb(252, 118, 60) !important;
}

.color-93-38 {
  background: rgb(252, 120, 60) !important;
}

.color-93-39 {
  background: rgb(252, 123, 60) !important;
}

.color-93-40 {
  background: rgb(252, 125, 60) !important;
}

.color-93-41 {
  background: rgb(252, 128, 60) !important;
}

.color-93-42 {
  background: rgb(252, 130, 60) !important;
}

.color-93-43 {
  background: rgb(252, 132, 60) !important;
}

.color-93-44 {
  background: rgb(252, 135, 60) !important;
}

.color-93-45 {
  background: rgb(252, 137, 60) !important;
}

.color-93-46 {
  background: rgb(252, 140, 60) !important;
}

.color-93-47 {
  background: rgb(252, 142, 60) !important;
}

.color-93-48 {
  background: rgb(252, 144, 60) !important;
}

.color-93-49 {
  background: rgb(252, 147, 60) !important;
}

.color-93-50 {
  background: rgb(252, 149, 60) !important;
}

.color-93-51 {
  background: rgb(252, 152, 60) !important;
}

.color-93-52 {
  background: rgb(252, 154, 60) !important;
}

.color-93-53 {
  background: rgb(252, 156, 60) !important;
}

.color-93-54 {
  background: rgb(252, 159, 60) !important;
}

.color-93-55 {
  background: rgb(252, 161, 60) !important;
}

.color-93-56 {
  background: rgb(252, 164, 60) !important;
}

.color-93-57 {
  background: rgb(252, 166, 60) !important;
}

.color-93-58 {
  background: rgb(252, 168, 60) !important;
}

.color-93-59 {
  background: rgb(252, 171, 60) !important;
}

.color-93-60 {
  background: rgb(252, 173, 60) !important;
}

.color-93-61 {
  background: rgb(252, 175, 60) !important;
}

.color-93-62 {
  background: rgb(252, 178, 60) !important;
}

.color-93-63 {
  background: rgb(252, 180, 60) !important;
}

.color-93-64 {
  background: rgb(252, 183, 60) !important;
}

.color-93-65 {
  background: rgb(252, 185, 60) !important;
}

.color-93-66 {
  background: rgb(252, 187, 60) !important;
}

.color-93-67 {
  background: rgb(252, 190, 60) !important;
}

.color-93-68 {
  background: rgb(252, 192, 60) !important;
}

.color-93-69 {
  background: rgb(252, 195, 60) !important;
}

.color-93-70 {
  background: rgb(252, 197, 60) !important;
}

.color-93-71 {
  background: rgb(252, 199, 60) !important;
}

.color-93-72 {
  background: rgb(252, 202, 60) !important;
}

.color-93-73 {
  background: rgb(252, 204, 60) !important;
}

.color-93-74 {
  background: rgb(252, 207, 60) !important;
}

.color-93-75 {
  background: rgb(252, 209, 60) !important;
}

.color-93-76 {
  background: rgb(252, 211, 60) !important;
}

.color-93-77 {
  background: rgb(252, 214, 60) !important;
}

.color-93-78 {
  background: rgb(252, 216, 60) !important;
}

.color-93-79 {
  background: rgb(252, 219, 60) !important;
}

.color-93-80 {
  background: rgb(252, 221, 60) !important;
}

.color-93-81 {
  background: rgb(252, 223, 60) !important;
}

.color-93-82 {
  background: rgb(252, 226, 60) !important;
}

.color-93-83 {
  background: rgb(252, 228, 60) !important;
}

.color-93-84 {
  background: rgb(252, 230, 60) !important;
}

.color-93-85 {
  background: rgb(252, 233, 60) !important;
}

.color-93-86 {
  background: rgb(252, 235, 60) !important;
}

.color-93-87 {
  background: rgb(252, 238, 60) !important;
}

.color-93-88 {
  background: rgb(252, 240, 60) !important;
}

.color-93-89 {
  background: rgb(252, 242, 60) !important;
}

.color-93-90 {
  background: rgb(252, 245, 60) !important;
}

.color-93-91 {
  background: rgb(252, 247, 60) !important;
}

.color-93-92 {
  background: rgb(252, 250, 60) !important;
}

.color-94-0 {
  background: rgb(140, 0, 28) !important;
}

.color-94-1 {
  background: rgb(252, 32, 60) !important;
}

.color-94-2 {
  background: rgb(252, 34, 60) !important;
}

.color-94-3 {
  background: rgb(252, 37, 60) !important;
}

.color-94-4 {
  background: rgb(252, 39, 60) !important;
}

.color-94-5 {
  background: rgb(252, 41, 60) !important;
}

.color-94-6 {
  background: rgb(252, 44, 60) !important;
}

.color-94-7 {
  background: rgb(252, 46, 60) !important;
}

.color-94-8 {
  background: rgb(252, 49, 60) !important;
}

.color-94-9 {
  background: rgb(252, 51, 60) !important;
}

.color-94-10 {
  background: rgb(252, 53, 60) !important;
}

.color-94-11 {
  background: rgb(252, 56, 60) !important;
}

.color-94-12 {
  background: rgb(252, 58, 60) !important;
}

.color-94-13 {
  background: rgb(252, 60, 60) !important;
}

.color-94-14 {
  background: rgb(252, 63, 60) !important;
}

.color-94-15 {
  background: rgb(252, 65, 60) !important;
}

.color-94-16 {
  background: rgb(252, 67, 60) !important;
}

.color-94-17 {
  background: rgb(252, 70, 60) !important;
}

.color-94-18 {
  background: rgb(252, 72, 60) !important;
}

.color-94-19 {
  background: rgb(252, 75, 60) !important;
}

.color-94-20 {
  background: rgb(252, 77, 60) !important;
}

.color-94-21 {
  background: rgb(252, 79, 60) !important;
}

.color-94-22 {
  background: rgb(252, 82, 60) !important;
}

.color-94-23 {
  background: rgb(252, 84, 60) !important;
}

.color-94-24 {
  background: rgb(252, 86, 60) !important;
}

.color-94-25 {
  background: rgb(252, 89, 60) !important;
}

.color-94-26 {
  background: rgb(252, 91, 60) !important;
}

.color-94-27 {
  background: rgb(252, 94, 60) !important;
}

.color-94-28 {
  background: rgb(252, 96, 60) !important;
}

.color-94-29 {
  background: rgb(252, 98, 60) !important;
}

.color-94-30 {
  background: rgb(252, 101, 60) !important;
}

.color-94-31 {
  background: rgb(252, 103, 60) !important;
}

.color-94-32 {
  background: rgb(252, 105, 60) !important;
}

.color-94-33 {
  background: rgb(252, 108, 60) !important;
}

.color-94-34 {
  background: rgb(252, 110, 60) !important;
}

.color-94-35 {
  background: rgb(252, 112, 60) !important;
}

.color-94-36 {
  background: rgb(252, 115, 60) !important;
}

.color-94-37 {
  background: rgb(252, 117, 60) !important;
}

.color-94-38 {
  background: rgb(252, 120, 60) !important;
}

.color-94-39 {
  background: rgb(252, 122, 60) !important;
}

.color-94-40 {
  background: rgb(252, 124, 60) !important;
}

.color-94-41 {
  background: rgb(252, 127, 60) !important;
}

.color-94-42 {
  background: rgb(252, 129, 60) !important;
}

.color-94-43 {
  background: rgb(252, 131, 60) !important;
}

.color-94-44 {
  background: rgb(252, 134, 60) !important;
}

.color-94-45 {
  background: rgb(252, 136, 60) !important;
}

.color-94-46 {
  background: rgb(252, 138, 60) !important;
}

.color-94-47 {
  background: rgb(252, 141, 60) !important;
}

.color-94-48 {
  background: rgb(252, 143, 60) !important;
}

.color-94-49 {
  background: rgb(252, 146, 60) !important;
}

.color-94-50 {
  background: rgb(252, 148, 60) !important;
}

.color-94-51 {
  background: rgb(252, 150, 60) !important;
}

.color-94-52 {
  background: rgb(252, 153, 60) !important;
}

.color-94-53 {
  background: rgb(252, 155, 60) !important;
}

.color-94-54 {
  background: rgb(252, 157, 60) !important;
}

.color-94-55 {
  background: rgb(252, 160, 60) !important;
}

.color-94-56 {
  background: rgb(252, 162, 60) !important;
}

.color-94-57 {
  background: rgb(252, 164, 60) !important;
}

.color-94-58 {
  background: rgb(252, 167, 60) !important;
}

.color-94-59 {
  background: rgb(252, 169, 60) !important;
}

.color-94-60 {
  background: rgb(252, 172, 60) !important;
}

.color-94-61 {
  background: rgb(252, 174, 60) !important;
}

.color-94-62 {
  background: rgb(252, 176, 60) !important;
}

.color-94-63 {
  background: rgb(252, 179, 60) !important;
}

.color-94-64 {
  background: rgb(252, 181, 60) !important;
}

.color-94-65 {
  background: rgb(252, 183, 60) !important;
}

.color-94-66 {
  background: rgb(252, 186, 60) !important;
}

.color-94-67 {
  background: rgb(252, 188, 60) !important;
}

.color-94-68 {
  background: rgb(252, 190, 60) !important;
}

.color-94-69 {
  background: rgb(252, 193, 60) !important;
}

.color-94-70 {
  background: rgb(252, 195, 60) !important;
}

.color-94-71 {
  background: rgb(252, 198, 60) !important;
}

.color-94-72 {
  background: rgb(252, 200, 60) !important;
}

.color-94-73 {
  background: rgb(252, 202, 60) !important;
}

.color-94-74 {
  background: rgb(252, 205, 60) !important;
}

.color-94-75 {
  background: rgb(252, 207, 60) !important;
}

.color-94-76 {
  background: rgb(252, 209, 60) !important;
}

.color-94-77 {
  background: rgb(252, 212, 60) !important;
}

.color-94-78 {
  background: rgb(252, 214, 60) !important;
}

.color-94-79 {
  background: rgb(252, 217, 60) !important;
}

.color-94-80 {
  background: rgb(252, 219, 60) !important;
}

.color-94-81 {
  background: rgb(252, 221, 60) !important;
}

.color-94-82 {
  background: rgb(252, 224, 60) !important;
}

.color-94-83 {
  background: rgb(252, 226, 60) !important;
}

.color-94-84 {
  background: rgb(252, 228, 60) !important;
}

.color-94-85 {
  background: rgb(252, 231, 60) !important;
}

.color-94-86 {
  background: rgb(252, 233, 60) !important;
}

.color-94-87 {
  background: rgb(252, 235, 60) !important;
}

.color-94-88 {
  background: rgb(252, 238, 60) !important;
}

.color-94-89 {
  background: rgb(252, 240, 60) !important;
}

.color-94-90 {
  background: rgb(252, 243, 60) !important;
}

.color-94-91 {
  background: rgb(252, 245, 60) !important;
}

.color-94-92 {
  background: rgb(252, 247, 60) !important;
}

.color-94-93 {
  background: rgb(252, 250, 60) !important;
}

.color-95-0 {
  background: rgb(140, 0, 28) !important;
}

.color-95-1 {
  background: rgb(252, 32, 60) !important;
}

.color-95-2 {
  background: rgb(252, 34, 60) !important;
}

.color-95-3 {
  background: rgb(252, 37, 60) !important;
}

.color-95-4 {
  background: rgb(252, 39, 60) !important;
}

.color-95-5 {
  background: rgb(252, 41, 60) !important;
}

.color-95-6 {
  background: rgb(252, 44, 60) !important;
}

.color-95-7 {
  background: rgb(252, 46, 60) !important;
}

.color-95-8 {
  background: rgb(252, 48, 60) !important;
}

.color-95-9 {
  background: rgb(252, 51, 60) !important;
}

.color-95-10 {
  background: rgb(252, 53, 60) !important;
}

.color-95-11 {
  background: rgb(252, 55, 60) !important;
}

.color-95-12 {
  background: rgb(252, 58, 60) !important;
}

.color-95-13 {
  background: rgb(252, 60, 60) !important;
}

.color-95-14 {
  background: rgb(252, 62, 60) !important;
}

.color-95-15 {
  background: rgb(252, 65, 60) !important;
}

.color-95-16 {
  background: rgb(252, 67, 60) !important;
}

.color-95-17 {
  background: rgb(252, 69, 60) !important;
}

.color-95-18 {
  background: rgb(252, 72, 60) !important;
}

.color-95-19 {
  background: rgb(252, 74, 60) !important;
}

.color-95-20 {
  background: rgb(252, 76, 60) !important;
}

.color-95-21 {
  background: rgb(252, 79, 60) !important;
}

.color-95-22 {
  background: rgb(252, 81, 60) !important;
}

.color-95-23 {
  background: rgb(252, 83, 60) !important;
}

.color-95-24 {
  background: rgb(252, 86, 60) !important;
}

.color-95-25 {
  background: rgb(252, 88, 60) !important;
}

.color-95-26 {
  background: rgb(252, 91, 60) !important;
}

.color-95-27 {
  background: rgb(252, 93, 60) !important;
}

.color-95-28 {
  background: rgb(252, 95, 60) !important;
}

.color-95-29 {
  background: rgb(252, 98, 60) !important;
}

.color-95-30 {
  background: rgb(252, 100, 60) !important;
}

.color-95-31 {
  background: rgb(252, 102, 60) !important;
}

.color-95-32 {
  background: rgb(252, 105, 60) !important;
}

.color-95-33 {
  background: rgb(252, 107, 60) !important;
}

.color-95-34 {
  background: rgb(252, 109, 60) !important;
}

.color-95-35 {
  background: rgb(252, 112, 60) !important;
}

.color-95-36 {
  background: rgb(252, 114, 60) !important;
}

.color-95-37 {
  background: rgb(252, 116, 60) !important;
}

.color-95-38 {
  background: rgb(252, 119, 60) !important;
}

.color-95-39 {
  background: rgb(252, 121, 60) !important;
}

.color-95-40 {
  background: rgb(252, 123, 60) !important;
}

.color-95-41 {
  background: rgb(252, 126, 60) !important;
}

.color-95-42 {
  background: rgb(252, 128, 60) !important;
}

.color-95-43 {
  background: rgb(252, 130, 60) !important;
}

.color-95-44 {
  background: rgb(252, 133, 60) !important;
}

.color-95-45 {
  background: rgb(252, 135, 60) !important;
}

.color-95-46 {
  background: rgb(252, 137, 60) !important;
}

.color-95-47 {
  background: rgb(252, 140, 60) !important;
}

.color-95-48 {
  background: rgb(252, 142, 60) !important;
}

.color-95-49 {
  background: rgb(252, 144, 60) !important;
}

.color-95-50 {
  background: rgb(252, 147, 60) !important;
}

.color-95-51 {
  background: rgb(252, 149, 60) !important;
}

.color-95-52 {
  background: rgb(252, 151, 60) !important;
}

.color-95-53 {
  background: rgb(252, 154, 60) !important;
}

.color-95-54 {
  background: rgb(252, 156, 60) !important;
}

.color-95-55 {
  background: rgb(252, 158, 60) !important;
}

.color-95-56 {
  background: rgb(252, 161, 60) !important;
}

.color-95-57 {
  background: rgb(252, 163, 60) !important;
}

.color-95-58 {
  background: rgb(252, 165, 60) !important;
}

.color-95-59 {
  background: rgb(252, 168, 60) !important;
}

.color-95-60 {
  background: rgb(252, 170, 60) !important;
}

.color-95-61 {
  background: rgb(252, 172, 60) !important;
}

.color-95-62 {
  background: rgb(252, 175, 60) !important;
}

.color-95-63 {
  background: rgb(252, 177, 60) !important;
}

.color-95-64 {
  background: rgb(252, 179, 60) !important;
}

.color-95-65 {
  background: rgb(252, 182, 60) !important;
}

.color-95-66 {
  background: rgb(252, 184, 60) !important;
}

.color-95-67 {
  background: rgb(252, 186, 60) !important;
}

.color-95-68 {
  background: rgb(252, 189, 60) !important;
}

.color-95-69 {
  background: rgb(252, 191, 60) !important;
}

.color-95-70 {
  background: rgb(252, 193, 60) !important;
}

.color-95-71 {
  background: rgb(252, 196, 60) !important;
}

.color-95-72 {
  background: rgb(252, 198, 60) !important;
}

.color-95-73 {
  background: rgb(252, 201, 60) !important;
}

.color-95-74 {
  background: rgb(252, 203, 60) !important;
}

.color-95-75 {
  background: rgb(252, 205, 60) !important;
}

.color-95-76 {
  background: rgb(252, 208, 60) !important;
}

.color-95-77 {
  background: rgb(252, 210, 60) !important;
}

.color-95-78 {
  background: rgb(252, 212, 60) !important;
}

.color-95-79 {
  background: rgb(252, 215, 60) !important;
}

.color-95-80 {
  background: rgb(252, 217, 60) !important;
}

.color-95-81 {
  background: rgb(252, 219, 60) !important;
}

.color-95-82 {
  background: rgb(252, 222, 60) !important;
}

.color-95-83 {
  background: rgb(252, 224, 60) !important;
}

.color-95-84 {
  background: rgb(252, 226, 60) !important;
}

.color-95-85 {
  background: rgb(252, 229, 60) !important;
}

.color-95-86 {
  background: rgb(252, 231, 60) !important;
}

.color-95-87 {
  background: rgb(252, 233, 60) !important;
}

.color-95-88 {
  background: rgb(252, 236, 60) !important;
}

.color-95-89 {
  background: rgb(252, 238, 60) !important;
}

.color-95-90 {
  background: rgb(252, 240, 60) !important;
}

.color-95-91 {
  background: rgb(252, 243, 60) !important;
}

.color-95-92 {
  background: rgb(252, 245, 60) !important;
}

.color-95-93 {
  background: rgb(252, 247, 60) !important;
}

.color-95-94 {
  background: rgb(252, 250, 60) !important;
}

.color-96-0 {
  background: rgb(140, 0, 28) !important;
}

.color-96-1 {
  background: rgb(252, 32, 60) !important;
}

.color-96-2 {
  background: rgb(252, 34, 60) !important;
}

.color-96-3 {
  background: rgb(252, 37, 60) !important;
}

.color-96-4 {
  background: rgb(252, 39, 60) !important;
}

.color-96-5 {
  background: rgb(252, 41, 60) !important;
}

.color-96-6 {
  background: rgb(252, 44, 60) !important;
}

.color-96-7 {
  background: rgb(252, 46, 60) !important;
}

.color-96-8 {
  background: rgb(252, 48, 60) !important;
}

.color-96-9 {
  background: rgb(252, 51, 60) !important;
}

.color-96-10 {
  background: rgb(252, 53, 60) !important;
}

.color-96-11 {
  background: rgb(252, 55, 60) !important;
}

.color-96-12 {
  background: rgb(252, 57, 60) !important;
}

.color-96-13 {
  background: rgb(252, 60, 60) !important;
}

.color-96-14 {
  background: rgb(252, 62, 60) !important;
}

.color-96-15 {
  background: rgb(252, 64, 60) !important;
}

.color-96-16 {
  background: rgb(252, 67, 60) !important;
}

.color-96-17 {
  background: rgb(252, 69, 60) !important;
}

.color-96-18 {
  background: rgb(252, 71, 60) !important;
}

.color-96-19 {
  background: rgb(252, 74, 60) !important;
}

.color-96-20 {
  background: rgb(252, 76, 60) !important;
}

.color-96-21 {
  background: rgb(252, 78, 60) !important;
}

.color-96-22 {
  background: rgb(252, 81, 60) !important;
}

.color-96-23 {
  background: rgb(252, 83, 60) !important;
}

.color-96-24 {
  background: rgb(252, 85, 60) !important;
}

.color-96-25 {
  background: rgb(252, 88, 60) !important;
}

.color-96-26 {
  background: rgb(252, 90, 60) !important;
}

.color-96-27 {
  background: rgb(252, 92, 60) !important;
}

.color-96-28 {
  background: rgb(252, 95, 60) !important;
}

.color-96-29 {
  background: rgb(252, 97, 60) !important;
}

.color-96-30 {
  background: rgb(252, 99, 60) !important;
}

.color-96-31 {
  background: rgb(252, 101, 60) !important;
}

.color-96-32 {
  background: rgb(252, 104, 60) !important;
}

.color-96-33 {
  background: rgb(252, 106, 60) !important;
}

.color-96-34 {
  background: rgb(252, 108, 60) !important;
}

.color-96-35 {
  background: rgb(252, 111, 60) !important;
}

.color-96-36 {
  background: rgb(252, 113, 60) !important;
}

.color-96-37 {
  background: rgb(252, 115, 60) !important;
}

.color-96-38 {
  background: rgb(252, 118, 60) !important;
}

.color-96-39 {
  background: rgb(252, 120, 60) !important;
}

.color-96-40 {
  background: rgb(252, 122, 60) !important;
}

.color-96-41 {
  background: rgb(252, 125, 60) !important;
}

.color-96-42 {
  background: rgb(252, 127, 60) !important;
}

.color-96-43 {
  background: rgb(252, 129, 60) !important;
}

.color-96-44 {
  background: rgb(252, 132, 60) !important;
}

.color-96-45 {
  background: rgb(252, 134, 60) !important;
}

.color-96-46 {
  background: rgb(252, 136, 60) !important;
}

.color-96-47 {
  background: rgb(252, 139, 60) !important;
}

.color-96-48 {
  background: rgb(252, 141, 60) !important;
}

.color-96-49 {
  background: rgb(252, 143, 60) !important;
}

.color-96-50 {
  background: rgb(252, 145, 60) !important;
}

.color-96-51 {
  background: rgb(252, 148, 60) !important;
}

.color-96-52 {
  background: rgb(252, 150, 60) !important;
}

.color-96-53 {
  background: rgb(252, 152, 60) !important;
}

.color-96-54 {
  background: rgb(252, 155, 60) !important;
}

.color-96-55 {
  background: rgb(252, 157, 60) !important;
}

.color-96-56 {
  background: rgb(252, 159, 60) !important;
}

.color-96-57 {
  background: rgb(252, 162, 60) !important;
}

.color-96-58 {
  background: rgb(252, 164, 60) !important;
}

.color-96-59 {
  background: rgb(252, 166, 60) !important;
}

.color-96-60 {
  background: rgb(252, 169, 60) !important;
}

.color-96-61 {
  background: rgb(252, 171, 60) !important;
}

.color-96-62 {
  background: rgb(252, 173, 60) !important;
}

.color-96-63 {
  background: rgb(252, 176, 60) !important;
}

.color-96-64 {
  background: rgb(252, 178, 60) !important;
}

.color-96-65 {
  background: rgb(252, 180, 60) !important;
}

.color-96-66 {
  background: rgb(252, 183, 60) !important;
}

.color-96-67 {
  background: rgb(252, 185, 60) !important;
}

.color-96-68 {
  background: rgb(252, 187, 60) !important;
}

.color-96-69 {
  background: rgb(252, 189, 60) !important;
}

.color-96-70 {
  background: rgb(252, 192, 60) !important;
}

.color-96-71 {
  background: rgb(252, 194, 60) !important;
}

.color-96-72 {
  background: rgb(252, 196, 60) !important;
}

.color-96-73 {
  background: rgb(252, 199, 60) !important;
}

.color-96-74 {
  background: rgb(252, 201, 60) !important;
}

.color-96-75 {
  background: rgb(252, 203, 60) !important;
}

.color-96-76 {
  background: rgb(252, 206, 60) !important;
}

.color-96-77 {
  background: rgb(252, 208, 60) !important;
}

.color-96-78 {
  background: rgb(252, 210, 60) !important;
}

.color-96-79 {
  background: rgb(252, 213, 60) !important;
}

.color-96-80 {
  background: rgb(252, 215, 60) !important;
}

.color-96-81 {
  background: rgb(252, 217, 60) !important;
}

.color-96-82 {
  background: rgb(252, 220, 60) !important;
}

.color-96-83 {
  background: rgb(252, 222, 60) !important;
}

.color-96-84 {
  background: rgb(252, 224, 60) !important;
}

.color-96-85 {
  background: rgb(252, 227, 60) !important;
}

.color-96-86 {
  background: rgb(252, 229, 60) !important;
}

.color-96-87 {
  background: rgb(252, 231, 60) !important;
}

.color-96-88 {
  background: rgb(252, 233, 60) !important;
}

.color-96-89 {
  background: rgb(252, 236, 60) !important;
}

.color-96-90 {
  background: rgb(252, 238, 60) !important;
}

.color-96-91 {
  background: rgb(252, 240, 60) !important;
}

.color-96-92 {
  background: rgb(252, 243, 60) !important;
}

.color-96-93 {
  background: rgb(252, 245, 60) !important;
}

.color-96-94 {
  background: rgb(252, 247, 60) !important;
}

.color-96-95 {
  background: rgb(252, 250, 60) !important;
}

.color-97-0 {
  background: rgb(140, 0, 28) !important;
}

.color-97-1 {
  background: rgb(252, 32, 60) !important;
}

.color-97-2 {
  background: rgb(252, 34, 60) !important;
}

.color-97-3 {
  background: rgb(252, 37, 60) !important;
}

.color-97-4 {
  background: rgb(252, 39, 60) !important;
}

.color-97-5 {
  background: rgb(252, 41, 60) !important;
}

.color-97-6 {
  background: rgb(252, 43, 60) !important;
}

.color-97-7 {
  background: rgb(252, 46, 60) !important;
}

.color-97-8 {
  background: rgb(252, 48, 60) !important;
}

.color-97-9 {
  background: rgb(252, 50, 60) !important;
}

.color-97-10 {
  background: rgb(252, 53, 60) !important;
}

.color-97-11 {
  background: rgb(252, 55, 60) !important;
}

.color-97-12 {
  background: rgb(252, 57, 60) !important;
}

.color-97-13 {
  background: rgb(252, 60, 60) !important;
}

.color-97-14 {
  background: rgb(252, 62, 60) !important;
}

.color-97-15 {
  background: rgb(252, 64, 60) !important;
}

.color-97-16 {
  background: rgb(252, 66, 60) !important;
}

.color-97-17 {
  background: rgb(252, 69, 60) !important;
}

.color-97-18 {
  background: rgb(252, 71, 60) !important;
}

.color-97-19 {
  background: rgb(252, 73, 60) !important;
}

.color-97-20 {
  background: rgb(252, 76, 60) !important;
}

.color-97-21 {
  background: rgb(252, 78, 60) !important;
}

.color-97-22 {
  background: rgb(252, 80, 60) !important;
}

.color-97-23 {
  background: rgb(252, 82, 60) !important;
}

.color-97-24 {
  background: rgb(252, 85, 60) !important;
}

.color-97-25 {
  background: rgb(252, 87, 60) !important;
}

.color-97-26 {
  background: rgb(252, 89, 60) !important;
}

.color-97-27 {
  background: rgb(252, 92, 60) !important;
}

.color-97-28 {
  background: rgb(252, 94, 60) !important;
}

.color-97-29 {
  background: rgb(252, 96, 60) !important;
}

.color-97-30 {
  background: rgb(252, 98, 60) !important;
}

.color-97-31 {
  background: rgb(252, 101, 60) !important;
}

.color-97-32 {
  background: rgb(252, 103, 60) !important;
}

.color-97-33 {
  background: rgb(252, 105, 60) !important;
}

.color-97-34 {
  background: rgb(252, 108, 60) !important;
}

.color-97-35 {
  background: rgb(252, 110, 60) !important;
}

.color-97-36 {
  background: rgb(252, 112, 60) !important;
}

.color-97-37 {
  background: rgb(252, 115, 60) !important;
}

.color-97-38 {
  background: rgb(252, 117, 60) !important;
}

.color-97-39 {
  background: rgb(252, 119, 60) !important;
}

.color-97-40 {
  background: rgb(252, 121, 60) !important;
}

.color-97-41 {
  background: rgb(252, 124, 60) !important;
}

.color-97-42 {
  background: rgb(252, 126, 60) !important;
}

.color-97-43 {
  background: rgb(252, 128, 60) !important;
}

.color-97-44 {
  background: rgb(252, 131, 60) !important;
}

.color-97-45 {
  background: rgb(252, 133, 60) !important;
}

.color-97-46 {
  background: rgb(252, 135, 60) !important;
}

.color-97-47 {
  background: rgb(252, 137, 60) !important;
}

.color-97-48 {
  background: rgb(252, 140, 60) !important;
}

.color-97-49 {
  background: rgb(252, 142, 60) !important;
}

.color-97-50 {
  background: rgb(252, 144, 60) !important;
}

.color-97-51 {
  background: rgb(252, 147, 60) !important;
}

.color-97-52 {
  background: rgb(252, 149, 60) !important;
}

.color-97-53 {
  background: rgb(252, 151, 60) !important;
}

.color-97-54 {
  background: rgb(252, 153, 60) !important;
}

.color-97-55 {
  background: rgb(252, 156, 60) !important;
}

.color-97-56 {
  background: rgb(252, 158, 60) !important;
}

.color-97-57 {
  background: rgb(252, 160, 60) !important;
}

.color-97-58 {
  background: rgb(252, 163, 60) !important;
}

.color-97-59 {
  background: rgb(252, 165, 60) !important;
}

.color-97-60 {
  background: rgb(252, 167, 60) !important;
}

.color-97-61 {
  background: rgb(252, 170, 60) !important;
}

.color-97-62 {
  background: rgb(252, 172, 60) !important;
}

.color-97-63 {
  background: rgb(252, 174, 60) !important;
}

.color-97-64 {
  background: rgb(252, 176, 60) !important;
}

.color-97-65 {
  background: rgb(252, 179, 60) !important;
}

.color-97-66 {
  background: rgb(252, 181, 60) !important;
}

.color-97-67 {
  background: rgb(252, 183, 60) !important;
}

.color-97-68 {
  background: rgb(252, 186, 60) !important;
}

.color-97-69 {
  background: rgb(252, 188, 60) !important;
}

.color-97-70 {
  background: rgb(252, 190, 60) !important;
}

.color-97-71 {
  background: rgb(252, 192, 60) !important;
}

.color-97-72 {
  background: rgb(252, 195, 60) !important;
}

.color-97-73 {
  background: rgb(252, 197, 60) !important;
}

.color-97-74 {
  background: rgb(252, 199, 60) !important;
}

.color-97-75 {
  background: rgb(252, 202, 60) !important;
}

.color-97-76 {
  background: rgb(252, 204, 60) !important;
}

.color-97-77 {
  background: rgb(252, 206, 60) !important;
}

.color-97-78 {
  background: rgb(252, 208, 60) !important;
}

.color-97-79 {
  background: rgb(252, 211, 60) !important;
}

.color-97-80 {
  background: rgb(252, 213, 60) !important;
}

.color-97-81 {
  background: rgb(252, 215, 60) !important;
}

.color-97-82 {
  background: rgb(252, 218, 60) !important;
}

.color-97-83 {
  background: rgb(252, 220, 60) !important;
}

.color-97-84 {
  background: rgb(252, 222, 60) !important;
}

.color-97-85 {
  background: rgb(252, 225, 60) !important;
}

.color-97-86 {
  background: rgb(252, 227, 60) !important;
}

.color-97-87 {
  background: rgb(252, 229, 60) !important;
}

.color-97-88 {
  background: rgb(252, 231, 60) !important;
}

.color-97-89 {
  background: rgb(252, 234, 60) !important;
}

.color-97-90 {
  background: rgb(252, 236, 60) !important;
}

.color-97-91 {
  background: rgb(252, 238, 60) !important;
}

.color-97-92 {
  background: rgb(252, 241, 60) !important;
}

.color-97-93 {
  background: rgb(252, 243, 60) !important;
}

.color-97-94 {
  background: rgb(252, 245, 60) !important;
}

.color-97-95 {
  background: rgb(252, 247, 60) !important;
}

.color-97-96 {
  background: rgb(252, 250, 60) !important;
}

.color-98-0 {
  background: rgb(140, 0, 28) !important;
}

.color-98-1 {
  background: rgb(252, 32, 60) !important;
}

.color-98-2 {
  background: rgb(252, 34, 60) !important;
}

.color-98-3 {
  background: rgb(252, 37, 60) !important;
}

.color-98-4 {
  background: rgb(252, 39, 60) !important;
}

.color-98-5 {
  background: rgb(252, 41, 60) !important;
}

.color-98-6 {
  background: rgb(252, 43, 60) !important;
}

.color-98-7 {
  background: rgb(252, 46, 60) !important;
}

.color-98-8 {
  background: rgb(252, 48, 60) !important;
}

.color-98-9 {
  background: rgb(252, 50, 60) !important;
}

.color-98-10 {
  background: rgb(252, 52, 60) !important;
}

.color-98-11 {
  background: rgb(252, 55, 60) !important;
}

.color-98-12 {
  background: rgb(252, 57, 60) !important;
}

.color-98-13 {
  background: rgb(252, 59, 60) !important;
}

.color-98-14 {
  background: rgb(252, 61, 60) !important;
}

.color-98-15 {
  background: rgb(252, 64, 60) !important;
}

.color-98-16 {
  background: rgb(252, 66, 60) !important;
}

.color-98-17 {
  background: rgb(252, 68, 60) !important;
}

.color-98-18 {
  background: rgb(252, 71, 60) !important;
}

.color-98-19 {
  background: rgb(252, 73, 60) !important;
}

.color-98-20 {
  background: rgb(252, 75, 60) !important;
}

.color-98-21 {
  background: rgb(252, 77, 60) !important;
}

.color-98-22 {
  background: rgb(252, 80, 60) !important;
}

.color-98-23 {
  background: rgb(252, 82, 60) !important;
}

.color-98-24 {
  background: rgb(252, 84, 60) !important;
}

.color-98-25 {
  background: rgb(252, 86, 60) !important;
}

.color-98-26 {
  background: rgb(252, 89, 60) !important;
}

.color-98-27 {
  background: rgb(252, 91, 60) !important;
}

.color-98-28 {
  background: rgb(252, 93, 60) !important;
}

.color-98-29 {
  background: rgb(252, 96, 60) !important;
}

.color-98-30 {
  background: rgb(252, 98, 60) !important;
}

.color-98-31 {
  background: rgb(252, 100, 60) !important;
}

.color-98-32 {
  background: rgb(252, 102, 60) !important;
}

.color-98-33 {
  background: rgb(252, 105, 60) !important;
}

.color-98-34 {
  background: rgb(252, 107, 60) !important;
}

.color-98-35 {
  background: rgb(252, 109, 60) !important;
}

.color-98-36 {
  background: rgb(252, 111, 60) !important;
}

.color-98-37 {
  background: rgb(252, 114, 60) !important;
}

.color-98-38 {
  background: rgb(252, 116, 60) !important;
}

.color-98-39 {
  background: rgb(252, 118, 60) !important;
}

.color-98-40 {
  background: rgb(252, 120, 60) !important;
}

.color-98-41 {
  background: rgb(252, 123, 60) !important;
}

.color-98-42 {
  background: rgb(252, 125, 60) !important;
}

.color-98-43 {
  background: rgb(252, 127, 60) !important;
}

.color-98-44 {
  background: rgb(252, 130, 60) !important;
}

.color-98-45 {
  background: rgb(252, 132, 60) !important;
}

.color-98-46 {
  background: rgb(252, 134, 60) !important;
}

.color-98-47 {
  background: rgb(252, 136, 60) !important;
}

.color-98-48 {
  background: rgb(252, 139, 60) !important;
}

.color-98-49 {
  background: rgb(252, 141, 60) !important;
}

.color-98-50 {
  background: rgb(252, 143, 60) !important;
}

.color-98-51 {
  background: rgb(252, 145, 60) !important;
}

.color-98-52 {
  background: rgb(252, 148, 60) !important;
}

.color-98-53 {
  background: rgb(252, 150, 60) !important;
}

.color-98-54 {
  background: rgb(252, 152, 60) !important;
}

.color-98-55 {
  background: rgb(252, 154, 60) !important;
}

.color-98-56 {
  background: rgb(252, 157, 60) !important;
}

.color-98-57 {
  background: rgb(252, 159, 60) !important;
}

.color-98-58 {
  background: rgb(252, 161, 60) !important;
}

.color-98-59 {
  background: rgb(252, 164, 60) !important;
}

.color-98-60 {
  background: rgb(252, 166, 60) !important;
}

.color-98-61 {
  background: rgb(252, 168, 60) !important;
}

.color-98-62 {
  background: rgb(252, 170, 60) !important;
}

.color-98-63 {
  background: rgb(252, 173, 60) !important;
}

.color-98-64 {
  background: rgb(252, 175, 60) !important;
}

.color-98-65 {
  background: rgb(252, 177, 60) !important;
}

.color-98-66 {
  background: rgb(252, 179, 60) !important;
}

.color-98-67 {
  background: rgb(252, 182, 60) !important;
}

.color-98-68 {
  background: rgb(252, 184, 60) !important;
}

.color-98-69 {
  background: rgb(252, 186, 60) !important;
}

.color-98-70 {
  background: rgb(252, 188, 60) !important;
}

.color-98-71 {
  background: rgb(252, 191, 60) !important;
}

.color-98-72 {
  background: rgb(252, 193, 60) !important;
}

.color-98-73 {
  background: rgb(252, 195, 60) !important;
}

.color-98-74 {
  background: rgb(252, 198, 60) !important;
}

.color-98-75 {
  background: rgb(252, 200, 60) !important;
}

.color-98-76 {
  background: rgb(252, 202, 60) !important;
}

.color-98-77 {
  background: rgb(252, 204, 60) !important;
}

.color-98-78 {
  background: rgb(252, 207, 60) !important;
}

.color-98-79 {
  background: rgb(252, 209, 60) !important;
}

.color-98-80 {
  background: rgb(252, 211, 60) !important;
}

.color-98-81 {
  background: rgb(252, 213, 60) !important;
}

.color-98-82 {
  background: rgb(252, 216, 60) !important;
}

.color-98-83 {
  background: rgb(252, 218, 60) !important;
}

.color-98-84 {
  background: rgb(252, 220, 60) !important;
}

.color-98-85 {
  background: rgb(252, 223, 60) !important;
}

.color-98-86 {
  background: rgb(252, 225, 60) !important;
}

.color-98-87 {
  background: rgb(252, 227, 60) !important;
}

.color-98-88 {
  background: rgb(252, 229, 60) !important;
}

.color-98-89 {
  background: rgb(252, 232, 60) !important;
}

.color-98-90 {
  background: rgb(252, 234, 60) !important;
}

.color-98-91 {
  background: rgb(252, 236, 60) !important;
}

.color-98-92 {
  background: rgb(252, 238, 60) !important;
}

.color-98-93 {
  background: rgb(252, 241, 60) !important;
}

.color-98-94 {
  background: rgb(252, 243, 60) !important;
}

.color-98-95 {
  background: rgb(252, 245, 60) !important;
}

.color-98-96 {
  background: rgb(252, 247, 60) !important;
}

.color-98-97 {
  background: rgb(252, 250, 60) !important;
}

.color-99-0 {
  background: rgb(140, 0, 28) !important;
}

.color-99-1 {
  background: rgb(252, 32, 60) !important;
}

.color-99-2 {
  background: rgb(252, 34, 60) !important;
}

.color-99-3 {
  background: rgb(252, 36, 60) !important;
}

.color-99-4 {
  background: rgb(252, 39, 60) !important;
}

.color-99-5 {
  background: rgb(252, 41, 60) !important;
}

.color-99-6 {
  background: rgb(252, 43, 60) !important;
}

.color-99-7 {
  background: rgb(252, 45, 60) !important;
}

.color-99-8 {
  background: rgb(252, 48, 60) !important;
}

.color-99-9 {
  background: rgb(252, 50, 60) !important;
}

.color-99-10 {
  background: rgb(252, 52, 60) !important;
}

.color-99-11 {
  background: rgb(252, 54, 60) !important;
}

.color-99-12 {
  background: rgb(252, 57, 60) !important;
}

.color-99-13 {
  background: rgb(252, 59, 60) !important;
}

.color-99-14 {
  background: rgb(252, 61, 60) !important;
}

.color-99-15 {
  background: rgb(252, 63, 60) !important;
}

.color-99-16 {
  background: rgb(252, 66, 60) !important;
}

.color-99-17 {
  background: rgb(252, 68, 60) !important;
}

.color-99-18 {
  background: rgb(252, 70, 60) !important;
}

.color-99-19 {
  background: rgb(252, 72, 60) !important;
}

.color-99-20 {
  background: rgb(252, 75, 60) !important;
}

.color-99-21 {
  background: rgb(252, 77, 60) !important;
}

.color-99-22 {
  background: rgb(252, 79, 60) !important;
}

.color-99-23 {
  background: rgb(252, 81, 60) !important;
}

.color-99-24 {
  background: rgb(252, 84, 60) !important;
}

.color-99-25 {
  background: rgb(252, 86, 60) !important;
}

.color-99-26 {
  background: rgb(252, 88, 60) !important;
}

.color-99-27 {
  background: rgb(252, 90, 60) !important;
}

.color-99-28 {
  background: rgb(252, 93, 60) !important;
}

.color-99-29 {
  background: rgb(252, 95, 60) !important;
}

.color-99-30 {
  background: rgb(252, 97, 60) !important;
}

.color-99-31 {
  background: rgb(252, 99, 60) !important;
}

.color-99-32 {
  background: rgb(252, 102, 60) !important;
}

.color-99-33 {
  background: rgb(252, 104, 60) !important;
}

.color-99-34 {
  background: rgb(252, 106, 60) !important;
}

.color-99-35 {
  background: rgb(252, 108, 60) !important;
}

.color-99-36 {
  background: rgb(252, 111, 60) !important;
}

.color-99-37 {
  background: rgb(252, 113, 60) !important;
}

.color-99-38 {
  background: rgb(252, 115, 60) !important;
}

.color-99-39 {
  background: rgb(252, 117, 60) !important;
}

.color-99-40 {
  background: rgb(252, 120, 60) !important;
}

.color-99-41 {
  background: rgb(252, 122, 60) !important;
}

.color-99-42 {
  background: rgb(252, 124, 60) !important;
}

.color-99-43 {
  background: rgb(252, 126, 60) !important;
}

.color-99-44 {
  background: rgb(252, 129, 60) !important;
}

.color-99-45 {
  background: rgb(252, 131, 60) !important;
}

.color-99-46 {
  background: rgb(252, 133, 60) !important;
}

.color-99-47 {
  background: rgb(252, 135, 60) !important;
}

.color-99-48 {
  background: rgb(252, 138, 60) !important;
}

.color-99-49 {
  background: rgb(252, 140, 60) !important;
}

.color-99-50 {
  background: rgb(252, 142, 60) !important;
}

.color-99-51 {
  background: rgb(252, 144, 60) !important;
}

.color-99-52 {
  background: rgb(252, 146, 60) !important;
}

.color-99-53 {
  background: rgb(252, 149, 60) !important;
}

.color-99-54 {
  background: rgb(252, 151, 60) !important;
}

.color-99-55 {
  background: rgb(252, 153, 60) !important;
}

.color-99-56 {
  background: rgb(252, 155, 60) !important;
}

.color-99-57 {
  background: rgb(252, 158, 60) !important;
}

.color-99-58 {
  background: rgb(252, 160, 60) !important;
}

.color-99-59 {
  background: rgb(252, 162, 60) !important;
}

.color-99-60 {
  background: rgb(252, 164, 60) !important;
}

.color-99-61 {
  background: rgb(252, 167, 60) !important;
}

.color-99-62 {
  background: rgb(252, 169, 60) !important;
}

.color-99-63 {
  background: rgb(252, 171, 60) !important;
}

.color-99-64 {
  background: rgb(252, 173, 60) !important;
}

.color-99-65 {
  background: rgb(252, 176, 60) !important;
}

.color-99-66 {
  background: rgb(252, 178, 60) !important;
}

.color-99-67 {
  background: rgb(252, 180, 60) !important;
}

.color-99-68 {
  background: rgb(252, 182, 60) !important;
}

.color-99-69 {
  background: rgb(252, 185, 60) !important;
}

.color-99-70 {
  background: rgb(252, 187, 60) !important;
}

.color-99-71 {
  background: rgb(252, 189, 60) !important;
}

.color-99-72 {
  background: rgb(252, 191, 60) !important;
}

.color-99-73 {
  background: rgb(252, 194, 60) !important;
}

.color-99-74 {
  background: rgb(252, 196, 60) !important;
}

.color-99-75 {
  background: rgb(252, 198, 60) !important;
}

.color-99-76 {
  background: rgb(252, 200, 60) !important;
}

.color-99-77 {
  background: rgb(252, 203, 60) !important;
}

.color-99-78 {
  background: rgb(252, 205, 60) !important;
}

.color-99-79 {
  background: rgb(252, 207, 60) !important;
}

.color-99-80 {
  background: rgb(252, 209, 60) !important;
}

.color-99-81 {
  background: rgb(252, 212, 60) !important;
}

.color-99-82 {
  background: rgb(252, 214, 60) !important;
}

.color-99-83 {
  background: rgb(252, 216, 60) !important;
}

.color-99-84 {
  background: rgb(252, 218, 60) !important;
}

.color-99-85 {
  background: rgb(252, 221, 60) !important;
}

.color-99-86 {
  background: rgb(252, 223, 60) !important;
}

.color-99-87 {
  background: rgb(252, 225, 60) !important;
}

.color-99-88 {
  background: rgb(252, 227, 60) !important;
}

.color-99-89 {
  background: rgb(252, 230, 60) !important;
}

.color-99-90 {
  background: rgb(252, 232, 60) !important;
}

.color-99-91 {
  background: rgb(252, 234, 60) !important;
}

.color-99-92 {
  background: rgb(252, 236, 60) !important;
}

.color-99-93 {
  background: rgb(252, 239, 60) !important;
}

.color-99-94 {
  background: rgb(252, 241, 60) !important;
}

.color-99-95 {
  background: rgb(252, 243, 60) !important;
}

.color-99-96 {
  background: rgb(252, 245, 60) !important;
}

.color-99-97 {
  background: rgb(252, 248, 60) !important;
}

.color-99-98 {
  background: rgb(252, 250, 60) !important;
}

.color-100-0 {
  background: rgb(140, 0, 28) !important;
}

.color-100-1 {
  background: rgb(252, 32, 60) !important;
}

.color-100-2 {
  background: rgb(252, 34, 60) !important;
}

.color-100-3 {
  background: rgb(252, 36, 60) !important;
}

.color-100-4 {
  background: rgb(252, 39, 60) !important;
}

.color-100-5 {
  background: rgb(252, 41, 60) !important;
}

.color-100-6 {
  background: rgb(252, 43, 60) !important;
}

.color-100-7 {
  background: rgb(252, 45, 60) !important;
}

.color-100-8 {
  background: rgb(252, 48, 60) !important;
}

.color-100-9 {
  background: rgb(252, 50, 60) !important;
}

.color-100-10 {
  background: rgb(252, 52, 60) !important;
}

.color-100-11 {
  background: rgb(252, 54, 60) !important;
}

.color-100-12 {
  background: rgb(252, 56, 60) !important;
}

.color-100-13 {
  background: rgb(252, 59, 60) !important;
}

.color-100-14 {
  background: rgb(252, 61, 60) !important;
}

.color-100-15 {
  background: rgb(252, 63, 60) !important;
}

.color-100-16 {
  background: rgb(252, 65, 60) !important;
}

.color-100-17 {
  background: rgb(252, 68, 60) !important;
}

.color-100-18 {
  background: rgb(252, 70, 60) !important;
}

.color-100-19 {
  background: rgb(252, 72, 60) !important;
}

.color-100-20 {
  background: rgb(252, 74, 60) !important;
}

.color-100-21 {
  background: rgb(252, 76, 60) !important;
}

.color-100-22 {
  background: rgb(252, 79, 60) !important;
}

.color-100-23 {
  background: rgb(252, 81, 60) !important;
}

.color-100-24 {
  background: rgb(252, 83, 60) !important;
}

.color-100-25 {
  background: rgb(252, 85, 60) !important;
}

.color-100-26 {
  background: rgb(252, 88, 60) !important;
}

.color-100-27 {
  background: rgb(252, 90, 60) !important;
}

.color-100-28 {
  background: rgb(252, 92, 60) !important;
}

.color-100-29 {
  background: rgb(252, 94, 60) !important;
}

.color-100-30 {
  background: rgb(252, 96, 60) !important;
}

.color-100-31 {
  background: rgb(252, 99, 60) !important;
}

.color-100-32 {
  background: rgb(252, 101, 60) !important;
}

.color-100-33 {
  background: rgb(252, 103, 60) !important;
}

.color-100-34 {
  background: rgb(252, 105, 60) !important;
}

.color-100-35 {
  background: rgb(252, 108, 60) !important;
}

.color-100-36 {
  background: rgb(252, 110, 60) !important;
}

.color-100-37 {
  background: rgb(252, 112, 60) !important;
}

.color-100-38 {
  background: rgb(252, 114, 60) !important;
}

.color-100-39 {
  background: rgb(252, 116, 60) !important;
}

.color-100-40 {
  background: rgb(252, 119, 60) !important;
}

.color-100-41 {
  background: rgb(252, 121, 60) !important;
}

.color-100-42 {
  background: rgb(252, 123, 60) !important;
}

.color-100-43 {
  background: rgb(252, 125, 60) !important;
}

.color-100-44 {
  background: rgb(252, 128, 60) !important;
}

.color-100-45 {
  background: rgb(252, 130, 60) !important;
}

.color-100-46 {
  background: rgb(252, 132, 60) !important;
}

.color-100-47 {
  background: rgb(252, 134, 60) !important;
}

.color-100-48 {
  background: rgb(252, 136, 60) !important;
}

.color-100-49 {
  background: rgb(252, 139, 60) !important;
}

.color-100-50 {
  background: rgb(252, 141, 60) !important;
}

.color-100-51 {
  background: rgb(252, 143, 60) !important;
}

.color-100-52 {
  background: rgb(252, 145, 60) !important;
}

.color-100-53 {
  background: rgb(252, 148, 60) !important;
}

.color-100-54 {
  background: rgb(252, 150, 60) !important;
}

.color-100-55 {
  background: rgb(252, 152, 60) !important;
}

.color-100-56 {
  background: rgb(252, 154, 60) !important;
}

.color-100-57 {
  background: rgb(252, 156, 60) !important;
}

.color-100-58 {
  background: rgb(252, 159, 60) !important;
}

.color-100-59 {
  background: rgb(252, 161, 60) !important;
}

.color-100-60 {
  background: rgb(252, 163, 60) !important;
}

.color-100-61 {
  background: rgb(252, 165, 60) !important;
}

.color-100-62 {
  background: rgb(252, 168, 60) !important;
}

.color-100-63 {
  background: rgb(252, 170, 60) !important;
}

.color-100-64 {
  background: rgb(252, 172, 60) !important;
}

.color-100-65 {
  background: rgb(252, 174, 60) !important;
}

.color-100-66 {
  background: rgb(252, 176, 60) !important;
}

.color-100-67 {
  background: rgb(252, 179, 60) !important;
}

.color-100-68 {
  background: rgb(252, 181, 60) !important;
}

.color-100-69 {
  background: rgb(252, 183, 60) !important;
}

.color-100-70 {
  background: rgb(252, 185, 60) !important;
}

.color-100-71 {
  background: rgb(252, 188, 60) !important;
}

.color-100-72 {
  background: rgb(252, 190, 60) !important;
}

.color-100-73 {
  background: rgb(252, 192, 60) !important;
}

.color-100-74 {
  background: rgb(252, 194, 60) !important;
}

.color-100-75 {
  background: rgb(252, 196, 60) !important;
}

.color-100-76 {
  background: rgb(252, 199, 60) !important;
}

.color-100-77 {
  background: rgb(252, 201, 60) !important;
}

.color-100-78 {
  background: rgb(252, 203, 60) !important;
}

.color-100-79 {
  background: rgb(252, 205, 60) !important;
}

.color-100-80 {
  background: rgb(252, 208, 60) !important;
}

.color-100-81 {
  background: rgb(252, 210, 60) !important;
}

.color-100-82 {
  background: rgb(252, 212, 60) !important;
}

.color-100-83 {
  background: rgb(252, 214, 60) !important;
}

.color-100-84 {
  background: rgb(252, 216, 60) !important;
}

.color-100-85 {
  background: rgb(252, 219, 60) !important;
}

.color-100-86 {
  background: rgb(252, 221, 60) !important;
}

.color-100-87 {
  background: rgb(252, 223, 60) !important;
}

.color-100-88 {
  background: rgb(252, 225, 60) !important;
}

.color-100-89 {
  background: rgb(252, 228, 60) !important;
}

.color-100-90 {
  background: rgb(252, 230, 60) !important;
}

.color-100-91 {
  background: rgb(252, 232, 60) !important;
}

.color-100-92 {
  background: rgb(252, 234, 60) !important;
}

.color-100-93 {
  background: rgb(252, 236, 60) !important;
}

.color-100-94 {
  background: rgb(252, 239, 60) !important;
}

.color-100-95 {
  background: rgb(252, 241, 60) !important;
}

.color-100-96 {
  background: rgb(252, 243, 60) !important;
}

.color-100-97 {
  background: rgb(252, 245, 60) !important;
}

.color-100-98 {
  background: rgb(252, 248, 60) !important;
}

.color-100-99 {
  background: rgb(252, 250, 60) !important;
}