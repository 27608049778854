.span-status, .status {
  &.lead-outprocess {
    color: #999;
  }
  &.lead-assignedTransfer {
    color: #cd39ff;
  }
  &.lead-dms {
    color: #41bbcb;
  }
  &.lead-received {
    color: #009794 !important;
  }
  &.lead-not-in-process {
    color: #00aba9 !important;
  }
  &.lead-active {
    color: #6a00ff !important;
  }
  &.lead-follow-dms {
    color: #41bbcb !important;
  }
  &.lead-assigned {
    color: #aa00ff;
  }
  &.lead-recontact {
    color: rgba(243, 159, 175, 1);
  }
  &.lead-follow {
    color: #F39FAF;
  }
  &.lead-confirmed-appointment {
    color: #158BDB;
  }
  &.lead-offers-sent {
    color: rgba(243, 159, 175, 1);
  }
  &.lead-waiting-result {
    color: #fa6800;
  }
  &.lead-closed {
    color: #499411;
  }
  &.lead-sale {
    color: #a4c400;
  }
  &.lead-won {
    color: rgba(28, 134, 25, 1);
  }
  &.lead-mandate {
    color: rgba(70, 181, 116, 1);
  }
  &.lead-waiting-validation {
    color: #e3c800;
  }
  &.lead-abandoned {
    color: rgb(119,119,119);
  }
  &.notify-later {
    color: #e3c800;
  }
  &.lead-lost {
    color: rgba(241, 156, 0, 1);
  }
  &.lead-anonymized {
    color: #f4b90c;
  }
  &.lead-operator {
    color: #bbb;
  }
  &.lead-other {
    color: #c1c1c1;
  }
}
.seller-lead-to-treat-section .dismiss-valid {
  background-color: #f4b90c;
  color:white;
}

// Paramètres de base
$first-color: rgb(140, 0, 28); // Couleur fixe
$start-color: rgb(252, 32, 60); // Couleur de départ pour la transition
$end-color: rgb(252, 252, 60); // Couleur de fin pour la transition

// Fonction pour interpoler la couleur
@function interpolate-color($start-color, $end-color, $ratio) {
  @return rgb(
                  red($start-color) + $ratio * (red($end-color) - red($start-color)),
                  green($start-color) + $ratio * (green($end-color) - green($start-color)),
                  blue($start-color) + $ratio * (blue($end-color) - blue($start-color))
  );
}

// Fonction pour générer la couleur basée sur l'index et le total
@function generate-color($index, $total) {
  @if $index == 0 {
    @return $first-color;
  } @else {
    $ratio: calc(($index - 1) / ($total - 1)); // Ratio entre 0 et 1
    @return interpolate-color($start-color, $end-color, $ratio);
  }
}

// Nombre maximum de couleurs pour la prévision
$max-colors: 100; // Ajustez en fonction du besoin

// Générer les classes CSS
@for $total from 1 through $max-colors {
  @for $i from 0 through $total - 1 {
    .color-#{$total}-#{$i} {
      background: generate-color($i, $total)!important;
    }
  }
}

