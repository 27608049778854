@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?huj021");
  src: url("../fonts/icomoon.eot?#iefixhuj021") format("embedded-opentype"), url("../fonts/icomoon.woff?huj021") format("woff"), url("../fonts/icomoon.ttf?huj021") format("truetype"), url("../fonts/icomoon.svg?huj021#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-], [class*=" icon-"] {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=icon-]:before, [class*=" icon-"]:before {
  font-family: "icomoon";
  text-align: center;
  background-repeat: no-repeat;
}

.icon-home:before {
  content: "\e600";
}

.icon-pencil:before {
  content: "\e601";
}

.icon-bullhorn:before {
  content: "\e602";
}

.icon-file:before {
  content: "\e64c";
}

.icon-profile:before {
  content: "\e64d";
}

.icon-file2:before {
  content: "\e64e";
}

.icon-folder:before {
  content: "\e603";
}

.icon-tag:before {
  content: "\e64f";
}

.icon-tags:before {
  content: "\e650";
}

.icon-cart:before {
  content: "\e604";
}

.icon-support:before {
  content: "\e605";
}

.icon-phone:before {
  content: "\e606";
}

.icon-phone-hang-up:before {
  content: "\e607";
}

.icon-address-book:before {
  content: "\e608";
}

.icon-envelope:before {
  content: "\e609";
}

.icon-pushpin:before {
  content: "\e651";
}

.icon-location:before {
  content: "\e652";
}

.icon-location2:before {
  content: "\e60a";
}

.icon-map:before {
  content: "\e60b";
}

.icon-bell:before {
  content: "\e60c";
}

.icon-print:before {
  content: "\e60d";
}

.icon-keyboard:before {
  content: "\e656";
}

.icon-cabinet:before {
  content: "\e653";
}

.icon-drawer:before {
  content: "\e654";
}

.icon-drawer2:before {
  content: "\e655";
}

.icon-download:before {
  content: "\e60e";
}

.icon-upload:before {
  content: "\e60f";
}

.icon-undo:before {
  content: "\e610";
}

.icon-redo:before {
  content: "\e611";
}

.icon-forward:before {
  content: "\e612";
}

.icon-reply:before {
  content: "\e613";
}

.icon-bubbles:before {
  content: "\e614";
}

.icon-user:before {
  content: "\e615";
}

.icon-user2:before {
  content: "\e616";
}

.icon-user3:before {
  content: "\e617";
}

.icon-quotes-left:before {
  content: "\e618";
}

.icon-spinner:before {
  content: "\e619";
}

.icon-search:before {
  content: "\e657";
}

.icon-key:before {
  content: "\e61a";
}

.icon-lock:before {
  content: "\e61b";
}

.icon-wrench:before {
  content: "\e61c";
}

.icon-cog:before {
  content: "\e61d";
}

.icon-cogs:before {
  content: "\e61e";
}

.icon-hammer:before {
  content: "\e61f";
}

.icon-pie:before {
  content: "\e620";
}

.icon-bars:before {
  content: "\e621";
}

.icon-trophy:before {
  content: "\e622";
}

.icon-rocket:before {
  content: "\e623";
}

.icon-dashboard:before {
  content: "\e624";
}

.icon-fire:before {
  content: "\e625";
}

.icon-remove:before {
  content: "\e626";
}

.icon-briefcase:before {
  content: "\e627";
}

.icon-airplane:before {
  content: "\e628";
}

.icon-truck:before {
  content: "\e629";
}

.icon-target:before {
  content: "\e62a";
}

.icon-shield:before {
  content: "\e62b";
}

.icon-lightning:before {
  content: "\e62c";
}

.icon-switch:before {
  content: "\e62d";
}

.icon-powercord:before {
  content: "\e62e";
}

.icon-menu:before {
  content: "\e62f";
}

.icon-download2:before {
  content: "\e630";
}

.icon-outprocess:before {
  content: "\e635";
}

.icon-upload2:before {
  content: "\e631";
}

.icon-flag:before {
  content: "\e632";
}

.icon-eye:before {
  content: "\e633";
}

.icon-eye-blocked:before {
  content: "\e634";
}

.icon-bookmark:before {
  content: "\e635";
}

.icon-star:before {
  content: "\e636";
}

.icon-heart:before {
  content: "\e637";
}

.icon-thumbs-up:before {
  content: "\e638";
}

.icon-thumbs-up2:before {
  content: "\e639";
}

.icon-info:before {
  content: "\e63a";
}

.icon-blocked:before {
  content: "\e63b";
}

.icon-cancel-circle:before {
  content: "\e63c";
}

.icon-checkmark-circle:before {
  content: "\e63d";
}

.icon-spam:before {
  content: "\e63e";
}

.icon-close:before {
  content: "\e63f";
}

.icon-checkmark:before {
  content: "\e640";
}

.icon-play:before {
  content: "\e641";
}

.icon-backward:before {
  content: "\e642";
}

.icon-forward2:before {
  content: "\e643";
}

.icon-volume-high:before {
  content: "\e644";
}

.icon-loop:before {
  content: "\e645";
}

.icon-arrow-up:before {
  content: "\e646";
}

.icon-arrow-right:before {
  content: "\e647";
}

.icon-arrow-down:before {
  content: "\e648";
}

.icon-arrow-left:before {
  content: "\e649";
}

.icon-scissors:before {
  content: "\e64a";
}

.icon-filter:before {
  content: "\e64b";
}

.icon-clock:before {
  content: "\e658";
}

.icon-bubble:before {
  content: "\e659";
}

.icon-plus:before {
  content: "\e65a";
}

.icon-coin:before {
  content: "\e65c";
}

.icon-calendar:before {
  content: "\e65b";
}

.icon-gift:before {
  content: "\e65d";
}

.icon-link:before {
  content: "\e9cb";
}

.icon-arobase:before {
  content: "@";
  font-weight: bold;
}

.icon-transfer2:before {
  content: "\e606\e647";
  font-size: 0.5em;
}

.icon-sun:before {
  content: "\e9d4";
}

.data.dashboard .icon-transfer2:before,
.data .header .icon-transfer2:before {
  font-size: 1.3em;
  left: 5px;
}