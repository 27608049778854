@import "colors";
.lost-validation {

  text-align: left;

  .section {
    margin-bottom: 40px;

    h3 {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }

    &.leads {
      max-width: 1320px;
      margin: auto
    }
  }


  #contentFilter {
    background: $lightGreyBackground!important;

    width: 980px;
    margin: 40px auto;
  }
  .pos, .user {
    width: 40%;
  }
  .pos {
    .select2-container {
      text-align: left;
    }
  }

  span:not(.phone-link), .buttons {
    .button-icon {
      margin-right: 0;
      width: 30px;
      height: 30px;
      border: 0;
      cursor: pointer;
    }
  }

  .filters .select2-container-multi .select2-choices {
    border: none;
    .select2-search-field input {
      height: 26px;
      line-height: 26px;
    }
  }
  .icon-cancel-circle, .icon-checkmark-circle {
    position: absolute;
    right: 30px;
    top: 5px;
    display: block;
    font-size: 15px;
  }
  .icon-cancel-circle {
    color: red;
  }
  .icon-checkmark-circle {
    color: green;
  }
}
