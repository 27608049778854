@import "../colors";
/*
Version: 3.5.1 Timestamp: Tue Jul 22 18:58:56 EDT 2014
*/
#leadInfos .select2-container{
  border: 1px solid #41BBCB;
  margin-bottom: 15px;
}
.select2-container {
  margin: 0;
  position: relative;
  display: inline-block;
  /* inline-block for ie7 */
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  width: 100% !important;
}

.filter .select2-container-multi .select2-choices .select2-search-field {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index:1;
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
  /*
    Force border-box so that % widths fit the parent
    container without overlap because of margin/padding.
    More Info : http://www.quirksmode.org/css/box.html
  */
  -webkit-box-sizing: border-box; /* webkit */
  -moz-box-sizing: border-box; /* firefox */
  box-sizing: border-box; /* css3 */
}

.select2-container .select2-choice {
  display: block;
  height: 26px;
  padding: 0 0 0 8px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  line-height: 26px;
  color: #444;
  text-decoration: none;
  border-radius: 4px;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.5, #fff));
      background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 50%);
      background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 50%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#ffffff', endColorstr = '#eeeeee', GradientType = 0);
      background-image: linear-gradient(to top, #eee 0%, #fff 50%);*/
}

.filter .select2-container .select2-choice {
  position:relative;
  height: 58px;
  line-height: 58px ;
}

.filter .select2-container-multi .select2-choices .select2-search-field input {
  height:58px;
  margin:0;
}
.filter .select2-container-multi .select2-choices .select2-search-field input::-webkit-input-placeholder,
.filter .select2-container-multi .select2-choices .select2-search-field input::placeholder,
.filter .select2-container-multi .select2-choices .select2-search-field input::placeholder,
.filter .select2-container-multi .select2-choices .select2-search-field input:-moz-placeholder,
.filter .select2-container-multi .select2-choices .select2-search-field input::-moz-placeholder,
.filter .select2-container-multi .select2-choices .select2-search-field input:-ms-input-placeholder,
.filter .select2-container-multi .select2-choices .select2-search-field input::-ms-input-placeholder{
  color:red;
}

.filter .select2-container .select2-choice .select2-arrow b {
  margin-top: 23.5px;
}

.filter .select2-container-multi .select2-choices {
  border:none;
  background-image:none;
  min-height: 58px;
  max-height: 58px;
  overflow-y: auto;
}
.filter .select2-container .select2-choice .select2-arrow {
  margin-right:6px;
}

html[dir="rtl"] .select2-container .select2-choice {
  padding: 0 8px 0 0;
}

.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: #aaa;

  border-radius: 0 0 4px 4px;

  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.9, #fff));
  background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 90%);
  background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
  background-image: linear-gradient(to bottom, #eee 0%, #fff 90%);
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
  display: inline-block;
  color: black;
  font-size: 12px;
  vertical-align: top;
  min-width:90%;
  max-width: 92%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select2-container .select2-choice.select2-default > .select2-chosen {
  color: rgb(205, 205, 205) !important
}
html[dir="rtl"] .select2-container .select2-choice > .select2-chosen {
  margin-left: 26px;
  margin-right: 0;
}

.select2-container .select2-choice .select2-arrow {
  position: absolute;
  width: 15px;
  height: 100%;
  right: 3px;
}

.select2-container .select2-choice .select2-arrow b {
  border: 8px solid transparent;
  border-top: 11px solid #000;
  display: block;
  transform: scaleX(0.8);
  margin-top: 8px;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow b {
  border-top: 11px solid #888888;
}

.select2-container.select2-dropdown-open .select2-choice .select2-arrow b {
  display:none;
}

.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block;
}

.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer;
}

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  /* styles required for IE to work */
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-drop {
  width: 100%;
  margin-top: -1px;
  position: absolute;
  z-index: 9999;
  top: 100%;

  background: #fff;
  color: #000;
  border: 1px solid #aaa;
  border-top: 0;

  border-radius: 0 0 4px 4px;

  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
}

.select2-drop.select2-drop-above {
  margin-top: 1px;
  border-top: 1px solid #aaa;
  border-bottom: 0;

  border-radius: 4px 4px 0 0;

  -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, .15);
  box-shadow: 0 -4px 5px rgba(0, 0, 0, .15);
}

.select2-drop-active {
  border-style: none;
}

.select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #5897fb;
}

.select2-drop-auto-width {
  border-top: 1px solid #aaa;
  width: auto;
}

.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}

html[dir="rtl"] .select2-container .select2-choice .select2-arrow {
  left: 0;
  right: auto;

  border-left: none;
  border-right: 1px solid #aaa;
  border-radius: 4px 0 0 4px;
}

html[dir="rtl"] .select2-container .select2-choice .select2-arrow b {
  background-position: 2px 1px;
}

.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;

  position: relative;
  z-index: 10000;

  white-space: nowrap;
}

.select2-search input {
  width: 100%;
  height: auto !important;
  min-height: 26px;
  padding: 4px 20px 4px 5px;
  margin: 0;

  outline: 0;
  font-family: sans-serif;
  font-size: 1em;

  border: 1px solid #aaa;
  border-radius: 0;

  -webkit-box-shadow: none;
  box-shadow: none;

}
.select2-search:after {
  font-family: "icoscorimmo";
  content: "\e9c2";
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 10px;
  top: 8px;
  font-weight: bold;
  color: #888;
}

html[dir="rtl"] .select2-search input {
  padding: 4px 5px 4px 20px;

  background: #fff url('select2.png') no-repeat -37px -22px;
  background: url('select2.png') no-repeat -37px -22px, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: url('select2.png') no-repeat -37px -22px, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url('select2.png') no-repeat -37px -22px, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url('select2.png') no-repeat -37px -22px, linear-gradient(to bottom, #fff 85%, #eee 99%) 0 0;
}

.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px;
}

.select2-search input.select2-active {
  background: #fff url('select2-spinner.gif') no-repeat 100%;
  background: url('select2-spinner.gif') no-repeat 100%, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: url('select2-spinner.gif') no-repeat 100%, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url('select2-spinner.gif') no-repeat 100%, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url('select2-spinner.gif') no-repeat 100%, linear-gradient(to bottom, #fff 85%, #eee 99%) 0 0;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  outline: none;
}

.select2-dropdown-open .select2-choice {
  border-bottom-color: transparent;
  -webkit-box-shadow: 0 1px 0 #fff inset;
  box-shadow: 0 1px 0 #fff inset;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  background-color: #eee;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #fff), color-stop(0.5, #eee));
  background-image: -webkit-linear-gradient(center bottom, #fff 0%, #eee 50%);
  background-image: -moz-linear-gradient(center bottom, #fff 0%, #eee 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to top, #fff 0%, #eee 50%);
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  border: 1px solid #5897fb;
  border-top-color: transparent;

  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(0.5, #eee));
  background-image: -webkit-linear-gradient(center top, #fff 0%, #eee 50%);
  background-image: -moz-linear-gradient(center top, #fff 0%, #eee 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
}

.select2-dropdown-open .select2-choice .select2-arrow {
  background: transparent;
  border-left: none;
  filter: none;
}
html[dir="rtl"] .select2-dropdown-open .select2-choice .select2-arrow {
  border-right: none;
}

.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px 1px;
}

html[dir="rtl"] .select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -16px 1px;
}

.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* results */
.select2-results {
  max-height: 200px;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html[dir="rtl"] .select2-results {
  padding: 0 4px 0 0;
  margin: 4px 0 4px 4px;
}

.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0;
}

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold;
}

.select2-results .select2-result-label {
  padding: 3px 7px 4px;
  margin: 0;
  cursor: pointer;

  min-height: 1em;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select2-results-dept-1 .select2-result-label { padding-left: 20px }
.select2-results-dept-2 .select2-result-label { padding-left: 40px }
.select2-results-dept-3 .select2-result-label { padding-left: 60px }
.select2-results-dept-4 .select2-result-label { padding-left: 80px }
.select2-results-dept-5 .select2-result-label { padding-left: 100px }
.select2-results-dept-6 .select2-result-label { padding-left: 110px }
.select2-results-dept-7 .select2-result-label { padding-left: 120px }

.select2-results .select2-highlighted {
  background: rgba(0,1,107,1);
  color: #fff;
}

.select2-results li em {
  background: #feffde;
  font-style: normal;
}

.select2-results .select2-highlighted em {
  background: transparent;
}

.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000;
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-ajax-error,
.select2-results .select2-selection-limit {
  background: $lightGreyBackground2;
  display: list-item;
  padding-left: 5px;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: $lightGreyBackground2;
  display: list-item;
  cursor: default;
}
.select2-results .select2-disabled {
  background: $lightGreyBackground2;
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
  display: none;
}

.select2-more-results.select2-active {
  background: $lightGreyBackground2 url('select2-spinner.gif') no-repeat 100%;
}

.select2-results .select2-ajax-error {
  background: rgba(255, 50, 50, .2);
}

.select2-more-results {
  background: $lightGreyBackground2;
  display: list-item;
}

/* disabled styles */

.select2-container.select2-container-disabled .select2-choice {
  background-color: $lightGreyBackground2;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: $lightGreyBackground2;
  background-image: none;
  border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}


/* multiselect */

.select2-container-multi .select2-choices {
  height: auto !important;
  margin: 0;
  padding: 0 5px 0 0;
  position: relative;
  border: 1px solid #aaa;
  cursor: text;
  overflow: hidden;
  background-color: #fff;
}

html[dir="rtl"] .select2-container-multi .select2-choices {
  padding: 0 0 0 5px;
}

.select2-locked {
  padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
  min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
  outline: none;
}
.select2-container-multi .select2-choices li {
  float: left;
  list-style: none;
}
html[dir="rtl"] .select2-container-multi .select2-choices li
{
  float: right;
}
.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 1px 0;

  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #fff url('select2-spinner.gif') no-repeat 100% !important;
}

.select2-default {
  color: rgb(205, 205, 205) !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
  background: #C7C7C7;
  padding: 5px  28px 5px 5px;
  margin: 2px;
  position: relative;
  line-height: 13px;
  color: white;
  cursor: default;
  border-radius: 2px;
  z-index: 2;
}

html[dir="rtl"] .select2-container-multi .select2-choices .select2-search-choice
{
  margin: 3px 5px 3px 0;
  padding: 3px 18px 3px 5px;
}
.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}
.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #d4d4d4;
}

.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 24px;
  top: 2px;
  font-size: 1px;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  outline: 0;
}

abbr.select2-search-choice-close:after {
  font-family: "icoscorimmo";
  font-size: 9px;
  color: rgba(0,1,107,1);
  content: "\e9f7";
  padding: 4px;
  border-radius: 10px;
  font-weight: bold;
  width: 9px;
  height: 9px;
}
abbr.select2-search-choice-close:hover:after {
  background-color: rgba(0, 1, 107, .5);
  transition: all 0.3s ease
}
abbr.select2-search-choice-close:active:after {
  box-shadow: inset 0 .3em .3em;
  background-color: rgba(0, 1, 107, .4);
  transition: all 0.3s ease
}
html[dir="rtl"] .select2-search-choice-close {
  right: auto;
  left: 3px;
}

.select2-container-multi .select2-search-choice-close {
  right: 10px;
  position: absolute;
  top: 5px;
  width: 10px;
  height: 10px;
}
.select2-container-multi .select2-search-choice-close:after {
  font-family: "icoscorimmo";
  font-size: 9px;
  color: rgba(0,1,107,1);
  content: "\e9f7";
  padding: 4px;
  border-radius: 10px;
  font-weight: bold;
  width: 9px;
  height: 9px;
}
.select2-container-multi .select2-search-choice-close:hover:after {
  background-color: rgba(0, 1, 107, .5);
  transition: all 0.3s ease
}
.select2-container-multi .select2-search-choice-close:active:after {
  box-shadow: inset 0 .3em .3em;
  background-color: rgba(0, 1, 107, .4);
  transition: all 0.3s ease
}

html[dir="rtl"] .select2-container-multi .select2-search-choice-close {
  left: auto;
  right: 2px;
}


/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: $lightGreyBackground2;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 5px;
  border: 1px solid #ddd;
  background-image: none;
  //background-color: $lightGreyBackground2;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {    display: none;
  background: none;
}
/* end multiselect */


.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}
